import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const teamChat = ({
	width, height, fill, stroke,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
			<path
				d="M14.2383 12.0996C14.2383 11.1676 14.9938 10.4121 15.9258 10.4121C16.8578 10.4121 17.6133 11.1676 17.6133 12.0996C17.6133 13.0316 16.8578 13.7871 15.9258 13.7871C14.9938 13.7871 14.2383 13.0316 14.2383 12.0996Z"
				stroke={stroke || theme.color.general.white}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeMiterlimit="10"
			/>
			<path
				d="M8.08789 12.5137C8.08789 11.5817 8.8434 10.8262 9.77539 10.8262C10.7074 10.8262 11.4629 11.5817 11.4629 12.5137C11.4629 13.4457 10.7074 14.2012 9.77539 14.2012C8.8434 14.2012 8.08789 13.4457 8.08789 12.5137Z"
				stroke={stroke || theme.color.general.white}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeMiterlimit="10"
			/>
			<path
				d="M7.0752 18.8496C7.0752 17.6294 8.07401 16.6371 9.30611 16.6371H10.6002"
				stroke={stroke || theme.color.general.white}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeMiterlimit="10"
			/>
			<path
				d="M2.0127 12.5137C2.0127 11.5817 2.76821 10.8262 3.7002 10.8262C4.63218 10.8262 5.3877 11.5817 5.3877 12.5137C5.3877 13.4457 4.63218 14.2012 3.7002 14.2012C2.76821 14.2012 2.0127 13.4457 2.0127 12.5137Z"
				stroke={stroke || theme.color.general.white}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeMiterlimit="10"
			/>
			<path
				d="M1.15039 18.8496C1.15039 17.6294 2.1492 16.6371 3.3813 16.6371H4.90039"
				stroke={stroke || theme.color.general.white}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeMiterlimit="10"
			/>
			<path
				d="M1.15039 4.56211C1.15039 2.67743 2.67822 1.14961 4.56289 1.14961H10.2254C12.1101 1.14961 13.6379 2.67743 13.6379 4.56211V7.97461L13.5701 7.90681C12.9782 7.31491 12.0742 7.166 11.3248 7.53908C10.764 7.81831 10.13 7.97491 9.45664 7.97461H4.56289C2.67822 7.97461 1.15039 6.44678 1.15039 4.56211Z"
				stroke={stroke || theme.color.general.white}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeMiterlimit="10"
			/>
			<path
				d="M12.8506 18.8496C12.8506 17.3478 14.0799 16.1163 15.5963 16.1163H16.1048C17.6213 16.1163 18.8506 17.3478 18.8506 18.8496"
				stroke={stroke || theme.color.general.white}
				strokeWidth="1.25"
				strokeLinecap="round"
				strokeMiterlimit="10"
			/>
			<path
				d="M4.33789 3.625C4.85569 3.625 5.27539 4.04474 5.27539 4.5625C5.27539 5.08026 4.85569 5.5 4.33789 5.5C3.82009 5.5 3.40039 5.08026 3.40039 4.5625C3.40039 4.04474 3.82009 3.625 4.33789 3.625Z"
				fill={fill || theme.color.general.white}
			/>
			<path
				d="M7.33789 3.625C7.85569 3.625 8.27539 4.04474 8.27539 4.5625C8.27539 5.08026 7.85569 5.5 7.33789 5.5C6.82009 5.5 6.40039 5.08026 6.40039 4.5625C6.40039 4.04474 6.82009 3.625 7.33789 3.625Z"
				fill={fill || theme.color.general.white}
			/>
			<path
				d="M10.3379 3.625C10.8557 3.625 11.2754 4.04474 11.2754 4.5625C11.2754 5.08026 10.8557 5.5 10.3379 5.5C9.82009 5.5 9.40039 5.08026 9.40039 4.5625C9.40039 4.04474 9.82009 3.625 10.3379 3.625Z"
				fill={fill || theme.color.general.white}
			/>
		</svg>
	);
};