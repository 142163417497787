export const isObject = (value: unknown) => typeof value === 'object' && !Array.isArray(value) && value !== null;

export const getObjectFromArray = (array: object[]) => (
	array.reduce((acc, curr) => ({ ...acc, ...curr }), {})
);

export const cutText = (text: string, maxLength: number): string => {
	if (text && text?.length <= maxLength) {
		return text;
	}
	return `${text.slice(0, maxLength)}...`;
};