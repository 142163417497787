import React from 'react';
import ReactTexty from '../../../lib/Texty';

import Tag from '../../../atomic-design-components/atoms/Tag/Tag';
import theme from '../../../theme';

const TagsCell = ({ cellData }: {
	cellData: { name: string }[] | string[];
}) => {
	if (!cellData?.length) {
		return null;
	}

	return (
		<ReactTexty>
			{cellData.map((tag) => {
				const name = (tag && typeof tag === 'object' && 'name' in tag && tag.name) || (typeof tag === 'string' && tag);
				if (!name) {
					return null;
				}

				return (
					<Tag
						backgroundColor={theme.color.primary.lightest}
						color={theme.color.primary.main}
						text={name}
						key={name}
						fontSize={10}
						lineHeight={13}
						fontWeight={500}
					/>
				);
			})}
		</ReactTexty>
	);
};

export default TagsCell;