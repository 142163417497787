import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import FlexRow from '../../../atomic-design-components/atoms/FlexRow/FlexRow';
import Icon from '../../../atomic-design-components/atoms/Icon/Icon';
import Input from '../../../atomic-design-components/molecules/Input/Input';
import Link from '../../../atomic-design-components/atoms/Link/Link';
import Switch from '../../../atomic-design-components/molecules/Switch/Switch';
import Tooltip from '../../../atomic-design-components/atoms/Tooltip/Tooltip';
import Typography from '../../../atomic-design-components/atoms/Typography/Typography';
import NameWithLogo from '../../../components/NameWithLogo/NameWithLogo';
import { BOTS_URLS_START, MAX_INPUT_LENGTH } from '../../../constants';
import { popupAlertShow, upsertSidebarItem } from '../../../redux-saga/actions';
import { StyledCardContent, StyledLine } from '../styled';
import { IApiSchema, IDummyData } from '../../../types';
import ModalWindow from '../../../atomic-design-components/molecules/ModalWindow/ModalWindow';
import { selectSidebarMetaData } from '../../../redux-saga/selectors';
import { IBotsChannelData } from './SwitchableCards';

export interface IInitialData {
	channels: {
		[key: string]: {
			status?: IApiSchema['ChannelStatus'];
			is_visible?: boolean;
			bot_name?: string;
			bot_url?: string;
			token?: string;
			userID?: string;
			accessToken?: string;
			access_token?: string;
			page_name?: string;
			page_id?: string;
			fb_page_id?: string;
			phone?: string;
		}
	}
	customer_id: number;
	id: number;
	install_status: string;
	is_active: boolean;
	language: IApiSchema['Language'];
	license_id: string;
	name: string;
	status: IApiSchema['ChannelStatus'];
}

const SwitchableCard = ({ cardProps }: {
	cardProps: {
		initialData: IInitialData;
		bot: string;
		disableTooltip: boolean;
		inputValue: string;
		isActive: boolean;
		isVisible: boolean;
		isAvailable: boolean;
		isWidgetActive: boolean;
		isConnected: boolean;
		currBot: IBotsChannelData;
		onChannelChange: (isChecked: boolean, isVisible: boolean) => void;
		onTokenAdd: (e: { target: { value: string; }; } | string) => void;
		onTokenRemove: (e: React.FormEvent<HTMLElement>) => void;
		getConnectedText: IDummyData;
		t: (a: string, option?: { fields: number }) => string;
	};
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		initialData,
		bot,
		disableTooltip,
		inputValue,
		isActive,
		isVisible,
		isAvailable,
		isWidgetActive,
		isConnected,
		currBot,
		onChannelChange,
		onTokenAdd,
		onTokenRemove,
		getConnectedText,
		t,
	} = cardProps;

	const theme = useTheme();
	const [tokenValue, setTokenValue] = useState('');
	const [confirmDelete, setConfirmDelete] = useState(false);
	const { error } = useSelector(selectSidebarMetaData);
	const [limitError, setLimitError] = useState('');

	const channel = initialData?.channels[bot];

	useEffect(() => {
		setTokenValue(inputValue);
	}, [inputValue, error]);

	useEffect(() => {
		if (tokenValue.length === 0) {
			setTokenValue(inputValue);
		}
		if (tokenValue.length <= (MAX_INPUT_LENGTH.chatsWidgetToken || 0)) {
			setLimitError('');
		}
	}, [tokenValue]);

	const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		setLimitError('');
		if (inputValue !== tokenValue) {
			onTokenAdd(event);
		}
	};

	const handelDelete = (event: React.FormEvent<HTMLElement>) => {
		onTokenRemove(event);
		setConfirmDelete(false);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.value.length <= (MAX_INPUT_LENGTH.chatsWidgetToken || 0)) {
			setTokenValue(event.target.value);
		} else {
			setLimitError(t('validation:limit', { fields: (MAX_INPUT_LENGTH.chatsWidgetToken || 0) }));
		}
	};

	const onMetaPageConnect = () => {
		// @ts-ignore
		window.FB.getLoginStatus(() => {});

		let scope = 'pages_messaging,pages_show_list,pages_manage_metadata,pages_read_engagement,business_management';

		if (bot === 'instagram') {
			// instagram_basic,instagram_content_publish,instagram_manage_comments,
			// instagram_manage_insights,instagram_manage_messages,instagram_manage_media

			scope = 'instagram_basic,instagram_manage_messages,business_management,pages_show_list,pages_read_engagement,pages_messaging';
		}

		if (bot === 'whatsapp') {
			scope = 'business_management,public_profile,email,whatsapp_business_management,whatsapp_business_messaging';
		}

		// @ts-ignore
		window.FB.login(
			(response: {
				authResponse: {
					accessToken: string;
					userID: string;
				};
			}) => {
				let dataToSend;
				if (response?.authResponse?.accessToken) {
					dataToSend = {
						...initialData,
						channels: {
							...initialData.channels,
							[bot]: {
								...response,
								...response.authResponse,
								accessToken: response.authResponse.accessToken,
								userID: response.authResponse.userID,
								status: 'active',
							},
						},
					};

					dispatch(
						upsertSidebarItem({
							id: initialData?.id,
							type: 'widgets',
							requestBody: dataToSend,
							navigate,
						}),
					);
				}
			},
			{
				scope,
			},
		);
	};

	const isLiveChatConnected = (bot === 'live_chat' || bot === 'callback') && isConnected;
	const isChatBotWithToken = bot === 'telegram' || bot === 'viber';
	const activeLabel = isActive ? 'On' : 'Off';
	const visibleLabel = isVisible ? 'On' : 'Off';

	const getLinkHref = () => {
		if (channel?.bot_url) {
			return channel.bot_url;
		}

		if (bot === 'instagram') {
			return `${BOTS_URLS_START.instagram}${channel?.page_name}`;
		}

		if (bot === 'whatsapp') {
			return `${BOTS_URLS_START.whatsapp}${channel?.phone}`;
		}

		return `${BOTS_URLS_START[bot]}${channel?.page_id || channel?.bot_name}`;
	};

	const handleCopyClick = (event: {
		stopPropagation: () => void;
	}) => {
		event.stopPropagation();
		navigator.clipboard.writeText(tokenValue);
		dispatch(
			popupAlertShow({
				contentKey: 'tokenCopied',
				type: 'success',
				withCloseButton: true,
				iconName: 'copy',
			}),
		);
	};

	const isShowWidgetToggle = bot !== 'live_chat' && bot !== 'callback';

	return (
		<StyledCardContent
			key={currBot.name}
			data-testid="switchableCard-test"
			className={clsx(
				isActive && 'active',
				isWidgetActive ? !isAvailable && 'disabled' : 'disabled',
			)}
		>
			<FlexRow className="switchableCardItemRow" justifyContent="space-between">
				<FlexRow style={{ overflow: 'hidden' }}>
					<NameWithLogo iconName={`${currBot.iconName}Icon`} name={t(currBot.name)} />
				</FlexRow>

				<Tooltip
					text={t(currBot.hint)}
					color={theme.color.general.darkest}
					arrowPosition="right"
					left="-100px"
					whiteSpace="pre-wrap"
					disableTooltip={disableTooltip}
				>
					<Switch
						checked={isActive}
						disabled={!isActive && !isConnected}
						onChange={() => onChannelChange(!isActive, !isActive)}
						label={isWidgetActive && isConnected ? activeLabel : ''}
						textVariant="button1"
						id={bot}
					/>
				</Tooltip>
			</FlexRow>

			{isShowWidgetToggle
			&& (
				<FlexRow className="switchableCardItemRow noFirstItemRow noTransform" justifyContent="space-between">
					<FlexRow style={{ overflow: 'hidden' }}>
						<NameWithLogo name={t('showInWidget')} />
					</FlexRow>

					<Tooltip
						text={currBot.hintShowWidget && t(currBot.hintShowWidget)}
						color={theme.color.general.darkest}
						arrowPosition="right"
						left="-100px"
						whiteSpace="pre-wrap"
						disableTooltip={disableTooltip}
					>
						<Switch
							checked={isVisible && isActive}
							disabled={!isActive}
							onChange={() => onChannelChange(isActive, !isVisible)}
							label={isWidgetActive && isConnected ? visibleLabel : ''}
							textVariant="button1"
							id={bot}
							className={isVisible ? 'visible' : 'invisible'}
						/>
					</Tooltip>
				</FlexRow>
			)}

			<StyledLine />
			{!isLiveChatConnected && currBot.middleRow && (
				<FlexRow className="switchableCardItemRow" flexWrap="wrap" gap="8px">
					{currBot.middleRow.text && !isConnected && (
						<Typography text={t(currBot.middleRow.text)} variant="body2" />
					)}

					{isChatBotWithToken && (
						<Input
							label={currBot.middleRow.name}
							placeholder={currBot.middleRow.placeholder && t(currBot.middleRow.placeholder)}
							placeholderColor={theme.color.general.dark}
							fullWidth
							value={tokenValue}
							onChange={handleChange}
							onSubmit={onTokenAdd}
							onBlur={(event: React.FocusEvent<HTMLInputElement>) => handleOnBlur(event)}
							error={limitError}
							iconRightProps={
								inputValue && isWidgetActive
									? {
										name: 'trashCan',
										width: 16,
										height: 16,
										fill: theme.color.general.black,
										onClick: () => setConfirmDelete(true),
									}
									: {}
							}
							iconLeftProps={
								inputValue && isWidgetActive
									? {
										name: 'copy',
										width: 16,
										height: 16,
										fill: theme.color.general.black,
										onClick: handleCopyClick,
									}
									: {}
							}
						/>
					)}
				</FlexRow>
			)}
			{currBot.bottomRow?.text && (
				<FlexRow className="switchableCardItemRow">
					<Icon name="infoCircle" stroke={theme.color.general.dark} margin="0 6px 0 0" />
					<Typography text={t(currBot.bottomRow.text)} variant="body3" color={theme.color.general.dark} />
				</FlexRow>
			)}
			<FlexRow
				className={clsx(
					bot,
					'switchableCardItemRow',
					isWidgetActive && !isConnected && !isChatBotWithToken && 'connectButton',
				)}
				style={{
					cursor: bot === 'live_chat' || bot === 'callback' ? 'default' : 'pointer',
				}}
				justifyContent="center"
				onClick={isWidgetActive
					&& !isConnected
					&& !isChatBotWithToken
					&& (bot !== 'live_chat' && bot !== 'callback')
					? onMetaPageConnect
					: undefined}
			>
				{isConnected ? (
					<>
						{channel?.bot_url || channel?.page_id || channel?.bot_name ? (
							<Link
								isOuterLink
								href={getLinkHref()}
								noStyles
							>
								<Typography
									text={getConnectedText(bot, initialData, isActive, t).bot}
									variant="button1"
									className={clsx(isActive && 'active')}
									color={
										initialData?.channels[bot]?.status === 'active'
											? theme.color.secondary.main
											: theme.color.general.light
									}
									margin="0 5px 0 0"
								/>
							</Link>
						) : null}

						<Typography
							text={getConnectedText(bot, initialData, isActive, t).text}
							variant="button1"
							className={clsx(isActive && 'active')}
							color={
								initialData?.channels[bot]?.status === 'active'
									? theme.color.general.darker
									: theme.color.general.light
							}
						/>
					</>
				) : (
					<Typography
						text={t(
							isChatBotWithToken || bot === 'live_chat' || bot === 'callback' ? 'notConnected' : 'connect',
						)}
						variant="button1"
						color={
							isChatBotWithToken || bot === 'live_chat' || bot === 'callback'
								? theme.color.general.darker
								: theme.color.secondary.main
						}
						margin="0 auto"
					/>
				)}
				<ModalWindow
					onSubmit={(event?: React.FormEvent<HTMLElement>) => handelDelete(event!)}
					title={t('deleteTokenDescription')}
					isOpen={confirmDelete}
					onClose={() => setConfirmDelete(false)}
				/>
			</FlexRow>
		</StyledCardContent>
	);
};

export default SwitchableCard;