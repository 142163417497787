import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { selectUserSubscriptionDetails } from '../redux-saga/selectors';
import { getDataObjectFromLocalStorage } from '../utils/getDataObjectFromLocalStorage';
import { LocalStorageKeys } from '../types';

export const useIsSubscriptionEnds = () => {
	const userSubscriptionDetails = useSelector(selectUserSubscriptionDetails);
	const subscriptionEndsAt = (userSubscriptionDetails.payment_type === 'manual' && userSubscriptionDetails.subscription_end_at)
    || userSubscriptionDetails.wfp_subscription_end_at
    || null;

	const [subscriptionEnds, setSubscriptionEnds] = useState<{
		days: number | null,
		subscriptionEndsAlertClosed: boolean,
		isSubscriptionEnds: boolean,
	}>({
		days: null,
		subscriptionEndsAlertClosed: false,
		isSubscriptionEnds: false,
	});

	useEffect(() => {
		const currentDate = dayjs().format('YYYY-MM-DD');
		const endsAtDate = dayjs(subscriptionEndsAt).format('YYYY-MM-DD');
		const diffDays = dayjs(endsAtDate).diff(currentDate, 'day');
		const days = Number.isNaN(diffDays) ? null : diffDays;
		const isSubscriptionEnds = (days !== null && days >= 0 && days < 6) || false;
		const subscriptionEndsAlertClosed = getDataObjectFromLocalStorage(
			LocalStorageKeys.subscriptionEndsAlertClosed,
		);

		setSubscriptionEnds({ days, subscriptionEndsAlertClosed, isSubscriptionEnds });
	}, [subscriptionEndsAt]);

	return subscriptionEnds;
};