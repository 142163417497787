import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const chevronDown = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 6} height={height || 4} viewBox="0 0 6 4" fill="none">
			<path
				d="M3.70569 3.29679C3.31516 3.68731 2.682 3.68731 2.29147 3.29679L0.498937 1.50425C0.222736 1.22805 0.222816 0.780214 0.499114 0.504111C0.77531 0.228111 1.22294 0.22823 1.49899 0.504376L2.99858 2.0045L4.49817 0.504376C4.77422 0.22823 5.22185 0.228111 5.49805 0.504111C5.77435 0.780215 5.77442 1.22805 5.49822 1.50425L3.70569 3.29679Z"
				fill={fill || theme.color.general.black}
			/>
		</svg>
	);
};