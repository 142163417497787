import clsx from 'clsx';
import merge from 'deepmerge';
import dot from 'dot-object';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from '../../atomic-design-components/molecules/Button/Button';
import { DEFAULT_VALUES_DATA } from '../../config/defaultValues';
import { usePrevious } from '../../hooks/useReactRedux';
import { selectSidebarMetaData } from '../../redux-saga/selectors';
import validate from '../../utils/formValidation';
import { isObjectEmpty } from '../../utils/isObjectEmpty';
import FormBlock from './FormBlock';
import { StyledForm, StyledFormButtons } from './styled';
import useForm from './useForm';
import { IDummyData } from '../../types';
import { CUSTOM_EVENT__CLOSE_DRAWER } from '../../constants';
import LoaderCircular from '../../atomic-design-components/atoms/LoaderCircular/LoaderCircular';
import theme from '../../theme';

const Form = ({
	id,
	buttonsAreSticky,
	cancelButtonText,
	children,
	className,
	customOnChange,
	expansionPanelProps,
	fields: customFields,
	formConfig,
	formErrors: customFormErrors,
	formValues: customFormValues,
	FormBlockWrapper,
	getBeforeFormChildren,
	getFormButtons,
	initialValues: initialValuesBeforeCheck,
	initialValuesChanged: initialValuesChangedBeforeCheck,
	inProgress: customInProgress,
	isDraft: customIsDraft,
	isReadOnly,
	isButtonShown,
	isSubmitButtonAlwaysShown,
	isSubmitOnBlur,
	isSubmitWithRequiredFields,
	labelKey,
	labelType,
	optionsData,
	onSubmit,
	resetForm,
	saveBtnIconProps,
	saveBtnTextKey,
	serverError,
	setFormErrors: customSetFormErrors,
	setIsDraft: customSetIsDraft,
	submitByEnterPressed,
	type,
	isAlwaysActiveSubmitButton,
	withoutTranslate,
	updateInputValue: customUpdateInputValue,
	updateSelectValue: customUpdateSelectValue,
	validationRules: validationRulesInitial = {},
	valuesChanged: customValuesChanged,
	setValuesChanged: customSetValuesChanged,
	withActions,
	withCancelButton = false,
	withCustomValidationRule,
	withSaveInRedux,
}: {
	id?: string;
	buttonsAreSticky?: boolean;
	cancelButtonText?: string;
	children?: React.ReactNode;
	className?: string;
	isAlwaysActiveSubmitButton?: boolean;
	formErrors?: IDummyData;
	setIsDraft?: IDummyData;
	formValues?: IDummyData;
	FormBlockWrapper?: IDummyData;
	getBeforeFormChildren?: IDummyData;
	getFormButtons?: IDummyData;
	customOnChange?: (key: string, value: string) => void;
	expansionPanelProps?: Record<string, unknown>;
	fields?: IDummyData[];
	formConfig?: IDummyData;
	initialValues?: Record<string, unknown>;
	initialValuesChanged?: Record<string, unknown>;
	inProgress?: boolean;
	isDraft?: boolean;
	isReadOnly?: boolean;
	isButtonShown?: boolean;
	isSubmitButtonAlwaysShown?: boolean;
	isSubmitWithRequiredFields?: boolean;
	isSubmitOnBlur?: boolean;
	labelKey?: string;
	labelType?: string;
	optionsData?: Record<string, unknown>;
	onSubmit?: (values: unknown, e: { preventDefault: () => void } | undefined) => void | boolean;
	resetForm?: () => void;
	saveBtnIconProps?: Record<string, unknown>;
	saveBtnTextKey?: string;
	serverError?: boolean;
	setFormErrors?: (errors: Record<string, unknown>) => void;
	submitByEnterPressed?: boolean;
	type?: string;
	updateInputValue?: (key: string, value: string) => void;
	withoutTranslate?: boolean;
	updateSelectValue?: (key: string, value: string) => void;
	validationRules?: Record<string, unknown>;
	valuesChanged?: Record<string, unknown>;
	setValuesChanged?: (valuesChanged: Record<string, unknown>) => void;
	withActions?: boolean;
	withCancelButton?: boolean;
	withCustomValidationRule?: boolean;
	withSaveInRedux?: boolean;
}) => {
	const { t } = useTranslation(['all', 'validation']);
	const navigate = useNavigate();
	const [touchedFields, setTouchedFields] = useState([]);
	const [isSubmitPressed, setIsSubmitPressed] = useState(false);

	const { inProgress: sidebarInProgress } = useSelector(selectSidebarMetaData);

	const inProgress = customInProgress === undefined ? sidebarInProgress : customInProgress;

	const withTabs = formConfig?.withTabs || [];
	const prevId = usePrevious(id);

	const initialValuesAll = merge.all([
		DEFAULT_VALUES_DATA[type || ''] || {},
		initialValuesBeforeCheck || {},
		initialValuesChangedBeforeCheck ? dot.object(initialValuesChangedBeforeCheck) : {},
	]);

	const getFields = () => {
		if (customFields) {
			return customFields;
		}
		return formConfig?.fields;
	};

	const fields: IDummyData = getFields();

	const validationRules = Object.keys(validationRulesInitial).reduce((acc, curr) => {
		let customRules;
		if (
			// @ts-ignore
			Object.values(fields)
				.flat()
				.some((field: IDummyData) => {
					const isFieldPresent =	(field.key === curr
							|| (withTabs.includes(field.key)
								&& curr.startsWith(field.key)))
						// eslint-disable-next-line no-use-before-define
						&& (!field.getIsHidden || !field.getIsHidden(formValues, optionsData));

					if (isFieldPresent && field.validationRules) {
						customRules = { ...field.validationRules };
					}
					return isFieldPresent;
				})
		) {
			return { ...acc, [curr]: validationRulesInitial[curr], ...(customRules || {}) };
		}
		return acc;
	}, {});

	const {
		formValues,
		formErrors: errors,
		setFormErrors: setErrors,
		isDraft: formIsDraft,
		setIsDraft: setFormIsDraft,
		updateInputValue,
		updateSelectValue,
		updateCheckboxValue,
		valuesChanged,
		setValuesChanged,
		// @ts-ignore
		validateField,
	} = useForm({
		customFormValues,
		customOnChange,
		initialValuesAll,
		withoutTranslate,
		initialValuesChanged: initialValuesChangedBeforeCheck,
		isSubmitPressed,
		validationRules: validationRulesInitial,
		withTabs,
		optionsData,
		// @ts-ignore
		setTouchedFields,
		// @ts-ignore
		withCustomValidationRule,
		// @ts-ignore
		withSaveInRedux,
	});
	const formValuesChanged = customValuesChanged || valuesChanged;

	const setFormValuesChanged = customSetValuesChanged || setValuesChanged;
	const formErrors = customFormErrors || errors;
	const setFormErrors = customSetFormErrors || setErrors;
	const isDraft = customIsDraft || formIsDraft;
	const setIsDraft = customSetIsDraft || setFormIsDraft;
	const updateInput = customUpdateInputValue || updateInputValue;
	const updateSelect = customUpdateSelectValue || updateSelectValue;

	const formReset = useCallback(
		(event?: {
			preventDefault: () => void;
		}) => {
			if (event !== undefined) {
				event.preventDefault();
			}
			setFormValuesChanged(initialValuesChangedBeforeCheck
				? dot.dot(initialValuesChangedBeforeCheck)
				: {});
			setIsDraft(false);
			setFormErrors({});
			if (resetForm) {
				resetForm();
			}
		},
		[setFormErrors, setIsDraft, setFormValuesChanged],
	);

	useEffect(() => {
		if ((inProgress === false && !serverError) || (prevId !== id && typeof prevId !== 'undefined')) {
			formReset();
		}
	}, [inProgress, serverError, formReset, id, prevId]);

	const formSubmit = (
		e?: { preventDefault: () => void; },
		customValuesForOnBlur?: { [key: string]: string; },
	) => {
		if (e?.preventDefault) {
			e.preventDefault();
		}

		if (isReadOnly
			|| (isSubmitOnBlur
				&& isObjectEmpty(customValuesForOnBlur || formValuesChanged))) {
			return;
		}

		if (!isSubmitPressed) {
			setIsSubmitPressed(true);
		}

		const submitErrors = validate(validationRules)(
			customValuesForOnBlur ? { ...initialValuesAll, ...customValuesForOnBlur } : formValues,
			{
				values: customValuesForOnBlur
					? { ...initialValuesAll, ...customValuesForOnBlur }
					: formValues,
			},
		);
		setFormErrors(submitErrors);

		if (isObjectEmpty(submitErrors) && onSubmit) {
			const isValid = onSubmit(customValuesForOnBlur || formValuesChanged, e);

			setTimeout(() => {
				if (
					isValid !== false
					&& type
					&& ['knowledgeBaseFolder', 'knowledgeBase', 'knowledgeBaseArticle'].includes(type)
				) {
					navigate('..');
				}
			}, 1000);
		}
	};

	const onClose = () => {
		if (type && ['knowledgeBaseFolder', 'knowledgeBase', 'knowledgeBaseArticle'].includes(type)) {
			const event = new CustomEvent(CUSTOM_EVENT__CLOSE_DRAWER);

			window.dispatchEvent(event);

			setTimeout(() => {
				navigate('..');
			}, 350);
		}
		formReset();
	};

	const getFormBlock = (fields: {
		key: string;
		validationRules?: Record<string, unknown>;
		getIsHidden?: (
			values: Record<string, unknown>,
			optionsData: Record<string, unknown>
		) => boolean;
	}[], blockKey?: string, isSecondaryBlock?: boolean) => (
		<FormBlock
			withoutTranslate={withoutTranslate}
			blockKey={blockKey || ''}
			expansionPanelProps={expansionPanelProps}
			fields={customFields || fields || []}
			formErrors={formErrors}
			// @ts-ignore
			formSubmit={isSubmitOnBlur && formSubmit}
			formValues={formValues}
			formValuesChanged={formValuesChanged}
			FormBlockWrapper={FormBlockWrapper}
			initialValues={initialValuesAll}
			isSubmitOnBlur={isSubmitOnBlur}
			isReadOnly={isReadOnly}
			isSecondaryBlock={isSecondaryBlock || false}
			key={blockKey}
			labelKey={labelKey}
			labelType={labelType}
			optionsData={optionsData}
			setTouchedFields={setTouchedFields}
			submitByEnterPressed={submitByEnterPressed}
			touchedFields={touchedFields}
			type={type || ''}
			// @ts-ignore
			updateInput={updateInput}
			updateSelect={updateSelect}
			// @ts-ignore
			updateCheckbox={updateCheckboxValue}
			validateField={validateField}
			validationRules={validationRules}
		/>
	);

	const isShowOverlay = !isButtonShown || type === 'knowledgeBaseArticle' || type === 'knowledgeBaseFolder';

	// VR: form renders multiple times
	return (
		<StyledForm className={clsx(className, withActions && isDraft && 'buttonsAreShown')}>
			<form onSubmit={formSubmit}>
				{
					isShowOverlay && inProgress && (
						<div className="overlay">
							<LoaderCircular color={theme.color.primary.main} size="32px" borderWidth="2px" loaderBg="transparent" />
						</div>
					)
				}
				{getBeforeFormChildren && getBeforeFormChildren(formValues)}
				{fields
					&& (Array.isArray(fields)
						? getFormBlock(fields)
						: Object.keys(fields).map((blockKey) => getFormBlock(fields[blockKey], blockKey)))}
				{children}
				{((!isReadOnly && !isSubmitOnBlur) || isButtonShown) && (
					<StyledFormButtons className={buttonsAreSticky ? 'sticky submitFormButtons' : 'submitFormButtons'}>
						{withActions && (isSubmitButtonAlwaysShown || isDraft) && (
							<div className={buttonsAreSticky ? 'stickyButtonsWrapper' : ''}>
								{withCancelButton && (
									<Button
										fullWidth
										variant="general"
										onClick={onClose}
										disabled={inProgress}
										className="cancelButton"
									>
										{t(cancelButtonText || 'Cancel')}
									</Button>
								)}

								<Button
									fullWidth
									type="submit"
									isLoading={inProgress && isSubmitPressed}
									disabled={
										isAlwaysActiveSubmitButton
											? false
											: (isSubmitButtonAlwaysShown && (!isDraft
												|| inProgress
												|| isSubmitWithRequiredFields))
									}
									iconLeftProps={{ size: 16, ...saveBtnIconProps }}
								>
									{t(`${saveBtnTextKey || t('save')}`)}
								</Button>
							</div>
						)}
						{!withActions && getFormButtons && getFormButtons(isDraft, formValues)}
					</StyledFormButtons>
				)}
			</form>
		</StyledForm>
	);
};

export default Form;