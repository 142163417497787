import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const mailSend = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 16} fill="none" viewBox="0 0 16 16">
			<path
				d="M6.64913 12.5623L9.14285 15.0525C9.29765 15.2073 9.48945 15.32 9.70005 15.3799C9.91064 15.4397 10.133 15.4447 10.3461 15.3945C10.5592 15.3442 10.7559 15.2403 10.9175 15.0927C11.0792 14.945 11.2004 14.7585 11.2697 14.5508L15.3623 2.26168C15.4379 2.03547 15.4489 1.79268 15.3942 1.56053C15.3395 1.32839 15.2212 1.11609 15.0525 0.947441C14.8839 0.778794 14.6716 0.660476 14.4394 0.605762C14.2073 0.551047 13.9645 0.562101 13.7383 0.637684L1.44913 4.73368C1.24211 4.80325 1.05625 4.92444 0.909098 5.08582C0.761948 5.2472 0.65837 5.44343 0.608152 5.65597C0.557934 5.86851 0.562733 6.09034 0.622096 6.30051C0.681458 6.51068 0.793425 6.70225 0.947417 6.85711L4.07999 9.99311L3.9737 13.9531L6.64913 12.5623Z"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.9955 0.893738L4.08008 9.99317"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};