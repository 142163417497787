import React from 'react';

import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import Button from '../../../../../atomic-design-components/molecules/Button/Button';
import { StyledFlexRow } from '../styled';
import { IDummyData } from '../../../../../types';

const PlacementButtons = (props: {
	name: string;
	value: string;
	updateInput: (e: IDummyData) => void;
}) => {
	const { updateInput } = props;
	const theme = useTheme();
	const { t } = useTranslation('all');
	const { name, value } = props;
	const isPositionLeft = value === 'left';

	return (
		<StyledFlexRow>
			<Button
				fullWidth
				variant="general"
				className="leftButton"
				backgroundColor={isPositionLeft ? `${theme.color.general.dark}26` : ''}
				borderColor={isPositionLeft ? theme.color.general.light : ''}
				onClick={() => {
					updateInput({ target: { name, value: 'left' } });
				}}
			>
				{t('left')}
			</Button>
			<Button
				fullWidth
				variant="general"
				className="rightButton"
				backgroundColor={!isPositionLeft ? `${theme.color.general.dark}26` : ''}
				borderColor={!isPositionLeft ? theme.color.general.light : ''}
				onClick={() => {
					updateInput({ target: { name, value: 'right' } });
				}}
			>
				{t('right')}
			</Button>
		</StyledFlexRow>
	);
};

export default PlacementButtons;