import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const durationClock = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 18} height={height || 18} viewBox="0 0 18 18" fill="none">
			<g clipPath="url(#clip0_14012_5594)">
				<path
					d="M17.1715 8.89944C17.1593 10.2963 16.8418 11.6737 16.2412 12.9349"
					stroke={fill || theme.color.general.light}
					strokeWidth="1.14"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9.00053 17.0709C7.92744 17.0709 6.86486 16.8595 5.87346 16.4489C4.88206 16.0382 3.98125 15.4363 3.22246 14.6775C1.69002 13.1451 0.829102 11.0666 0.829102 8.89944"
					stroke={fill || theme.color.general.light}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M11.5152 11.4137L9.00092 8.27087H5.22949"
					stroke={fill || theme.color.general.light}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M15.2861 16.4423C16.3276 16.4423 17.1718 15.598 17.1718 14.5566C17.1718 13.5151 16.3276 12.6709 15.2861 12.6709C14.2447 12.6709 13.4004 13.5151 13.4004 14.5566C13.4004 15.598 14.2447 16.4423 15.2861 16.4423Z"
					stroke={fill || theme.color.general.light}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M1.35742 5.88232C1.6314 5.19763 1.99951 4.55448 2.45114 3.97147"
					stroke={fill || theme.color.general.light}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M4.87695 1.80917C5.58543 1.40007 6.35301 1.10322 7.15238 0.929169"
					stroke={fill || theme.color.general.light}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M16.6435 5.88232C16.3695 5.19763 16.0014 4.55448 15.5498 3.97147"
					stroke={fill || theme.color.general.light}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M13.1241 1.80917C12.4156 1.40007 11.648 1.10322 10.8486 0.929169"
					stroke={fill || theme.color.general.light}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_14012_5594">
					<rect width="17.6" height="17.6" fill="white" transform="translate(0.200195 0.200012)" />
				</clipPath>
			</defs>
		</svg>
	);
};