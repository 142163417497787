import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const messages = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
			<path
				d="M14.7519 6.78259V5.32691C14.7519 4.47274 14.0595 3.78027 13.2053 3.78027H4.11695C3.26275 3.7803 2.57031 4.47274 2.57031 5.32691V11.5623C2.57031 12.4165 3.26275 13.109 4.11695 13.109H5.14726M10.1635 12.072L5.84732 15.7191M16.8566 15.7401L12.5244 12.0795M5.74922 7.41852L10.4172 12.2898C10.9204 12.815 11.7586 12.815 12.2618 12.2898L16.9291 7.41921M17.4303 8.44282C17.4303 7.58865 16.7379 6.89621 15.8837 6.89621H6.79531C5.94114 6.89621 5.24867 7.58865 5.24867 8.44282V14.6782C5.24867 15.5324 5.94111 16.2248 6.79531 16.2248H15.8837C16.7378 16.2248 17.4303 15.5324 17.4303 14.6782V8.44282Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
			/>
		</svg>
	);
};