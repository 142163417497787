import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const crossBubble = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
			<g clipPath="url(#clip0_10369_4618)">
				<path
					d="M10.8573 14.2856L15.4287 15.4284L14.2859 11.9999V1.71415C14.2859 1.41104 14.1654 1.12035 13.9511 0.906024C13.7368 0.691697 13.4461 0.571289 13.143 0.571289H1.71442C1.41132 0.571289 1.12063 0.691697 0.906303 0.906024C0.691975 1.12035 0.571567 1.41104 0.571567 1.71415V13.1427C0.571567 13.4458 0.691975 13.7365 0.906303 13.9508C1.12063 14.1652 1.41132 14.2856 1.71442 14.2856H10.8573Z"
					stroke={fill || theme.color.general.white}
					strokeWidth="1.14"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9.87123 9.72133L5.2998 5.1499"
					stroke={fill || theme.color.general.white}
					strokeWidth="1.14"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9.87123 5.1499L5.2998 9.72133"
					stroke={fill || theme.color.general.white}
					strokeWidth="1.14"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_10369_4618">
					<rect width="16" height="16" fill={theme.color.general.white} transform="matrix(-1 0 0 1 16 0)" />
				</clipPath>
			</defs>
		</svg>
	);
};