import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const questionCircle = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
			<path
				d="M7.99986 15.4284C12.1025 15.4284 15.4284 12.1025 15.4284 7.99986C15.4284 3.89717 12.1025 0.571289 7.99986 0.571289C3.89717 0.571289 0.571289 3.89717 0.571289 7.99986C0.571289 12.1025 3.89717 15.4284 7.99986 15.4284Z"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.28564 6.28558C6.28564 5.94652 6.38619 5.61508 6.57455 5.33317C6.76292 5.05126 7.03066 4.83153 7.3439 4.70178C7.65715 4.57203 8.00183 4.53808 8.33437 4.60423C8.66691 4.67038 8.97237 4.83365 9.21211 5.07339C9.45186 5.31314 9.61513 5.6186 9.68128 5.95114C9.74742 6.28367 9.71347 6.62836 9.58372 6.9416C9.45397 7.25485 9.23425 7.52258 8.95234 7.71095C8.67042 7.89932 8.33898 7.99986 7.99993 7.99986V9.14272"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.99972 10.8569C7.83019 10.8569 7.66448 10.9072 7.52352 11.0014C7.38256 11.0956 7.2727 11.2294 7.20782 11.3861C7.14295 11.5427 7.12598 11.715 7.15905 11.8813C7.19212 12.0476 7.27376 12.2003 7.39363 12.3202C7.5135 12.44 7.66623 12.5217 7.8325 12.5547C7.99877 12.5878 8.17111 12.5708 8.32774 12.506C8.48436 12.4411 8.61823 12.3312 8.71241 12.1903C8.80659 12.0493 8.85686 11.8836 8.85686 11.7141C8.8539 11.4877 8.76265 11.2714 8.60254 11.1113C8.44243 10.9512 8.22613 10.8599 7.99972 10.8569Z"
				fill={fill || theme.color.general.black}
			/>
		</svg>
	);
};