import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const chevronUp = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 6} height={height || 4} viewBox="0 0 6 4" fill="none">
			<path
				d="M3.70569 0.703457C3.31516 0.312932 2.682 0.312933 2.29147 0.703457L0.498937 2.49599C0.222736 2.77219 0.222816 3.22003 0.499114 3.49613C0.77531 3.77213 1.22294 3.77201 1.49899 3.49587L2.99858 1.99575L4.49817 3.49587C4.77422 3.77201 5.22185 3.77213 5.49805 3.49613C5.77435 3.22003 5.77442 2.77219 5.49822 2.49599L3.70569 0.703457Z"
				fill={fill || theme.color.general.black}
			/>
		</svg>
	);
};