import dot from 'dot-object';
import React from 'react';

import { FORM_CONFIGS } from '../../config/forms';
import { isObjectEmpty } from '../../utils/isObjectEmpty';
import FormField from './FormField';
import { IDummyData } from '../../types';

const FormFields = ({
	additionalFormValues,
	fields,
	flexDirection = 'column',
	formErrors,
	formSubmit,
	formValues,
	withoutTranslate,
	initialValues,
	isReadOnly,
	isSubmitOnBlur,
	labelKey,
	labelType,
	optionsData,
	sidebarFormId,
	submitByEnterPressed,
	tabKey,
	type,
	updateCheckbox,
	updateInput,
	updateSelect,
	validationRules,
}: {
	withoutTranslate?: boolean;
	additionalFormValues?: Record<string, unknown>;
	fields: IDummyData;
	flexDirection?: string;
	formErrors: Record<string, unknown>;
	formSubmit: (e: React.FormEvent<HTMLFormElement>, values: Record<string, unknown>) => void;
	formValues: Record<string, unknown>;
	initialValues: Record<string, unknown>;
	isReadOnly: boolean;
	isSubmitOnBlur: boolean;
	labelKey: string;
	labelType: string;
	optionsData: Record<string, unknown>;
	sidebarFormId: string;
	submitByEnterPressed: boolean;
	tabKey: string;
	type: string;
	updateCheckbox: (name: string, value: boolean) => void;
	updateInput: (name: string, value: string) => void;
	updateSelect: (name: string, value: string) => void;
	validationRules: Record<string, unknown>;
}) => {
	if (!Array.isArray(fields)) {
		return null;
	}

	return (fields.map((field) => {
		const withTabs = FORM_CONFIGS[type]?.withTabs || [];
		const isTabs = withTabs.includes(field.key);
		// @ts-ignore
		const name: string = isTabs && !withoutTranslate ? `${field.key}.${tabKey}` : field.key;

		return (
			<FormField
				formFlexDirection={flexDirection}
				field={field}
				formError={!isObjectEmpty(formErrors)
					&& (formErrors[name]
						|| dot.pick(name, formErrors))}
				formErrors={field.validationRules && !isObjectEmpty(formErrors) && formErrors}
				formSubmit={formSubmit}
				formValue={formValues && (formValues[name] || dot.pick(name, formValues))}
				initialValue={initialValues && dot.pick(name, initialValues)}
				isDisabled={
					(field.isReadOnly !== false && isReadOnly)
					|| (field.getDisabled && field.getDisabled(formValues, initialValues, optionsData))
				}
				isHidden={field.getIsHidden && field.getIsHidden(formValues, initialValues)}
				isSubmitOnBlur={isSubmitOnBlur}
				key={field.label || field.key}
				labelKey={labelKey}
				labelType={labelType}
				name={name}
				optionsData={optionsData}
				optionsFromFormValues={
					field.getOptionsFromFormValues
					&& field.getOptionsFromFormValues(
						formValues,
						optionsData,
						initialValues,
						additionalFormValues,
					)
				}
				sidebarFormId={sidebarFormId}
				submitByEnterPressed={submitByEnterPressed}
				updateCheckbox={updateCheckbox}
				updateInput={updateInput}
				updateSelect={updateSelect}
				validationRules={validationRules}
				isTabs={isTabs}
			/>
		);
	}));
};

export default FormFields;