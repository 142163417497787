import styled from 'styled-components';
import { getCssFromTheme } from '../../../utils/css';

export const StyledLabel = styled.div<{
	color?: string;
	margin?: string;
	size?: string;
	$isUppercase?: boolean;
}>`
  ${({ theme, size }) => getCssFromTheme(theme, 'label', size)};

  display: inline-flex;
  margin: ${({ margin }) => margin};
  color: ${({ color, theme }) => color || theme.color.general.black};
  text-transform: ${({ $isUppercase }) => $isUppercase && 'uppercase'};
`;