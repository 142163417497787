import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const clock = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
			<path
				d="M10.0002 18.9145C14.9234 18.9145 18.9145 14.9234 18.9145 10.0002C18.9145 5.077 14.9234 1.08594 10.0002 1.08594C5.077 1.08594 1.08594 5.077 1.08594 10.0002C1.08594 14.9234 5.077 18.9145 10.0002 18.9145Z"
				stroke={fill || theme.color.general.darker}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 6.57227V10.0008L13.4834 14.0603"
				stroke={fill || theme.color.general.darker}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};