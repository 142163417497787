import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const typeSkibble = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 36} height={height || 36} viewBox="0 0 36 36" fill="none">
			<path
				d="M18.0011 34.3439C27.0271 34.3439 34.3439 27.0271 34.3439 18.0011C34.3439 8.97515 27.0271 1.6582 18.0011 1.6582C8.97515 1.6582 1.6582 8.97515 1.6582 18.0011C1.6582 27.0271 8.97515 34.3439 18.0011 34.3439Z"
				stroke={fill || theme.color.general.black}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.154 11.7732C27.9902 12.5615 29.5634 14.9228 29.7342 17.839H26.6285C26.3678 16.57 25.5609 15.6298 24.3472 15.2922C22.2174 14.7001 21.0336 16.5895 19.9378 18.3392C19.8797 18.4329 19.8233 18.5248 19.7652 18.6167L19.3142 19.3113L19.2455 19.4174C17.8802 21.5277 15.4034 25.3542 10.8355 24.0834C7.735 23.2209 6.32395 20.8189 6.26758 17.839H9.3557C9.52658 19.1169 10.2119 20.1668 11.6405 20.5644C14.2636 21.2944 15.6394 19.1098 16.9677 16.9836L17.4433 16.1883C19.1063 13.5495 21.3612 10.718 25.1522 11.7732H25.154Z"
				fill={fill || theme.color.general.black}
			/>
		</svg>
	);
};