import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
	breakpoints: {
		xxl: 1440,
		xl: 1024,
		lg: 768,
		md: 550,
		sm: 400,
		xs: 0,
	},
	grid: {
		xxl: { colsCount: 12, rightPanelCols: 3.5, leftPanelCols: 2.5 },
		xl: { colsCount: 12, rightPanelCols: 3.5, leftPanelCols: 2.5 },
		lg: { colsCount: 12, rightPanelCols: 3.5, leftPanelCols: 2.5 },
		md: { colsCount: 6, rightPanelCols: 3.5, leftPanelCols: 2.5 },
		sm: { colsCount: 4, rightPanelCols: 4, leftPanelCols: 4 },
		xs: { colsCount: 4, rightPanelCols: 4, leftPanelCols: 4 },
	},
	color: {
		primary: {
			lightest: '#FFE9C2',
			lighter: '#FFB84D',
			light: '#FF9A00',
			main: '#FF5F00',
			dark: '#E65400',
			darker: '#BF4600',
			darkest: '#401700',
		},
		secondary: {
			lightest: '#CCE5F8',
			lighter: '#99CCF1',
			light: '#66B2E9',
			main: '#007FDB',
			dark: '#005FCB',
			darker: '#003FBC',
			darkest: '#000776',
		},
		tertiary: {
			lightest: '#AEE0D7',
			lighter: '#35B099',
			light: '#66A499',
			main: '#006351',
			dark: '#004D33',
			darker: '#003D32',
			darkest: '#00231D',
		},
		general: {
			black: '#050505',
			white: '#FFFFFF',
			lightest: '#F5F5F5',
			lighter: '#DADADA',
			light: '#9D9D9D',
			dark: '#5E5E5E',
			darker: '#2D2D2D',
			darkest: '#121212',
			lightRed: '#F9CEC2',
		},
		status: {
			success: '#12B357',
			warning: '#FFA70D',
			error: '#E83C0C',
			new: '#0D81FF',
			inactive: '#9B9B9B',
		},
		tags: {
			light: {
				orange: '#FFD7BF', navy: '#C8D0EC', purple: '#DDC8EC', till: '#C8ECE6',
			},
			dark: { purple: '#7924B3', till: '#24B399', navy: '#2443B3' },
		},
	},
	font: {
		family: {
			primary: 'Inter, sans-serif',
		},
		size: {
			typography: {
				h1: {
					'font-size': '34px',
					'line-height': '40px',
					'font-weight': 700,
				},
				h2: {
					'font-size': '28px',
					'line-height': '32px',
					'font-weight': 700,
				},
				h3: {
					'font-size': '20px',
					'line-height': '24px',
					'font-weight': 700,
				},
				h4: {
					'font-size': '16px',
					'line-height': '22px',
					'font-weight': 600,
				},
				caption1: {
					'font-size': '14px',
					'line-height': '23px',
					'font-weight': 600,
				},
				caption2: {
					'font-size': '12px',
					'line-height': '21px',
					'font-weight': 600,
				},
				caption3: {
					'font-size': '10px',
					'line-height': '13px',
					'font-weight': 500,
				},
				body1: {
					'font-size': '16px',
					'line-height': '25px',
					'font-weight': 400,
				},
				body2: {
					'font-size': '14px',
					'line-height': '23px',
					'font-weight': 400,
				},
				body3: {
					'font-size': '12px',
					'line-height': '15px',
					'font-weight': 400,
				},
				input: {
					'font-size': '14px',
					'line-height': '18px',
					'font-weight': 400,
				},
				button1: {
					'font-size': '14px',
					'line-height': '18px',
					'font-weight': 500,
				},
				button2: {
					'font-size': '12px',
					'line-height': '15px',
					'font-weight': 700,
				},
				button3: {
					'font-size': '16px',
					'line-height': '21px',
					'font-weight': 500,
				},
			},

			label: {
				big: {
					'font-size': '12px',
					'line-height': '15px',
					'font-weight': 400,
				},
				medium: {
					'font-size': '9px',
					'line-height': '12px',
					'font-weight': 500,
				},
				small: { 'font-size': '8px', 'line-height': '14px', 'font-weight': 600 },
			},
			link: {
				'font-size': '14px',
				'line-height': '18px',
				'font-weight': 500,
			},
			tag: {
				'font-size': '10px',
				'line-height': '13px',
				'font-weight': 500,
			},
		},
	},
	get components() {
		return {
			badge: {
				primary: {
					'font-size': '12px',
					'line-height': '14px',
					'font-weight': 500,
					color: this.color.general.white,
					'background-color': this.color.primary.main,
					border: `1px solid ${this.color.primary.dark}`,
				},
				general: {
					'font-size': '12px',
					'line-height': '14px',
					'font-weight': 500,
					color: this.color.general.black,
					'background-color': this.color.general.white,
				},
			},
			button: {
				primary: {
					color: this.color.general.white,
					'background-color': this.color.primary.main,
					border: `1px solid ${this.color.primary.main}`,
				},
				secondary: {
					color: this.color.general.white,
					'background-color': this.color.secondary.main,
					border: `1px solid ${this.color.secondary.main}`,
				},
				general: {
					color: this.color.general.darker,
					'background-color': this.color.general.white,
					border: `1px solid ${this.color.general.lighter}`,
				},
				disabled: {
					color: this.color.general.light,
					'background-color': this.color.general.lightest,
				},
			},
		};
	},
};

export default theme;