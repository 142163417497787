import React from 'react';
import { components } from 'react-select';
import { useTheme } from 'styled-components';

import Tag from '../../../atoms/Tag/Tag';

interface IProps {
	removeProps: object;
}

const MultiValue = (props: IProps) => {
	const theme = useTheme();

	const { removeProps } = props;

	return (
		// @ts-ignore
		<components.MultiValueContainer {...props}>
			<Tag
				outlineColor={theme.color.general.lighter}
				isOutlined
				color={theme.color.general.darker}
				removeProps={removeProps}
				withCrossIcon
			>
				{/* @ts-ignore */}
				<components.MultiValueLabel {...props} />
			</Tag>
		</components.MultiValueContainer>
	);
};

export default MultiValue;