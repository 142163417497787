import { useState } from 'react';
import { IIconProps } from '../atomic-design-components/atoms/Icon/Icon';

export const useIsPasswordShown = () => {
	const [isPasswordShown, setPasswordShown] = useState(false);

	const handlePasswordShown = () => {
		setPasswordShown(!isPasswordShown);
	};

	const type = isPasswordShown ? 'text' : 'password';
	const iconRightProps: IIconProps = {
		name: isPasswordShown ? 'eyeSignCross' : 'eyeSign',
		onClick(e) {
			e.stopPropagation();
			handlePasswordShown();
		},
	};
	return { type, iconRightProps };
};