export const TIMEZONES = [{
	country: 'CI', name: 'Africa/Abidjan', status: 'Canonical', offset: '+00:00', link: '',
}, {
	country: 'GH', name: 'Africa/Accra', status: 'Canonical', offset: '+00:00', link: '',
}, {
	country: 'ET', name: 'Africa/Addis_Ababa', status: 'Alias', offset: '+03:00', link: 'Africa/Nairobi',
}, {
	country: 'DZ', name: 'Africa/Algiers', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'ER', name: 'Africa/Asmara', status: 'Alias', offset: '+03:00', link: 'Africa/Nairobi',
}, {
	country: 'ER', name: 'Africa/Asmera', status: 'Deprecated', offset: '+03:00', link: 'Africa/Nairobi',
}, {
	country: 'ML', name: 'Africa/Bamako', status: 'Alias', offset: '+00:00', link: 'Africa/Abidjan',
}, {
	country: 'CF', name: 'Africa/Bangui', status: 'Alias', offset: '+01:00', link: 'Africa/Lagos',
}, {
	country: 'GM', name: 'Africa/Banjul', status: 'Alias', offset: '+00:00', link: 'Africa/Abidjan',
}, {
	country: 'GW', name: 'Africa/Bissau', status: 'Canonical', offset: '+00:00', link: '',
}, {
	country: 'MW', name: 'Africa/Blantyre', status: 'Alias', offset: '+02:00', link: 'Africa/Maputo',
}, {
	country: 'CG', name: 'Africa/Brazzaville', status: 'Alias', offset: '+01:00', link: 'Africa/Lagos',
}, {
	country: 'BI', name: 'Africa/Bujumbura', status: 'Alias', offset: '+02:00', link: 'Africa/Maputo',
}, {
	country: 'EG', name: 'Africa/Cairo', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'MA', name: 'Africa/Casablanca', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'ES', name: 'Africa/Ceuta', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'GN', name: 'Africa/Conakry', status: 'Alias', offset: '+00:00', link: 'Africa/Abidjan',
}, {
	country: 'SN', name: 'Africa/Dakar', status: 'Alias', offset: '+00:00', link: 'Africa/Abidjan',
}, {
	country: 'TZ', name: 'Africa/Dar_es_Salaam', status: 'Alias', offset: '+03:00', link: 'Africa/Nairobi',
}, {
	country: 'DJ', name: 'Africa/Djibouti', status: 'Alias', offset: '+03:00', link: 'Africa/Nairobi',
}, {
	country: 'CM', name: 'Africa/Douala', status: 'Alias', offset: '+01:00', link: 'Africa/Lagos',
}, {
	country: 'EH', name: 'Africa/El_Aaiun', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'SL', name: 'Africa/Freetown', status: 'Alias', offset: '+00:00', link: 'Africa/Abidjan',
}, {
	country: 'BW', name: 'Africa/Gaborone', status: 'Alias', offset: '+02:00', link: 'Africa/Maputo',
}, {
	country: 'ZW', name: 'Africa/Harare', status: 'Alias', offset: '+02:00', link: 'Africa/Maputo',
}, {
	country: 'ZA', name: 'Africa/Johannesburg', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'SS', name: 'Africa/Juba', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'UG', name: 'Africa/Kampala', status: 'Alias', offset: '+03:00', link: 'Africa/Nairobi',
}, {
	country: 'SD', name: 'Africa/Khartoum', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'RW', name: 'Africa/Kigali', status: 'Alias', offset: '+02:00', link: 'Africa/Maputo',
}, {
	country: 'CD', name: 'Africa/Kinshasa', status: 'Alias', offset: '+01:00', link: 'Africa/Lagos',
}, {
	country: 'NG', name: 'Africa/Lagos', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'GA', name: 'Africa/Libreville', status: 'Alias', offset: '+01:00', link: 'Africa/Lagos',
}, {
	country: 'TG', name: 'Africa/Lome', status: 'Alias', offset: '+00:00', link: 'Africa/Abidjan',
}, {
	country: 'AO', name: 'Africa/Luanda', status: 'Alias', offset: '+01:00', link: 'Africa/Lagos',
}, {
	country: 'CD', name: 'Africa/Lubumbashi', status: 'Alias', offset: '+02:00', link: 'Africa/Maputo',
}, {
	country: 'ZM', name: 'Africa/Lusaka', status: 'Alias', offset: '+02:00', link: 'Africa/Maputo',
}, {
	country: 'GQ', name: 'Africa/Malabo', status: 'Alias', offset: '+01:00', link: 'Africa/Lagos',
}, {
	country: 'MZ', name: 'Africa/Maputo', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'LS', name: 'Africa/Maseru', status: 'Alias', offset: '+02:00', link: 'Africa/Johannesburg',
}, {
	country: 'SZ', name: 'Africa/Mbabane', status: 'Alias', offset: '+02:00', link: 'Africa/Johannesburg',
}, {
	country: 'SO', name: 'Africa/Mogadishu', status: 'Alias', offset: '+03:00', link: 'Africa/Nairobi',
}, {
	country: 'LR', name: 'Africa/Monrovia', status: 'Canonical', offset: '+00:00', link: '',
}, {
	country: 'KE', name: 'Africa/Nairobi', status: 'Canonical', offset: '+03:00', link: '',
}, {
	country: 'TD', name: 'Africa/Ndjamena', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'NE', name: 'Africa/Niamey', status: 'Alias', offset: '+01:00', link: 'Africa/Lagos',
}, {
	country: 'MR', name: 'Africa/Nouakchott', status: 'Alias', offset: '+00:00', link: 'Africa/Abidjan',
}, {
	country: 'BF', name: 'Africa/Ouagadougou', status: 'Alias', offset: '+00:00', link: 'Africa/Abidjan',
}, {
	country: 'BJ', name: 'Africa/Porto-Novo', status: 'Alias', offset: '+01:00', link: 'Africa/Lagos',
}, {
	country: 'ST', name: 'Africa/Sao_Tome', status: 'Canonical', offset: '+00:00', link: '',
}, {
	country: 'ML', name: 'Africa/Timbuktu', status: 'Deprecated', offset: '+00:00', link: 'Africa/Abidjan',
}, {
	country: 'LY', name: 'Africa/Tripoli', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'TN', name: 'Africa/Tunis', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'NA', name: 'Africa/Windhoek', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'US', name: 'America/Adak', status: 'Canonical', offset: '−10:00', link: '',
}, {
	country: 'US', name: 'America/Anchorage', status: 'Canonical', offset: '−09:00', link: '',
}, {
	country: 'AI', name: 'America/Anguilla', status: 'Alias', offset: '−04:00', link: 'America/Port_of_Spain',
}, {
	country: 'AG', name: 'America/Antigua', status: 'Alias', offset: '−04:00', link: 'America/Port_of_Spain',
}, {
	country: 'BR', name: 'America/Araguaina', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'AR', name: 'America/Argentina/Buenos_Aires', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'AR', name: 'America/Argentina/Catamarca', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'AR', name: 'America/Argentina/ComodRivadavia', status: 'Deprecated', offset: '−03:00', link: 'America/Argentina/Catamarca',
}, {
	country: 'AR', name: 'America/Argentina/Cordoba', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'AR', name: 'America/Argentina/Jujuy', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'AR', name: 'America/Argentina/La_Rioja', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'AR', name: 'America/Argentina/Mendoza', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'AR', name: 'America/Argentina/Rio_Gallegos', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'AR', name: 'America/Argentina/Salta', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'AR', name: 'America/Argentina/San_Juan', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'AR', name: 'America/Argentina/San_Luis', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'AR', name: 'America/Argentina/Tucuman', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'AR', name: 'America/Argentina/Ushuaia', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'AW', name: 'America/Aruba', status: 'Alias', offset: '−04:00', link: 'America/Curacao',
}, {
	country: 'PY', name: 'America/Asuncion', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'CA', name: 'America/Atikokan', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'US', name: 'America/Atka', status: 'Deprecated', offset: '−10:00', link: 'America/Adak',
}, {
	country: 'BR', name: 'America/Bahia', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'MX', name: 'America/Bahia_Banderas', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'BB', name: 'America/Barbados', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'BR', name: 'America/Belem', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'BZ', name: 'America/Belize', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'CA', name: 'America/Blanc-Sablon', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'BR', name: 'America/Boa_Vista', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'CO', name: 'America/Bogota', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'US', name: 'America/Boise', status: 'Canonical', offset: '−07:00', link: '',
}, {
	country: 'AR', name: 'America/Buenos_Aires', status: 'Deprecated', offset: '−03:00', link: 'America/Argentina/Buenos_Aires',
}, {
	country: 'CA', name: 'America/Cambridge_Bay', status: 'Canonical', offset: '−07:00', link: '',
}, {
	country: 'BR', name: 'America/Campo_Grande', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'MX', name: 'America/Cancun', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'VE', name: 'America/Caracas', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'AR', name: 'America/Catamarca', status: 'Deprecated', offset: '−03:00', link: 'America/Argentina/Catamarca',
}, {
	country: 'GF', name: 'America/Cayenne', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'KY', name: 'America/Cayman', status: 'Alias', offset: '−05:00', link: 'America/Panama',
}, {
	country: 'US', name: 'America/Chicago', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'MX', name: 'America/Chihuahua', status: 'Canonical', offset: '−07:00', link: '',
}, {
	country: 'CA', name: 'America/Coral_Harbour', status: 'Deprecated', offset: '−05:00', link: 'America/Atikokan',
}, {
	country: 'AR', name: 'America/Cordoba', status: 'Deprecated', offset: '−03:00', link: 'America/Argentina/Cordoba',
}, {
	country: 'CR', name: 'America/Costa_Rica', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'CA', name: 'America/Creston', status: 'Canonical', offset: '−07:00', link: '',
}, {
	country: 'BR', name: 'America/Cuiaba', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'CW', name: 'America/Curacao', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'GL', name: 'America/Danmarkshavn', status: 'Canonical', offset: '+00:00', link: '',
}, {
	country: 'CA', name: 'America/Dawson', status: 'Canonical', offset: '−07:00', link: '',
}, {
	country: 'CA', name: 'America/Dawson_Creek', status: 'Canonical', offset: '−07:00', link: '',
}, {
	country: 'US', name: 'America/Denver', status: 'Canonical', offset: '−07:00', link: '',
}, {
	country: 'US', name: 'America/Detroit', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'DM', name: 'America/Dominica', status: 'Alias', offset: '−04:00', link: 'America/Port_of_Spain',
}, {
	country: 'CA', name: 'America/Edmonton', status: 'Canonical', offset: '−07:00', link: '',
}, {
	country: 'BR', name: 'America/Eirunepe', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'SV', name: 'America/El_Salvador', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'MX', name: 'America/Ensenada', status: 'Deprecated', offset: '−08:00', link: 'America/Tijuana',
}, {
	country: 'CA', name: 'America/Fort_Nelson', status: 'Canonical', offset: '−07:00', link: '',
}, {
	country: 'US', name: 'America/Fort_Wayne', status: 'Deprecated', offset: '−05:00', link: 'America/Indiana/Indianapolis',
}, {
	country: 'BR', name: 'America/Fortaleza', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'CA', name: 'America/Glace_Bay', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'GL', name: 'America/Godthab', status: 'Deprecated', offset: '−03:00', link: 'America/Nuuk',
}, {
	country: 'CA', name: 'America/Goose_Bay', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'TC', name: 'America/Grand_Turk', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'GD', name: 'America/Grenada', status: 'Alias', offset: '−04:00', link: 'America/Port_of_Spain',
}, {
	country: 'GP', name: 'America/Guadeloupe', status: 'Alias', offset: '−04:00', link: 'America/Port_of_Spain',
}, {
	country: 'GT', name: 'America/Guatemala', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'EC', name: 'America/Guayaquil', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'GY', name: 'America/Guyana', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'CA', name: 'America/Halifax', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'CU', name: 'America/Havana', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'MX', name: 'America/Hermosillo', status: 'Canonical', offset: '−07:00', link: '',
}, {
	country: 'US', name: 'America/Indiana/Indianapolis', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'US', name: 'America/Indiana/Knox', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'US', name: 'America/Indiana/Marengo', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'US', name: 'America/Indiana/Petersburg', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'US', name: 'America/Indiana/Tell_City', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'US', name: 'America/Indiana/Vevay', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'US', name: 'America/Indiana/Vincennes', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'US', name: 'America/Indiana/Winamac', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'US', name: 'America/Indianapolis', status: 'Deprecated', offset: '−05:00', link: 'America/Indiana/Indianapolis',
}, {
	country: 'CA', name: 'America/Inuvik', status: 'Canonical', offset: '−07:00', link: '',
}, {
	country: 'CA', name: 'America/Iqaluit', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'JM', name: 'America/Jamaica', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'AR', name: 'America/Jujuy', status: 'Deprecated', offset: '−03:00', link: 'America/Argentina/Jujuy',
}, {
	country: 'US', name: 'America/Juneau', status: 'Canonical', offset: '−09:00', link: '',
}, {
	country: 'US', name: 'America/Kentucky/Louisville', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'US', name: 'America/Kentucky/Monticello', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'US', name: 'America/Knox_IN', status: 'Deprecated', offset: '−06:00', link: 'America/Indiana/Knox',
}, {
	country: 'BQ', name: 'America/Kralendijk', status: 'Alias', offset: '−04:00', link: 'America/Curacao',
}, {
	country: 'BO', name: 'America/La_Paz', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'PE', name: 'America/Lima', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'US', name: 'America/Los_Angeles', status: 'Canonical', offset: '−08:00', link: '',
}, {
	country: 'US', name: 'America/Louisville', status: 'Deprecated', offset: '−05:00', link: 'America/Kentucky/Louisville',
}, {
	country: 'SX', name: 'America/Lower_Princes', status: 'Alias', offset: '−04:00', link: 'America/Curacao',
}, {
	country: 'BR', name: 'America/Maceio', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'NI', name: 'America/Managua', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'BR', name: 'America/Manaus', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'MF', name: 'America/Marigot', status: 'Alias', offset: '−04:00', link: 'America/Port_of_Spain',
}, {
	country: 'MQ', name: 'America/Martinique', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'MX', name: 'America/Matamoros', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'MX', name: 'America/Mazatlan', status: 'Canonical', offset: '−07:00', link: '',
}, {
	country: 'AR', name: 'America/Mendoza', status: 'Deprecated', offset: '−03:00', link: 'America/Argentina/Mendoza',
}, {
	country: 'US', name: 'America/Menominee', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'MX', name: 'America/Merida', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'US', name: 'America/Metlakatla', status: 'Canonical', offset: '−09:00', link: '',
}, {
	country: 'MX', name: 'America/Mexico_City', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'PM', name: 'America/Miquelon', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'CA', name: 'America/Moncton', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'MX', name: 'America/Monterrey', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'UY', name: 'America/Montevideo', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'CA', name: 'America/Montreal', status: 'Deprecated', offset: '−05:00', link: 'America/Toronto',
}, {
	country: 'MS', name: 'America/Montserrat', status: 'Alias', offset: '−04:00', link: 'America/Port_of_Spain',
}, {
	country: 'BS', name: 'America/Nassau', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'US', name: 'America/New_York', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'CA', name: 'America/Nipigon', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'US', name: 'America/Nome', status: 'Canonical', offset: '−09:00', link: '',
}, {
	country: 'BR', name: 'America/Noronha', status: 'Canonical', offset: '−02:00', link: '',
}, {
	country: 'US', name: 'America/North_Dakota/Beulah', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'US', name: 'America/North_Dakota/Center', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'US', name: 'America/North_Dakota/New_Salem', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'GL', name: 'America/Nuuk', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'MX', name: 'America/Ojinaga', status: 'Canonical', offset: '−07:00', link: '',
}, {
	country: 'PA', name: 'America/Panama', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'CA', name: 'America/Pangnirtung', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'SR', name: 'America/Paramaribo', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'US', name: 'America/Phoenix', status: 'Canonical', offset: '−07:00', link: '',
}, {
	country: 'HT', name: 'America/Port-au-Prince', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'TT', name: 'America/Port_of_Spain', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'BR', name: 'America/Porto_Acre', status: 'Deprecated', offset: '−05:00', link: 'America/Rio_Branco',
}, {
	country: 'BR', name: 'America/Porto_Velho', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'PR', name: 'America/Puerto_Rico', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'CL', name: 'America/Punta_Arenas', status: 'Canonical', offset: '−03:00', link: 'Magallanes Region',
}, {
	country: 'CA', name: 'America/Rainy_River', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'CA', name: 'America/Rankin_Inlet', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'BR', name: 'America/Recife', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'CA', name: 'America/Regina', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'CA', name: 'America/Resolute', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'BR', name: 'America/Rio_Branco', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'AR', name: 'America/Rosario', status: 'Deprecated', offset: '−03:00', link: 'America/Argentina/Cordoba',
}, {
	country: 'MX', name: 'America/Santa_Isabel', status: 'Deprecated', offset: '−08:00', link: 'America/Tijuana',
}, {
	country: 'BR', name: 'America/Santarem', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'CL', name: 'America/Santiago', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'DO', name: 'America/Santo_Domingo', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'BR', name: 'America/Sao_Paulo', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'GL', name: 'America/Scoresbysund', status: 'Canonical', offset: '−01:00', link: '',
}, {
	country: 'US', name: 'America/Shiprock', status: 'Deprecated', offset: '−07:00', link: 'America/Denver',
}, {
	country: 'US', name: 'America/Sitka', status: 'Canonical', offset: '−09:00', link: '',
}, {
	country: 'BL', name: 'America/St_Barthelemy', status: 'Alias', offset: '−04:00', link: 'America/Port_of_Spain',
}, {
	country: 'CA', name: 'America/St_Johns', status: 'Canonical', offset: '−03:30', link: '',
}, {
	country: 'KN', name: 'America/St_Kitts', status: 'Alias', offset: '−04:00', link: 'America/Port_of_Spain',
}, {
	country: 'LC', name: 'America/St_Lucia', status: 'Alias', offset: '−04:00', link: 'America/Port_of_Spain',
}, {
	country: 'VI', name: 'America/St_Thomas', status: 'Alias', offset: '−04:00', link: 'America/Port_of_Spain',
}, {
	country: 'VC', name: 'America/St_Vincent', status: 'Alias', offset: '−04:00', link: 'America/Port_of_Spain',
}, {
	country: 'CA', name: 'America/Swift_Current', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'HN', name: 'America/Tegucigalpa', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'GL', name: 'America/Thule', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'CA', name: 'America/Thunder_Bay', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'MX', name: 'America/Tijuana', status: 'Canonical', offset: '−08:00', link: '',
}, {
	country: 'CA', name: 'America/Toronto', status: 'Canonical', offset: '−05:00', link: '',
}, {
	country: 'VG', name: 'America/Tortola', status: 'Alias', offset: '−04:00', link: 'America/Port_of_Spain',
}, {
	country: 'CA', name: 'America/Vancouver', status: 'Canonical', offset: '−08:00', link: '',
}, {
	country: 'VI', name: 'America/Virgin', status: 'Deprecated', offset: '−04:00', link: 'America/Port_of_Spain',
}, {
	country: 'CA', name: 'America/Whitehorse', status: 'Canonical', offset: '−07:00', link: '',
}, {
	country: 'CA', name: 'America/Winnipeg', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'US', name: 'America/Yakutat', status: 'Canonical', offset: '−09:00', link: '',
}, {
	country: 'CA', name: 'America/Yellowknife', status: 'Canonical', offset: '−07:00', link: '',
}, {
	country: 'AQ', name: 'Antarctica/Casey', status: 'Canonical', offset: '+11:00', link: '',
}, {
	country: 'AQ', name: 'Antarctica/Davis', status: 'Canonical', offset: '+07:00', link: '',
}, {
	country: 'AQ', name: 'Antarctica/DumontDUrville', status: 'Canonical', offset: '+10:00', link: '',
}, {
	country: 'AU', name: 'Antarctica/Macquarie', status: 'Canonical', offset: '+10:00', link: '',
}, {
	country: 'AQ', name: 'Antarctica/Mawson', status: 'Canonical', offset: '+05:00', link: '',
}, {
	country: 'AQ', name: 'Antarctica/McMurdo', status: 'Alias', offset: '+12:00', link: 'Pacific/Auckland',
}, {
	country: 'AQ', name: 'Antarctica/Palmer', status: 'Canonical', offset: '−03:00', link: 'Chilean Antarctica Region',
}, {
	country: 'AQ', name: 'Antarctica/Rothera', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'AQ', name: 'Antarctica/South_Pole', status: 'Deprecated', offset: '+12:00', link: 'Pacific/Auckland',
}, {
	country: 'AQ', name: 'Antarctica/Syowa', status: 'Canonical', offset: '+03:00', link: '',
}, {
	country: 'AQ', name: 'Antarctica/Troll', status: 'Canonical', offset: '+00:00', link: '+01:00',
}, {
	country: 'AQ', name: 'Antarctica/Vostok', status: 'Canonical', offset: '+06:00', link: '',
}, {
	country: 'SJ', name: 'Arctic/Longyearbyen', status: 'Alias', offset: '+01:00', link: 'Europe/Oslo',
}, {
	country: 'YE', name: 'Asia/Aden', status: 'Alias', offset: '+03:00', link: 'Asia/Riyadh',
}, {
	country: 'KZ', name: 'Asia/Almaty', status: 'Canonical', offset: '+06:00', link: '',
}, {
	country: 'JO', name: 'Asia/Amman', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'RU', name: 'Asia/Anadyr', status: 'Canonical', offset: '+12:00', link: '',
}, {
	country: 'KZ', name: 'Asia/Aqtau', status: 'Canonical', offset: '+05:00', link: '',
}, {
	country: 'KZ', name: 'Asia/Aqtobe', status: 'Canonical', offset: '+05:00', link: '',
}, {
	country: 'TM', name: 'Asia/Ashgabat', status: 'Canonical', offset: '+05:00', link: '',
}, {
	country: 'TM', name: 'Asia/Ashkhabad', status: 'Deprecated', offset: '+05:00', link: 'Asia/Ashgabat',
}, {
	country: 'KZ', name: 'Asia/Atyrau', status: 'Canonical', offset: '+05:00', link: '',
}, {
	country: 'IQ', name: 'Asia/Baghdad', status: 'Canonical', offset: '+03:00', link: '',
}, {
	country: 'BH', name: 'Asia/Bahrain', status: 'Alias', offset: '+03:00', link: 'Asia/Qatar',
}, {
	country: 'AZ', name: 'Asia/Baku', status: 'Canonical', offset: '+04:00', link: '',
}, {
	country: 'TH', name: 'Asia/Bangkok', status: 'Canonical', offset: '+07:00', link: '',
}, {
	country: 'RU', name: 'Asia/Barnaul', status: 'Canonical', offset: '+07:00', link: '',
}, {
	country: 'LB', name: 'Asia/Beirut', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'KG', name: 'Asia/Bishkek', status: 'Canonical', offset: '+06:00', link: '',
}, {
	country: 'BN', name: 'Asia/Brunei', status: 'Canonical', offset: '+08:00', link: '',
}, {
	country: 'IN', name: 'Asia/Calcutta', status: 'Deprecated', offset: '+05:30', link: 'Asia/Kolkata',
}, {
	country: 'RU', name: 'Asia/Chita', status: 'Canonical', offset: '+09:00', link: '',
}, {
	country: 'MN', name: 'Asia/Choibalsan', status: 'Canonical', offset: '+08:00', link: '',
}, {
	country: 'CN', name: 'Asia/Chongqing', status: 'Deprecated', offset: '+08:00', link: 'Asia/Shanghai',
}, {
	country: 'CN', name: 'Asia/Chungking', status: 'Deprecated', offset: '+08:00', link: 'Asia/Shanghai',
}, {
	country: 'LK', name: 'Asia/Colombo', status: 'Canonical', offset: '+05:30', link: '',
}, {
	country: 'BD', name: 'Asia/Dacca', status: 'Deprecated', offset: '+06:00', link: 'Asia/Dhaka',
}, {
	country: 'SY', name: 'Asia/Damascus', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'BD', name: 'Asia/Dhaka', status: 'Canonical', offset: '+06:00', link: '',
}, {
	country: 'TL', name: 'Asia/Dili', status: 'Canonical', offset: '+09:00', link: '',
}, {
	country: 'AE', name: 'Asia/Dubai', status: 'Canonical', offset: '+04:00', link: '',
}, {
	country: 'TJ', name: 'Asia/Dushanbe', status: 'Canonical', offset: '+05:00', link: '',
}, {
	country: 'CY', name: 'Asia/Famagusta', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'PS', name: 'Asia/Gaza', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'CN', name: 'Asia/Harbin', status: 'Deprecated', offset: '+08:00', link: 'Asia/Shanghai',
}, {
	country: 'PS', name: 'Asia/Hebron', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'VN', name: 'Asia/Ho_Chi_Minh', status: 'Canonical', offset: '+07:00', link: '',
}, {
	country: 'HK', name: 'Asia/Hong_Kong', status: 'Canonical', offset: '+08:00', link: '',
}, {
	country: 'MN', name: 'Asia/Hovd', status: 'Canonical', offset: '+07:00', link: '',
}, {
	country: 'RU', name: 'Asia/Irkutsk', status: 'Canonical', offset: '+08:00', link: '',
}, {
	country: 'TR', name: 'Asia/Istanbul', status: 'Alias', offset: '+03:00', link: 'Europe/Istanbul',
}, {
	country: 'ID', name: 'Asia/Jakarta', status: 'Canonical', offset: '+07:00', link: '',
}, {
	country: 'ID', name: 'Asia/Jayapura', status: 'Canonical', offset: '+09:00', link: '',
}, {
	country: 'IL', name: 'Asia/Jerusalem', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'AF', name: 'Asia/Kabul', status: 'Canonical', offset: '+04:30', link: '',
}, {
	country: 'RU', name: 'Asia/Kamchatka', status: 'Canonical', offset: '+12:00', link: '',
}, {
	country: 'PK', name: 'Asia/Karachi', status: 'Canonical', offset: '+05:00', link: '',
}, {
	country: 'CN', name: 'Asia/Kashgar', status: 'Deprecated', offset: '+06:00', link: 'Asia/Urumqi',
}, {
	country: 'NP', name: 'Asia/Kathmandu', status: 'Canonical', offset: '+05:45', link: '',
}, {
	country: 'NP', name: 'Asia/Katmandu', status: 'Deprecated', offset: '+05:45', link: 'Asia/Kathmandu',
}, {
	country: 'RU', name: 'Asia/Khandyga', status: 'Canonical', offset: '+09:00', link: '',
}, {
	country: 'IN', name: 'Asia/Kolkata', status: 'Canonical', offset: '+05:30', link: 'Time in India',
}, {
	country: 'RU', name: 'Asia/Krasnoyarsk', status: 'Canonical', offset: '+07:00', link: '',
}, {
	country: 'MY', name: 'Asia/Kuala_Lumpur', status: 'Canonical', offset: '+08:00', link: '',
}, {
	country: 'MY', name: 'Asia/Kuching', status: 'Canonical', offset: '+08:00', link: '',
}, {
	country: 'KW', name: 'Asia/Kuwait', status: 'Alias', offset: '+03:00', link: 'Asia/Riyadh',
}, {
	country: 'MO', name: 'Asia/Macao', status: 'Deprecated', offset: '+08:00', link: 'Asia/Macau',
}, {
	country: 'MO', name: 'Asia/Macau', status: 'Canonical', offset: '+08:00', link: '',
}, {
	country: 'RU', name: 'Asia/Magadan', status: 'Canonical', offset: '+11:00', link: '',
}, {
	country: 'ID', name: 'Asia/Makassar', status: 'Canonical', offset: '+08:00', link: '',
}, {
	country: 'PH', name: 'Asia/Manila', status: 'Canonical', offset: '+08:00', link: '',
}, {
	country: 'OM', name: 'Asia/Muscat', status: 'Alias', offset: '+04:00', link: 'Asia/Dubai',
}, {
	country: 'CY', name: 'Asia/Nicosia', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'RU', name: 'Asia/Novokuznetsk', status: 'Canonical', offset: '+07:00', link: '',
}, {
	country: 'RU', name: 'Asia/Novosibirsk', status: 'Canonical', offset: '+07:00', link: '',
}, {
	country: 'RU', name: 'Asia/Omsk', status: 'Canonical', offset: '+06:00', link: '',
}, {
	country: 'KZ', name: 'Asia/Oral', status: 'Canonical', offset: '+05:00', link: '',
}, {
	country: 'KH', name: 'Asia/Phnom_Penh', status: 'Alias', offset: '+07:00', link: 'Asia/Bangkok',
}, {
	country: 'ID', name: 'Asia/Pontianak', status: 'Canonical', offset: '+07:00', link: '',
}, {
	country: 'KP', name: 'Asia/Pyongyang', status: 'Canonical', offset: '+09:00', link: '',
}, {
	country: 'QA', name: 'Asia/Qatar', status: 'Canonical', offset: '+03:00', link: '',
}, {
	country: 'KZ', name: 'Asia/Qostanay', status: 'Canonical', offset: '+06:00', link: '',
}, {
	country: 'KZ', name: 'Asia/Qyzylorda', status: 'Canonical', offset: '+05:00', link: '',
}, {
	country: 'MM', name: 'Asia/Rangoon', status: 'Deprecated', offset: '+06:30', link: 'Asia/Yangon',
}, {
	country: 'SA', name: 'Asia/Riyadh', status: 'Canonical', offset: '+03:00', link: '',
}, {
	country: 'VN', name: 'Asia/Saigon', status: 'Deprecated', offset: '+07:00', link: 'Asia/Ho_Chi_Minh',
}, {
	country: 'RU', name: 'Asia/Sakhalin', status: 'Canonical', offset: '+11:00', link: '',
}, {
	country: 'UZ', name: 'Asia/Samarkand', status: 'Canonical', offset: '+05:00', link: '',
}, {
	country: 'KR', name: 'Asia/Seoul', status: 'Canonical', offset: '+09:00', link: '',
}, {
	country: 'CN', name: 'Asia/Shanghai', status: 'Canonical', offset: '+08:00', link: '',
}, {
	country: 'SG', name: 'Asia/Singapore', status: 'Canonical', offset: '+08:00', link: '',
}, {
	country: 'RU', name: 'Asia/Srednekolymsk', status: 'Canonical', offset: '+11:00', link: '',
}, {
	country: 'TW', name: 'Asia/Taipei', status: 'Canonical', offset: '+08:00', link: '',
}, {
	country: 'UZ', name: 'Asia/Tashkent', status: 'Canonical', offset: '+05:00', link: '',
}, {
	country: 'GE', name: 'Asia/Tbilisi', status: 'Canonical', offset: '+04:00', link: '',
}, {
	country: 'IR', name: 'Asia/Tehran', status: 'Canonical', offset: '+03:30', link: '',
}, {
	country: 'IL', name: 'Asia/Tel_Aviv', status: 'Deprecated', offset: '+02:00', link: 'Asia/Jerusalem',
}, {
	country: 'BT', name: 'Asia/Thimbu', status: 'Deprecated', offset: '+06:00', link: 'Asia/Thimphu',
}, {
	country: 'BT', name: 'Asia/Thimphu', status: 'Canonical', offset: '+06:00', link: '',
}, {
	country: 'JP', name: 'Asia/Tokyo', status: 'Canonical', offset: '+09:00', link: '',
}, {
	country: 'RU', name: 'Asia/Tomsk', status: 'Canonical', offset: '+07:00', link: '',
}, {
	country: 'ID', name: 'Asia/Ujung_Pandang', status: 'Deprecated', offset: '+08:00', link: 'Asia/Makassar',
}, {
	country: 'MN', name: 'Asia/Ulaanbaatar', status: 'Canonical', offset: '+08:00', link: '',
}, {
	country: 'MN', name: 'Asia/Ulan_Bator', status: 'Deprecated', offset: '+08:00', link: 'Asia/Ulaanbaatar',
}, {
	country: 'CN', name: 'Asia/Urumqi', status: 'Canonical', offset: '+06:00', link: 'Asia/Urumqi',
}, {
	country: 'RU', name: 'Asia/Ust-Nera', status: 'Canonical', offset: '+10:00', link: '',
}, {
	country: 'LA', name: 'Asia/Vientiane', status: 'Alias', offset: '+07:00', link: 'Asia/Bangkok',
}, {
	country: 'RU', name: 'Asia/Vladivostok', status: 'Canonical', offset: '+10:00', link: '',
}, {
	country: 'RU', name: 'Asia/Yakutsk', status: 'Canonical', offset: '+09:00', link: '',
}, {
	country: 'MM', name: 'Asia/Yangon', status: 'Canonical', offset: '+06:30', link: '',
}, {
	country: 'RU', name: 'Asia/Yekaterinburg', status: 'Canonical', offset: '+05:00', link: '',
}, {
	country: 'AM', name: 'Asia/Yerevan', status: 'Canonical', offset: '+04:00', link: '',
}, {
	country: 'PT', name: 'Atlantic/Azores', status: 'Canonical', offset: '−01:00', link: '',
}, {
	country: 'BM', name: 'Atlantic/Bermuda', status: 'Canonical', offset: '−04:00', link: '',
}, {
	country: 'ES', name: 'Atlantic/Canary', status: 'Canonical', offset: '+00:00', link: '',
}, {
	country: 'CV', name: 'Atlantic/Cape_Verde', status: 'Canonical', offset: '−01:00', link: '',
}, {
	country: 'FO', name: 'Atlantic/Faeroe', status: 'Deprecated', offset: '+00:00', link: 'Atlantic/Faroe',
}, {
	country: 'FO', name: 'Atlantic/Faroe', status: 'Canonical', offset: '+00:00', link: '',
}, {
	country: 'SJ', name: 'Atlantic/Jan_Mayen', status: 'Deprecated', offset: '+01:00', link: 'Europe/Oslo',
}, {
	country: 'PT', name: 'Atlantic/Madeira', status: 'Canonical', offset: '+00:00', link: '',
}, {
	country: 'IS', name: 'Atlantic/Reykjavik', status: 'Canonical', offset: '+00:00', link: '',
}, {
	country: 'GS', name: 'Atlantic/South_Georgia', status: 'Canonical', offset: '−02:00', link: '',
}, {
	country: 'SH', name: 'Atlantic/St_Helena', status: 'Alias', offset: '+00:00', link: 'Africa/Abidjan',
}, {
	country: 'FK', name: 'Atlantic/Stanley', status: 'Canonical', offset: '−03:00', link: '',
}, {
	country: 'AU', name: 'Australia/ACT', status: 'Deprecated', offset: '+10:00', link: 'Australia/Sydney',
}, {
	country: 'AU', name: 'Australia/Adelaide', status: 'Canonical', offset: '+09:30', link: '',
}, {
	country: 'AU', name: 'Australia/Brisbane', status: 'Canonical', offset: '+10:00', link: '',
}, {
	country: 'AU', name: 'Australia/Broken_Hill', status: 'Canonical', offset: '+09:30', link: '',
}, {
	country: 'AU', name: 'Australia/Canberra', status: 'Deprecated', offset: '+10:00', link: 'Australia/Sydney',
}, {
	country: 'AU', name: 'Australia/Currie', status: 'Deprecated', offset: '+10:00', link: 'Australia/Hobart',
}, {
	country: 'AU', name: 'Australia/Darwin', status: 'Canonical', offset: '+09:30', link: '',
}, {
	country: 'AU', name: 'Australia/Eucla', status: 'Canonical', offset: '+08:45', link: '',
}, {
	country: 'AU', name: 'Australia/Hobart', status: 'Canonical', offset: '+10:00', link: '',
}, {
	country: 'AU', name: 'Australia/LHI', status: 'Deprecated', offset: '+10:30', link: 'Australia/Lord_Howe',
}, {
	country: 'AU', name: 'Australia/Lindeman', status: 'Canonical', offset: '+10:00', link: '',
}, {
	country: 'AU', name: 'Australia/Lord_Howe', status: 'Canonical', offset: '+10:30', link: 'This is the only time zone in the world that uses 30-minute DST transitions.',
}, {
	country: 'AU', name: 'Australia/Melbourne', status: 'Canonical', offset: '+10:00', link: '',
}, {
	country: 'AU', name: 'Australia/North', status: 'Deprecated', offset: '+09:30', link: 'Australia/Darwin',
}, {
	country: 'AU', name: 'Australia/NSW', status: 'Deprecated', offset: '+10:00', link: 'Australia/Sydney',
}, {
	country: 'AU', name: 'Australia/Perth', status: 'Canonical', offset: '+08:00', link: '',
}, {
	country: 'AU', name: 'Australia/Queensland', status: 'Deprecated', offset: '+10:00', link: 'Australia/Brisbane',
}, {
	country: 'AU', name: 'Australia/South', status: 'Deprecated', offset: '+09:30', link: 'Australia/Adelaide',
}, {
	country: 'AU', name: 'Australia/Sydney', status: 'Canonical', offset: '+10:00', link: '',
}, {
	country: 'AU', name: 'Australia/Tasmania', status: 'Deprecated', offset: '+10:00', link: 'Australia/Hobart',
}, {
	country: 'AU', name: 'Australia/Victoria', status: 'Deprecated', offset: '+10:00', link: 'Australia/Melbourne',
}, {
	country: 'AU', name: 'Australia/West', status: 'Deprecated', offset: '+08:00', link: 'Australia/Perth',
}, {
	country: 'AU', name: 'Australia/Yancowinna', status: 'Deprecated', offset: '+09:30', link: 'Australia/Broken_Hill',
}, {
	country: 'BR', name: 'Brazil/Acre', status: 'Deprecated', offset: '−05:00', link: 'America/Rio_Branco',
}, {
	country: 'BR', name: 'Brazil/DeNoronha', status: 'Deprecated', offset: '−02:00', link: 'America/Noronha',
}, {
	country: 'BR', name: 'Brazil/East', status: 'Deprecated', offset: '−03:00', link: 'America/Sao_Paulo',
}, {
	country: 'BR', name: 'Brazil/West', status: 'Deprecated', offset: '−04:00', link: 'America/Manaus',
}, {
	country: 'CA', name: 'Canada/Atlantic', status: 'Deprecated', offset: '−04:00', link: 'America/Halifax',
}, {
	country: 'CA', name: 'Canada/Central', status: 'Deprecated', offset: '−06:00', link: 'America/Winnipeg',
}, {
	country: 'CA', name: 'Canada/Eastern', status: 'Deprecated', offset: '−05:00', link: 'America/Toronto',
}, {
	country: 'CA', name: 'Canada/Mountain', status: 'Deprecated', offset: '−07:00', link: 'America/Edmonton',
}, {
	country: 'CA', name: 'Canada/Newfoundland', status: 'Deprecated', offset: '−03:30', link: 'America/St_Johns',
}, {
	country: 'CA', name: 'Canada/Pacific', status: 'Deprecated', offset: '−08:00', link: 'America/Vancouver',
}, {
	country: 'CA', name: 'Canada/Saskatchewan', status: 'Deprecated', offset: '−06:00', link: 'America/Regina',
}, {
	country: 'CA', name: 'Canada/Yukon', status: 'Deprecated', offset: '−07:00', link: 'America/Whitehorse',
}, {
	country: '', name: 'CET', status: 'Deprecated', offset: '+01:00', link: 'Europe/Paris',
}, {
	country: 'CL', name: 'Chile/Continental', status: 'Deprecated', offset: '−04:00', link: 'America/Santiago',
}, {
	country: 'CL', name: 'Chile/EasterIsland', status: 'Deprecated', offset: '−06:00', link: 'Pacific/Easter',
}, {
	country: '', name: 'CST6CDT', status: 'Deprecated', offset: '−06:00', link: 'America/Chicago',
}, {
	country: 'CU', name: 'Cuba', status: 'Deprecated', offset: '−05:00', link: 'America/Havana',
}, {
	country: '', name: 'EET', status: 'Deprecated', offset: '+02:00', link: 'Europe/Sofia',
}, {
	country: 'EG', name: 'Egypt', status: 'Deprecated', offset: '+02:00', link: 'Africa/Cairo',
}, {
	country: 'IE', name: 'Eire', status: 'Deprecated', offset: '+01:00', link: 'Europe/Dublin',
}, {
	country: '', name: 'EST', status: 'Deprecated', offset: '−05:00', link: 'America/Cancun',
}, {
	country: '', name: 'EST5EDT', status: 'Deprecated', offset: '−05:00', link: 'America/New_York',
}, {
	country: '', name: 'Etc/GMT', status: 'Canonical', offset: '+00:00', link: '',
}, {
	country: '', name: 'Etc/GMT+0', status: 'Alias', offset: '+00:00', link: 'Etc/GMT',
}, {
	country: '', name: 'Etc/GMT+1', status: 'Canonical', offset: '−01:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT+10', status: 'Canonical', offset: '−10:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT+11', status: 'Canonical', offset: '−11:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT+12', status: 'Canonical', offset: '−12:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT+2', status: 'Canonical', offset: '−02:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT+3', status: 'Canonical', offset: '−03:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT+4', status: 'Canonical', offset: '−04:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT+5', status: 'Canonical', offset: '−05:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT+6', status: 'Canonical', offset: '−06:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT+7', status: 'Canonical', offset: '−07:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT+8', status: 'Canonical', offset: '−08:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT+9', status: 'Canonical', offset: '−09:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT-0', status: 'Alias', offset: '+00:00', link: 'Etc/GMT',
}, {
	country: '', name: 'Etc/GMT-1', status: 'Canonical', offset: '+01:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT-10', status: 'Canonical', offset: '+10:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT-11', status: 'Canonical', offset: '+11:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT-12', status: 'Canonical', offset: '+12:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT-13', status: 'Canonical', offset: '+13:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT-14', status: 'Canonical', offset: '+14:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT-2', status: 'Canonical', offset: '+02:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT-3', status: 'Canonical', offset: '+03:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT-4', status: 'Canonical', offset: '+04:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT-5', status: 'Canonical', offset: '+05:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT-6', status: 'Canonical', offset: '+06:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT-7', status: 'Canonical', offset: '+07:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT-8', status: 'Canonical', offset: '+08:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT-9', status: 'Canonical', offset: '+09:00', link: 'Etc area description',
}, {
	country: '', name: 'Etc/GMT0', status: 'Alias', offset: '+00:00', link: 'Etc/GMT',
}, {
	country: '', name: 'Etc/Greenwich', status: 'Deprecated', offset: '+00:00', link: 'Etc/GMT',
}, {
	country: '', name: 'Etc/UCT', status: 'Deprecated', offset: '+00:00', link: 'Etc/UTC',
}, {
	country: '', name: 'Etc/Universal', status: 'Deprecated', offset: '+00:00', link: 'Etc/UTC',
}, {
	country: '', name: 'Etc/UTC', status: 'Canonical', offset: '+00:00', link: '',
}, {
	country: '', name: 'Etc/Zulu', status: 'Deprecated', offset: '+00:00', link: 'Etc/UTC',
}, {
	country: 'NL', name: 'Europe/Amsterdam', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'AD', name: 'Europe/Andorra', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'RU', name: 'Europe/Astrakhan', status: 'Canonical', offset: '+04:00', link: '',
}, {
	country: 'GR', name: 'Europe/Athens', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'GB', name: 'Europe/Belfast', status: 'Deprecated', offset: '+00:00', link: 'Europe/London',
}, {
	country: 'RS', name: 'Europe/Belgrade', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'DE', name: 'Europe/Berlin', status: 'Canonical', offset: '+01:00', link: 'Time in Germany',
}, {
	country: 'SK', name: 'Europe/Bratislava', status: 'Alias', offset: '+01:00', link: 'Europe/Prague',
}, {
	country: 'BE', name: 'Europe/Brussels', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'RO', name: 'Europe/Bucharest', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'HU', name: 'Europe/Budapest', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'DE', name: 'Europe/Busingen', status: 'Alias', offset: '+01:00', link: 'Europe/Zurich',
}, {
	country: 'MD', name: 'Europe/Chisinau', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'DK', name: 'Europe/Copenhagen', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'IE', name: 'Europe/Dublin', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'GI', name: 'Europe/Gibraltar', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'GG', name: 'Europe/Guernsey', status: 'Alias', offset: '+00:00', link: 'Europe/London',
}, {
	country: 'FI', name: 'Europe/Helsinki', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'IM', name: 'Europe/Isle_of_Man', status: 'Alias', offset: '+00:00', link: 'Europe/London',
}, {
	country: 'TR', name: 'Europe/Istanbul', status: 'Canonical', offset: '+03:00', link: '',
}, {
	country: 'JE', name: 'Europe/Jersey', status: 'Alias', offset: '+00:00', link: 'Europe/London',
}, {
	country: 'RU', name: 'Europe/Kaliningrad', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'UA', name: 'Europe/Kiev', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'RU', name: 'Europe/Kirov', status: 'Canonical', offset: '+03:00', link: '',
}, {
	country: 'PT', name: 'Europe/Lisbon', status: 'Canonical', offset: '+00:00', link: '',
}, {
	country: 'SI', name: 'Europe/Ljubljana', status: 'Alias', offset: '+01:00', link: 'Europe/Belgrade',
}, {
	country: 'GB', name: 'Europe/London', status: 'Canonical', offset: '+00:00', link: '',
}, {
	country: 'LU', name: 'Europe/Luxembourg', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'ES', name: 'Europe/Madrid', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'MT', name: 'Europe/Malta', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'AX', name: 'Europe/Mariehamn', status: 'Alias', offset: '+02:00', link: 'Europe/Helsinki',
}, {
	country: 'BY', name: 'Europe/Minsk', status: 'Canonical', offset: '+03:00', link: '',
}, {
	country: 'MC', name: 'Europe/Monaco', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'RU', name: 'Europe/Moscow', status: 'Canonical', offset: '+03:00', link: '',
}, {
	country: 'CY', name: 'Europe/Nicosia', status: 'Alias', offset: '+02:00', link: 'Asia/Nicosia',
}, {
	country: 'NO', name: 'Europe/Oslo', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'FR', name: 'Europe/Paris', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'ME', name: 'Europe/Podgorica', status: 'Alias', offset: '+01:00', link: 'Europe/Belgrade',
}, {
	country: 'CZ', name: 'Europe/Prague', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'LV', name: 'Europe/Riga', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'IT', name: 'Europe/Rome', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'RU', name: 'Europe/Samara', status: 'Canonical', offset: '+04:00', link: '',
}, {
	country: 'SM', name: 'Europe/San_Marino', status: 'Alias', offset: '+01:00', link: 'Europe/Rome',
}, {
	country: 'BA', name: 'Europe/Sarajevo', status: 'Alias', offset: '+01:00', link: 'Europe/Belgrade',
}, {
	country: 'RU', name: 'Europe/Saratov', status: 'Canonical', offset: '+04:00', link: '',
}, {
	country: 'UA', name: 'Europe/Simferopol', status: 'Canonical', offset: '+03:00', link: 'Disputed - Reflects data in the TZDB.',
}, {
	country: 'MK', name: 'Europe/Skopje', status: 'Alias', offset: '+01:00', link: 'Europe/Belgrade',
}, {
	country: 'BG', name: 'Europe/Sofia', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'SE', name: 'Europe/Stockholm', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'EE', name: 'Europe/Tallinn', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'AL', name: 'Europe/Tirane', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'MD', name: 'Europe/Tiraspol', status: 'Deprecated', offset: '+02:00', link: 'Europe/Chisinau',
}, {
	country: 'RU', name: 'Europe/Ulyanovsk', status: 'Canonical', offset: '+04:00', link: '',
}, {
	country: 'UA', name: 'Europe/Uzhgorod', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'LI', name: 'Europe/Vaduz', status: 'Alias', offset: '+01:00', link: 'Europe/Zurich',
}, {
	country: 'VA', name: 'Europe/Vatican', status: 'Alias', offset: '+01:00', link: 'Europe/Rome',
}, {
	country: 'AT', name: 'Europe/Vienna', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'LT', name: 'Europe/Vilnius', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'RU', name: 'Europe/Volgograd', status: 'Canonical', offset: '+03:00', link: '',
}, {
	country: 'PL', name: 'Europe/Warsaw', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: 'HR', name: 'Europe/Zagreb', status: 'Alias', offset: '+01:00', link: 'Europe/Belgrade',
}, {
	country: 'UA', name: 'Europe/Zaporozhye', status: 'Canonical', offset: '+02:00', link: '',
}, {
	country: 'CH', name: 'Europe/Zurich', status: 'Canonical', offset: '+01:00', link: '',
}, {
	country: '', name: 'Factory', status: 'Canonical', offset: '+00:00', link: '',
}, {
	country: 'GB', name: 'GB', status: 'Deprecated', offset: '+00:00', link: 'Europe/London',
}, {
	country: 'GB', name: 'GB-Eire', status: 'Deprecated', offset: '+00:00', link: 'Europe/London',
}, {
	country: '', name: 'GMT', status: 'Alias', offset: '+00:00', link: 'Etc/GMT',
}, {
	country: '', name: 'GMT+0', status: 'Deprecated', offset: '+00:00', link: 'Etc/GMT',
}, {
	country: '', name: 'GMT-0', status: 'Deprecated', offset: '+00:00', link: 'Etc/GMT',
}, {
	country: '', name: 'GMT0', status: 'Deprecated', offset: '+00:00', link: 'Etc/GMT',
}, {
	country: '', name: 'Greenwich', status: 'Deprecated', offset: '+00:00', link: 'Etc/GMT',
}, {
	country: 'HK', name: 'Hongkong', status: 'Deprecated', offset: '+08:00', link: 'Asia/Hong_Kong',
}, {
	country: '', name: 'HST', status: 'Deprecated', offset: '−10:00', link: 'Pacific/Honolulu',
}, {
	country: 'IS', name: 'Iceland', status: 'Deprecated', offset: '+00:00', link: 'Atlantic/Reykjavik',
}, {
	country: 'MG', name: 'Indian/Antananarivo', status: 'Alias', offset: '+03:00', link: 'Africa/Nairobi',
}, {
	country: 'IO', name: 'Indian/Chagos', status: 'Canonical', offset: '+06:00', link: '',
}, {
	country: 'CX', name: 'Indian/Christmas', status: 'Canonical', offset: '+07:00', link: '',
}, {
	country: 'CC', name: 'Indian/Cocos', status: 'Canonical', offset: '+06:30', link: '',
}, {
	country: 'KM', name: 'Indian/Comoro', status: 'Alias', offset: '+03:00', link: 'Africa/Nairobi',
}, {
	country: 'TF', name: 'Indian/Kerguelen', status: 'Canonical', offset: '+05:00', link: '',
}, {
	country: 'SC', name: 'Indian/Mahe', status: 'Canonical', offset: '+04:00', link: '',
}, {
	country: 'MV', name: 'Indian/Maldives', status: 'Canonical', offset: '+05:00', link: '',
}, {
	country: 'MU', name: 'Indian/Mauritius', status: 'Canonical', offset: '+04:00', link: '',
}, {
	country: 'YT', name: 'Indian/Mayotte', status: 'Alias', offset: '+03:00', link: 'Africa/Nairobi',
}, {
	country: 'RE', name: 'Indian/Reunion', status: 'Canonical', offset: '+04:00', link: '',
}, {
	country: 'IR', name: 'Iran', status: 'Deprecated', offset: '+03:30', link: 'Asia/Tehran',
}, {
	country: 'IL', name: 'Israel', status: 'Deprecated', offset: '+02:00', link: 'Asia/Jerusalem',
}, {
	country: 'JM', name: 'Jamaica', status: 'Deprecated', offset: '−05:00', link: 'America/Jamaica',
}, {
	country: 'JP', name: 'Japan', status: 'Deprecated', offset: '+09:00', link: 'Asia/Tokyo',
}, {
	country: 'MH', name: 'Kwajalein', status: 'Deprecated', offset: '+12:00', link: 'Pacific/Kwajalein',
}, {
	country: 'LY', name: 'Libya', status: 'Deprecated', offset: '+02:00', link: 'Africa/Tripoli',
}, {
	country: '', name: 'MET', status: 'Deprecated', offset: '+01:00', link: 'Europe/Paris',
}, {
	country: 'MX', name: 'Mexico/BajaNorte', status: 'Deprecated', offset: '−08:00', link: 'America/Tijuana',
}, {
	country: 'MX', name: 'Mexico/BajaSur', status: 'Deprecated', offset: '−07:00', link: 'America/Mazatlan',
}, {
	country: 'MX', name: 'Mexico/General', status: 'Deprecated', offset: '−06:00', link: 'America/Mexico_City',
}, {
	country: '', name: 'MST', status: 'Deprecated', offset: '−07:00', link: 'America/Phoenix',
}, {
	country: '', name: 'MST7MDT', status: 'Deprecated', offset: '−07:00', link: 'America/Denver',
}, {
	country: 'US', name: 'Navajo', status: 'Deprecated', offset: '−07:00', link: 'America/Denver',
}, {
	country: 'NZ', name: 'NZ', status: 'Deprecated', offset: '+12:00', link: 'Pacific/Auckland',
}, {
	country: 'NZ', name: 'NZ-CHAT', status: 'Deprecated', offset: '+12:45', link: 'Pacific/Chatham',
}, {
	country: 'WS', name: 'Pacific/Apia', status: 'Canonical', offset: '+13:00', link: '',
}, {
	country: 'NZ', name: 'Pacific/Auckland', status: 'Canonical', offset: '+12:00', link: '',
}, {
	country: 'PG', name: 'Pacific/Bougainville', status: 'Canonical', offset: '+11:00', link: '',
}, {
	country: 'NZ', name: 'Pacific/Chatham', status: 'Canonical', offset: '+12:45', link: '',
}, {
	country: 'FM', name: 'Pacific/Chuuk', status: 'Canonical', offset: '+10:00', link: '',
}, {
	country: 'CL', name: 'Pacific/Easter', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'VU', name: 'Pacific/Efate', status: 'Canonical', offset: '+11:00', link: '',
}, {
	country: 'KI', name: 'Pacific/Enderbury', status: 'Canonical', offset: '+13:00', link: '',
}, {
	country: 'TK', name: 'Pacific/Fakaofo', status: 'Canonical', offset: '+13:00', link: '',
}, {
	country: 'FJ', name: 'Pacific/Fiji', status: 'Canonical', offset: '+12:00', link: '',
}, {
	country: 'TV', name: 'Pacific/Funafuti', status: 'Canonical', offset: '+12:00', link: '',
}, {
	country: 'EC', name: 'Pacific/Galapagos', status: 'Canonical', offset: '−06:00', link: '',
}, {
	country: 'PF', name: 'Pacific/Gambier', status: 'Canonical', offset: '−09:00', link: '',
}, {
	country: 'SB', name: 'Pacific/Guadalcanal', status: 'Canonical', offset: '+11:00', link: '',
}, {
	country: 'GU', name: 'Pacific/Guam', status: 'Canonical', offset: '+10:00', link: '',
}, {
	country: 'US', name: 'Pacific/Honolulu', status: 'Canonical', offset: '−10:00', link: '',
}, {
	country: 'UM', name: 'Pacific/Johnston', status: 'Deprecated', offset: '−10:00', link: 'Pacific/Honolulu',
}, {
	country: 'KI', name: 'Pacific/Kiritimati', status: 'Canonical', offset: '+14:00', link: '',
}, {
	country: 'FM', name: 'Pacific/Kosrae', status: 'Canonical', offset: '+11:00', link: '',
}, {
	country: 'MH', name: 'Pacific/Kwajalein', status: 'Canonical', offset: '+12:00', link: '',
}, {
	country: 'MH', name: 'Pacific/Majuro', status: 'Canonical', offset: '+12:00', link: '',
}, {
	country: 'PF', name: 'Pacific/Marquesas', status: 'Canonical', offset: '−09:30', link: '',
}, {
	country: 'UM', name: 'Pacific/Midway', status: 'Alias', offset: '−11:00', link: 'Pacific/Pago_Pago',
}, {
	country: 'NR', name: 'Pacific/Nauru', status: 'Canonical', offset: '+12:00', link: '',
}, {
	country: 'NU', name: 'Pacific/Niue', status: 'Canonical', offset: '−11:00', link: '',
}, {
	country: 'NF', name: 'Pacific/Norfolk', status: 'Canonical', offset: '+11:00', link: '',
}, {
	country: 'NC', name: 'Pacific/Noumea', status: 'Canonical', offset: '+11:00', link: '',
}, {
	country: 'AS', name: 'Pacific/Pago_Pago', status: 'Canonical', offset: '−11:00', link: '',
}, {
	country: 'PW', name: 'Pacific/Palau', status: 'Canonical', offset: '+09:00', link: '',
}, {
	country: 'PN', name: 'Pacific/Pitcairn', status: 'Canonical', offset: '−08:00', link: '',
}, {
	country: 'FM', name: 'Pacific/Pohnpei', status: 'Canonical', offset: '+11:00', link: '',
}, {
	country: 'FM', name: 'Pacific/Ponape', status: 'Deprecated', offset: '+11:00', link: 'Pacific/Pohnpei',
}, {
	country: 'PG', name: 'Pacific/Port_Moresby', status: 'Canonical', offset: '+10:00', link: '',
}, {
	country: 'CK', name: 'Pacific/Rarotonga', status: 'Canonical', offset: '−10:00', link: '',
}, {
	country: 'MP', name: 'Pacific/Saipan', status: 'Alias', offset: '+10:00', link: 'Pacific/Guam',
}, {
	country: 'WS', name: 'Pacific/Samoa', status: 'Deprecated', offset: '−11:00', link: 'Pacific/Pago_Pago',
}, {
	country: 'PF', name: 'Pacific/Tahiti', status: 'Canonical', offset: '−10:00', link: '',
}, {
	country: 'KI', name: 'Pacific/Tarawa', status: 'Canonical', offset: '+12:00', link: '',
}, {
	country: 'TO', name: 'Pacific/Tongatapu', status: 'Canonical', offset: '+13:00', link: '',
}, {
	country: 'FM', name: 'Pacific/Truk', status: 'Deprecated', offset: '+10:00', link: 'Pacific/Chuuk',
}, {
	country: 'UM', name: 'Pacific/Wake', status: 'Canonical', offset: '+12:00', link: '',
}, {
	country: 'WF', name: 'Pacific/Wallis', status: 'Canonical', offset: '+12:00', link: '',
}, {
	country: 'FM', name: 'Pacific/Yap', status: 'Deprecated', offset: '+10:00', link: 'Pacific/Chuuk',
}, {
	country: 'PL', name: 'Poland', status: 'Deprecated', offset: '+01:00', link: 'Europe/Warsaw',
}, {
	country: 'PT', name: 'Portugal', status: 'Deprecated', offset: '+00:00', link: 'Europe/Lisbon',
}, {
	country: 'CN', name: 'PRC', status: 'Deprecated', offset: '+08:00', link: 'Asia/Shanghai',
}, {
	country: '', name: 'PST8PDT', status: 'Deprecated', offset: '−08:00', link: 'America/Los_Angeles',
}, {
	country: 'TW', name: 'ROC', status: 'Deprecated', offset: '+08:00', link: 'Asia/Taipei',
}, {
	country: 'KR', name: 'ROK', status: 'Deprecated', offset: '+09:00', link: 'Asia/Seoul',
}, {
	country: 'SG', name: 'Singapore', status: 'Deprecated', offset: '+08:00', link: 'Asia/Singapore',
}, {
	country: 'TR', name: 'Turkey', status: 'Deprecated', offset: '+03:00', link: 'Europe/Istanbul',
}, {
	country: '', name: 'UCT', status: 'Deprecated', offset: '+00:00', link: 'Etc/UTC',
}, {
	country: '', name: 'Universal', status: 'Deprecated', offset: '+00:00', link: 'Etc/UTC',
}, {
	country: 'US', name: 'US/Alaska', status: 'Deprecated', offset: '−09:00', link: 'America/Anchorage',
}, {
	country: 'US', name: 'US/Aleutian', status: 'Deprecated', offset: '−10:00', link: 'America/Adak',
}, {
	country: 'US', name: 'US/Arizona', status: 'Deprecated', offset: '−07:00', link: 'America/Phoenix',
}, {
	country: 'US', name: 'US/Central', status: 'Deprecated', offset: '−06:00', link: 'America/Chicago',
}, {
	country: 'US', name: 'US/East-Indiana', status: 'Deprecated', offset: '−05:00', link: 'America/Indiana/Indianapolis',
}, {
	country: 'US', name: 'US/Eastern', status: 'Deprecated', offset: '−05:00', link: 'America/New_York',
}, {
	country: 'US', name: 'US/Hawaii', status: 'Deprecated', offset: '−10:00', link: 'Pacific/Honolulu',
}, {
	country: 'US', name: 'US/Indiana-Starke', status: 'Deprecated', offset: '−06:00', link: 'America/Indiana/Knox',
}, {
	country: 'US', name: 'US/Michigan', status: 'Deprecated', offset: '−05:00', link: 'America/Detroit',
}, {
	country: 'US', name: 'US/Mountain', status: 'Deprecated', offset: '−07:00', link: 'America/Denver',
}, {
	country: 'US', name: 'US/Pacific', status: 'Deprecated', offset: '−08:00', link: 'America/Los_Angeles',
}, {
	country: 'WS', name: 'US/Samoa', status: 'Deprecated', offset: '−11:00', link: 'Pacific/Pago_Pago',
}, {
	country: '', name: 'UTC', status: 'Alias', offset: '+00:00', link: 'Etc/UTC',
}, {
	country: 'RU', name: 'W-SU', status: 'Deprecated', offset: '+03:00', link: 'Europe/Moscow',
}, {
	country: '', name: 'WET', status: 'Deprecated', offset: '+00:00', link: 'Europe/Lisbon',
}, {
	country: '', name: 'Zulu', status: 'Deprecated', offset: '+00:00', link: 'Etc/UTC',
}];