import React from 'react';
import { useTheme } from 'styled-components';

import { useNavigate } from 'react-router-dom';
import Tag from '../../../atomic-design-components/atoms/Tag/Tag';
import Typography from '../../../atomic-design-components/atoms/Typography/Typography';

const ChatsTableCell = ({ chatId, messagesCount }: {
	chatId: string;
	messagesCount: number;

}) => {
	const theme = useTheme();
	const navigate = useNavigate();

	const onChatsClick = (e: {
		stopPropagation: () => void;
	}) => {
		e.stopPropagation();
		navigate(chatId ? `../chats/${chatId}` : '../chats');
	};

	return (
		<Tag
			// @ts-ignore
			onClick={onChatsClick}
			color={theme.color.secondary.dark}
			backgroundColor={theme.color.secondary.main}
			hexOpacity="1A"
			withBackgroundOpacity
			iconLeftProps={{
				margin: '0 5px 0 0',
				width: 15,
				height: 15,
				name: 'chats',
				fill: theme.color.secondary.dark,
			}}
		>
			<Typography variant="button1">{messagesCount}</Typography>
		</Tag>
	);
};

export default ChatsTableCell;