import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import clsx from 'clsx';

import { StyledCheckboxWrapper } from './styled';
import Icon from '../../atoms/Icon/Icon';
import Typography, { ITypographyProps } from '../../atoms/Typography/Typography';

const Checkbox = ({
	checked,
	className,
	disabled,
	fontWeight,
	handleChange,
	label,
	labelType = 'input',
	name,
	readOnly,
	type = 'checkbox',
	value,
	children,
	checkboxColor,
	fill,
	withLabelChildren,
	...otherProps
}: {
	checked?: boolean;
	className?: string;
	disabled?: boolean;
	fontWeight?: string | number;
	handleChange?: (e: boolean, f: {
		target: {
			name: string;
			value: string;
		}
	}) => void;
	label?: string;
	labelType?: ITypographyProps['variant'];
	name?: string;
	readOnly?: boolean;
	type?: 'checkbox' | 'radio';
	value?: string;
	children?: React.ReactNode;
	checkboxColor?: string;
	fill?: string;
	withLabelChildren?: boolean;

}) => {
	const theme = useTheme();
	const [stateChecked, setStateChecked] = useState(checked || false);

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (handleChange) {
			handleChange(e.target.checked, e);
		}

		setStateChecked(e.target.checked);
	};

	const onLabelClick = () => {
		setStateChecked((prev) => (type === 'radio' ? true : !prev));
		if (handleChange) {
			handleChange(type === 'radio' ? true : !stateChecked, { target: { name: name || '', value: value || '' } });
		}
	};

	useEffect(() => {
		setStateChecked(checked || false);
	}, [checked]);

	return (
		<StyledCheckboxWrapper className={clsx(className, disabled && 'disabled', type)}>
			<input
				type={type}
				checked={stateChecked}
				disabled={disabled}
				name={name}
				onChange={disabled || readOnly ? undefined : onChange}
				readOnly={readOnly}
				value={value}
				{...otherProps}
			/>
			{stateChecked ? (
				<Icon name={`${type}Checked`} fill={fill || theme.color.secondary.main} checkboxColor={checkboxColor} />
			) : (
				<Icon name={`${type}Unchecked`} />
			)}
			{(withLabelChildren || label) && (
				<Typography
					variant={labelType}
					// @ts-ignore
					onClick={disabled || readOnly ? undefined : onLabelClick}
					fontWeight={fontWeight}
				>
					{label || children}
				</Typography>
			)}
			{!withLabelChildren && children}
		</StyledCheckboxWrapper>
	);
};

export default Checkbox;