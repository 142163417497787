import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StyledFieldsCustomisation } from './styled';
import Select from '../../../atomic-design-components/molecules/Select/Select';
import Switch from '../../../atomic-design-components/molecules/Switch/Switch';
import Typography from '../../../atomic-design-components/atoms/Typography/Typography';
import Input from '../../../atomic-design-components/molecules/Input/Input';
import { selectMainData } from '../../../redux-saga/selectors';
import { isObjectEmpty } from '../../../utils/isObjectEmpty';
import { getSelectValue } from '../../../utils/select';
import { IDummyData } from '../../../types';

const InputWithSwitch = (props: {
	name: string;
	value: IDummyData;
	updateSelect: IDummyData;
	updateInput: (a: IDummyData) => void;
	optionsFromFormValues: IDummyData;
	error: string;
}) => {
	const {
		name,
		value,
		updateSelect,
		updateInput,
		optionsFromFormValues,
		error,
	} = props;
	const { t } = useTranslation(['all', 'validation']);
	const theme = useTheme();
	const departments = useSelector(selectMainData('departments')) || [];
	const fieldsSettings = value;
	const is_form_displayed = optionsFromFormValues?.is_form_displayed;

	const choosedDep = fieldsSettings?.values?.length || 0;
	const allDeps = departments.length;

	const onSwitchChecked = (checked: boolean, nameParam: string) => {
		const newFieldsValue = {
			...fieldsSettings,
			[nameParam]: checked,
		};

		updateSelect(name)(newFieldsValue);
	};

	const onDepartmentChoose = (option: IDummyData) => {
		option.map((opt: IDummyData) => {
			let newOpt = opt;
			if (isObjectEmpty(opt)) {
				newOpt = departments.find((dep: { id: string }) => dep.id === opt);
			}
			return newOpt;
		});

		updateSelect('departments.values')(option);
	};

	const onURLChange = (e: {
		target: {
			value: string;
			name: string;
		};
	}) => {
		const value = e?.target?.value;
		updateInput({ target: { name: 'privacy_policy.document_url', value } });
	};

	return (
		<StyledFieldsCustomisation>
			{name === 'departments' && (
				<>
					<div className="row field">
						<Typography
							variant="button1"
							text={t('departmentsField', { choosedDep, allDeps })}
							color={theme.color.general.black}
						/>
						<Switch
							id="departmentsVisible"
							className="col2"
							checked={fieldsSettings?.is_selected}
							onChange={(checked) => onSwitchChecked(checked, 'is_selected')}
						/>
					</div>
					{fieldsSettings.is_selected && (
						<Select
							label="departments"
							isMulti
							options={departments}
							labelKey="name"
							valueKey="id"
							iconLeftProps={{ name: 'multiUsers', fill: theme.color.general.dark }}
							fullWidth
							isClearable
							// @ts-ignore
							value={getSelectValue(fieldsSettings?.values, departments, {
								valueKey: 'id',
								labelKey: 'name',
							}) || ''}
							onChange={onDepartmentChoose}
						/>
					)}
				</>
			)}
			{props?.name === 'privacy_policy' && (
				<div className="rows">
					<Typography variant="button1" text={t('privacyPolicy')} color={theme.color.general.black} />
					<Switch
						id="policyRequired"
						checked={fieldsSettings?.is_required}
						onChange={(checked) => onSwitchChecked(checked, 'is_required')}
						disabled={!is_form_displayed}
					/>
					{fieldsSettings.is_required && (
						<Input
							type="text"
							iconLeftProps={{ name: 'linkSign', size: 16, fill: theme.color.general.dark }}
							label={t('link')}
							placeholder={t('privacyPolicy')}
							fullWidth
							value={fieldsSettings?.document_url}
							onChange={onURLChange}
							error={error}
							id="privacy_policy"
							required={fieldsSettings.is_required}
							disabled={!is_form_displayed}
						/>
					)}
				</div>
			)}
		</StyledFieldsCustomisation>
	);
};

export default InputWithSwitch;