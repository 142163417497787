import styled from 'styled-components';

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
`;

export const StyledModal = styled.div`
  position: relative;
  z-index: 1001;
  width: 478px;
  min-height: 190px;
  max-height: 300px;
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 6px rgb(0 0 0 / 10%);

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: 343px;
    min-height: 200px;
  }
  
  .closeIcon {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 20px 0 20px;
  overflow-wrap: anywhere;
`;

export const Buttons = styled.div`
  display: flex;
  width: 100%;
`;