import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const state = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 14} height={height || 14} viewBox="0 0 14 14" fill="none">
			<circle cx="7" cy="7" r="5" fill={fill} />
			<rect x="1" y="1" width="12" height="12" rx="6" stroke={theme.color.general.white} strokeWidth="2" />
		</svg>
	);
};