import React, { useRef } from 'react';

import Input from '../../atomic-design-components/molecules/Input/Input';
import useAutosizeTextArea from '../../hooks/useAutosizeTextArea';
import { IDummyData } from '../../types';

interface IProps {
	field: {
		maxLength?: number;
		iconLeftProps?: Record<string, unknown>;
		iconRightProps?: Record<string, unknown>;
		type: string;
		onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
		onInputValueChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
		multiline?: boolean;
		addElementToInput?: IDummyData;
		additionToInput?: React.ReactNode;
		min?: number;
		max?: number;
		rows?: number;
		step?: number;
	};
	isHidden?: boolean;
	disabled?: boolean;
	formSubmit?: () => void;
	isSubmitOnBlur?: boolean;
	name: string;
	required?: boolean;
	submitByEnterPressed?: boolean;
	t: (key: string) => string;
	updateInput: IDummyData;
	value: string;
}

const FormInput = (props: IProps) => {
	const {
		field,
		isHidden,
		disabled,
		formSubmit,
		isSubmitOnBlur,
		name,
		required,
		submitByEnterPressed,
		t,
		updateInput,
		value,
	} = props;

	const {
		iconLeftProps,
		iconRightProps,
		type,
		maxLength,
		onChange,
		multiline,
		addElementToInput,
		additionToInput,
		min,
		max,
		rows,
		step,
	} = field;

	const inputRef = useRef(null);
	useAutosizeTextArea(multiline && inputRef.current, value, 400);

	const onChangeInput = (field: {
		onInputValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	}) => (e: IDummyData) => {
		updateInput(e.target ? e : { target: { name, value: e } }, field.onInputValueChange);
	};

	// @ts-ignore
	return (
		<Input
			{...props}
			iconLeftProps={iconLeftProps}
			iconRightProps={iconRightProps}
			// @ts-ignore
			type={type}
			onBlur={isSubmitOnBlur ? formSubmit : undefined}
			// @ts-ignore
			onEnter={isSubmitOnBlur && !multiline && formSubmit}
			submitByEnterPressed={submitByEnterPressed}
			// @ts-ignore
			onChange={onChange || onChangeInput(field)}
			multiline={multiline || false}
			min={min}
			maxLength={maxLength}
			max={max}
			rows={rows}
			step={step}
			value={value || ''}
			primaryFocusColor
			required={required}
			inputRef={inputRef}
		>
			{additionToInput}
			{!isHidden && addElementToInput && addElementToInput(updateInput, t, disabled)}
		</Input>
	);
};

export default FormInput;