import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const callback = ({
	width, height, stroke,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 20} height={height || 20} fill="none" viewBox="0 0 20 20">
			<path
				d="M16.3126 13.4733L13.1574 11.5801C12.6916 11.3006 12.0902 11.4638 11.828 11.9367L11.1881 13.0913C11.1511 13.1579 11.0668 13.182 11.001 13.1441C9.35438 12.1946 7.81329 10.6553 6.86126 9.00796C6.8235 8.94266 6.84691 8.85836 6.91335 8.82109L8.06947 8.17225C8.54041 7.90798 8.69953 7.30827 8.42174 6.84526L6.52709 3.68739C6.26022 3.24261 5.67763 3.09157 5.22835 3.35079C3.75955 4.19798 3.84765 4.1408 3.79458 4.19349C2.00195 5.9725 3.36356 10.0372 6.66332 13.3371C9.96316 16.637 14.0287 17.9974 15.8065 16.2059C15.8596 16.1524 15.8031 16.2391 16.6492 14.7721C16.9084 14.3228 16.7574 13.7402 16.3126 13.4733Z"
				stroke={stroke || theme.color.general.white}
				strokeWidth="1.14"
			/>
			<path
				d="M16.8798 4.76074L15.2393 3.12011C15.0791 2.95996 14.8194 2.95996 14.6593 3.12011L12.0509 5.72854L11.1516 4.8291C10.9074 4.58495 10.4881 4.73452 10.4535 5.07795L10.0023 9.54866C9.97648 9.80454 10.1904 10.0215 10.4476 9.9983L14.9591 9.58814C15.3051 9.55667 15.4572 9.13489 15.212 8.88965L14.2715 7.94921L16.8798 5.34078C17.0401 5.18063 17.0401 4.92092 16.8798 4.76074Z"
				stroke={stroke || theme.color.general.white}
				strokeWidth="1.14"
			/>
		</svg>
	);
};