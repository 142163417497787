import React from 'react';
import { useTheme } from 'styled-components';
import ReactTexty from '../../../lib/Texty';

import Typography from '../../../atomic-design-components/atoms/Typography/Typography';
import { transformDate } from '../../../utils/dates';

const PlanPeriodEndDate = ({ cellData, rowData }: {
	cellData: string;
	rowData: { plan_id: number; planDueMilliseconds: number };
}) => {
	const theme = useTheme();
	const subscription_expire_at = rowData.plan_id === 1 ? '∞' : transformDate(cellData);
	const isPlanExpired = +rowData.planDueMilliseconds < Date.now();

	return (
		<Typography
			color={isPlanExpired && subscription_expire_at !== '∞' ? theme.color.status.error : theme.color.status.success}
			variant="body2"
			as={ReactTexty}
		>
			{subscription_expire_at}
		</Typography>
	);
};

export default PlanPeriodEndDate;