import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const off = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 17} height={height || 16} viewBox="0 0 17 16" fill="none">
			<path
				d="M8.99902 7.57031L8.99902 0.570312"
				stroke={fill || theme.color.secondary.darker}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.131 2.62892C15.2007 3.65516 15.9388 4.97794 16.2506 6.42713C16.5623 7.87632 16.4334 9.38564 15.8803 10.7609C15.3273 12.1363 14.3754 13.3147 13.1472 14.1446C11.9189 14.9745 10.4705 15.418 8.98814 15.418C7.5058 15.418 6.05736 14.9745 4.82912 14.1446C3.60088 13.3147 2.64901 12.1363 2.09596 10.7609C1.54291 9.38564 1.41396 7.87632 1.72571 6.42713C2.03745 4.97794 2.77563 3.65516 3.84529 2.62892"
				stroke={fill || theme.color.secondary.darker}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};