import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from 'styled-components';
import { useWidth } from '../../hooks/useWidth';
import Logo from '../Logo/Logo';

import { StyledTextWrapper, StyledLine, StyledFooterText } from './styled';
import Typography from '../../atomic-design-components/atoms/Typography/Typography';
import LanguageSelect from '../LanguageSelect/LanguageSelect';

const TextPagesWrapper = ({
	children, text, type,
}: {
	children?: React.ReactNode;
	text?: string;
	type: string;
}) => {
	const { t } = useTranslation('auth');
	const theme = useTheme();
	const width = useWidth();
	const isMobile = width && width < theme.breakpoints.lg;

	return (
		<StyledTextWrapper className={`${type}Wrapper`}>
			<div className="headerWrapper">
				<Logo variant="medium" isExtended={!isMobile} isTextPage to="../sign-in" />
				<LanguageSelect isIntroScreensSelect />
			</div>
			<StyledLine />
			<Typography
				variant={(isMobile && 'h2') || 'h1'}
				className="text"
				textAlign="left"
				text={t(text || type)}
				margin="0"
			/>
			<StyledLine />
			{children}
			<StyledFooterText>
				<Typography variant="body2" className="footerText">
					{`Skibble ${new Date().getFullYear()} © ${t('footerText')}`}
				</Typography>
			</StyledFooterText>
		</StyledTextWrapper>
	);
};

export default TextPagesWrapper;