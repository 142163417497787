import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const contacts = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
			<path
				d="M16.8572 18.9145H3.14291C2.77919 18.9145 2.43036 18.77 2.17317 18.5128C1.91597 18.2556 1.77148 17.9068 1.77148 17.5431V2.45737C1.77148 2.09364 1.91597 1.74481 2.17317 1.48762C2.43036 1.23043 2.77919 1.08594 3.14291 1.08594H16.8572C17.2209 1.08594 17.5698 1.23043 17.8269 1.48762C18.0841 1.74481 18.2286 2.09364 18.2286 2.45737V17.5431C18.2286 17.9068 18.0841 18.2556 17.8269 18.5128C17.5698 18.77 17.2209 18.9145 16.8572 18.9145Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.88574 1.08594V18.9145"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.6855 5.88672H13.4284"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};