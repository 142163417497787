import styled from 'styled-components';

import Link from '../../atomic-design-components/atoms/Link/Link';
import ExpansionPanel from '../../atomic-design-components/organisms/ExpansionPanel/ExpansionPanel';
import { convertHexToRGBA } from '../../utils/convertHexToRgba';

export const StyledForm = styled.div`
  form {
    position: relative;
    display: flex;
    flex-direction: column;

    .formInput.link {
      margin-top: 20px;
    }

    .firstInOrder {
      order: -2;
    }

    .secondInOrder {
      order: -1;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      height: 100%;
      background-color: ${({ theme }) => `${convertHexToRGBA(theme.color.general.white, 0.5)}`};
  
      .loader {
        position: relative;
        top: calc(50% - 16px);
        left: calc(50% - 16px);
      }
    }
  }

  .wrapper {
    margin-bottom: 25px;
  }

  .checkbox.formCheckbox {
    margin-bottom: 5px !important;
  }

  .isHidden {
    display: none;
  }
  
  #passwordSecurity .inputContainer:not(:last-child) {
    margin-bottom: 20px;
  }

  .formBlock {

    .infoIcon {
      display: inline-flex;
      margin-left: 10px;
      border: 1px solid ${({ theme }) => theme.color.general.dark};

      path {
        fill: ${({ theme }) => theme.color.general.dark};
      }
    }

    &.clientFilters .selectWrapper {

      .react-select__value-container .input {
        display: none;
      }

      &.focused {

        .react-select__option,
        .react-select__value-container {

          .icon path {
            stroke: none;
          }
        }
      }
    }
  }
`;

export const StyledFormButtons = styled.div`
  margin: 0 20px;
  background-color: ${({ theme }) => theme.color.general.white};

  button {

    .icon {
      margin-right: 6px;
    }
  }

  &.sticky {
    position: sticky;
    right: 20px;
    bottom: 0;
    left: 20px;
    z-index: 8;
    padding-bottom: 20px;

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 1}px) {
      right: 0;
      left: 0;
    }
  }

  .stickyButtonsWrapper {
    display: flex;
    border-radius: 5px;

    & button:not(:first-child) {
      margin-left: 10px;
    }

    .cancelButton {
      flex-shrink: 1;
    }
  }
`;

export const StyledAdditionToInputLink = styled(Link)`
  position: absolute;
  top: -8px;
  right: 9px;
  z-index: 2;
  padding: 0 7px;
  border-radius: 7px;
  background-color: #fff;

  a {
    font-size: 12px;
    line-height: 15px;
  }
`;

export const StyledExpansionPanel = styled(ExpansionPanel)`
  position: relative;
  padding: 20px;
  border-top: 1px solid ${({ theme }) => theme.color.general.lighter};

  &:first-child {
    border: none;
  }

  &.hidden {
    display: none;
  }
`;