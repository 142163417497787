import { useEffect } from 'react';
import { IDummyData } from '../types';

const useAutosizeTextArea = (
	textAreaRef: IDummyData,
	value: IDummyData,
	maxHeight: number,
	initialHeight = 0,
) => {
	useEffect(() => {
		if (textAreaRef) {
			textAreaRef.style.height = `${initialHeight}px`;
			const { scrollHeight } = textAreaRef;

			if (scrollHeight <= maxHeight) {
				textAreaRef.style.height = `${scrollHeight}px`;
			} else {
				textAreaRef.style.height = `${maxHeight}px`;
			}
		}
	}, [textAreaRef, value]);
};

export default useAutosizeTextArea;