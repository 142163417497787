import styled from 'styled-components';
import Input from '../Input/Input';

export const StyledInput = styled(Input)<{
	active: boolean;
	extraPadding: number;
}>`
  &.inputWrapper {

    .withInsideButtons {
      margin-bottom: 0;
      padding-bottom: ${({ active, extraPadding }) => active && `calc(60px + ${extraPadding}px) !important`};
    }

    .multiline {
      min-height: 56px;
    }

    &.hasError {
      margin-bottom: 0;
      border-color: ${({ theme }) => theme.color.status.error};
    }

    &:focus-within:not(.searchInput, .hasError) {
      outline: none;
      border: 1px solid ${({ theme }) => theme.color.general.light};
    }

    .ql-editor {
      max-height: 95px;
    }

    .attachButton .icon {
      margin: 0;
    }
  }
`;

export const StyledInputContainer = styled.div`
  flex-grow: 1;
  position: relative;

  .uploadItemsListContainer {
    bottom: 45px;
    left: 15px;

    &.hasError {
      bottom: 70px;
    }
  }
`;