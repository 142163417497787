import { RESET_FORM_VALUES, SET_FORM_VALUES, SIGN_OUT } from '../constants';
import { IAction, IFormsState } from '../../types';

const defaultState: IFormsState = {
	formValues: {},
};

export default (state = defaultState, action = {} as IAction): IFormsState => {
	switch (action.type) {
		case SET_FORM_VALUES:
			return {
				...state,
				[action.payload.name || 'formValues']: { ...action.payload.values },
			};
		case RESET_FORM_VALUES:
			return {
				...state,
				[action.payload.name || 'formValues']: {},
			};
		case SIGN_OUT:
			return defaultState;
		default:
			return state;
	}
};