import { pick } from 'dot-object';
import React, { useEffect, useState } from 'react';

import FlexRow from '../../atomic-design-components/atoms/FlexRow/FlexRow';
import Icon from '../../atomic-design-components/atoms/Icon/Icon';
import Tabs from '../../atomic-design-components/molecules/Tabs/Tabs';
import Typography from '../../atomic-design-components/atoms/Typography/Typography';
import { FORM_CONFIGS } from '../../config/forms';
import { SUPPORTED_CURRENCIES } from '../../constants';
import { SUPPORTED_LNGS } from '../../locales';
import FormFields from './FormFields';
import { IDummyData } from '../../types';

const defaultTabs = {
	translations: 'uk',
	prices: 'UAH',
};

const FormBlockTabs = ({
	blockKey, formFieldsProps = {}, fields, type,
}: {
	blockKey: string;
	formFieldsProps: IDummyData;
	fields: IDummyData;
	type: string;
}) => {
	const { isReadOnly } = formFieldsProps;
	const [tabs, setTabs] = useState([]);
	const [errorTabIndex, setErrorTabIndex] = useState({ index: -1 });

	useEffect(() => {
		const inputWithError = Object.keys(formFieldsProps.formErrors)
			.filter((error) => fields.filter((field: { key: string }) => error.includes(field.key)));

		let errorId;
		inputWithError.forEach((el) => {
			errorId = tabs.find((tab) => el.includes(`.${tab}`));
		});
		// @ts-ignore
		const index = tabs.indexOf(errorId);

		setErrorTabIndex({ ...errorTabIndex, index });
	}, [formFieldsProps.formErrors]);

	const getTabOptions = () => {
		const tabsKeys = FORM_CONFIGS[type]?.withTabs
			.filter((key: string) => fields.some((field: { key: string }) => field.key === key));

		if (!tabsKeys) return;

		// @ts-ignore
		let tabs = [defaultTabs[blockKey]];
		tabsKeys.forEach((key: string) => {
			const tabValueObj = pick(key, formFieldsProps.formValues) || {};

			Object.keys(tabValueObj).forEach((lng) => {
				if (!tabs.includes(lng) && tabValueObj[lng]) {
					tabs = [...tabs, lng];
				}
			});
		});

		return tabs;
	};

	useEffect(() => {
		const tabs = getTabOptions();

		if (tabs) {
			// @ts-ignore
			setTabs(tabs);
		}
	}, [formFieldsProps.initialValues?.id]);

	const getTabScreen = (tabKey: string) => (
		// @ts-ignore
		<FormFields
			fields={fields}
			tabKey={tabKey}
			{...formFieldsProps}
		/>
	);

	const onDropItemClick = (id: string) => {
		// @ts-ignore
		setTabs([...tabs, id]);
	};

	const getTabTitle = (title: string) => (
		// @ts-ignore
		<FlexRow id={title} gap="6px">
			{blockKey === 'translations' && <Icon width={16} height={12} name={`flag${title.toUpperCase()}`} />}
			<Typography text={title} textTransform="uppercase" />
		</FlexRow>
	);

	const getDropdownItems = () => {
		if (blockKey === 'prices') {
			return SUPPORTED_CURRENCIES
				// @ts-ignore
				.filter((currency) => !tabs.includes(currency)).map((currency) => ({
					id: currency,
					label: currency.toUpperCase(),
				}));
		}
		// @ts-ignore
		return SUPPORTED_LNGS.filter((lng) => !tabs.includes(lng)).map((lng) => ({
			id: lng,
			label: lng.toUpperCase(),
		}));
	};

	return (
		<Tabs
			isDisabled={isReadOnly}
			withAddAction
			// @ts-ignore
			tabsTitles={tabs.map((tab) => getTabTitle(tab))}
			// @ts-ignore
			tabsContents={tabs.map((tab) => getTabScreen(tab))}
			// @ts-ignore
			dropdownItems={getDropdownItems()}
			onDropItemClick={onDropItemClick}
			typographyType="link"
			errorTabIndex={errorTabIndex}
		/>
	);
};

export default FormBlockTabs;