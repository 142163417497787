import React from 'react';
import { useTranslation } from 'react-i18next';

import TextPagesWrapper from '../../components/IntroScreens/TextPagesWrapper';
import Typography from '../../atomic-design-components/atoms/Typography/Typography';

const TermsAndConditions = () => {
	const { t } = useTranslation('termsAndConditions');
	const href = 'https://www.skibble.chat/';
	return (
		<TextPagesWrapper type="termsAndConditions">
			<Typography variant="body1" text={t('text01')} className="inline" />
			<a href={href} target="_blank" rel="noreferrer">
				<Typography variant="body1" text={t('widgetLink')} className="inline" />
			</a>
			<Typography variant="body1" text={t('text02')} />
			<a href={href} target="_blank" rel="noreferrer">
				<Typography variant="body1" text={t('link')} className="inline" />
			</a>
			<Typography variant="body1" text={t('text1')} />
			<Typography variant="h3" text={t('header1')} />
			<Typography variant="body1" text={t('text2')} />
			<Typography variant="h3" text={t('header2')} />
			<Typography variant="body1" text={t('text3')} />
		</TextPagesWrapper>
	);
};

export default TermsAndConditions;