import { globalHandleError } from './globalHandleError';
import { LocalStorageKeys } from '../types';

export const getDataObjectFromLocalStorage = (key: LocalStorageKeys) => {
	try {
		const data = localStorage.getItem(key);

		if (data) {
			return JSON.parse(data);
		}
	} catch (error) {
		globalHandleError({
			module: 'utils',
			subModule: 'getDataObjectFromLocalStorage',
			error,
		});
	}

	return null;
};