import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const reply = ({
	width, height, fill, stroke,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 20} height={height || 20} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill={fill || theme.color.general.white} d="M0 0h20v20H0z" />
			<path d="M17.4286 16.2858c-3.3406-2.8263-4.1623-4-7.4286-4H8.2857v3.4286L2.5714 9.4286l5.7143-5.7142v3.4285h1.1428c5.7143 0 6.8572 5.7143 8.0001 9.1429Z" stroke={stroke || theme.color.general.dark} strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};