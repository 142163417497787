import React, { useEffect } from 'react';
import { IRefType } from '../types';

const useClickOutside = (ref: IRefType, callback: () => void) => {
	useEffect(() => {
		const handleClickOutside = (event: React.MouseEvent<Element, MouseEvent>) => {
			if (ref.current && !ref.current.contains(event.target)) {
				callback();
			}
		};

		// @ts-ignore
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// @ts-ignore
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [callback, ref]);
};

export default useClickOutside;