import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const coins = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 17} height={height || 17} viewBox="0 0 17 17" fill="none">
			<path
				d="M10.7854 9.57143C13.6258 9.57143 15.9283 8.54808 15.9283 7.28571C15.9283 6.02335 13.6258 5 10.7854 5C7.94511 5 5.64258 6.02335 5.64258 7.28571C5.64258 8.54808 7.94511 9.57143 10.7854 9.57143Z"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.64258 7.28613V14.1433C5.64258 15.4004 7.92829 16.429 10.7854 16.429C13.6426 16.429 15.9283 15.4004 15.9283 14.1433V7.28613"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.9283 10.7139C15.9283 11.971 13.6426 12.9996 10.7854 12.9996C7.92829 12.9996 5.64258 11.971 5.64258 10.7139"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.6713 2.71441C9.33585 1.89027 7.78138 1.49169 6.21415 1.57155C3.36843 1.57155 1.07129 2.60013 1.07129 3.85727C1.07129 4.53155 1.73415 5.13727 2.78557 5.57155"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.78557 12.4289C1.73415 11.9946 1.07129 11.3889 1.07129 10.7146V3.85742"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.78557 9.00042C1.73415 8.56613 1.07129 7.96042 1.07129 7.28613"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};