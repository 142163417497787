import { IApiSchema } from './types';

export enum EnumChatEventType {
	operator_connected = 'operator_connected',
	contact_online = 'contact_online',
	contact_offline = 'contact_offline',
	plan_prolonged = 'plan_prolonged',
	plan_updated = 'plan_updated',
	chat_closed = 'chat_closed',
	chat_reopened = 'chat_reopened',
	contact_not_typing = 'contact_not_typing',
	contact_typing = 'contact_typing',
	chat_started = 'chat_started',
	contact_updated = 'contact_updated',
	forwarded_operator = 'forwarded_operator',
	forwarded_department = 'forwarded_department',
	operator_joined = 'operator_joined',
	operator_rated = 'operator_rated',
	contact_message = 'contact_message',
	operator_message = 'operator_message',
	system_message = 'system_message',
	callback_message = 'callback_message',
	operator_not_typing = 'operator_not_typing',
	operator_typing = 'operator_typing',
	contact_connected = 'contact_connected',
	operator_offline = 'operator_offline',
	operator_online = 'operator_online',
	bulk_message = 'bulk_message',
}

export interface IWebsocketSimpleMessage {
	type: EnumChatEventType.operator_not_typing
		| EnumChatEventType.operator_typing
		| EnumChatEventType.contact_connected
		| EnumChatEventType.operator_connected
		| EnumChatEventType.operator_offline
		| EnumChatEventType.operator_online
		| EnumChatEventType.plan_updated
		| EnumChatEventType.plan_prolonged
}

export interface IWebsocketSimpleContactMessage {
	type: EnumChatEventType.contact_online
		| EnumChatEventType.contact_offline
		| EnumChatEventType.contact_typing
		| EnumChatEventType.contact_not_typing
	contact_id: number
}

export interface IWebsocketOperatorMessage {
	type: EnumChatEventType.contact_message
		| EnumChatEventType.operator_message
		| EnumChatEventType.system_message
		| EnumChatEventType.bulk_message
		| EnumChatEventType.callback_message
		| EnumChatEventType.contact_updated
		| EnumChatEventType.operator_joined
		| EnumChatEventType.chat_started
		| EnumChatEventType.forwarded_operator
		| EnumChatEventType.forwarded_department
	id: number
	chat_id: number
	channel: IApiSchema['ChatChannelType']
	data: IApiSchema['IChatEventBrief']['data']
	chat_data: Record<string, never>;
	at: string
	contact_id: number
	contact_uid: string
	widget_id: number
	widget_name?: string
	license_uid: string
	operator_id?: number
	customer_id?: number
	read_allowed_ids: number[]
	chat_operator_id?: number
	operator_name?: string
	operator_pic?: string
	status: IApiSchema['ChatStatus']
}

export interface IWebsocketSuccessMessage {
	status: 'ok';
	type: EnumChatEventType;
	event_obj?: IWebsocketOperatorMessage;
}

export interface IWebsocketFailMessage {
	status: 'error';
	type: EnumChatEventType;
	detail: string;
	message_id?: number;
}

export type IWebsocketMessage = IWebsocketSimpleMessage
	| IWebsocketSimpleContactMessage
	| IWebsocketOperatorMessage
	| IWebsocketSuccessMessage
	| IWebsocketFailMessage;