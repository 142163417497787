import { css, DefaultTheme } from 'styled-components';

export type TComponent = keyof DefaultTheme['components'] | keyof DefaultTheme['font']['size'] | undefined;

export const getCssFromTheme = (theme: DefaultTheme, component: TComponent, type = '') => {
	// @ts-ignore
	const mainObject = theme.components[component] || theme.font.size[component];
	const cssObject = type ? mainObject?.[type] : mainObject;

	if (cssObject) {
		return css`
  ${Object.keys(cssObject).reduce((accString, cssKey) => `${accString}${cssKey}: ${cssObject[cssKey]};`, '')};
    `;
	}

	return '';
};