import styled from 'styled-components';
import { getCssFromTheme, TComponent } from '../../../utils/css';

export const StyledCheckboxWrapper = styled.div<{
	labelType?: TComponent;
}>`
  ${({ theme, labelType }) => getCssFromTheme(theme, labelType)};
  
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  & input {
    position: absolute;
    z-index: 1;
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0;
  }

  &.disabled {
    color: ${({ theme: { color } }) => color.general.light};
    cursor: not-allowed;

    & input {
      cursor: not-allowed;
    }

    svg {

      > circle {
        fill: ${({ theme: { color } }) => color.general.lighter};
      }

      rect {
        fill: ${({ theme: { color } }) => color.general.lighter};
        stroke: ${({ theme: { color } }) => color.general.lighter};
      }

      g {
        filter: none;
      }
    }
  }

  & .typography {
    margin-left: 10px;
  }
`;