import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useIsPasswordShown } from '../../hooks/useIsPasswordShown';
import CustomPasswordChecklist from '../../components/IntroScreens/components/CustomPasswordChecklist';
import Form from '../../components/Form/Form';
import { FORM_CONFIGS } from '../../config/forms';
import Button from '../../atomic-design-components/molecules/Button/Button';
import { userSignUp } from '../../redux-saga/actions';
import { selectUserSystemParams } from '../../redux-saga/selectors';

const FormCreate = ({ initialValues }: {
	initialValues: {
		full_name: string;
		company_name: string;
		company_phone: string;
		email: string;
	};
}) => {
	const [password, setPassword] = useState('');
	const [isPasswordValid, setIsPasswordValid] = useState<boolean | undefined>(undefined);
	const [isSubmitPressed, setIsSubmitPressed] = useState(false);

	const { inProgress } = useSelector(selectUserSystemParams);

	const { t } = useTranslation('auth');
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { type, iconRightProps } = useIsPasswordShown();

	const onFormSubmit = (credentials: {
		full_name: string;
		company_name: string;
		company_phone: string;
		email: string;
		code: string;
		password: string;
	}) => {
		dispatch(
			userSignUp(
				{ ...credentials, company_phone: `+${credentials.company_phone.replace(/\D/g, '')}` },
				navigate,
			),
		);
	};

	return (
		<Form
			className="form"
			type="signUp"
			// @ts-ignore
			withCustomValidationRule
			initialValues={initialValues}
			// @ts-ignore
			onSubmit={onFormSubmit}
			validationRules={{
				full_name: ['required'],
				company_name: ['required'],
				company_phone: ['required', 'phone'],
				email: ['required', 'email'],
				password: ['required', { type: 'password', isPasswordValid }, 'exceptCyrillicSymbol'],
			}}
			fields={[
				FORM_CONFIGS.signUp.fields[0],
				FORM_CONFIGS.signUp.fields[1],
				FORM_CONFIGS.signUp.fields[2],
				FORM_CONFIGS.signUp.fields[3],
				{
					...FORM_CONFIGS.signUp.fields[4],
					type,
					iconRightProps,
					onInputValueChange: (value: string) => setPassword(value),
				},
			]}
		>
			<CustomPasswordChecklist
				password={password}
				setIsPasswordValid={setIsPasswordValid}
				className={isSubmitPressed && !isPasswordValid ? 'passError' : ''}
			/>
			<Button
				fullWidth
				variant="primary"
				type="submit"
				isLoading={inProgress}
				iconName="peopleSign"
				onClick={() => setIsSubmitPressed(true)}
			>
				{t('signUp')}
			</Button>
		</Form>
	);
};

export default FormCreate;