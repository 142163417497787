import styled from 'styled-components';
import Userpic from '../../atomic-design-components/atoms/Userpic/Userpic';
import Icon from '../../atomic-design-components/atoms/Icon/Icon';

export const StyledPic = styled(Userpic)<{
	i: number,
}>`
  position: absolute;
  left: ${({ i }) => i * 19}px;
`;

export const StyledIcon = styled(Icon)<{
	i: number,
}>`
  position: absolute;
  left: ${({ i }) => i * 19}px;
  border-radius: 50%;
`;

export const StyledUserpicsMore = styled.div<{
	i: number,
}>`
  position: absolute;
  left: ${({ i }) => i * 19}px;
  width: 32px;
  height: 32px;
  padding: 6px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.secondary.lightest};
  color: ${({ theme }) => theme.color.secondary.darker};
  text-align: center;

  .activeRow & {
    background-color: ${({ theme }) => theme.color.secondary.main};
    color: ${({ theme }) => theme.color.general.white};
  }
`;