import { globalHandleError } from '../utils/globalHandleError';

interface IProps {
	children: JSX.Element;
}

const ErrorBoundary = ({ children }: IProps) => {
	try {
		return children || null;
	} catch (error) {
		globalHandleError({
			module: 'ErrorBoundary',
			subModule: 'catch block',
			error,
		});

		return null;
	}
};

export default ErrorBoundary;