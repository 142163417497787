import React, { useEffect, useState } from 'react';

import { StyledButtonWrapper, StyledQuantityInput } from './styled';

const toString = (value: string | number) => (typeof value === 'number' ? value.toString(10) : value);

const toNumber = (value: string | number | undefined) => {
	if (value && typeof value === 'string') {
		return +value;
	}
	return value;
};

const QuantityInput = ({
	onChange,
	disabled,
	initialValue,
	inputClassName,
	value,
	min,
	max,
	placeholder,
	step,
	withQuantityButtons,
	...otherProps
}: {
	fullWidth?: boolean;
	onChange?: (value: number) => void;
	className?: string;
	disabled?: boolean;
	initialValue?: string | number;
	inputClassName?: string;
	isOneNumberInput?: boolean;
	value?: string | number;
	min?: number;
	max?: number;
	placeholder?: string;
	step?: number;
	withQuantityButtons?: boolean;
}) => {
	const [inputValue, setInputValue] = useState(toString(initialValue || '') || '');

	useEffect(() => {
		if (typeof value !== 'undefined') {
			setInputValue(toString(value) || '');
		}
	}, [value]);

	useEffect(() => {
		if (typeof inputValue === 'string' && inputValue?.charAt(0) === '0' && inputValue?.length > 1) {
			setInputValue(inputValue.substring(1));
		}
	}, [inputValue]);

	const checkMinMax = (inputValue: string) => !((min !== undefined && +inputValue < min)
		|| (max !== undefined && +inputValue > max));

	const onButtonClick = (changeDirection: string) => (e: React.MouseEvent) => {
		e.preventDefault();
		const getNewValue = (oldValue: string) => {
			const numValue = +oldValue + +`${changeDirection}1` * (+(step || 1) || 1);
			return numValue.toString(10);
		};
		const checkPassed = checkMinMax(getNewValue(inputValue));
		if (checkPassed) {
			setInputValue((prevState) => getNewValue(prevState));

			if (onChange) {
				const val = toNumber(getNewValue(inputValue) || '0');
				// @ts-ignore
				onChange(val || 0);
			}
		}
	};

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		const checkPassed = checkMinMax(value);

		if (!Number.isNaN(+value) && checkPassed) {
			setInputValue(value);

			if (onChange) {
				// @ts-ignore
				onChange(e, toNumber(value));
			}
		}
	};

	return (
		<>
			<StyledQuantityInput
				step={step}
				type="number"
				disabled={disabled}
				value={inputValue}
				onChange={onInputChange}
				placeholder={placeholder}
				className={inputClassName}
				{...otherProps}
			/>
			{withQuantityButtons && !disabled && (
				<StyledButtonWrapper>
					<div onClick={onButtonClick('+')}>
						+
						{/* <Icon name="plus" /> */}
					</div>
					<div onClick={onButtonClick('-')}>
						-
						{/* <Icon name="minus" /> */}
					</div>
				</StyledButtonWrapper>
			)}
		</>
	);
};

export default QuantityInput;