import styled from 'styled-components';
import { getCssFromTheme } from '../../../utils/css';

export const StyledBadgeWrapper = styled.div<{ backgroundColor?: string }>`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  flex-shrink: 0;

  &.invisible {
    display: none;
  }

  &.menuItemBadge {
    position: absolute;
    top: 17px;
    right: 25px;

    &:not(.isMini) {
      opacity: 1;
      animation: toBadgeMini 0.7s ease-in;
    }

    &.isMini {
      position: absolute;
      top: 12px;
      right: -7px;
      width: 12px;
      height: 12px;
      margin-left: auto;
      border-radius: 50%;
      background-color: ${({ backgroundColor }) => backgroundColor};
      opacity: 1;

      span {
        position: relative;
        top: 2px;
        right: -2px;
        min-width: 8px;
        height: 8px;
        padding: 0;
        transform: none;
      }
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {

    &.menuItemBadge.isMini {
      right: -2px;
    }
  }

  @keyframes toBadgeMini {

    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const StyledBadge = styled.span<{ variant?: string, backgroundColor?: string, borderColor?: string }>`
  ${({ theme, variant }) => getCssFromTheme(theme, 'badge', variant)};

  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 18px;
  height: 18px;
  padding: 0 5px;
  box-sizing: border-box;
  border: ${({ borderColor }) => borderColor && `1px solid ${borderColor}`};
  border-radius: 12px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};

  &.badgeOnly {
    position: relative;
  }

  &.invisible {
    transition: transform 195ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  &.anchorTopRight {
    top: 0;
    right: 0;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;

    &.invisible {
      transform: scale(0) translate(50%, -50%);
    }
  }

  &.anchorTopLeft {
    top: 0;
    left: 0;
    transform: scale(1) translate(-50%, -50%);
    transform-origin: 0% 0%;

    &.invisible {
      transform: scale(0) translate(-50%, -50%);
    }
  }

  &.anchorBottomRight {
    right: 0;
    bottom: 0;
    transform: scale(1) translate(50%, 50%);
    transform-origin: 100% 100%;

    &.invisible {
      transform: scale(0) translate(50%, 50%);
    }
  }

  &.anchorBottomLeft {
    bottom: 0;
    left: 0;
    transform: scale(1) translate(-50%, 50%);
    transform-origin: 0% 100%;

    &.invisible {
      transform: scale(0) translate(-50%, 50%);
    }
  }
`;