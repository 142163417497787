import styled from 'styled-components';
import Switch from 'react-switch';

export const StyledSwitch = styled(Switch)`
  border-radius: 2em !important;

  &.disabled {
    cursor: not-allowed;
  }
`;

export const StyledSwitchLabel = styled.label<{
	justifyContent?: string;
	margin?: string;
	gap?: string;
}>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;
  margin: ${({ margin }) => margin};
  cursor: auto;
  gap: ${({ gap }) => gap};

  .typography {
    cursor: default;
  }

  &.revert {

    .switch {
      order: 1;
    }
  }
`;