import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const infoCircle = ({
	width, height, fill, stroke,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill={fill || 'none'}>
			<g clipPath="url(#clip0_246_82540)">
				<path
					d="M7.99986 15.4284C12.1025 15.4284 15.4284 12.1025 15.4284 7.99986C15.4284 3.89717 12.1025 0.571289 7.99986 0.571289C3.89717 0.571289 0.571289 3.89717 0.571289 7.99986C0.571289 12.1025 3.89717 15.4284 7.99986 15.4284Z"
					stroke={stroke || theme.color.general.black}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M8 8V12"
					stroke={stroke || theme.color.general.black}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M8.00014 5.31278C8.31573 5.31278 8.57157 5.05694 8.57157 4.74135C8.57157 4.42576 8.31573 4.16992 8.00014 4.16992C7.68455 4.16992 7.42871 4.42576 7.42871 4.74135C7.42871 5.05694 7.68455 5.31278 8.00014 5.31278Z"
					stroke={stroke || theme.color.general.black}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_246_82540">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};