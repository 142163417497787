import { TIMEZONES } from './tz.data';
import { IDummyData } from '../types';

const findTzByKey = (key: string) => {
	let modifiedKey = key;
	if (key === 'Europe/Kyiv') {
		modifiedKey = 'Europe/Kiev';
	}
	return TIMEZONES.find((item) => item.name === modifiedKey);
};

const newTzItem = (_ref: { data: IDummyData; displayName: string; offset: string | number; }) => {
	const { data } = _ref;
	const { displayName } = _ref;
	const { offset } = _ref;
	return {
		value: data.name,
		label: '(GMT'.concat(data.offset, ') ').concat(displayName),
		included: [data.name],
		country: data.country,
		offset: data.offset,
		offsetValue: offset,
	};
};

const clientTz = () => {
	const clientTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const clientTzObj = findTzByKey(clientTz);
	return clientTzObj?.status === 'Deprecated' ? clientTzObj.link : clientTz;
};

const proceedDependences = (item: IDummyData) => {
	const newItem = { ...item };
	// proceed name offset
	const offsetParts = newItem.offset.split(/\d+/g);
	let offsetPrefix;

	if (offsetParts[0] === '+') {
		offsetPrefix = '+';
	} else {
		offsetPrefix = '-';
	}

	newItem.offset = newItem.offset.replace(offsetParts[0], offsetPrefix);
	const offset = +newItem.offset.replace(':', ''); // proceed name

	const nameArr = newItem.name.split('/').slice(1);
	let displayName = nameArr.join('-').replace(/_/g, ' '); // proceed key

	if (displayName === 'Kiev') displayName = 'Kyiv';
	if (displayName === 'Zaporozhye') displayName = 'Zaporizhzhia';
	const key = ''.concat(newItem.country, '_').concat(newItem.offset);
	return {
		key,
		offset,
		displayName,
	};
};

const listTz = () => {
	const newTz = TIMEZONES.reduce((obj, item) => {
		const newObj = { ...obj };
		if (item.status === 'Deprecated') {
			if (item.link) {
				// proceed tz item by using linking item
				const linkingItem = findTzByKey(item.link);

				const proceedDependencesUpd = proceedDependences(linkingItem || {});
				const { key } = proceedDependencesUpd;
				const { displayName } = proceedDependencesUpd;
				const { offset } = proceedDependencesUpd;

				// @ts-ignore
				if (obj[key]) {
					// @ts-ignore
					obj[key].included.push(item.name);
				} else {
					// @ts-ignore
					newObj[key] = newTzItem({
						data: linkingItem || {},
						displayName,
						offset,
					});
				}
			}
		} else if (item.country === '') {
			// todo
		} else {
			// proceed tz item
			const proceedDependences2 = proceedDependences(item);
			const newKey = proceedDependences2.key;
			const displayNameUpd = proceedDependences2.displayName;
			const offsetUpd = proceedDependences2.offset;

			// @ts-ignore
			if (newObj[newKey]) {
				// @ts-ignore
				if (newObj[newKey].included && !newObj[newKey].included.includes(item.name)) {
					// @ts-ignore
					newObj[newKey].label += ', '.concat(displayNameUpd);

					// @ts-ignore
					newObj[newKey].included.push(item.name);
				}
			} else {
				// @ts-ignore
				newObj[newKey] = newTzItem({
					data: item,
					displayName: displayNameUpd,
					offset: offsetUpd,
				});
			}
		}

		return newObj;
	}, {});

	// @ts-ignore
	return Object.values(newTz).sort((a, b) => a.offsetValue - b.offsetValue);
};

const findTzByName = (name: string, list?: IDummyData[]) => {
	let modifiedName = name;

	if (name === 'Europe/Kyiv') {
		modifiedName = 'Europe/Kiev';
	}
	const data = list || (listTz() as { included?: string[] }[]);
	return data.find((item) => item.included && item.included.includes(modifiedName));
};

export {
	listTz,
	clientTz,
	findTzByName,
	TIMEZONES,
	findTzByKey,
};