import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const warningSign = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 17} viewBox="0 0 16 17" fill="none">
			<path
				d="M8 4.5V7.92857"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.00014 11.929C8.31573 11.929 8.57157 11.6732 8.57157 11.3576C8.57157 11.042 8.31573 10.7861 8.00014 10.7861C7.68455 10.7861 7.42871 11.042 7.42871 11.3576C7.42871 11.6732 7.68455 11.929 8.00014 11.929Z"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.13454 1.42893L0.928167 7.63531C0.450612 8.11286 0.450612 8.88714 0.928167 9.36469L7.13454 15.5711C7.6121 16.0486 8.38637 16.0486 8.86393 15.5711L15.0703 9.36469C15.5479 8.88714 15.5479 8.11286 15.0703 7.63531L8.86393 1.42893C8.38637 0.951377 7.6121 0.951377 7.13454 1.42893Z"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};