import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const folder = ({
	width, height, stroke,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
			<g clipPath="url(#clip0_1446_30780)">
				<path
					d="M0.570325 13.4485C0.570325 13.7113 0.674684 13.9632 0.860466 14.149C1.04625 14.3348 1.29825 14.4392 1.56098 14.4392H14.4397C14.7024 14.4392 14.9544 14.3348 15.1401 14.149C15.3259 13.9632 15.4303 13.7113 15.4303 13.4485M0.570325 13.4485L0.570312 6.51386L0.570325 6.50893M0.570325 13.4485V6.50893M15.4303 13.4485V4.03721C15.4303 3.77445 15.3259 3.52247 15.1401 3.33669C14.9544 3.15091 14.7024 3.04652 14.4397 3.04652H6.42932C6.16658 3.04652 5.91461 2.94216 5.7288 2.75638C5.4673 2.49482 5.08467 2.11225 4.82317 1.85069C4.63735 1.66491 4.38538 1.56055 4.12265 1.56055H1.56098C1.01384 1.56055 0.570325 2.00407 0.570325 2.55121V6.50893M15.4303 13.4485L15.4303 6.51386C15.4303 6.25113 15.3259 5.99912 15.1401 5.81334C14.9543 5.62756 14.7024 5.5232 14.4396 5.5232H1.56097C1.29824 5.5232 1.04623 5.62756 0.860484 5.81334C0.675833 5.99796 0.571621 6.24798 0.570325 6.50893"
					stroke={stroke || theme.color.general.black}
					strokeWidth="1.14"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
				/>
			</g>
			<defs>
				<clipPath id="clip0_1446_30780">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};