import React from 'react';
import { useTranslation } from 'react-i18next';

import TextPagesWrapper from '../../components/IntroScreens/TextPagesWrapper';
import Typography from '../../atomic-design-components/atoms/Typography/Typography';

const PrivacyPolicy = () => {
	const { t } = useTranslation('privacyPolicy');
	const href = 'https://zakon.rada.gov.ua/laws/show/2297-17#Text';
	return (
		<TextPagesWrapper type="privacyPolicy">
			<Typography variant="h3" text={t('header1')} />
			<Typography variant="body1" text={t('text1')} />
			<Typography variant="h3" text={t('header2')} />
			<Typography variant="body1" text={t('text2')} />
			<Typography variant="h3" text={t('header3')} />
			<Typography variant="body1" text={t('text3')} />
			<Typography variant="h3" text={t('header4')} />
			<Typography variant="body1" text={t('text4')} className="inline" />
			<a href={href} target="_blank" rel="noreferrer">
				<Typography variant="body1" text={t('link')} className="inline" />
			</a>
			<Typography variant="body1" text={t('text5')} />
		</TextPagesWrapper>
	);
};

export default PrivacyPolicy;