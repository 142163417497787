import React from 'react';

import { StyledRating } from './styled';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';
import theme from '../../../theme';

const RatingView = (props: {
	fill: string;
	value?: string | number;
	cellData: string | number;
}) => {
	const {
		value, fill, cellData,
	} = props;

	return (
		<StyledRating>
			<Icon
				name="starSign"
				fill={(value || cellData) === 0 ? theme.color.general.lightest : fill}
				stroke={
					(value || cellData) === 0
						? theme.color.general.lighter
						: theme.color.primary.lighter
				}
			/>
			<Typography variant="button2" text={value || cellData} margin="0 0 0 8px" />
		</StyledRating>
	);
};

export default RatingView;