import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const clockReaction = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 18} height={height || 18} viewBox="0 0 18 18" fill="none">
			<g clipPath="url(#clip0_14012_5624)">
				<path
					d="M8.68555 10.2572V3.34286C9.59354 3.34286 10.4926 3.52171 11.3315 3.86918C12.1704 4.21666 12.9326 4.72596 13.5747 5.36801C14.2167 6.01006 14.726 6.77229 15.0735 7.61117C15.421 8.45005 15.5998 9.34915 15.5998 10.2572H8.68555Z"
					stroke={fill || theme.color.general.light}
					strokeWidth="1.14"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M6.7998 0.828583H10.5712"
					stroke={fill || theme.color.general.light}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M8.68555 0.828583V3.34287"
					stroke={fill || theme.color.general.light}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M14.9717 2.71429L16.2288 3.97144"
					stroke={fill || theme.color.general.light}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M3.7959 5.36688L5.12847 6.69945"
					stroke={fill || theme.color.general.light}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M1.77148 10.2571H3.6572"
					stroke={fill || theme.color.general.light}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M3.7959 15.1475L5.12847 13.8149"
					stroke={fill || theme.color.general.light}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M9 15.2857V17.1715"
					stroke={fill || theme.color.general.light}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M13.4502 13.4503L14.7828 14.7829"
					stroke={fill || theme.color.general.light}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_14012_5624">
					<rect width="17.6" height="17.6" fill="white" transform="translate(0.200195 0.200012)" />
				</clipPath>
			</defs>
		</svg>
	);
};