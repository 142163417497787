import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import MenuItem from './MenuItem';
import { StyledMenuItems } from './styled';
import { IDummyData } from '../../types';

const MenuGroup = ({
	entitiesArr,
	children,
	isExtended,
	isMobile,
	textColor,
	onClick,
	margin,
	padding,
	isFreePlan,
	isRoleSensitive = false,
	role,
}: {
	entitiesArr?: IDummyData[];
	children?: IDummyData;
	isExtended?: boolean;
	isMobile?: boolean;
	textColor?: string;
	onClick?: () => void;
	margin?: string;
	padding?: string;
	isFreePlan?: boolean;
	isRoleSensitive?: boolean;
	role?: string;

}) => {
	const location = useLocation();
	const pathname = location?.pathname;

	const { action } = useParams();

	const isActiveKey = (key: string) => (pathname && pathname !== '/'
		? pathname
			.replace(/[^a-zA-Z ]/g, '')
			.replace(action || '', '')
			.includes(key.toLowerCase())
		: key === 'dashboard');

	return (
		<StyledMenuItems textColor={textColor} padding={padding} margin={margin}>
			{entitiesArr
				&& entitiesArr.map((entity) => {
					const {
						key, route, iconName, sortObject, rolesWithAccess,
					} = entity;

					if (!isRoleSensitive || rolesWithAccess.includes(role)) {
						return (
							<MenuItem
								iconName={iconName}
								key={key}
								text={key}
								route={route}
								isActive={isActiveKey(key)}
								isExtended={isExtended}
								isFreePlan={isFreePlan}
								isMobile={isMobile}
								// @ts-ignore
								location={location}
								sortObject={sortObject}
								onClick={onClick}
							/>
						);
					}

					return null;
				})}
			{children}
		</StyledMenuItems>
	);
};

export default MenuGroup;