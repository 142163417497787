import React from 'react';
import clsx from 'clsx';

import { StyledTag } from './styled';
import Icon, { IIconProps } from '../Icon/Icon';
import { IDummyData } from '../../../types';

const Tag = ({
	backgroundColor,
	className,
	color,
	children,
	fontSize,
	hexOpacity,
	lineHeight,
	fontWeight,
	isOutlined,
	outlineColor,
	onCrossClick,
	removeProps = {},
	selected,
	text,
	withBackgroundOpacity,
	withCrossIcon,
	...rest
}: {
	backgroundColor?: string;
	className?: string;
	as?: IDummyData;
	color?: string;
	children?: React.ReactNode;
	fontSize?: number;
	hexOpacity?: string;
	lineHeight?: number;
	fontWeight?: string | number;
	isOutlined?: boolean;
	iconLeftProps?: IIconProps,
	iconRightProps?: IIconProps,
	outlineColor?: string;
	onCrossClick?: () => void;
	removeProps?: object;
	selected?: boolean;
	text?: string;
	withBackgroundOpacity?: boolean;
	withCrossIcon?: boolean;
}) => (
	<StyledTag
		className={clsx(
			className,
			'tag',
			selected && 'selected',
			isOutlined && 'isOutlined',
			withBackgroundOpacity && 'withBackgroundOpacity',
		)}
		fontWeight={fontWeight}
		backgroundColor={backgroundColor}
		color={color}
		hexOpacity={hexOpacity}
		outlineColor={outlineColor}
		fontSize={fontSize}
		lineHeight={lineHeight}
		{...rest}
	>
		{children || text}
		{withCrossIcon && (
			<Icon
				name="cross"
				height={8}
				width={8}
				fill={color}
				className="crossIcon"
				onClick={onCrossClick}
				{...removeProps}
			/>
		)}
	</StyledTag>
);

export default Tag;