import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const multiUsers = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 16} fill="none" viewBox="0 0 16 16">
			<path
				d="M1.0758 15.4001H5.12342C5.38641 15.4001 5.59961 15.1869 5.59961 14.924L5.59961 10.8763C5.59961 10.6133 5.38641 10.4001 5.12342 10.4001H1.0758C0.812807 10.4001 0.599609 10.6133 0.599609 10.8763L0.599609 14.924C0.599609 15.1869 0.812807 15.4001 1.0758 15.4001Z"
				stroke={fill || theme.color.general.darkest}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.97619 5.6001L10.0238 5.6001C10.2868 5.6001 10.5 5.3869 10.5 5.12391V1.07629C10.5 0.813295 10.2868 0.600098 10.0238 0.600098L5.97619 0.600098C5.7132 0.600098 5.5 0.813295 5.5 1.07629L5.5 5.12391C5.5 5.3869 5.7132 5.6001 5.97619 5.6001Z"
				stroke={fill || theme.color.general.darkest}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3 10.4001V9.10011C3 8.54783 3.44772 8.10011 4 8.10011H8M13 10.4001V9.10011C13 8.54783 12.5523 8.10011 12 8.10011H8M8 8.10011V5.6001"
				stroke={fill || theme.color.general.black}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.8756 15.4001H14.9232C15.1862 15.4001 15.3994 15.1869 15.3994 14.924V10.8763C15.3994 10.6133 15.1862 10.4001 14.9232 10.4001H10.8756C10.6126 10.4001 10.3994 10.6133 10.3994 10.8763V14.924C10.3994 15.1869 10.6126 15.4001 10.8756 15.4001Z"
				stroke={fill || theme.color.general.darkest}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};