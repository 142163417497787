import {
	POPUP_ALERT_HIDE,
	POPUP_ALERT_HIDE_ALL,
	POPUP_ALERT_SHOW,
	SIGN_OUT,
	TOGGLE_DRAWER_EXTENDED,
	TOGGLE_NAV_MENU_EXTENDED,
} from '../constants';
import {
	IAction, ICommonState, IPopupAlert, LocalStorageKeys,
} from '../../types';

const defaultState: ICommonState = {
	isNavMenuExtended: false,
	isDrawerExtended: false,
	popupAlerts: [],
};

const buildPopupAlerts = (
	alerts: IPopupAlert[],
	payload: IPopupAlert,
) => {
	const updatedPopupAlerts = alerts;
	const {
		id,
		type,
		contentKey,
		contentParams,
		content,
		iconName,
		iconProps,
		timeout,
		stacked,
		withCloseButton,
		className,
		onCloseCustom,
	} = payload;

	if (stacked) {
		const similarItemIndex = alerts?.findIndex(
			(alert) => type === alert.type
				&& (content === alert.content || contentKey === alert.contentKey),
		);

		if (similarItemIndex !== -1) {
			updatedPopupAlerts.splice(similarItemIndex, 1);
		}
	}

	const newAlert = {
		timeout,
		id,
		type,
		content,
		contentKey,
		contentParams,
		withCloseButton,
		iconName,
		iconProps,
		className,
		onCloseCustom,
	};

	if (updatedPopupAlerts.length > 0 && timeout && timeout > 0 && withCloseButton) {
		const warningAlerts = updatedPopupAlerts
			.filter((alert) => alert.timeout === 0 && !alert.withCloseButton);
		const otherAlerts = updatedPopupAlerts
			.filter((alert) => alert.timeout !== 0 && alert.withCloseButton);

		return [...warningAlerts, newAlert, ...otherAlerts];
	}

	return [newAlert, ...updatedPopupAlerts];
};

export default (state = defaultState, action = {} as IAction): ICommonState => {
	switch (action.type) {
		case TOGGLE_NAV_MENU_EXTENDED: {
			const isNavMenuExtended = typeof action.extendedValue === 'undefined' ? !state.isNavMenuExtended : action.extendedValue;
			localStorage.setItem(
				LocalStorageKeys.menuState,
				JSON.stringify({ isExtended: isNavMenuExtended }),
			);

			return {
				...state,
				isNavMenuExtended,
			};
		}
		case TOGGLE_DRAWER_EXTENDED: {
			const isDrawerExtended = typeof action.extendedValue === 'undefined' ? !state.isDrawerExtended : action.extendedValue;
			localStorage.setItem(
				LocalStorageKeys.rightDrawerState,
				JSON.stringify({ isExtended: isDrawerExtended }),
			);

			return {
				...state,
				isDrawerExtended,
			};
		}
		case POPUP_ALERT_SHOW:
			return { ...state, popupAlerts: buildPopupAlerts(state.popupAlerts, action.payload) };
		case POPUP_ALERT_HIDE:
			return {
				...state,
				popupAlerts: state.popupAlerts.filter((alert) => alert.id !== action.id),
			};
		case POPUP_ALERT_HIDE_ALL:
			return {
				...state,
				popupAlerts: state.popupAlerts.filter((alert) => alert.timeout === 0),
			};
		case SIGN_OUT:
			return defaultState;
		default:
			return state;
	}
};