import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import Icon from '../../atoms/Icon/Icon';
import Typography from '../../atoms/Typography/Typography';
import Button from '../Button/Button';
import {
	Backdrop, StyledModal, Buttons, Container,
} from './styled';

const ModalWindow = ({
	isOpen,
	onSubmit,
	onClose,
	title,
	description,
}: {
	isOpen: boolean;
	title: string;
	description?: string;
	onClose:() => void;
	onSubmit:(event?: React.FormEvent<HTMLElement>) => void;
}) => {
	const { t } = useTranslation(['all']);
	const theme = useTheme();

	const [isDeleteProgress, setIsDeleteProgress] = useState<boolean>(false);

	const handleCloseClick = () => {
		setIsDeleteProgress(false);
		onClose();
	};

	const handleClick = () => {
		setIsDeleteProgress(true);
		onSubmit();
	};

	if (!isOpen) return null;
	const modalRoot = document.getElementById('modal-root');

	const modalContent = (
		<Backdrop onClick={handleCloseClick}>
			<StyledModal onClick={(e) => e.stopPropagation()}>
				<Icon
					name="cross"
					className="closeIcon"
					wrapperColor="transparent"
					fill="black"
					height={25}
					width={25}
					onClick={handleCloseClick}
				/>
				<Container>
					<Typography variant="h3" style={{ textAlign: 'center', marginBottom: '16px' }}>
						{title}
					</Typography>

					{description && (
						<Typography variant="body1" style={{ textAlign: 'center', marginBottom: '16px' }}>
							{description}
						</Typography>
					)}
					<Buttons>
						<Button fullWidth variant="general" onClick={onClose} className="cancelButton" disabled={isDeleteProgress}>
							{t('no')}
						</Button>
						<Button
							fullWidth
							variant="general"
							style={{ color: theme.color.general.white, marginLeft: '10px' }}
							onClick={handleClick}
							backgroundColor={theme.color.primary.main}
							isLoading={isDeleteProgress}
						>
							{t('yes')}
						</Button>
					</Buttons>
				</Container>
			</StyledModal>
		</Backdrop>
	);
	return modalRoot ? ReactDOM.createPortal(
		modalContent,
		modalRoot,
	) : null;
};

export default ModalWindow;