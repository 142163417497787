import axios, {
	AxiosError,
	AxiosRequestHeaders,
	AxiosResponse,
	InternalAxiosRequestConfig,
} from 'axios';
import { popupAlertShow, userLogout } from './redux-saga/actions';
import { store } from './index';

export const API_VERSION_V1 = '';
export const API_VERSION_V2 = '/v2';

const api = axios.create({
	baseURL: `${process.env.REACT_APP_API_HOST || 'http://localhost:8000'}`,
});

export const handleRequestFulfilled = (config: InternalAxiosRequestConfig) => {
	const additionalConfigs: { headers?: AxiosRequestHeaders } = {};
	const token = store.getState().user.token || '';

	additionalConfigs.headers = config.headers;

	if (token) {
		additionalConfigs.headers.Authorization = `Bearer ${token}`;
	}

	return {
		...config,
		...additionalConfigs,
	};
};

export const handleRequestRejected = (error: AxiosError) => Promise.reject(error);

export const handleResponseFulfilled = (response: AxiosResponse) => response;

export const handleResponseRejected = ({ response }: AxiosError) => {
	if (response && 'detail' in response && Object.keys(response).length === 1) {
		const regEx = /^(Auth0:\d\d\d\s+[\s\S]*)$/;
		// @ts-ignore
		const errorText = typeof response.detail === 'string' ? response.detail : response.detail?.[0]?.msg;

		let currentError = errorText;
		if (regEx.test(errorText)) {
			const authText = errorText.split(' ', 1)[0];
			currentError = errorText.replace(`${authText} `, '');
		}

		const { isAuthenticated } = store.getState().user;

		const authTokenError = ['Missing bearer token', 'Unauthorized'].includes(errorText);

		if (isAuthenticated && authTokenError) {
			store.dispatch(userLogout());
		}

		let contentParams: Record<string, string> | undefined;

		const regExpUserNotExist = /User \[\S+] not exists/g;

		if (regExpUserNotExist.test(errorText)) {
			const [, email] = errorText.split(/[[\]]/);

			currentError = 'emailNotExist';

			contentParams = { email };
		}

		store.dispatch(
			popupAlertShow({
				contentKey: currentError || 'somethingWentWrong',
				contentParams,
				type: 'error',
				timeout: 10000,
				withCloseButton: true,
				iconName: 'statusDeleted',
				stacked: authTokenError,
			}),
		);
	}

	return Promise.reject(response);
};

api.interceptors.request.use(handleRequestFulfilled, handleRequestRejected);

api.interceptors.response.use(handleResponseFulfilled, handleResponseRejected);

export default api;