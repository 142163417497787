import React from 'react';
import { useTheme } from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import FlexRow from '../../atomic-design-components/atoms/FlexRow/FlexRow';
import HeaderMenuIcon from '../../atomic-design-components/molecules/HeaderMenuIcon/HeaderMenuIcon';
import { NAV_MENU_COLORS } from '../../constants';
import { toggleNavMenuExtended } from '../../redux-saga/actions';
import { selectIsNavMenuExtended } from '../../redux-saga/selectors';
import ErrorBoundary from '../../wrappers/ErrorBoundary';
import Logo from '../Logo/Logo';
import NavMenu from '../NavMenu/NavMenu';
import MenuItem from '../NavMenu/MenuItem';
import { StyledHeaderMobile } from './styled';

const HeaderMobile = ({
	colorScheme, isTablet, isRevert,
}: {
	colorScheme: 'system_administrator' | 'customer_administrator' | 'customer_owner' | 'operator';
	isTablet: boolean;
	isRevert: boolean;
}) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const isNavMenuExtended = useSelector(selectIsNavMenuExtended);

	const onIconClick = () => {
		dispatch(toggleNavMenuExtended());
	};

	return (
		<>
			<StyledHeaderMobile colorScheme={colorScheme} className={isNavMenuExtended ? 'extended' : ''}>
				<FlexRow justifyContent="space-between">
					<Logo
						variant="small"
						isExtended
						hasMiniLogo
						logoColor={theme.color.general.black}
						to="../chats"
						className="headerMobileLogo"
					/>
					<MenuItem iconName="chats" to="/chats" />
					<HeaderMenuIcon
						className="menuIcon"
						opened={isNavMenuExtended}
						onClick={onIconClick}
						fill={NAV_MENU_COLORS[colorScheme].dark || theme.color.general.white}
					/>
				</FlexRow>
			</StyledHeaderMobile>
			<ErrorBoundary>
				<NavMenu
					isMobile
					colorScheme={colorScheme}
					isTablet={isTablet}
					isRevert={isRevert}
					onClick={onIconClick}
				/>
			</ErrorBoundary>
		</>
	);
};

export default HeaderMobile;