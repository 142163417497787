import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import ReactHelmet from '../../wrappers/ReactHelmet';

import Icon from '../../atomic-design-components/atoms/Icon/Icon';
import Typography from '../../atomic-design-components/atoms/Typography/Typography';
import IntroScreenWrapper from '../../components/IntroScreens/IntroScreenWrapper';
import backBlue from '../../assets/images/backBlue.jpg';
import { selectWholeUserState } from '../../redux-saga/selectors';

const RequestSent = () => {
	const theme = useTheme();

	const { t } = useTranslation('auth');

	const user = useSelector(selectWholeUserState);

	return (
		<>
			<ReactHelmet title="signTitle" />

			<IntroScreenWrapper type="requestSent" imageUrl={backBlue} text={t('confirmYourEmail')}>
				<Icon name="requestSent" margin="30px 0 0" />
				<div className="inscriptionWrapper">
					<Typography
						variant="body2"
						color={theme.color.general.black}
						margin="20px 0 0 0"
						className="inline"
						text={t('requestText')}
					/>
					{' '}
					<Typography variant="body2" fontWeight={500} color={theme.color.primary.main} className="inline">
						{user?.details?.email || 'you@yourcompany.com'}
					</Typography>
					{' '}
					<Typography
						variant="body2"
						color={theme.color.general.black}
						margin="20px 0 0 0"
						className="inline"
						text={t('requestText1')}
					/>
				</div>
			</IntroScreenWrapper>
		</>
	);
};

export default RequestSent;