import i18n, { InitOptions } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LANGUAGE, resources } from './locales';

const lngDetector = new LanguageDetector(null, {
	order: ['cookie'],
	lookupCookie: 'lng',
	caches: ['cookie'],
});

i18n
	.use(lngDetector)
	.use(initReactI18next)
	.init({
		resources,
		load: 'languageOnly',
		languages: Object.keys(resources),
		fallbackLng: DEFAULT_LANGUAGE,
		lng: DEFAULT_LANGUAGE,
		keySeparator: false, // we do not use keys in form messages.welcome
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	} as InitOptions);

export default i18n;