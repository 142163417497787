import React, { Dispatch, SetStateAction } from 'react';
import {
	ALL_STATUSES_ICONS,
	BULK_MESSAGE_STATUSES_ICONS,
	CURRENCY,
	INDUSTRIES,
	MESSENGERS,
	MESSENGERS_INFO,
	ROLES,
	STATUSES_ICONS,
	STATUSES_ICONS_KNOWLEDGE_BASE,
	WIDGET_COLORS, MAX_INPUT_LENGTH,
} from '../constants';
import { LANGUAGES_FULL_NAME } from '../locales';
import { transformStringSelectValue } from '../utils/select';
import FlexRow from '../atomic-design-components/atoms/FlexRow/FlexRow';
import Icon from '../atomic-design-components/atoms/Icon/Icon';
import Typography from '../atomic-design-components/atoms/Typography/Typography';
import DateTimeInputs from '../atomic-design-components/organisms/DateTimeInputs/DateTimeInputs';
import ResultsBlock from '../containers/BulkMessaging/components/ResultsBlock';
import ChooseIconType from '../containers/MyCompanyChatWidgets/components/ChooseIconType';
import FieldsCustomisation from '../containers/MyCompanyChatWidgets/components/FieldsCustomisation';
import InputWithSwitch from '../containers/MyCompanyChatWidgets/components/InputWithSwitch';
import IsHideBrandingSwitch from '../containers/MyCompanyChatWidgets/components/IsHideBrandingSwitch';
import SetupPanel from '../containers/MyCompanyChatWidgets/components/SetupPanel';
import SwitchableCards from '../containers/MyCompanyChatWidgets/components/SwitchableCards';
import PlacementButtons from '../containers/MyCompanyChatWidgets/widgetPreview/ChooseChannels/components/PlacementButtons';
import theme from '../theme';
import { getAvailableTranslation } from '../utils/getAvailableTranslation';
import { IDummyData } from '../types';

export const PHONE = {
	key: 'phone.phone',
	label: 'phone',
	iconLeftProps: { name: 'phone' },
};

const STATUS = {
	key: 'status',
	label: 'status',
	options: Object.keys(STATUSES_ICONS).map((status) => ({
		id: status,
		label: status,
		iconName: STATUSES_ICONS[status],
		className: 'statusIcon',
	})),
	component: 'dropdown',
	isSearchable: false,
};

export const FULL_NAME = {
	key: 'full_name',
	label: 'fullName',
	iconLeftProps: { name: 'contactCard' },
};

export const EMAIL = {
	label: 'email',
	key: 'email',
	iconLeftProps: { name: 'doggySign' },
};

const getMessengerItem = (key: string, value: string) => {
	const isPresetPart = value.includes(MESSENGERS_INFO[key]?.presetPart as string)
		? value
		: `${MESSENGERS_INFO[key]?.presetPart}${value}`;
	return (value === MESSENGERS_INFO[key]?.presetPart
		? ''
		: isPresetPart);
};

const TELEGRAM = {
	key: 'usernames.telegram',
	label: 'telegram',
	iconLeftProps: { name: 'telegramIcon', className: 'leftIcon withoutHover' },
	placeholder: '@username',
	onInputValueChange(
		value: string,
		setFormValues: Dispatch<SetStateAction<Record<string, string>>>,
	) {
		setFormValues((prev) => ({ ...prev, telegram: getMessengerItem('telegram', value) }));
	},
};

const VIBER = {
	key: 'usernames.viber',
	label: 'viber',
	iconLeftProps: { name: 'viberIcon', className: 'leftIcon withoutHover' },
	onInputValueChange(
		value: string,
		setFormValues: Dispatch<SetStateAction<Record<string, string>>>,
	) {
		setFormValues((prev) => ({ ...prev, viber: getMessengerItem('viber', value) }));
	},
};

const WHATSAPP = {
	key: 'usernames.whatsapp',
	label: 'whatsapp',
	iconLeftProps: { name: 'whatsappIcon', className: 'leftIcon withoutHover' },
	onInputValueChange(
		value: string,
		setFormValues: Dispatch<SetStateAction<Record<string, string>>>,
	) {
		setFormValues((prev) => (
			{ ...prev, whatsapp: getMessengerItem('whatsapp', value) }
		));
	},
};

const INSTAGRAM = {
	key: 'usernames.instagram',
	label: 'instagram',
	iconLeftProps: { name: 'instagramIcon', className: 'leftIcon withoutHover' },
	placeholder: '@username',
	onInputValueChange(
		value: string,
		setFormValues: Dispatch<SetStateAction<Record<string, string>>>,
	) {
		setFormValues((prev) => ({ ...prev, instagram: getMessengerItem('instagram', value) }));
	},
};

const FACEBOOK = {
	key: 'usernames.facebook',
	label: 'facebook',
	iconLeftProps: { name: 'facebookIcon', className: 'leftIcon withoutHover' },
	placeholder: '@username',
	onInputValueChange(
		value: string,
		setFormValues: Dispatch<SetStateAction<Record<string, string>>>,
	) {
		setFormValues((prev) => ({ ...prev, facebook: getMessengerItem('facebook', value) }));
	},
};

const getLangOptions = () => Object.keys(LANGUAGES_FULL_NAME).map((lng) => ({
	id: lng,
	label: LANGUAGES_FULL_NAME[lng],
}));

export const LANGUAGE = {
	key: 'lang',
	label: 'language',
	component: 'dropdown',
	isMulti: false,
	iconLeftProps: { name: 'dashboard', fill: theme.color.general.light },
	options: getLangOptions(),
};

export const LANGUAGES = {
	key: 'languages',
	label: 'languages',
	component: 'dropdown',
	isMulti: true,
	iconLeftProps: { name: 'planet', fill: theme.color.general.light },
	options: getLangOptions(),
};

export const LANGUAGE_WIDGET = {
	key: 'language',
	label: 'widgetLanguage',
	component: 'dropdown',
	isMulti: false,
	iconLeftProps: { name: 'planet', fill: theme.color.general.light },
	options: getLangOptions(),
};

export const FORM_CONFIGS: IDummyData = {
	signUp: {
		fields: [
			{
				label: 'fullName',
				key: 'full_name',
				placeholder: 'firstLastName',
				variant: 'secondary',
				labelType: 'border',
				iconLeftProps: { name: 'contactCard' },
				required: true,
			},
			{
				label: 'companyName',
				key: 'company_name',
				placeholder: 'yourCompanyName',
				variant: 'secondary',
				labelType: 'border',
				iconLeftProps: { name: 'briefcase' },
				required: true,
			},
			{
				key: 'company_phone',
				label: 'companyPhone',
				iconLeftProps: { name: 'phone' },
				required: true,
				placeholder: '+380',
			},
			{
				...EMAIL,
				placeholder: 'you@yourcompany.com',
				required: true,
			},
			{
				label: 'password',
				key: 'password',
				placeholder: 'enterYourPassword',
				variant: 'secondary',
				type: 'password',
				labelType: 'border',
				iconLeftProps: { name: 'passwordLocked' },
				className: 'password',
				required: true,
			},
		],
	},
	signIn: {
		fields: [
			{
				...EMAIL,
				placeholder: 'you@yourcompany.com',
			},
			{
				key: 'password',
				label: 'password',
				variant: 'secondary',
				type: 'password',
				placeholder: 'enterYourPassword',
				labelType: 'border',
				iconLeftProps: { name: 'passwordLocked' },
				className: 'password',
			},
		],
	},
	passwordRecovery: {
		fields: [
			{
				...EMAIL,
				placeholder: 'you@yourcompany.com',
			},
		],
	},
	newPassword: {
		fields: [
			{
				key: 'password',
				label: 'newPassword',
				variant: 'secondary',
				type: 'password',
				placeholder: 'enterYourPassword',
				labelType: 'border',
				iconLeftProps: { name: 'passwordLocked' },
				className: 'password',
			},
		],
	},
	members: {
		validationRules: {
			full_name: [{
				type: 'limit',
				fields: ['membersNameLimit'],
			},
			],
		},
		fields: {
			noTitle: [
				{
					...STATUS,
					getDisabled: (
						formValues: {
							status: string;
						},
						initialValues: {
							status: string;
						},
					) => ['pending', 'pending_inactive'].includes(initialValues.status),
					isOptionDisabled: () => (option: {
						id: string;
					}) => ['pending', 'pending_inactive'].includes(option.id),
				},
				{
					key: 'role',
					label: 'role',
					component: 'dropdown',
					getDisabled: (
						formValues: {
							status: string
						},
						initialValues: {
							role: string;
							id: string;
						},
						optionsData: {
							role: string;
							currentUserMemberId: string;
						},
					) => initialValues.role === 'system_administrator' || optionsData.role === 'customer_administrator' || optionsData.currentUserMemberId === initialValues.id,
					isOptionDisabled: (optionsData: { role: string}) => (option: { id: string }) => (
						option.id === 'system_administrator'
              || (optionsData.role !== 'system_administrator' && option.id === 'customer_owner')
					),
					options: Object.keys(ROLES).map((role) => transformStringSelectValue(role)),
					iconLeftProps: { name: 'user', fill: theme.color.general.light },
					isSearchable: false,
				},
				{
					key: 'departments',
					label: 'departments',
					component: 'dropdown',
					isMulti: true,
					labelKey: 'name',
					optionsKeys: ['departments'],
					iconLeftProps: { name: 'multiUsers', fill: theme.color.general.light },
				},
			],
			memberInfo: [
				{ ...FULL_NAME, validationLimitValue: MAX_INPUT_LENGTH.membersNameLimit },
				{ ...EMAIL, disabled: true },
				{ ...PHONE, label: 'phone' },
				LANGUAGES,
			],
		},
	},
	contacts: {
		validationRules: {
			'phone.phone': ['phone'],
			email: ['email',
				{
					type: 'limit',
					fields: ['emailLimit'],
				},
			],
			tags: [{
				type: 'newOptionLimit',
				fields: ['newTagValueLimit'],
			},
			{
				type: 'optionsLimit',
				fields: ['tagsLimit'],
			},
			],
			comment: [{
				type: 'limit',
				fields: ['commentLimit'],
			},
			],
			full_name: [{
				type: 'limit',
				fields: ['fullName'],
			},
			],
		},
		fields: {
			guestInfo: [
				{
					key: 'tags',
					label: 'tags',
					iconLeftProps: { name: 'tag', fill: theme.color.general.light },
					isMulti: true,
					component: 'dropdown',
					optionsKeys: ['tags'],
					getOptions: (optionsData: {
						tags: {
							name: string;
						}[];
					}) => optionsData.tags
						?.map((tag) => ({ id: tag?.name, name: tag?.name })),
					isCreatable: true,
					createLabelText: 'addTag',
					isClearable: true,
					labelKey: 'name',
					isTranslatedLabel: false,
					validationLimitValue: [
						{ optionsLimit: MAX_INPUT_LENGTH.tagsLimit },
						{ newOptionLimit: MAX_INPUT_LENGTH.newTagValueLimit },
					],
				},
				{
					...FULL_NAME,
					label: 'name',
					iconLeftProps: { name: 'contactCard' },
					validationLimitValue: MAX_INPUT_LENGTH.fullName,
				},
				LANGUAGES,
				{
					key: 'comment',
					label: 'comment',
					multiline: true,
					validationLimitValue: MAX_INPUT_LENGTH.commentLimit,
				},
			],
			contactInfo: [
				{ ...EMAIL, validationLimitValue: MAX_INPUT_LENGTH.emailLimit },
				PHONE, TELEGRAM, VIBER, INSTAGRAM, FACEBOOK, WHATSAPP],

			cameFrom: [],
		},
	},
	bulkMessaging: {
		validationRules: {
			name: ['required',
				{
					type: 'limit',
					fields: ['nameBulkMsgLimit'],
				},
			],
			message: ['required',
				{
					type: 'limit',
					fields: ['messageBulkMsgLimit'],
				},
			],
			tags: [
				{
					type: 'requiredIfFieldsEmpty',
					fields: ['tags', 'channels'],
				},
			],
			channels: [
				{
					type: 'requiredIfFieldsEmpty',
					fields: ['tags', 'channels'],
				},
			],
		},
		fields: {
			general: [
				{
					...STATUS,
					options: Object.keys(BULK_MESSAGE_STATUSES_ICONS).map((status) => ({
						id: status,
						label: status,
						iconName: BULK_MESSAGE_STATUSES_ICONS[status],
						className: 'statusIcon',
					})),
					getDisabled: () => true,
				},
				{
					label: 'name',
					key: 'name',
					variant: 'secondary',
					labelType: 'border',
					required: true,
					validationLimitValue: MAX_INPUT_LENGTH.nameBulkMsgLimit,
				},
			],
			clientFilters: [
				{
					key: 'tags',
					label: 'tags',
					iconLeftProps: { name: 'tag', fill: theme.color.general.light },
					isMulti: true,
					component: 'dropdown',
					optionsKeys: ['tags'],
					getOptions: (optionsData: {
						tags: {
							name: string;
						}[];
					}) => optionsData.tags
						?.map((tag) => ({ id: tag?.name, name: tag?.name })),
					isClearable: true,
					labelKey: 'name',
					isTranslatedLabel: false,
					validationTranslationKeys: ['tags', 'messengers'],
				},
				{
					key: 'channels',
					label: 'messengers',
					iconLeftProps: { name: 'chats', fill: theme.color.general.light },
					isMulti: true,
					component: 'dropdown',
					options: MESSENGERS,
					customGetOptionLabel: (option: {
						id: string;
						label: string;
					}) => (
						<FlexRow gap="10px" className="custom">
							<Icon name={`${option.id}Icon`} size={18} />
							<Typography variant="input" text={option.label} />
						</FlexRow>
					),
					isClearable: true,
					validationTranslationKeys: ['tags', 'messengers'],
				},
			],
			message: [
				{
					key: 'message',
					label: 'message',
					component: 'messageTextarea',
					placeholder: 'bulkMessagePlaceholder',
					required: true,
					initialHeight: 100,
					validationLimitValue: MAX_INPUT_LENGTH.messageBulkMsgLimit,
				},
				{
					label: 'withSchedule',
					key: 'is_scheduled',
					component: 'checkbox',
				},
				{
					key: 'scheduled_at',
					component: (props: {
						updateSelect: (name: string) => (value: Date) => void;
						is_scheduled: boolean;
						value: Date;
						name: string;
					}) => <DateTimeInputs {...props} onChange={props.updateSelect} key={props.name} />,
					getDisabled: (props: {
						is_scheduled: boolean;
					}) => !props.is_scheduled,
				},
			],
			results: [
				{
					key: 'result',
					component: (props: {
						value?: {
							success?: never[];
							failure?: {
								chat_id: string;
								name: string;
								id: string;
								channel: string;
							}[]
						};
					}) => <ResultsBlock {...props} key="result" />,
					getIsHidden: (formValues: {
						status: string;
					}) => !['complete', 'complete_with_error'].includes(formValues.status),
				},
			],
		},
	},
	customers: {
		accountStatus: {
			validationRules: {},
			fields: [
				{
					...STATUS,
					isDisabled: true,
					options: Object.keys(ALL_STATUSES_ICONS).map((status) => ({
						id: status,
						label: status,
						iconName: ALL_STATUSES_ICONS[status],
						className: 'statusIcon',
					})),
				},
			],
		},
		currentPlan: {
			validationRules: {},
			fields: {
				currentPlan: [
					{
						key: 'plan',
						label: 'plan',
						component: 'dropdown',
						iconLeftProps: { name: 'planet', fill: theme.color.general.light },
						optionsKeys: ['plans'],
						customGetOptionLabel: (
							option: {
								titles: {
									uk: string;
								};
							},
							t: (a: string) => string,
							lng: string,
						) => getAvailableTranslation(option?.titles, lng),
						onSelectValueChange(value: {
							id: number;
						}, setFormValues: Dispatch<SetStateAction<Record<string, null>>>) {
							// Free plan
							if (value?.id === 1) {
								setFormValues({ subscription_expire_at: null });
							}
						},
						isSearchable: false,
					},
					{
						key: 'subscription_expire_at',
						component: 'date',
						label: 'planEnds',
						minDate: new Date(),
						getDisabled: (formValues: {
							plan: {
								id: number;
							};
						}) => formValues.plan?.id === 1 // Free plan
						,
					},
				],
			},
		},
		information: {
			validationRules: {
				email: ['email', {
					type: 'limit',
					fields: ['customersEmail'],
				}],
				'phone.phone': ['required', 'phone'],
				website_url: ['website'],
				customer_tags: [{
					type: 'newOptionLimit',
					fields: ['customersNewTag'],
				},
				{
					type: 'optionsLimit',
					fields: ['customersTagLimit'],
				},
				],
				comment: [{
					type: 'limit',
					fields: ['customersComment'],
				}],
			},
			fields: {
				details: [],
				information: [
					{
						key: 'customer_tags',
						label: 'tags',
						iconLeftProps: { name: 'tag', fill: theme.color.general.light },
						isMulti: true,
						component: 'dropdown',
						optionsKeys: ['customer_tags'],
						getOptions: (optionsData: {
							customer_tags: {
								name: string;
							}[];
						}) => optionsData.customer_tags
							?.map((tag) => ({ id: tag?.name, name: tag?.name })),
						isCreatable: true,
						createLabelText: 'addTag',
						isClearable: true,
						labelKey: 'name',
						isTranslatedLabel: false,
						validationLimitValue: [
							{ newOptionLimit: MAX_INPUT_LENGTH.customersNewTag },
							{ optionsLimit: MAX_INPUT_LENGTH.customersTagLimit },
						],
					},
					{
						key: 'website_url',
						label: 'website',
						iconLeftProps: { name: 'linkSign' },
					},
					{ ...PHONE, label: 'companyPhone' },
					{ ...EMAIL, validationLimitValue: MAX_INPUT_LENGTH.customersEmail },
					{
						key: 'comment',
						label: 'comment',
						multiline: true,
						validationLimitValue: MAX_INPUT_LENGTH.customersComment,
					},
				],
			},
		},
	},
	customersInvoice: {
		validationRules: {
			email: ['required', 'email'],
			full_name: ['required'],
			EDRPOU: ['required', 'onlyDigits'],
			TIN: ['required', 'onlyDigits'],
			IBAN: ['required'],
			amount: ['required', 'price'],
		},
		fields: {
			customerInfo: [
				{
					key: 'company_name',
					label: 'customerCompany',
					iconLeftProps: { name: 'contactCard' },
					isDisabled: true,
				},
				{ ...EMAIL, label: 'companyEmail' },
				{ ...FULL_NAME, iconLeftProps: { name: 'briefcase' } },
				{
					key: 'EDRPOU',
					label: 'EDRPOU',
					isUppercase: true,
					iconLeftProps: { name: 'briefcase' },
				},
				{
					key: 'TIN',
					label: 'TIN',
					isUppercase: true,
					iconLeftProps: { name: 'briefcase' },
				},
				{
					key: 'IBAN',
					label: 'IBAN',
					isUppercase: true,
					iconLeftProps: { name: 'briefcase' },
				},
				{
					key: 'purposeOfPayment',
					label: 'purposeOfPayment',
					multiline: true,
				},
				{
					key: 'amount',
					label: 'amount',
					iconLeftProps: { name: 'dollarSign' },
					onInputValueChange(
						value: string,
						setFormValues: Dispatch<SetStateAction<Record<string, string>>>,
					) {
						const presetPart = CURRENCY.UAH;
						const presetPartVal = value.includes(presetPart as string)
							? value
							: `${presetPart} ${value}`;
						const formattedValue = value === presetPart
							? `${value} `
							: presetPartVal;
						setFormValues((prev) => ({ ...prev, amount: formattedValue }));
					},
				},
			],
		},
	},
	departments: {
		validationRules: {
			name: ['required', {
				type: 'limit',
				fields: ['departmentName'],
			}],
		},
		fields: [
			{
				...STATUS,
				options: Object.keys(STATUSES_ICONS)
					.filter((option) => option !== 'pending')
					.filter((option) => option !== 'pending_inactive')
					.map((status) => ({
						id: status,
						label: status,
						iconName: ALL_STATUSES_ICONS[status],
						className: 'statusIcon',
					})),
				getIsHidden: (formValues: {
					id: string;
				}) => !formValues?.id,
			},
			{
				key: 'name',
				label: 'departmentName',
				iconLeftProps: { name: 'tag' },
				validationLimitValue: MAX_INPUT_LENGTH.departmentName,
			},
			{
				key: 'members',
				label: 'addMembers',
				component: 'dropdown',
				isMulti: true,
				labelKey: 'full_name',
				optionsKeys: ['members'],
				iconLeftProps: { name: 'invitePeople', fill: theme.color.general.light },
			},
		],
	},
	widgets: {
		validationRules: {
			name: ['required', {
				type: 'limit',
				fields: ['chatsWidgetName'],
			}],
		},
		fields: {
			general: [
				{
					...STATUS,
					options: Object.keys(STATUSES_ICONS)
						.filter((option) => option !== 'pending')
						.filter((option) => option !== 'pending_inactive')
						.map((status) => ({
							id: status,
							label: status,
							iconName: ALL_STATUSES_ICONS[status],
							className: 'statusIcon',
						})),
					getIsHidden: (formValues: {
						id: string;
					}) => !formValues?.id,
				},
				{
					key: 'name',
					label: 'name',
					iconLeftProps: { name: 'widgets' },
					validationLimitValue: MAX_INPUT_LENGTH.chatsWidgetName,
				},
				{
					...LANGUAGE_WIDGET,
				},
			],
			channelConfig: [
				{
					key: 'channels',
					// @ts-ignore
					component: () => <SwitchableCards />,
				},
			],
			setup: [
				{
					key: 'license_id',
					component: ({ value }: {
						value: {
							license_id: string;
						};
						// @ts-ignore
					}) => <SetupPanel data={value} />,
				},
			],
		},
	},
	widgetsNew: {
		validationRules: {
			name: ['required', {
				type: 'limit',
				fields: ['limitWidgetName'],
			},
			],
		},
		fields: [
			{
				key: 'name',
				label: 'name',
				placeholder: 'enterChatWidgetName',
				iconLeftProps: { name: 'widgets' },
				validationLimitValue: MAX_INPUT_LENGTH.limitWidgetName,
			},
		],
	},
	companyGeneral: {
		validationRules: {
			alias: ['required', {
				type: 'limit',
				fields: ['myCompanyName'],
			}],
			email: ['email', {
				type: 'limit',
				fields: ['myCompanyEmail'],
			}],
			'phone.phone': ['required', 'phone'],
			website_url: ['website'],
			description: [
				{
					type: 'limit',
					fields: ['myCompanyDescription'],
				},
			],
		},
		fields: {
			companyInformation: [
				{
					label: 'companyName',
					key: 'alias',
					placeholder: 'yourCompanyName',
					variant: 'secondary',
					labelType: 'border',
					iconLeftProps: { name: 'briefcase' },
					required: true,
					validationLimitValue: MAX_INPUT_LENGTH.myCompanyName,
				},
				{
					label: 'companyWebsite',
					key: 'website_url',
					placeholder: 'www.',
					variant: 'secondary',
					labelType: 'border',
					iconLeftProps: { name: 'monitor' },
				},
				{
					...EMAIL,
					label: 'companyEmail',
					placeholder: 'email@example.com',
					validationLimitValue: MAX_INPUT_LENGTH.myCompanyEmail,
				},
				{ ...PHONE, label: 'companyPhone', placeholder: '+380' },
				{
					key: 'industry',
					label: 'industry',
					component: 'dropdown',
					iconLeftProps: { name: 'settings', fill: theme.color.general.dark },
					options: INDUSTRIES.map((industry) => transformStringSelectValue(industry)),
				},
				{
					key: 'description',
					label: 'description',
					placeholder: 'companyInfoDescription',
					variant: 'secondary',
					labelType: 'border',
					multiline: true,
					rows: 4,
					validationLimitValue: MAX_INPUT_LENGTH.myCompanyDescription,
				},
			],
		},
	},
	customization: {
		validationRules: {
			privacy_policy: ['privacy_policy_required_custom', 'privacy_policy_website_custom'],
		},
		fields: {
			widgetAppearance: {
				widgetColor: [
					{
						label: 'color',
						key: 'color',
						labelType: 'border',
						component: 'dropdown',
						options: Object.keys(WIDGET_COLORS).map((color) => ({
							id: WIDGET_COLORS[color],
							label: color,
							color: WIDGET_COLORS[color],
							className: 'widgetColorIcon',
						})),
					},
				],
				options: [
					{
						key: 'is_hide_branding',
						component: (props: {
							updateCheckbox: (checked: boolean, e: React.ChangeEvent<HTMLInputElement>) => void;
							value: boolean;
							// @ts-ignore
						}) => <IsHideBrandingSwitch {...props} key="is_hide_branding" />,
						label: 'hideBranding',
						textVariant: 'button1',
						id: 'is_hide_branding',
						revert: true,
						justifyContent: 'space-between',
						labelTopHidden: true,
					},
				],
			},
			widgetButton: {
				iconType: [
					{
						key: 'button_style',
						component: (props: {
							updateSelect: (name: string) => (value: string) => void;
							value: string;
							name: string;
							// @ts-ignore
						}) => <ChooseIconType {...props} key={props.value} />,
					},
				],
				buttonPlacement: [
					{
						key: 'button_placement',
						component: (props: {
							updateSelect: (name: string) => (value: string) => void;
							value: string;
							name: string;
							// @ts-ignore
						}) => <PlacementButtons {...props} key={props.value} />,
					},
				],
			},
			callbackButton: {
				iconType: [
					{
						key: 'callback_button_style',
						component: (props: {
							updateSelect: (name: string) => (value: string) => void;
							value: string;
							name: string;
							// @ts-ignore
						}) => <ChooseIconType {...props} key={props.value} />,
					},
				],
				buttonPlacement: [
					{
						key: 'callback_button_placement',
						component: (props: {
							updateSelect: (name: string) => (value: string) => void;
							value: string;
							name: string;
							// @ts-ignore
						}) => <PlacementButtons {...props} key={props.value} />,
					},
				],
			},
			forms: [
				{
					key: 'is_form_displayed',
					component: 'switch',
					label: 'preChat',
					textVariant: 'button1',
					revert: true,
					justifyContent: 'space-between',
				},
				{
					key: 'form_fields',
					component: (props: {
						value: {
							[field: string]: {
								is_visible: boolean;
								is_required: boolean;
							};
						};
						updateSelect: (name: string) => (value: Record<string, {
							is_visible: boolean;
							is_required: boolean;
						}>) => void;
						optionsFromFormValues: {
							is_form_displayed: boolean;
						};
					}) => <FieldsCustomisation {...props} key="form_fields" />,
					getOptionsFromFormValues: (formValues: {
						is_form_displayed: boolean;
					}) => (
						{ is_form_displayed: formValues.is_form_displayed }
					),
				},
				{
					key: 'privacy_policy',
					component: (props: {
						value: string;
						updateSelect: (name: string) => (value: string) => void;
						// @ts-ignore
					}) => <InputWithSwitch {...props} key={props.value} />,
					getOptionsFromFormValues: (formValues: {
						is_form_displayed: boolean;
					}) => (
						{ is_form_displayed: formValues.is_form_displayed }
					),
				},
			],
		},
	},
	subscriptionPlans: {
		withTabs: ['titles', 'prices.price_monthly', 'prices.price_annually'],
		validationRules: {
			status: ['required'],
			customers: [],
			'titles.uk': ['required', {
				type: 'limit',
				fields: ['subscriptionPlanName'],
			}],
			'prices.price_monthly.UAH': ['required', 'onlyDigits'],
			'prices.price_annually.UAH': ['required', 'onlyDigits'],
			'features.widgets': ['onlyDigits', 'required'],
			'features.channels': ['onlyDigits', 'required'],
			'features.users': ['onlyDigits', 'required'],
			'features.templates': ['onlyDigits', 'required'],
		},
		fields: {
			general: [
				{
					...STATUS,
					options: Object.keys(STATUSES_ICONS)
						.filter((option) => option !== 'pending')
						.filter((option) => option !== 'pending_inactive')
						.map((status) => ({
							id: status,
							label: status,
							iconName: ALL_STATUSES_ICONS[status],
							className: 'statusIcon',
						})),
					required: true,
				},
				{
					key: 'customers',
					label: 'addCustomer',
					component: 'dropdown',
					isMulti: true,
					labelKey: 'alias',
					optionsKeys: ['customers'],
					iconLeftProps: { name: 'invitePeople', fill: theme.color.general.light },
				},
			],
			translations: [
				{
					label: 'title',
					key: 'titles',
					variant: 'secondary',
					labelType: 'border',
					required: true,
					validationLimitValue: MAX_INPUT_LENGTH.subscriptionPlanName,
				},
			],
			planCharacteristics: [
				{
					label: 'widgets',
					key: 'features.widgets',
					placeholder: '0',
					variant: 'secondary',
					labelType: 'border',
					iconLeftProps: { name: 'chats' },
					required: true,
				},
				{
					label: 'channels',
					key: 'features.channels',
					placeholder: '0',
					variant: 'secondary',
					labelType: 'border',
					iconLeftProps: { name: 'channels' },
					required: true,
				},
				{
					label: 'users',
					key: 'features.users',
					placeholder: '0',
					variant: 'secondary',
					labelType: 'border',
					iconLeftProps: { name: 'users' },
					required: true,
				},
				{
					label: 'templates',
					key: 'features.templates',
					placeholder: '0',
					variant: 'secondary',
					labelType: 'border',
					iconLeftProps: { name: 'templates' },
					required: true,
				},
				{
					label: 'is_branding',
					key: 'features.is_branding',
					component: 'checkbox',
				},
				{
					label: 'is_customization',
					key: 'features.is_customization',
					component: 'checkbox',
				},
			],
			prices: [
				{
					label: 'priceMonthly',
					key: 'prices.price_monthly',
					variant: 'secondary',
					labelType: 'border',
					iconLeftProps: { name: 'dollarSign' },
					required: true,
					placeholder: '0',
				},
				{
					label: 'priceAnnually',
					key: 'prices.price_annually',
					variant: 'secondary',
					labelType: 'border',
					iconLeftProps: { name: 'dollarSign' },
					required: true,
					placeholder: '0',
				},
			],
		},
	},
	knowledgeBaseFolder: {
		withTabs: ['title'],
		validationRules: {
			name: ['required', {
				type: 'limit',
				fields: ['knowledgeBaseFolderName'],
			}],
			status: ['required'],
			widget_license_id_list: ['required'],
		},
		fields: [
			{
				label: 'title',
				key: 'name',
				variant: 'secondary',
				iconLeftProps: { name: 'folder', fill: theme.color.general.light },
				labelType: 'border',
				validationLimitValue: MAX_INPUT_LENGTH.knowledgeBaseFolderName,
			},
			{
				...STATUS,
				options: Object.keys(STATUSES_ICONS_KNOWLEDGE_BASE)
					.filter((option) => option !== 'deleted')
					.map((status) => {
						const updStatus = status === 'disabled' ? 'disabled' : status;
						return ({
							id: updStatus,
							label: status,
							iconName: STATUSES_ICONS_KNOWLEDGE_BASE[status],
							className: 'statusIcon',
						});
					}),
			},
			{
				key: 'widget_license_id_list',
				label: 'widget',
				component: 'dropdown',
				labelKey: 'name',
				optionsKeys: ['widget_license_id_list'],
				getOptions: (optionsData: {
						widget_license_id_list: {
							widgets: {
							license_id: string;
							name: string;
						}[]};
					}) => optionsData.widget_license_id_list.widgets
					?.map((widget) => ({ id: widget?.license_id, name: widget?.name })),
				iconLeftProps: { name: 'widgets', fill: theme.color.general.light },
			},
		],
	},
	knowledgeBaseArticle: {
		validationRules: {
			status: ['required'],
			widget_license_id_list: ['required'],
			user_page_link: ['website', {
				type: 'limit',
				fields: ['knowledgeBaseLink'],
			}],
			keyword_ids: [{
				type: 'newOptionLimit',
				fields: ['newKeywordsLimit'],
			},
			{
				type: 'optionsLimit',
				fields: ['knowledgeBaseKeywordsLimit'],
			},
			],
		},
		fields: {
			general: [
				{
					...STATUS,
					options: Object.keys(STATUSES_ICONS_KNOWLEDGE_BASE)
						.filter((option) => option !== 'deleted')
						.map((status) => {
							const updStatus = status === 'disabled' ? 'disabled' : status;
							return ({
								id: updStatus,
								label: status,
								iconName: STATUSES_ICONS_KNOWLEDGE_BASE[status],
								className: 'statusIcon',
							});
						}),
				},
				{
					key: 'widget_license_id_list',
					label: 'widgets',
					component: 'dropdown',
					isMulti: true,
					labelKey: 'name',
					optionsKeys: ['widget_license_id_list'],
					getOptions: (optionsData: {
						widget_license_id_list: {
							widgets: {
								license_id: string;
								name: string;
							}[]};
					}) => optionsData.widget_license_id_list.widgets
						?.map((widget) => ({ id: widget?.license_id, name: widget?.name })),
					iconLeftProps: { name: 'widgets', fill: theme.color.general.light },
				},
				{
					key: 'folder_id',
					label: 'folder',
					labelKey: 'label',
					component: 'dropdown',
					tooltipText: 'selectFolderTooltip',
					isClearable: true,
					getDisabled: (formValues: {
						widget_license_id_list: {
							id: number;
							name: string;
						}[]
					}) => formValues?.widget_license_id_list?.length !== 1,
					getOptions: (optionsData: {
						folders: {
							folders: {
								id: string;
								name: string;
							}[]
						}
					}) => optionsData.folders.folders?.map((el) => ({
						id: el.id.toString(),
						label: el.name,
					})),
					optionsKeys: ['folders'],
					iconLeftProps: { name: 'folder', stroke: theme.color.general.light },
				},
				{
					key: 'keyword_ids',
					label: 'keywords',
					labelKey: 'label',
					component: 'dropdown',
					isCreatable: true,
					createLabelText: 'addKeywords',
					isMulti: true,
					optionsKeys: ['knowledgeBaseKeywords'],
					getOptions(optionsData: {
						keywords: {
							keywords: {
								id: string;
								name: string;
							}[]
						}
					}) {
						return optionsData.keywords.keywords
							?.map((keyword) => ({ id: keyword.id.toString(), label: keyword.name }));
					},
					iconLeftProps: { name: 'starSign', stroke: theme.color.general.light },
					validationLimitValue: [
						{ newOptionLimit: MAX_INPUT_LENGTH.newKeywordsLimit },
						{ optionsLimit: MAX_INPUT_LENGTH.knowledgeBaseKeywordsLimit },
					],
				},
				{
					key: 'article_interactions',
					component: 'switch',
					label: 'feedbackFunctions',
					textVariant: 'button1',
					revert: true,
					justifyContent: 'space-between',
				},
				{
					label: 'link',
					key: 'user_page_link',
					placeholder: 'www.skibble.chat',
					variant: 'secondary',
					labelType: 'border',
					iconLeftProps: { name: 'linkSign' },
					className: 'link',
					validationLimitValue: MAX_INPUT_LENGTH.knowledgeBaseLink,
				},
			],
		},
	},
};