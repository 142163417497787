import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const twitterSign = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 17} height={height || 13} viewBox="0 0 17 13" fill="none">
			<path
				d="M14.5219 3.23983C14.532 3.38201 14.532 3.52422 14.532 3.6664C14.532 8.0031 11.2325 13 5.20205 13C3.34417 13 1.61829 12.4617 0.166504 11.5274C0.430474 11.5578 0.684256 11.568 0.958382 11.568C2.49136 11.568 3.90255 11.05 5.02946 10.1664C3.58783 10.1359 2.3797 9.19141 1.96345 7.8914C2.16651 7.92185 2.36954 7.94217 2.58276 7.94217C2.87717 7.94217 3.17161 7.90153 3.4457 7.83047C1.94317 7.52576 0.816225 6.20546 0.816225 4.61093V4.57032C1.25276 4.81407 1.76042 4.96641 2.29845 4.9867C1.4152 4.39763 0.836538 3.39217 0.836538 2.25466C0.836538 1.6453 0.998945 1.08671 1.28323 0.599203C2.89745 2.58983 5.32387 3.88981 8.04466 4.03202C7.99391 3.78827 7.96344 3.53438 7.96344 3.28047C7.96344 1.47264 9.42538 0 11.2426 0C12.1868 0 13.0396 0.396093 13.6386 1.03594C14.3797 0.893756 15.0903 0.619524 15.7198 0.243752C15.4761 1.00549 14.9584 1.64533 14.2782 2.05155C14.9381 1.98049 15.5777 1.79764 16.1665 1.54376C15.7199 2.19373 15.1615 2.77262 14.5219 3.23983Z"
				fill={fill || theme.color.general.white}
			/>
		</svg>
	);
};