import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const dashboard = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
			<path
				d="M18.229 9.31445H12.7433C12.3646 9.31445 12.0576 9.62146 12.0576 10.0002V18.2287C12.0576 18.6074 12.3646 18.9145 12.7433 18.9145H18.229C18.6078 18.9145 18.9148 18.6074 18.9148 18.2287V10.0002C18.9148 9.62146 18.6078 9.31445 18.229 9.31445Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.229 1.08594H12.7433C12.3646 1.08594 12.0576 1.39294 12.0576 1.77165V4.52822C12.0576 4.90693 12.3646 5.21394 12.7433 5.21394H18.229C18.6078 5.21394 18.9148 4.90693 18.9148 4.52822V1.77165C18.9148 1.39294 18.6078 1.08594 18.229 1.08594Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.25737 1.08594H1.77165C1.39294 1.08594 1.08594 1.39294 1.08594 1.77165V10.0002C1.08594 10.3789 1.39294 10.6859 1.77165 10.6859H7.25737C7.63608 10.6859 7.94308 10.3789 7.94308 10.0002V1.77165C7.94308 1.39294 7.63608 1.08594 7.25737 1.08594Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.25737 14.7871H1.77165C1.39294 14.7871 1.08594 15.0941 1.08594 15.4728V18.2294C1.08594 18.6081 1.39294 18.9151 1.77165 18.9151H7.25737C7.63608 18.9151 7.94308 18.6081 7.94308 18.2294V15.4728C7.94308 15.0941 7.63608 14.7871 7.25737 14.7871Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};