import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '../../atomic-design-components/atoms/Typography/Typography';

const NotFound = () => {
	const { t } = useTranslation('all');
	return <Typography variant="h2" text={t('pageNotFound')} margin="15px 30px" />;
};

export default NotFound;