import React from 'react';

import clsx from 'clsx';
import Icon from '../Icon/Icon';
import { StyledLoader } from './styled';

const Loader = ({
	left, size, top, withOverlay,
}: {
	left?: string;
	size?: string;
	top?: string;
	withOverlay?: boolean;
}) => {
	let iconSize;
	if (size) {
		const parsedSize = +size.replace('px', '');
		iconSize = Number.isNaN(parsedSize) ? undefined : parsedSize;
	} else {
		iconSize = undefined;
	}
	return (
		<StyledLoader
			top={top}
			left={left}
			size={size}
			className={clsx('loader', withOverlay && 'withOverlay')}
		>
			<Icon name="logo" size={iconSize} />
		</StyledLoader>
	);
};

export default Loader;