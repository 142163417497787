import React, { useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { initializeApp } from '../redux-saga/actions';
import { selectIsFreePlan, selectWholeUserState } from '../redux-saga/selectors';
import { ROUTES_FORBIDDEN } from '../constants';

interface IProps {
	children?: React.ReactElement;
}

const PrivateRoute: React.FC<IProps> = ({ children }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { i18n } = useTranslation('all');

	const user = useSelector(selectWholeUserState);
	const role = user?.details?.role;

	const { pathname } = location;
	const isFreePlan = useSelector(selectIsFreePlan);

	useEffect(() => {
		if (!user.isAppInitialized) {
			dispatch(initializeApp(navigate, location, i18n.changeLanguage));
		}
	}, [user.isAppInitialized]);

	if (!user.isAppInitialized || user.inProgress || !user.details?.email) {
		return null;
	}

	const isForbidden = ROUTES_FORBIDDEN[role]?.some(
		(path: string) => location.pathname.includes(path),
	)
	// system_administrator sees payments on different route - /payments
    || (role === 'system_administrator' && pathname.includes('/my-company/payments'))
    || (isFreePlan
      && (pathname.includes('/bulk-messaging')
        || pathname.includes('/my-company/chat-widgets/auto-messages')
        || pathname.includes('/knowledgebase')));

	if (isForbidden) {
		return <Navigate replace to="/404" />;
	}

	return user.isAuthenticated ? children || null : <Navigate replace to="/sign-in" />;
};

export default PrivateRoute;