import { put, select } from 'redux-saga/effects';

import { popupAlertShow, userLogout } from './redux-saga/actions';
import { verifyToken } from './redux-saga/sagas/auth';
import { selectUserSystemParams } from './redux-saga/selectors';
import { ApiUrlType } from './types';
import { store } from './index';

interface IParams {
	apiUrlParam?: string,
	id?: number,
	queryParams?: { key: string, value: string | number }[],
	type: ApiUrlType,
	isNewRequestType?: boolean;
	searchParams?: Record<string, string>;
}

interface IParamsBuildRequest extends IParams {
	apiMethod: 'GET' | 'POST' | 'PATCH' | 'DELETE';
	requestBody?: unknown;
	tokenArg?: string;
	tokenExpArg?: string;
	isOpenData?: boolean;
	license_ids?: string[];
	license_id?: string;
}

export const API_URL_START = process.env.REACT_APP_API_HOST || 'http://localhost:8000';

export const WEBSOCKET_URL_START = process.env.REACT_APP_WS_HOST || 'ws://localhost:8000';

export const GET_API_URLS = (apiUrlParam?: string): Record<ApiUrlType, string> => {
	const apiUrlParamSegment = apiUrlParam ? `/${apiUrlParam}` : '';
	return ({
		billingPlans: '/manager/plans',
		bulkMessaging: '/manager/bulks',
		bulkContacts: '/manager/bulks/contacts',
		subscriptionPlans: '/manager/admin/plans',
		subscriptionPlansByCustomer: '/manager/admin/plans/customer-plans',
		cancelSubscription: '/manager/billing/payments/cancel',
		chats: '/manager/chats',
		chatCounter: '/manager/chats/count-new-chats',
		chatDetails: '/manager/chats/events',
		chatFoundNear: '/manager/chats/events/near',
		contacts: '/manager/contacts',
		contactsOnline: '/chat/contacts_online',
		countries: '/api/common/country_codes',
		currentCompany: '/manager/current_company',
		currentUser: '/manager/current_user',
		customers: `/manager/customers${apiUrlParamSegment}`,
		customer_tags: '/manager/customers/tags',
		customersInvoice: '/manager/customers/generate-invoice',
		deleteCompany: '/manager/customers/delete_company',
		departments: '/manager/my_company/departments',
		payments: '/manager/billing/payments',
		templates: '/manager/my_company/templates',
		widgets: '/manager/my_company/widgets',
		widgetsKnowledgeBase: '/manager/knowledge-base/manager/widgets',
		knowledgeBase: '/manager/knowledge-base/manager/articles',
		emailConfirm: '/api/auth/confirm_email',
		invite: '/manager/members/invite',
		inviteLink: `/manager/members/${apiUrlParam}/invite`,
		inviteResend: `/manager/members/${apiUrlParam}/resend_invite`,
		inviteDetails: '/api/auth/confirm_invite_details',
		inviteSignup: '/api/auth/confirm_invite',
		knowledgeBaseFolder: '/manager/knowledge-base/manager/folders',
		knowledgeBaseArticle: '/manager/knowledge-base/manager/articles',
		knowledgeBaseKeywords: '/manager/knowledge-base/manager/articles/keywords',
		knowledgeBaseSearch: '/manager/knowledge-base/manager/articles/search',
		knowledgeBaseUpdatePosition: '/manager/knowledge-base/manager/update-positions',
		members: '/manager/members',
		refreshToken: '/api/auth/refresh-token',
		requestResetPassword: '/api/auth/request_reset_password',
		resetPassword: '/api/auth/reset_password',
		updatePassword: '/api/auth/update_password',
		signIn: '/api/auth/signin',
		signUp: '/api/auth/signup',
		tags: '/manager/contacts/tags',
		tokenRefresh: '/api/auth/refresh',
		fileUrl: `/chat/file_url${apiUrlParam}`,
		filesUpload: '/chat/operator/upload',
		statistics: `/manager/stats/metrics/${apiUrlParam}`,
		statisticMetric: `/manager/stats/top/${apiUrlParam}`,
		updateCurrentPlan: '/manager/plans/set-plan-subscription',
		wayforpayServiceUrl: '/billing/payment-confirm',
	});
};

export function buildApiUrl(params: IParams) {
	const {
		apiUrlParam,
		id,
		queryParams = [],
		type,
		isNewRequestType,
		searchParams,
	} = params;

	let url = `${API_URL_START}${GET_API_URLS(apiUrlParam)[type]}`;

	if (id) {
		url += url.endsWith('/') ? id : `/${id}`;
	}

	// _C_ - comma, _E_ - equals (backend uses this encoding to parse the url

	if (queryParams.length && !isNewRequestType) {
		const filters = queryParams
			.filter(
				(param) => ![
					'sort',
					'limit',
					'offset',
					'date_from',
					'date_to',
					'columns',
					'before',
					'searchValue',
					'searchFields',
					'customer_id',
					'tags',
					'customer_tags',
					'plan_id',
					'license_ids',
					'license_id',
					'channel',
					'channels',
				].includes(param.key),
			)
			.map((val) => `${val.key}_E_${val.value}`) || [];

		const getParamValue = (key: string) => queryParams
			.find((param) => param.key === key)?.value.toString();

		const searchFields = getParamValue('searchFields');
		const searchValueParsedToFilters = searchFields
			? [`${searchFields}__like_E_${encodeURIComponent(getParamValue('searchValue') || '')}`]
			: [];

		const filtersAll = filters.concat(searchValueParsedToFilters).join('_C_');

		const urlParams = {
			filters: filtersAll,
			sort: getParamValue('sort')
				?.replace(/,/g, '_C_')
				.replace(/-asc/g, '_E_asc')
				.replace(/-desc/g, '_E_desc')
				.replace(/=asc/g, '_E_asc')
				.replace(/=desc/g, '_E_desc'),
			limit: getParamValue('limit'),
			offset: getParamValue('offset'),
			date_from: getParamValue('date_from'),
			date_to: getParamValue('date_to'),
			before: getParamValue('before'),
			tags: getParamValue('tags'),
			customer_tags: getParamValue('customer_tags'),
			license_ids: getParamValue('license_ids'),
			license_id: getParamValue('license_id'),
			channel: getParamValue('channel'),
			channels: getParamValue('channels'),
			customer_id: getParamValue('customer_id'),
			plan_id: getParamValue('plan_id'),
		};

		const urlParamsFiltered = Object.keys(urlParams).filter(
			// @ts-ignore
			(key) => !!urlParams[key] || urlParams[key] === 0 || !!urlParams[key]?.length,
		);

		if (urlParamsFiltered.length) {
			// @ts-ignore
			const queryString = urlParamsFiltered.map((urlParam) => `${urlParam}=${urlParams[urlParam]}`).join('&');

			url += `?${queryString}`;
		}
	}

	if (isNewRequestType && searchParams) {
		url += '?';
		Object.keys(searchParams)
			.forEach((key, index, array) => {
				url += `${key}=${searchParams[key]}`;

				if (array.length !== index + 1) {
					url += '&';
				}
			});
	}

	return url;
}

export const prepareBuildRequest = (params: IParamsBuildRequest & { token: string }) => {
	const {
		apiMethod,
		apiUrlParam,
		id,
		queryParams,
		requestBody,
		type,
		isOpenData,
		isNewRequestType,
		searchParams,
		token,
	} = params;

	const isMultipartFormData = type === 'filesUpload';

	const headers: HeadersInit = {
		Origin: window.location.origin,
	};

	if (token && !isOpenData) {
		headers.Authorization = `Bearer ${token}`;
	}

	if (!isMultipartFormData) {
		headers['Content-Type'] = 'application/json';
	}

	const options: RequestInit = {
		headers: new Headers(headers),
		method: apiMethod,
	};

	if (requestBody) {
		// @ts-ignore
		options.body = isMultipartFormData ? requestBody : JSON.stringify(requestBody);
	}

	return new Request(
		buildApiUrl({
			apiUrlParam,
			id,
			queryParams,
			type,
			isNewRequestType,
			searchParams,
		}),
		options,
	);
};

export const getIsAddToken = (params: IParamsBuildRequest) => (
	params.type !== 'refreshToken' && params.type !== 'signIn' && !params.isOpenData
);

export function* buildRequestGenerator(params: IParamsBuildRequest): Generator<Request> {
	let token: string = '';

	if (getIsAddToken(params)) {
		// @ts-ignore
		token = yield verifyToken(
			params.tokenArg,
			params.tokenExpArg,
		);
	}

	return prepareBuildRequest({
		...params,
		token,
	});
}

export const buildRequestFromComponent = (params: IParamsBuildRequest): Request => {
	let token: string = '';

	if (getIsAddToken(params)) {
		token = store.getState().user.token || '';
	}

	return prepareBuildRequest({
		...params,
		token,
	});
};

export const fetchData = async (request: string | URL | globalThis.Request) => {
	const response = await fetch(request);

	if (response.status === 204) {
		return { status: 'ok', message: 'No content' };
	}

	return response.json();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* showError(response: any) {
	if (response?.detail && Object.keys(response).length === 1) {
		const regEx = /^(Auth0:\d\d\d\s+[\s\S]*)$/;
		const errorText = typeof response.detail === 'string' ? response.detail : response.detail?.[0]?.msg;

		let currentError = errorText;
		if (regEx.test(errorText)) {
			const authText = errorText.split(' ', 1)[0];
			currentError = errorText.replace(`${authText} `, '');
		}

		const { isAuthenticated } = yield select(selectUserSystemParams);

		const authTokenError = ['Missing bearer token', 'Unauthorized'].includes(errorText);

		if (isAuthenticated && authTokenError) {
			yield put(userLogout());
		}

		let contentParams: Record<string, string> | undefined;

		const regExpUserNotExist = /User \[\S+] not exists/g;

		if (regExpUserNotExist.test(errorText)) {
			const [, email] = errorText.split(/[[\]]/);

			currentError = 'emailNotExist';

			contentParams = { email };
		}

		yield put(
			popupAlertShow({
				contentKey: currentError || 'somethingWentWrong',
				contentParams,
				type: 'error',
				timeout: 10000,
				withCloseButton: true,
				iconName: 'statusDeleted',
				stacked: authTokenError,
			}),
		);

		throw new Error(response);
	}
}