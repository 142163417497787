import dayjs from 'dayjs';
import { useState } from 'react';
import i18Next from 'i18next';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useInterval } from '../hooks/useInterval';

const utc = require('dayjs/plugin/utc');
const timezone = require('dayjs/plugin/timezone');
const updateLocale = require('dayjs/plugin/updateLocale');

require('dayjs/locale/uk');
require('dayjs/locale/en');

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(updateLocale);

type TDate = string | number | Date | dayjs.Dayjs | null | undefined;

export const transformDate = (date: TDate, format?: string): string => {
	if (!date) {
		return '';
	}
	return dayjs(date).format(format || 'DD/MM/YYYY');
};

// @ts-ignore
export const transformDateToUTC = (date: TDate): string => dayjs(date).utc().format();

export const transformDateToUtcDayWithZeroedTime = (date?: TDate): string => (date
	? `${dayjs(date).format().substring(0, 10)}T00:00:00Z`
	: `${dayjs().format().substring(0, 10)}T00:00:00Z`);

export const transformDateWithDay = (date: TDate): string => {
	const { language } = i18Next;

	// @ts-ignore
	const timezone = dayjs.tz.guess();

	// @ts-ignore
	dayjs.updateLocale('uk', {
		weekdaysMin: ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
	});

	if (language === 'en') {
		// @ts-ignore
		return dayjs.utc(date).tz(timezone).locale(language).format('ddd, DD MMM HH:mm');
	}
	// @ts-ignore
	return dayjs.utc(date).tz(timezone).locale(language).format('dd, DD MMMM HH:mm');
};

export const useTransformDateFromNow = (date: TDate): string | null => {
	dayjs.extend(relativeTime);
	const [currentTime, setCurrentTime] = useState(dayjs());
	useInterval(() => setCurrentTime(dayjs()), 1000, []);

	const { language } = i18Next;

	const messageDate = dayjs(date);
	const diffHours = currentTime?.diff(messageDate, 'hours');
	const diffWeek = currentTime?.diff(messageDate, 'week');

	if (diffHours > 24) {
		// @ts-ignore
		return dayjs.utc(date).locale(language).format('DD MMM YY');
	}
	if (diffWeek === 0 && diffHours >= 24) {
		// @ts-ignore
		return dayjs.utc(date)?.locale(language)?.format('dddd');
	}
	if (diffHours < 24) {
		// @ts-ignore
		return dayjs.utc(date).locale(language)?.fromNow();
	}
	return null;
};

export const transformDateToClientTimezone = (
	date: TDate,
	timezone?: string,
	format?: string,
	hideTz?: boolean,
): string => {
	if (!date) {
		return '';
	}

	if (timezone) {
		const timezoneToShow = timezone === 'Europe/Kiev' ? 'Europe/Kyiv' : timezone;
		const dateTransformed = dayjs
			// @ts-ignore
			.utc(date)
			.tz(timezone)
			.format(format || 'DD/MM/YYYY, HH:mm');

		return hideTz ? dateTransformed : `${dateTransformed} (${timezoneToShow})`;
	}

	const operatorTimezone = dayjs
		// @ts-ignore
		.tz.guess() === 'Europe/Kiev' ? 'Europe/Kyiv' : dayjs.tz.guess();

	const dateTransformed = dayjs
		// @ts-ignore
		.utc(date)
		.tz(operatorTimezone)
		.format(format || 'DD/MM/YYYY, HH:mm');

	return hideTz ? dateTransformed : `${dateTransformed} (${operatorTimezone})`;
};

export const transformUtcDateToISOWithTZ = (date: string, format?: string): string => {
	const tzoffset = new Date(date).getTimezoneOffset() * 60000;
	const localISOTime = new Date(new Date(date).valueOf() - tzoffset).toISOString();
	return format ? dayjs(localISOTime).format(format) : localISOTime;
};