import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const copy = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 17} viewBox="0 0 16 17" fill="none">
			<g clipPath="url(#clip0_10904_102779)">
				<path
					d="M4.57157 15.9282L14.2859 15.9282C14.917 15.9282 15.4287 15.4165 15.4287 14.7854L15.4287 5.07108C15.4287 4.4399 14.917 3.92822 14.2859 3.92822L4.57157 3.92822C3.94038 3.92822 3.42871 4.4399 3.42871 5.07108L3.42871 14.7854C3.42871 15.4165 3.94038 15.9282 4.57157 15.9282Z"
					stroke={fill || theme.color.general.white}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M0.571289 12.4999V2.21415C0.571289 1.91104 0.691697 1.62035 0.906024 1.40602C1.12035 1.1917 1.41104 1.07129 1.71415 1.07129H11.9999"
					stroke={fill || theme.color.general.white}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_10904_102779">
					<rect width="16" height="16" fill={fill || theme.color.general.white} transform="translate(0 0.5)" />
				</clipPath>
			</defs>
		</svg>
	);
};