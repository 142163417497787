import clsx from 'clsx';
import ru from 'date-fns/locale/ru';
import uk from 'date-fns/locale/uk';
import { useTheme } from 'styled-components';
import React, { useState, useEffect } from 'react';
import Calendar, { CalendarContainer, registerLocale } from 'react-datepicker';

import { Locale } from 'date-fns';
import { rangeDate, rangeOption } from '../../../utils/datePickerRangeOption';
import Input from '../../molecules/Input/Input';
import Select from '../../molecules/Select/Select';

import { StyledDatePicker } from './styled';

registerLocale('uk', uk as unknown as Locale);
registerLocale('ru', ru as unknown as Locale);

interface IProps {
	dateRange?: Date[];
	onChange: (date: Date | null, event?: React.MouseEvent<HTMLElement>
		| React.KeyboardEvent<HTMLElement>) => void;
	startPeriod?: string;
	placeholder?: string;
	minDate?: Date;
	maxDate?: Date;
	isClearable?: boolean;
	withIcon?: boolean;
	hasSelect?: boolean;
	selectOptions?: { id: string; period: string }[];
	t?: (key: string) => string;
	currLang?: string;
	setDateRange?: (date: Date | undefined) => void;
	value?: Date;
	selectsRange?: boolean;
	startDate?: Date;
	endDate?: Date;
}

const DatePicker = (props: IProps) => {
	const {
		dateRange,
		onChange,
		startPeriod,
		placeholder,
		minDate,
		maxDate,
		isClearable,
		withIcon,
		hasSelect,
		selectOptions,
		t,
		currLang,
		setDateRange,
		value,
	} = props;

	const theme = useTheme();

	const isSameRange = dateRange?.[0]?.toISOString()
		// @ts-ignore
		=== rangeDate[startPeriod]?.[0]?.toISOString()
		// @ts-ignore
    && dateRange?.[1]?.toISOString() === rangeDate[startPeriod]?.[1]?.toISOString();

	const [selectValue, setSelectValue] = useState(
		startPeriod ? { id: startPeriod, period: startPeriod } : '',
	);
	useEffect(() => {
		// @ts-ignore
		setSelectValue(isSameRange ? { id: startPeriod, period: startPeriod } : '');
	}, [isSameRange]);

	const onSelectChange = ({ id, period }: {
		id: string;
		period: string;
	}) => {
		// @ts-ignore
		setDateRange(rangeDate[id]);
		setSelectValue({ id, period });
	};

	// eslint-disable-next-line react/no-unstable-nested-components
	const MyContainer = ({ className, children }: {
		className: string;
		children: React.ReactNode;
	}) => (
		<CalendarContainer className={className}>
			<div className="myContainer">
				<Select
					// @ts-ignore
					options={selectOptions || rangeOption}
					fullWidth
					valueKey="id"
					labelKey="period"
					variant="primary"
					withoutValidation
					// @ts-ignore
					value={selectValue}
					// @ts-ignore
					onChange={onSelectChange}
					className="datePickerSelect"
					iconLeftProps={{ name: 'calendar', fill: theme.color.general.light }}
					t={t}
				/>
				{children}
			</div>
		</CalendarContainer>
	);

	const delayValidateDate = 1000 * 60 * 60 * 24 * 365 * 100; // 100 years

	const now = Date.now();

	const minValidateDate = new Date(now - delayValidateDate);
	const maxValidateDate = new Date(now + delayValidateDate);

	return (
		<StyledDatePicker className={clsx(isClearable && 'isClearable', 'datePickerContainer')}>
			<Calendar
				{...props}
				selected={value}
				onChange={onChange}
				dateFormat="dd/MM/yyyy"
				minDate={minDate || minValidateDate}
				maxDate={maxDate || maxValidateDate}
				showDisabledMonthNavigation
				isClearable={isClearable}
				// @ts-ignore
				customInput={
					withIcon
					// @ts-ignore
					&& <Input iconLeftProps={{ name: 'calendar', width: 18, height: 18 }} {...props} />
				}
				locale={currLang || 'en'}
				calendarStartDay={1} // to start from Monday
				// @ts-ignore
				calendarContainer={hasSelect && MyContainer}
				placeholderText={placeholder}
			/>
		</StyledDatePicker>
	);
};

export default DatePicker;