import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const forward = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
			<circle cx="8" cy="8" r="8" fill={fill || theme.color.status.warning} />
			<path
				d="M4.2853 11.1429C5.95558 9.72975 6.36644 9.14289 7.99958 9.14289H8.85672V10.8572L11.7139 7.71432L8.85672 4.85718V6.57146H8.2853C5.42815 6.57146 4.85672 9.42861 4.2853 11.1429Z"
				stroke={theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};