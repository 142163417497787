import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const plusCircle = ({
	width, height, fill, fillColor,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill={fillColor || 'none'}>
			<path
				d="M7.99986 15.4284C12.1025 15.4284 15.4284 12.1025 15.4284 7.99986C15.4284 3.89717 12.1025 0.571289 7.99986 0.571289C3.89717 0.571289 0.571289 3.89717 0.571289 7.99986C0.571289 12.1025 3.89717 15.4284 7.99986 15.4284Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8 4.57129V11.4284"
				stroke={fill || theme.color.general.white}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.57129 8H11.4284"
				stroke={fill || theme.color.general.white}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};