import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const channels = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
			<path
				d="M6.0051 8.50508C6.55635 8.50508 7.00376 8.95249 7.00376 9.50373C7.00376 10.055 6.55635 10.5024 6.0051 10.5024C5.45383 10.5024 5.00645 10.055 5.00645 9.50373C5.00645 8.95249 5.45383 8.50508 6.0051 8.50508Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
			/>
			<path
				d="M9.99977 8.50508C10.551 8.50508 10.9984 8.95249 10.9984 9.50373C10.9984 10.055 10.551 10.5024 9.99977 10.5024C9.44849 10.5024 9.00111 10.055 9.00111 9.50373C9.00111 8.95249 9.44849 8.50508 9.99977 8.50508Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
			/>
			<path
				d="M13.9944 8.50508C14.5457 8.50508 14.9931 8.95249 14.9931 9.50373C14.9931 10.055 14.5457 10.5024 13.9944 10.5024C13.4432 10.5024 12.9958 10.055 12.9958 9.50373C12.9958 8.95249 13.4432 8.50508 13.9944 8.50508Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
			/>
			<path
				d="M17.4898 12.9991C17.4898 13.5506 17.0426 13.9977 16.4911 13.9977H8.00242L5.00642 16.9937V13.9977H3.50842C3.24354 13.9977 2.98962 13.8926 2.80237 13.7051C2.61487 13.5179 2.50977 13.264 2.50977 12.9991V6.00842C2.50977 5.74351 2.61487 5.48962 2.80237 5.30237C2.98962 5.11484 3.24354 5.00977 3.50842 5.00977H16.4911C16.756 5.00977 17.0099 5.11484 17.1972 5.30237C17.3847 5.48962 17.4898 5.74351 17.4898 6.00842V12.9991Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit="10"
			/>
		</svg>
	);
};