import { IDataState, IStore } from '../types';

export const selectWholeUserState = (state: IStore) => state.user;

export const selectUserDetails = (state: IStore) => state.user.details;

export const selectUserSubscriptionDetails = (state: IStore) => state.user.subscriptionDetails;

export const selectIsFreePlan = (state: IStore) => state.user.subscriptionDetails?.plan_id === 1;

export const selectUserName = (state: IStore) => state.user.details.member_full_name;

export const selectUserMemberId = (state: IStore) => state.user.details.member_id;

export const selectUserCompanyId = (state: IStore) => state.user.details.customer_id;

export const selectUserNameWithPic = (state: IStore) => ({
	name: state.user.details.member_full_name,
	pic: state.user.details.member_profile_picture,
});

export const selectUserRole = (state: IStore) => state.user.details.role;

export const selectUserToken = (state: IStore) => state.user.token;

export const selectUserInviteDetails = (state: IStore) => state.user.inviteDetails;

export const selectUserSystemParams = (state: IStore) => ({
	isAuthenticated: state.user.isAuthenticated,
	isAppInitialized: state.user.isAppInitialized,
	inProgress: state.user.inProgress,
});

export const selectIsUserOnline = (state: IStore) => state.user.isUserOnline;

export const selectResetPassMetaData = (state: IStore) => ({
	inProgress: state.user.resetPassInProgress,
	error: state.user.error,
});

export const selectCurrentSubscription = (state: IStore) => state.user.subscriptionDetails;

// company related

export const selectCompanyData = (state: IStore) => state.company.general;

export const selectCompanyId = (state: IStore) => state.company.general?.id;

export const selectCompanyMetaData = (state: IStore) => ({
	inProgress: state.company.inProgress,
	error: state.company.error,
});

export const selectBillingPlans = (state: IStore) => state.company.billingPlans;

// unsorted

export const selectTableTotalItems = (state: IStore) => state.data.total_items;

export const selectMainDataLoading = (state: IStore) => state.data.inProgress;

export const selectMainDataInitialized = (state: IStore) => state.data.isDataInitialized;

export const selectMainData = (type: keyof IDataState) => (state: IStore) => state.data[type];

export const selectDataType = (state: IStore) => state.data.type;
export const selectSidebarDetails = (state: IStore) => state.sidebar.details;

export const selectSidebarDetailsStatus = (state: IStore) => state.sidebar.details?.status;

export const selectSidebarMetaData = (state: IStore) => ({
	inProgress: state.sidebar.sidebarInProgress,
	error: state.sidebar.error,
});

export const selectPopupAlerts = (state: IStore) => state.common.popupAlerts;

export const selectIsNavMenuExtended = (state: IStore) => state.common.isNavMenuExtended;

export const selectFilesUpload = (state: IStore) => state.fileUpload;

export const selectFilesDraft = (state: IStore) => state.fileUpload.filesChosen;

export const selectFilesUploaded = (state: IStore) => state.fileUpload.filesUploaded;

export const selectFileUrlsLoaded = (state: IStore) => state.fileUpload.fileUrlsLoadedByCode;

export const selectFormValues = (state: IStore) => state.forms.formValues;