import styled from 'styled-components';

export const StyledTextPanel = styled.div<{
	justifyContent?: string;
	alignItems?: 'start' | 'end' | 'center' | 'stretch';
	backgroundColor?: string;
	color?: string;
	align?: string;
	gap?: string;
	margin?: string;
	padding?: string;
	borderRadius?: string;
	borderColor?: string;
  textAlign?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}>`
  position: relative;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '10px 15px'};
  border-radius: ${({ borderRadius }) => borderRadius || '3px'};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  gap: ${({ gap }) => gap};
  text-align: ${({ textAlign }) => textAlign};

  .titleAndContent {
    width: 100%;
  }

  &.bordered {
    border: ${({ borderColor, color }) => `1px solid ${borderColor || color}`};
  }

  &.fullWidth {
    width: 100%;
  }
`;