import styled from 'styled-components';

import Drawer from '../../atomic-design-components/organisms/Drawer/Drawer';
import { HEADER_HEIGHT } from '../../constants';
import { getScreenHeight } from '../../utils/getScreenHeight';

export const StyledMainWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .titleRow {
    height: ${HEADER_HEIGHT}px;
    padding: 10px 20px;
    border-bottom: 1px solid ${({ theme: { color } }) => color.general.lighter};

    .closeIcon {
      border: 1px solid ${({ theme: { color } }) => color.general.lighter};
      border-radius: 3px;
    }
  }

  .popupLinkText {
    text-transform: uppercase;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({
	theme,
}) => theme.breakpoints.xl}px) {
    overflow-y: auto;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    height: ${getScreenHeight()}px;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {

    .oneLinePopup {
      max-width: 100%;
      white-space: nowrap;
    }
  }
`;

export const StyledGrid = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
`;

export const StyledMain = styled.div`
  position: relative;
  flex-grow: 1;

  &::-webkit-scrollbar {
    display: none;
  }

  .titleRow {

    .itemsCountBadge {
      margin-right: 20px;
      margin-left: 10px;
    }

    .menuIcon {
      margin-left: auto;
    }
  }

  &.withBackIcon {

    .titleRow .menuIcon {
      padding: 10px;
      border: 1px solid ${({ theme: { color } }) => color.general.light};
      border-radius: 3px;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    overflow-y: auto;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    overflow-y: auto;
  }
`;

// @ts-ignore
export const StyledLeftStaticDrawer = styled(Drawer)<{
	isOpen?: boolean;
}>`
  min-width: 200px;
  overflow-x: hidden;
  border-right: 1px solid ${({ theme: { color } }) => color.general.lighter};

  &.chats,
  &.teamChats {
    min-width: 340px;
  }

  &.templates {
    min-width: 300px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: ${HEADER_HEIGHT}px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    z-index: 5;
    border: none;
  }
`;

// @ts-ignore
export const StyledRightDrawer = styled(Drawer)`
  min-width: ${({ minWidth }) => minWidth || '280px'};
  border-left: 1px solid ${({ theme: { color } }) => color.general.lighter};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    z-index: 5;
    margin-top: ${HEADER_HEIGHT};
    border: none;
  }
`;