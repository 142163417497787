import styled from 'styled-components';

export const StyledPopupAlert = styled.div<{
	splitThemeColor: [string, string];
}>`
  display: inline-flex;
  align-items: center;
  margin: 0 auto;
  padding: 12px 16px 13px 16px;
  border-radius: 3px;
  background-color: ${({ splitThemeColor, theme: { color } }) => `${
		// @ts-ignore
		color[splitThemeColor[0]][splitThemeColor[1]]
	}d9`};
  color: ${({ theme }) => theme.color.general.white};
  white-space: pre-line;
  box-shadow: ${({ splitThemeColor, theme: { color } }) => `0px 14px 15px ${
		// @ts-ignore
		color[splitThemeColor[0]][splitThemeColor[1]]
	}40`};
  transition: all 0.2s;

  &:not(.warning) {
    animation: scale-in-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  &.top {
    margin-bottom: 15px;
  }

  &.bottom {
    margin-top: 15px;
  }

  .content {
    overflow-wrap: anywhere;
    margin-right: 12px;
  }

  .icon {
    cursor: pointer;
  }

  .leftIcon {
    margin-right: 12px;
    cursor: default;
  }

  .rightIcon {
    margin-left: 12px;
  }

  &.hide {
    animation: scale-out-center 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }

  @keyframes scale-out-center {

    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes scale-in-center {

    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }
`;