import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const plans = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
			<path
				d="M7 6V5M7 6C5.89333 6 5 6 5 7.2C5 9 9 9 9 10.8C9 12 8.10667 12 7 12M7 6C8.10667 6 9 6.456 9 7.2M5 10.8C5 11.7 5.89333 12 7 12M7 12V13M5.14279 0.571411H12.5714C12.8745 0.571411 13.1652 0.691819 13.3795 0.906146C13.5938 1.12047 13.7142 1.41116 13.7142 1.71427V12.5714M3.4285 2.85713H10.2856C10.9168 2.85713 11.4285 3.3688 11.4285 3.99998V14.2857C11.4285 14.9169 10.9168 15.4286 10.2856 15.4286H3.4285C2.79732 15.4286 2.28564 14.9169 2.28564 14.2857V3.99998C2.28564 3.3688 2.79732 2.85713 3.4285 2.85713Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};