import React, { useEffect } from 'react';
import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StyledButton } from './styled';
import Icon, { IIconProps } from '../../atoms/Icon/Icon';
import Tooltip, { ITooltipProps } from '../../atoms/Tooltip/Tooltip';
import theme from '../../../theme';
import { useTimeout } from '../../../hooks/useTimeout';
import LoaderCircular from '../../atoms/LoaderCircular/LoaderCircular';
import { selectSidebarMetaData } from '../../../redux-saga/selectors';

const typographyVariant = {
	small: 'button3',
	medium: 'button1',
};

export interface IButtonProps {
	backgroundColor?: string;
	borderColor?: string;
	borderRadius?: string;
	children?: React.ReactNode;
	className?: string;
	confirmButtonProps?: IButtonProps
		& { onDeleteConfirm?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void; };
	color?: string;
	disabled?: boolean;
	fullWidth?: boolean;
	hoverType?: 'light' | 'dark' | 'opacity';
	iconName?: string;
	iconSize?: number;
	iconRightProps?: IIconProps;
	iconLeftProps?: IIconProps;
	isLoading?: boolean;
	margin?: string;
	maxWidth?: string;
	onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	padding?: string;
	size?: 'small' | 'medium';
	text?: string;
	tooltipProps?: ITooltipProps;
	type?: 'button' | 'submit' | 'reset';
	uppercase?: boolean;
	variant?: 'primary' | 'secondary' | 'general' | 'bordered';
	withoutBorder?: boolean;
	style?: React.CSSProperties;
}

const Button = (props: IButtonProps) => {
	const {
		backgroundColor,
		borderColor,
		borderRadius,
		children,
		className,
		confirmButtonProps,
		color,
		disabled,
		fullWidth,
		hoverType = 'light',
		iconName,
		iconSize,
		iconRightProps,
		iconLeftProps,
		isLoading,
		margin,
		maxWidth,
		onClick,
		padding,
		size = 'medium',
		text,
		tooltipProps,
		type = 'button',
		uppercase,
		variant = 'primary',
		withoutBorder,
		...otherProps
	} = props;
	const { t } = useTranslation('all');
	const [isConfirmDeleteActive, setIsConfirmDeleteActive] = React.useState(false);
	const { error } = useSelector(selectSidebarMetaData);
	const deleteConfirmRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);

	const onDeleteClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.preventDefault();
		setIsConfirmDeleteActive(true);
	};

	useEffect(() => {
		if (error) {
			setIsConfirmDeleteActive(false);
		}
	}, [error]);

	useTimeout(
		() => {
			setIsConfirmDeleteActive(false);
		},
		3000,
		[isConfirmDeleteActive],
	);

	const onDeleteClickConfirm = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.preventDefault();
		if (confirmButtonProps?.onDeleteConfirm) {
			confirmButtonProps.onDeleteConfirm(e);
		}

		if (onClick) {
			onClick(e);
		}

		deleteConfirmRef.current = setTimeout(() => {
			setIsConfirmDeleteActive(false);
		}, 1000);
	};

	useEffect(() => () => {
		// @ts-ignore
		clearTimeout(deleteConfirmRef.current);
	}, []);

	const confirmButtonPropsExtended = confirmButtonProps && isConfirmDeleteActive
		? {
			backgroundColor: confirmButtonProps.backgroundColor || theme.color.general.white,
			color: confirmButtonProps.color || theme.color.status.error,
			variant: confirmButtonProps.variant || 'general',
			onClick: onDeleteClickConfirm,
		}
		: {};

	const confirmIconProps = confirmButtonProps
		&& isConfirmDeleteActive
		&& confirmButtonProps.iconLeftProps;
	const confirmText = confirmButtonProps && isConfirmDeleteActive && t('clickToConfirm');
	const hasChildren = !!children || !!confirmText || !!text;

	const getLoaderBg = () => {
		if (variant === 'general' || variant === 'primary') {
			return confirmButtonProps ? theme.color.status.error : theme.color.primary.main;
		}
		return 'transparent';
	};

	return (
		// @ts-ignore
		<Tooltip {...tooltipProps} disableTooltip={tooltipProps ? tooltipProps.disableTooltip : true}>
			<StyledButton
				backgroundColor={backgroundColor}
				// @ts-ignore
				borderRadius={borderRadius}
				borderColor={borderColor}
				color={color}
				hoverType={hoverType}
				disabled={disabled || isLoading}
				margin={margin}
				maxWidth={maxWidth}
				// @ts-ignore
				onClick={confirmButtonProps ? onDeleteClick : onClick}
				padding={padding}
				type={type}
				// @ts-ignore
				typographyVariant={typographyVariant[size]}
				variant={variant}
				className={clsx(
					className,
					variant,
					disabled && 'disabled',
					fullWidth && 'fullWidth',
					uppercase && 'uppercase',
					withoutBorder && 'withoutBorder',
					confirmButtonProps && isConfirmDeleteActive && 'confirmState',
					isLoading && 'loading',
					// @ts-ignore
					confirmButtonProps && isConfirmDeleteActive && confirmButtonProps?.classNameConfirm,
				)}
				{...otherProps}
				{...confirmButtonPropsExtended}
			>
				{
					isLoading
						? (
							<LoaderCircular
								color="#fff"
								size="16px"
								borderWidth="2px"
								loaderBg={getLoaderBg()}
							/>
						)
						: (
							<>
								{(iconName || iconLeftProps) && (
									<Icon
										name={iconName}
										fill={color || theme.color.general.white}
										margin={hasChildren ? '0 6px 0 0' : '0'}
										size={iconSize || 16}
										{...iconLeftProps}
										{...confirmIconProps}
									/>
								)}
								{children || confirmText || text}
								{iconRightProps && (
									<Icon fill={color || theme.color.general.white} margin="0 0 0 6px" {...iconRightProps} />
								)}
							</>
						)
				}
			</StyledButton>
		</Tooltip>
	);
};

export default Button;