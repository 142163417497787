import React from 'react';
import { useTheme } from 'styled-components';
import ReactTexty from '../../lib/Texty';

import Tag from '../../atomic-design-components/atoms/Tag/Tag';
import Typography, { ITypographyProps } from '../../atomic-design-components/atoms/Typography/Typography';
import { IDummyData } from '../../types';

const NameWithTag = ({
	rowData, typographyVariant, t,
}: {
	rowData: IDummyData
	typographyVariant?: ITypographyProps['variant']
	t: (key: string) => string;
}) => {
	const theme = useTheme();
	const TAG_CONFIG = {
		installed: {
			light: `${theme.color.status.success}40`,
			dark: theme.color.status.success,
			text: 'installed',
		},
		not_installed: {
			light: `${theme.color.status.error}40`,
			dark: theme.color.status.error,
			text: 'installedNot',
		},
	};

	const currentStatus: 'installed' | 'not_installed' = rowData?.install_status;

	return (
		<>
			<Typography
				component="div"
				variant={typographyVariant || 'body2'}
				className="name"
				margin="0 5px 0 0"
				as={ReactTexty}
			>
				{rowData.name || 'chatWidget #1'}
			</Typography>
			<Tag
				backgroundColor={TAG_CONFIG[currentStatus].light}
				color={TAG_CONFIG[currentStatus].dark}
				text={t(TAG_CONFIG[currentStatus].text)}
				className="withoutInactive"
				as={ReactTexty}
				fontSize={10}
				lineHeight={16}
			/>
		</>
	);
};

export default NameWithTag;