import React from 'react';
import { useTheme } from 'styled-components';
import { useWidth } from '../../../hooks/useWidth';
import LanguageSelect from '../../LanguageSelect/LanguageSelect';

import { StyledHeader } from './styled';
import Logo from '../../Logo/Logo';

const HeaderIntroScreen = () => {
	const theme = useTheme();
	const screenWidth = useWidth();
	const isMobile = screenWidth && screenWidth < theme.breakpoints.sm;
	const isNearTablet = screenWidth && screenWidth < 1140;
	const isTablet = screenWidth && screenWidth < theme.breakpoints.lg;
	const width = !isMobile ? '204px' : '';

	return (
		<StyledHeader className="introHeader">
			<Logo variant={isMobile || isTablet ? 'medium' : 'large'} to="../sign-in" />
			{(!isNearTablet || isTablet) && (
				<LanguageSelect
					isIntroScreensSelect
					width={width}
				/>
			)}
		</StyledHeader>
	);
};

export default HeaderIntroScreen;