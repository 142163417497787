import { useEffect, useState } from 'react';

export const useWidth = () => {
	const isClient = typeof window !== 'undefined';

	const getWidth = () => (isClient ? document.documentElement.clientWidth : undefined);

	const [width, setWidth] = useState(getWidth);

	useEffect(() => {
		if (isClient) {
			const handleResize = () => setWidth(getWidth());

			window.addEventListener('resize', handleResize);
			return () => window.removeEventListener('resize', handleResize);
		}
	}, []);

	return width || 0;
};