import React from 'react';
import { useDispatch } from 'react-redux';
import ReactTexty from '../../../lib/Texty';
import Typography from '../../../atomic-design-components/atoms/Typography/Typography';
import { popupAlertShow } from '../../../redux-saga/actions';
import theme from '../../../theme';

const TextWithCopyOnClick = ({
	cellData,
	textVariant,
}: {
	cellData: string;
	textVariant?: string;
}) => {
	const dispatch = useDispatch();

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
		setTimeout(
			() => navigator.clipboard.writeText(cellData).then(
				() => {},
				() => {},
			),
		);

		dispatch(
			popupAlertShow({
				contentKey: 'dataCopied',
				type: 'success',
				withCloseButton: true,
				iconName: 'copy',
			}),
		);
	};

	return (
		<Typography
			// @ts-ignore
			variant={textVariant || 'body2'}
			color={theme.color.secondary.main}
			className="emailForCopy"
			onClick={handleClick}
			as={ReactTexty}
		>
			{cellData}
		</Typography>
	);
};

export default TextWithCopyOnClick;