import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const menu = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 20} height={height || 20} fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M8 4c.9468 0 1.7143-.7675 1.7143-1.7143S8.9468.5714 8 .5714s-1.7143.7675-1.7143 1.7143S7.0532 4 8 4ZM8 9.7142c.9468 0 1.7143-.7675 1.7143-1.7143 0-.9467-.7675-1.7143-1.7143-1.7143S6.2857 7.0532 6.2857 8c0 .9468.7675 1.7143 1.7143 1.7143ZM8 15.4286c.9468 0 1.7143-.7675 1.7143-1.7143S8.9468 12 8 12s-1.7143.7675-1.7143 1.7143S7.0532 15.4286 8 15.4286Z" fill={fill || theme.color.general.dark} />
		</svg>
	);
};