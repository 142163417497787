import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const statusPending = ({
	width, height,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 17} height={height || 16} viewBox="0 0 17 16" fill="none">
			<g clipPath="url(#clip0_9628_61236)">
				<path
					d="M8.49986 15.4287C12.6025 15.4287 15.9284 12.1028 15.9284 8.0001C15.9284 3.89742 12.6025 0.571533 8.49986 0.571533C4.39717 0.571533 1.07129 3.89742 1.07129 8.0001C1.07129 12.1028 4.39717 15.4287 8.49986 15.4287Z"
					fill={theme.color.primary.lightest}
					stroke={theme.color.status.warning}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M6.78516 5.14307V10.8574"
					stroke={theme.color.status.warning}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M10.2139 5.14307V10.8574"
					stroke={theme.color.status.warning}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_9628_61236">
					<rect width="16" height="16" fill="white" transform="translate(0.5)" />
				</clipPath>
			</defs>
		</svg>
	);
};