import React from 'react';
import clsx from 'clsx';
import { StyledColor } from './styled';

interface IProps {
	color: string | { key: string; value: string }
	dataTooltip?: string;
	disabled?: boolean;
	selected?: boolean;
	size?: number;
	withBorder?: boolean;
	className?: string;
}

const Color = ({
	color, dataTooltip, disabled, selected, size, withBorder, ...rest
}: IProps) => {
	if (!color) {
		return null;
	}

	return (
		<StyledColor
			className={clsx('color', selected && 'selected', disabled && 'disabled')}
			color={typeof color === 'string' ? color : color?.key}
			data-tooltip={dataTooltip}
			size={size}
			withBorder={withBorder}
			{...rest}
		/>
	);
};

export default Color;