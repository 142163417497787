import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SEARCH_SUBSTRING_CHAT_OPERATOR } from '../constants';
import { selectUserRole } from '../redux-saga/selectors';
import { getIsAllowChatOperator } from './getIsAllowChatOperator';

export const getIsChatOperator = () => {
	const role = useSelector(selectUserRole);
	const location = useLocation();
	const isAllowChatOperator = getIsAllowChatOperator(role);

	const isChatOperatorInSearch = location.search.includes(SEARCH_SUBSTRING_CHAT_OPERATOR);

	return isAllowChatOperator && isChatOperatorInSearch;
};