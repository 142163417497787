import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const widgets = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
			<g clipPath="url(#clip0_11636_42132)">
				<path
					d="M4.51465 7.25737V2.45737C4.51465 2.09364 4.65914 1.74481 4.91633 1.48762C5.17352 1.23043 5.52235 1.08594 5.88608 1.08594H17.5432C17.9069 1.08594 18.2558 1.23043 18.513 1.48762C18.7702 1.74481 18.9147 2.09364 18.9147 2.45737V13.4288C18.9147 13.7925 18.7702 14.1413 18.513 14.3985C18.2558 14.6557 17.9069 14.8002 17.5432 14.8002H15.4861"
					stroke={fill || theme.color.general.darker}
					strokeWidth="1.14"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M4.51465 5.20117H18.9147"
					stroke={fill || theme.color.general.darker}
					strokeWidth="1.14"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M10.6859 10.6855H2.45737C1.69995 10.6855 1.08594 11.2996 1.08594 12.057V17.5427C1.08594 18.3001 1.69995 18.9141 2.45737 18.9141H10.6859C11.4434 18.9141 12.0574 18.3001 12.0574 17.5427V12.057C12.0574 11.2996 11.4434 10.6855 10.6859 10.6855Z"
					stroke={fill || theme.color.general.darker}
					strokeWidth="1.14"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M1.08594 14.1152H12.0574"
					stroke={fill || theme.color.general.darker}
					strokeWidth="1.14"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_11636_42132">
					<rect width="19.2" height="19.2" fill="white" transform="translate(0.400391 0.400391)" />
				</clipPath>
			</defs>
		</svg>
	);
};