import dayjs from 'dayjs';

import {
	FILE_UPLOAD,
	FILE_UPLOAD_ERROR,
	FILE_UPLOAD_SUCCESS,
	INPUT_FILES_ADD,
	INPUT_FILES_CLEAR,
	INPUT_FILE_REMOVE,
	SET_FILE_URL_LOADED,
	SIGN_OUT,
} from '../constants';
import { IAction, IFileUploadState } from '../../types';

const defaultState: IFileUploadState = {
	errors: {},
	errorLatest: null,
	filesUploaded: {},
	filesChosen: {},
	inProgress: false,
	filesInProgress: {},
	fileUrlsLoadedByCode: {},
};

export default (state = defaultState, action = {} as IAction): IFileUploadState => {
	const { stateKey, chatID } = action;

	switch (action.type) {
		case INPUT_FILES_ADD:
			return {
				...state,
				[stateKey]: {
					// @ts-ignore
					...state[stateKey],
					// @ts-ignore
					[chatID]: [...(state[stateKey][chatID] || []), ...action.files],
				},
			};
		case INPUT_FILE_REMOVE:
			return {
				...state,
				[stateKey]: {
					// @ts-ignore
					...state[stateKey],
					// @ts-ignore
					[chatID]: state[stateKey][chatID]
						?.filter((file: { preview: string }) => file.preview !== action.filePreviewId),
				},
				filesUploaded: {
					...state.filesUploaded,
					[chatID]: state.filesUploaded[chatID]
						?.filter((file: { preview: string }) => file.preview !== action.filePreviewId),
				},
				filesInProgress: {
					...state.filesInProgress,
					[chatID]: state.filesInProgress[chatID]
						?.filter((fileId: number) => action.filePreviewId !== fileId),
				},
				errors: {
					...state.errors,
					[chatID]: state.errors[chatID]
						?.filter((errorFileId: number) => action.filePreviewId !== errorFileId),
				},
			};
		case FILE_UPLOAD:
			return {
				...state,
				inProgress: true,
				filesInProgress: {
					...state.filesInProgress,
					[chatID]: [...(state.filesInProgress[chatID] || []), action.newFiles[0]],
				},
			};
		case FILE_UPLOAD_SUCCESS:
			return {
				...state,
				inProgress: false,
				filesUploaded: {
					...state.filesUploaded,
					[chatID]: [...(state.filesUploaded[chatID] || []), action.file],
				},
				filesInProgress: {
					...state.filesInProgress,
					[chatID]: state.filesInProgress[chatID].filter(
						(file: { name: string }) => action.file.name !== file.name,
					),
				},
			};
		case FILE_UPLOAD_ERROR:
			return {
				...state,
				inProgress: false,
			};
		case INPUT_FILES_CLEAR:
			return {
				...state,
				filesInProgress: {},
				filesChosen: {},
				filesUploaded: {},
			};
		case SET_FILE_URL_LOADED:
			return {
				...state,
				fileUrlsLoadedByCode: {
					...state.fileUrlsLoadedByCode,
					[action.code]: { url: action.url || '', date: dayjs() },
				},
			};
		case SIGN_OUT:
			return defaultState;
		default:
			return state;
	}
};