import React from 'react';
import { IPropsSvgIcon } from '../../types';

export const typeXLS = ({
	width, height,
}: IPropsSvgIcon) => (
	<svg width={width || 30} height={height || 36} fill="none" viewBox="0 0 20 26">
		<path d="M.334 1.8333C.334 1.097.931.5 1.6673.5H13.334l8.6667 8.6667v16c0 .7363-.597 1.3333-1.3334 1.3333h-19C.931 26.5.334 25.903.334 25.1667V1.8333Z" fill="#12B357" />
		<path d="M22.0007 9.1667h-6.5c-1.1967 0-2.1667-.97-2.1667-2.1667V.5l8.6667 8.6667Z" fill="#017E1E" />
		<path d="m6.2177 15.8144.9533 1.6111h.037l.9578-1.6111h1.1288L7.8519 18.178l1.475 2.3637H8.1774l-.9695-1.6135h-.037l-.9694 1.6135H5.0566l1.4796-2.3637-1.4519-2.3636h1.1334ZM9.927 20.5417v-4.7273h.9995v3.9032h2.0266v.8241h-3.026ZM16.1795 17.1739c-.0185-.1862-.0977-.3308-.2378-.4339-.14-.1031-.3301-.1547-.5701-.1547-.1631 0-.3009.0231-.4132.0693-.1123.0446-.1985.1069-.2585.187a.4497.4497 0 0 0-.0877.2723c-.0031.0847.0146.1585.0531.2216.04.0631.0946.1177.1638.1639.0693.0446.1493.0839.2401.1177.0908.0323.1877.06.2908.0831l.4248.1016c.2062.0461.3954.1077.5678.1846.1723.077.3216.1716.4478.284a1.178 1.178 0 0 1 .2931.397c.0708.1523.107.327.1085.5239-.0015.2893-.0754.5402-.2216.7525-.1446.2108-.3539.3747-.6278.4917-.2724.1154-.6009.1731-.9856.1731-.3817 0-.714-.0585-.9972-.1754-.2816-.117-.5017-.2901-.6601-.5194-.157-.2308-.2393-.5163-.247-.8563h.9671c.0108.1585.0562.2908.1362.397.0816.1046.1901.1839.3255.2377.1369.0523.2916.0785.4639.0785.1693 0 .3163-.0246.4409-.0739.1262-.0492.2239-.1177.2931-.2054.0693-.0877.1039-.1885.1039-.3024 0-.1061-.0315-.1954-.0946-.2677-.0616-.0723-.1524-.1339-.2724-.1847-.1185-.0508-.2639-.0969-.4363-.1385l-.5147-.1292c-.3985-.097-.7132-.2486-.9441-.4548-.2308-.2062-.3454-.4839-.3439-.8332-.0015-.2863.0746-.5363.2285-.7502.1555-.2139.3686-.3809.6394-.5009s.5786-.18.9233-.18c.3509 0 .6571.06.9187.18.2631.12.4678.287.614.5009.1462.2139.2216.4616.2262.7432h-.9579Z" fill="#fff" />
	</svg>
);