import styled from 'styled-components';
import { NAV_MENU_COLORS } from '../../constants';

export const StyledHeaderMobile = styled.div<{
	colorScheme: 'system_administrator' | 'customer_administrator' | 'customer_owner' | 'operator';
}>`
  position: relative;
  z-index: 11;
  width: 100vw;
  height: max-content;
  padding: 14px;
  border-bottom: 1px solid transparent;
  background-color: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].light};

  &.extended {
    border-bottom: 1px solid ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].lighter};
  }

  .menuItem {
    margin-right: 9px;

    .item {
      padding: 8px;
    }
  }

  .headerMobileLogo {
    flex-grow: 1;
  }

  .menuItemIcon path {
    stroke: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].dark};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {

    .menuItem {
      order: 3;
    }

    .headerMobileLogo {
      order: 2;
      flex-grow: 0;
    }

    .menuIcon {
      order: 1;
    }
  }
`;