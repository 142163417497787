import React from 'react';
import clsx from 'clsx';

import { calculateImageHeight } from '../../../utils/imageProcessing';
import {
	StyledActions, StyledImage, StyledImageAsBackground, StyledImageWithActionsWrapper,
} from './styled';

const Image = ({
	actionIcons,
	actionIconsType = 'permanent',
	alignItems = 'center',
	alt = '',
	asBackground,
	backgroundSize = 'contain',
	children,
	className,
	height,
	justifyContent = 'center',
	imageRef,
	maxHeight,
	maxWidth,
	radius = '',
	src = '',
	type,
	width,
	...otherProps
}: {
	actionIcons?: React.ReactNode;
	actionIconsType?: 'permanent' | 'onHover';
	alignItems?: 'start' | 'end' | 'center' | 'stretch';
	alt?: string;
	asBackground?: boolean;
	backgroundSize?: string;
	children?: React.ReactNode;
	className?: string;
	height?: number | string;
	justifyContent?: 'start' | 'end' | 'center' | 'stretch' | 'space-around' | 'space-between' | 'space-evenly';
	imageRef?: React.RefObject<HTMLDivElement>;
	maxHeight?: number | string;
	maxWidth?: number | string;
	radius?: number | string;
	src?: string;
	type?: 'square' | 'horizontal' | 'vertical' | 'custom';
	width?: number | string;
}) => {
	if (asBackground && actionIcons) {
		return (
			<StyledImageWithActionsWrapper radius={radius} {...otherProps}>
				<StyledImageAsBackground
					src={src}
					height={height}
					width={width}
					className={clsx(className, 'image')}
					backgroundSize={backgroundSize}
					maxHeight={maxHeight}
					maxWidth={maxWidth}
					{...otherProps}
				>
					<StyledActions className={clsx(actionIconsType, 'imageActions')} radius={radius}>
						{actionIcons}
					</StyledActions>
				</StyledImageAsBackground>
			</StyledImageWithActionsWrapper>
		);
	}

	if (asBackground && !actionIcons) {
		return (
			<StyledImageAsBackground
				src={src}
				height={height}
				width={width}
				radius={radius}
				className={clsx(className, 'image')}
				backgroundSize={backgroundSize}
				maxHeight={maxHeight}
				maxWidth={maxWidth}
				{...otherProps}
			/>
		);
	}

	if (!asBackground) {
		return (
			<StyledImage
				height={calculateImageHeight(width || 0, height, type)}
				width={width}
				radius={radius}
				className={className}
				alignItems={alignItems}
				justifyContent={justifyContent}
				maxHeight={maxHeight}
				maxWidth={maxWidth}
				ref={imageRef}
				{...otherProps}
			>
				<img src={src} alt={alt} />
				{children}
			</StyledImage>
		);
	}

	return null;
};

export default Image;