import { isObjectEmpty } from './isObjectEmpty';
import { DEFAULT_LANGUAGE } from '../locales';

export const getAvailableTranslation = (textObject: Record<string, string>, currLng = '') => {
	if (isObjectEmpty(textObject)) {
		return '';
	}

	let value: string | undefined = textObject[currLng] || textObject[DEFAULT_LANGUAGE];

	if (!value) {
		value = Object.values(textObject).find((item) => item);
	}

	return value;
};