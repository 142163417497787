import React, {
	forwardRef, useRef, useState, useEffect,
} from 'react';
import ReactQuill, { Quill } from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import 'quill-emoji/dist/quill-emoji.css';

// @ts-ignore
import quillEmoji from 'quill-emoji';

const {
	EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji,
} = quillEmoji;
Quill.register(
	{
		'formats/emoji': EmojiBlot,
		'modules/emoji-shortname': ShortNameEmoji,
		'modules/emoji-toolbar': ToolbarEmoji,
		'modules/emoji-textarea': TextAreaEmoji,
	},
	true,
);

const TOOLBAR_OPTIONS = [
	[{ header: [1, 2, 3, false] }],
	[
		'bold',
		'italic',
		'underline',
		'strike',
	],
	[{ list: 'ordered' }, { list: 'bullet' }],
	['clean'],
];

interface IProps {
	id?: string;
	value?: string;
	placeholder?: string;
	readOnly?: boolean;
	onChange?: (value: { html: string; markdown: string }) => void;
}

const RichTextEditor = forwardRef((props: IProps, ref) => {
	const [value, setValue] = useState(props.value || '');
	const reactQuillRef = useRef(null);

	useEffect(() => {
		setValue(props.value || '');
	}, [JSON.stringify(props)]);

	const onChange = (content: string) => {
		setValue(content);

		if (props.onChange) {
			props.onChange({
				html: content,
				markdown: content,
			});
		}
	};

	return (
		<ReactQuill
			id={props.id}
			// @ts-ignore
			ref={ref || reactQuillRef}
			theme="snow"
			placeholder={props.placeholder}
			modules={{
				toolbar: {
					container: TOOLBAR_OPTIONS,
				},
			}}
			formats={['header', 'bold', 'italic', 'underline', 'strike', 'list', 'emoji']}
			value={value}
			onChange={onChange}
			readOnly={props.readOnly}
		/>
	);
});

export default RichTextEditor;