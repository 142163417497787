import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const peopleSign = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 16} fill="none" viewBox="0 0 16 16">
			<path
				d="M15.4286 9.14258L10.72 15.4283L7.57715 13.074"
				stroke={fill || theme.color.general.white}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.71401 5.71415C7.13417 5.71415 8.28544 4.56288 8.28544 3.14272C8.28544 1.72256 7.13417 0.571289 5.71401 0.571289C4.29385 0.571289 3.14258 1.72256 3.14258 3.14272C3.14258 4.56288 4.29385 5.71415 5.71401 5.71415Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.42843 14.2852H0.571289V12.571C0.572183 11.5545 0.874291 10.561 1.43946 9.7161C2.00462 8.8712 2.80749 8.21272 3.74667 7.82384C4.68585 7.43496 5.7192 7.33313 6.71622 7.53119C7.71324 7.72926 8.62921 8.21834 9.34843 8.93667"
				stroke={fill || theme.color.general.white}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};