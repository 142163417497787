import React, { useEffect, useState } from 'react';
import { Tab, Tabs as TabsComponent } from 'react-tabs-scrollable';
import 'react-tabs-scrollable/dist/rts.css';

import { useTheme } from 'styled-components';
import DropdownMenu from '../../atoms/Dropdown/Dropdown';
import FlexRow from '../../atoms/FlexRow/FlexRow';
import Icon from '../../atoms/Icon/Icon';
import { StyledTabs } from './styled';

const Tabs = ({
	typographyType,
	withAddAction = true,
	tabsTitles,
	tabsContents,
	dropdownItems,
	onDropItemClick,
	isDisabled,
	errorTabIndex,
}: {
	typographyType: string;
	withAddAction?: boolean;
	tabsTitles: string[];
	tabsContents: string[];
	dropdownItems: string[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onDropItemClick: (e: any) => void;
	isDisabled: boolean;
	errorTabIndex: {
		index: number;
	};
}) => {
	const [activeTab, setActiveTab] = useState(0);

	useEffect(() => {
		if (errorTabIndex.index >= 0) setActiveTab(errorTabIndex.index);
	}, [errorTabIndex]);

	if (!tabsTitles?.length && !tabsContents?.length && !dropdownItems?.length) {
		return null;
	}

	const onTabClick = (e: never, idx: number) => {
		setActiveTab(idx);
	};

	const theme = useTheme();

	return (
		<StyledTabs variant={typographyType || 'caption1'}>
			<FlexRow margin="0 0 20px">
				{!isDisabled && withAddAction && !!dropdownItems?.length && (
					<DropdownMenu
						// @ts-ignore
						MenuButton={Icon}
						buttonProps={{
							// @ts-ignore
							name: 'plus',
							wrapperWidth: 44,
							wrapperHeight: 44,
							borderRadius: '3px',
							stroke: theme.color.general.black,
						}}
						openDirection="right"
						className="tabsDropdown"
						// @ts-ignore
						dropdownItems={dropdownItems}
						onItemClick={onDropItemClick}
					/>
				)}
				<TabsComponent
					activeTab={activeTab}
					// @ts-ignore
					onTabClick={onTabClick}
					hideNavBtnsOnMobile={false}
					rightNavBtnClassName="right-nav-btn"
					rightBtnIcon={<Icon name="chevronLeft" />}
					leftBtnIcon={<Icon name="chevronLeft" />}
				>
					{tabsTitles.map((item) => (
						// @ts-ignore
						<Tab key={item.props.id} id={item.props.id}>
							{item}
						</Tab>
					))}
				</TabsComponent>
			</FlexRow>
			{tabsContents.map((item, index) => (
				<div className="animate__animated animate__fadeInLeft" role="tabpanel" key={item}>
					{activeTab === index && <div className="mx-4">{item}</div>}
				</div>
			))}
		</StyledTabs>
	);
};

export default Tabs;