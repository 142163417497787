import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import PopupAlert from '../atomic-design-components/molecules/PopupAlert/PopupAlert';
import { popupAlertHide } from '../redux-saga/actions';
import { selectPopupAlerts } from '../redux-saga/selectors';

const StyledPopupAlerts = styled.div`
  position: absolute;  
  top: 20px;
  right: 50%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  transform: translate(50%, 0);

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    top: 10px;
    width: 80%;
  }
`;

const PopupAlertsComponent = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation('popupAlerts');
	const popupAlerts = useSelector(selectPopupAlerts);

	return (
		<StyledPopupAlerts>
			{popupAlerts?.map((alert) => (
				<PopupAlert
					{...alert}
					key={alert?.id}
					onClose={(id) => dispatch(popupAlertHide(id))}
					content={alert?.content || t(alert?.contentKey || '', alert?.contentParams)}
					index={popupAlerts?.indexOf(alert)}
				/>
			))}
		</StyledPopupAlerts>
	);
};

export default PopupAlertsComponent;