import React from 'react';
import { IPropsSvgIcon } from '../../types';

export const noTeamChatMessages = ({
	width, height,
}: IPropsSvgIcon) => (
	<svg width={width || 200} height={height || 200} fill="none" viewBox="0 0 201 201">
		<g clipPath="url(#clip0_1377_179689)">
			<circle cx="100.5" cy="100.5" r="100" fill="#F5F5F5" />
			<path
				d="M83.1549 129.153L65.4395 138.114L65.7439 134.487L78.1147 121.248L83.1549 129.153Z"
				fill="#2D2D2D"
			/>
			<path
				d="M66.6032 113.482L107.134 151.708C109.041 153.505 112.138 152.729 112.999 150.238L142.292 65.3708C143.248 62.6008 140.758 59.874 137.959 60.6269L45.1837 85.6223C42.4396 86.3615 41.5747 89.8747 43.6516 91.8367L66.6012 113.482"
				fill="#9D9D9D"
			/>
			<path
				d="M129.859 72.6641L78.0102 124.23L65.6957 137.905C64.7629 138.854 63.1785 138.044 63.3763 136.721L66.8321 113.684L129.859 72.6641Z"
				fill="#5E5E5E"
			/>
		</g>
		<defs>
			<clipPath id="clip0_1377_179689">
				<rect width="200" height="200" fill="white" transform="translate(0.5 0.5)" />
			</clipPath>
		</defs>
	</svg>
);