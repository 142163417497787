import styled from 'styled-components';

import { HEADER_HEIGHT, NAV_MENU_COLORS, NAV_MENU_WIDTH } from '../../constants';
import { getScreenHeight } from '../../utils/getScreenHeight';

// @ts-ignore
export const StyledNavMenu = styled.nav<{
	colorScheme: 'system_administrator' | 'customer_administrator' | 'customer_owner' | 'operator',
}>`
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  padding: 14px;
  background-color: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].light};
  transition: width 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
	
  & > .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: visible auto;
	  
    &::-webkit-scrollbar {
      display: none;
	}
  }

  .onlineIcon rect {
    stroke: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].light};
  }

  &.extended {
    width: ${NAV_MENU_WIDTH.desktop.extended};

    &.tabletView {
      position: absolute;
      top: ${HEADER_HEIGHT}px;
      bottom: 0;
      left: 0;
      width: 300px;
      border-radius: 0 0 5px 0;
      box-shadow: 0 15px 25px ${({ theme }) => `${theme.color.general.white}14`};
    }

    .skibbleTextLogo {
      opacity: 1;
      transition: opacity 1.5s;
    }
  }

  .toggleIcon {
    position: absolute;
    top: 20px;
    right: -10px;
    transition: transform 0.2s cubic-bezier(0.25, 0.5, 0.5, 0.9);

    rect[x='2'] {
      fill: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].dark};
    }
  }

  &.condensed {
    width: ${NAV_MENU_WIDTH.desktop.condensed};

    .toggleIcon {
      transform: rotate(180deg);
    }

    .skibbleTextLogo {
      width: 0;
      margin: 0;
      opacity: 0;
      transition: opacity 0s;
    }
  }

  .menuItem a,
  .typography {
    color: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].dark};
  }

  .menuItemIcon path,
  line {
    stroke: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].dark};
  }

  .menuItemIcon.logoSymbol path[fill-rule] {
    fill: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].dark};
  }

  .label {
    color: ${({ theme }) => theme.color.general.dark};
  }

  .tag:not(.alpha) {
    width: fit-content;
    margin-top: 3px;
    background-color: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].dark};
    color: ${({ theme }) => theme.color.general.white};
  }

  .logoutLink {
    display: block;
    margin-top: 20px;
  }

  .menuItem .active {
    border: 1px solid ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].dark};
    border-radius: 3px;
    background-color: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].dark};

    .typography {
      color: ${({ theme }) => theme.color.general.white};
    }

    .menuItemIcon path,
    line {
      stroke: ${({ theme }) => theme.color.general.white};
    }

    .menuItemIcon.logoSymbol path[fill-rule] {
      fill: ${({ theme }) => theme.color.general.white};
    }
  }

  .menuItemBadge.isMini {
    background-color: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].light};
  }

  hr {
    background-color: ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].lighter};
  }

  .topWrapper {
    flex-grow: 1;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {

    &.extended {
      height: ${getScreenHeight() - HEADER_HEIGHT + 14}px;
    }
  }

  @media only screen and (max-height: ${({ theme }) => theme.breakpoints.lg}px) {

    &.extended.revertView {
      height: calc(${getScreenHeight()}px - ${HEADER_HEIGHT}px);
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    padding: 0;

    &.extended {
      position: absolute;
      top: 52px;
      z-index: 100;
      width: ${NAV_MENU_WIDTH.mobile.extended};
      padding: 14px;
      overflow-y: auto;
      border-top: 1px solid ${({ colorScheme }) => NAV_MENU_COLORS[colorScheme].lighter};
      transform: translateY(0%);
      transition: transform 0.4s ease-in-out;
    }

    &.condensed {
      position: absolute;
      bottom: ${getScreenHeight()}px;
      left: 0;
      width: ${NAV_MENU_WIDTH.mobile.condensed};
      transform: translateY(-100%);
      transition: transform 0.6s ease-in-out;
    }
  }
`;

export const StyledMenuItems = styled.div<{
	margin?: string,
	padding?: string,
	textColor?: string,
}>`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin || '20px 0'};
  padding: ${({ padding }) => padding};

  a {
    width: 100%;
    color: ${({ textColor }) => textColor};
  }

  .menuItem.link {

    &:hover:not(.disabled) {

      a {
        opacity: 1;
      }

      .extendedItem.typography {
        opacity: 0.7;
      }
    }
  }
`;

export const StyledMenuItem = styled.div<{
	active?: boolean,
	activeBGColor?: string,
	daysCount?: number,
}>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 7px;
  border: 1px solid transparent;
  border-radius: 3px;
  white-space: nowrap;

  .tag.alpha {
    margin-left: 7px;
  }

  &.userState {
    padding: 12px 0;

    .userInfo {
      display: flex;
      flex-direction: column;
      width: 135px;
      margin: 0 10px;
      flex-grow: 1;

      .caption1 {
        margin: 0;
      }
    }
  }

  .typography {
    margin-left: 8px;
  }

  &.subscriptionBlock {
    align-items: start;
    margin: 12px 0 0;
    background-color: ${({ theme }) => theme.color.status.error};

    .typography {
      color: ${({ theme }) => theme.color.general.white};
    }

    .daysIcon .day-number {
      ${({ daysCount }) => `&#day-${daysCount}`} {
        display: block;
      }
    }
  }

  .condensedItem {
    align-self: flex-start;
    margin-top: 4px;
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 0.6s ease-in-out;

    &.typography {
      margin-left: 0;
    }
  }

  .extendedItem {
    transform: translateX(0%);
    opacity: 1;
    transition: opacity 1.5s;
  }

  .icon {
    margin: 0;
  }

  &.active {
    background-color: ${({ activeBGColor }) => activeBGColor || '#2d2d2d'};
    color: white;
  }
`;

export const StyledLine = styled.hr<{
	lineColor?: string,
}>`
  height: 1px;
  margin: 0;
  border-width: 0;
  background-color: ${({ lineColor }) => lineColor};
  color: ${({ theme }) => theme.color.general.white};
`;