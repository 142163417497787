import styled from 'styled-components';
import { getCssFromTheme } from '../../../utils/css';

export const StyledExpansionPanel = styled.div<{
	marginBottomWhenOpened?: string;
}>`
  &.opened {
    margin-bottom: ${({ marginBottomWhenOpened }) => marginBottomWhenOpened};

    > div:not(.panelHeader, :last-child) {
      margin-bottom: 20px;
    }
  }

  .body1 {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'caption1')};
  }
`;

export const StyledHeaderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &.opened {
    margin-bottom: 20px;

    .empty & {
      margin-bottom: 0;
    }

    & .headerChevronIcon {
      transform: rotate(180deg);
    }
  }

  &.disabled {
    cursor: default;
  }
`;