import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const zoomSign = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 14} height={height || 14} viewBox="0 0 14 14" fill="none">
			<g clipPath="url(#clip0_12653_17057)">
				<path
					d="M6.01207 10.968C8.74888 10.968 10.9675 8.74936 10.9675 6.01256C10.9675 3.27575 8.74888 1.05713 6.01207 1.05713C3.27526 1.05713 1.05664 3.27575 1.05664 6.01256C1.05664 8.74936 3.27526 10.968 6.01207 10.968Z"
					stroke={fill || theme.color.general.black}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M12.9422 12.9429L9.51367 9.51428"
					stroke={fill || theme.color.general.black}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M6.08398 3.79993V8.37136"
					stroke={fill || theme.color.general.black}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M3.79883 6.08569H8.37026"
					stroke={fill || theme.color.general.black}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_12653_17057">
					<rect width="12.8" height="12.8" fill="white" transform="translate(0.599609 0.599976)" />
				</clipPath>
			</defs>
		</svg>
	);
};