import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { StyledFieldsCustomisation } from './styled';
import Switch from '../../../atomic-design-components/molecules/Switch/Switch';
import Icon from '../../../atomic-design-components/atoms/Icon/Icon';
import Tooltip from '../../../atomic-design-components/atoms/Tooltip/Tooltip';
import Typography from '../../../atomic-design-components/atoms/Typography/Typography';
import { IDummyData } from '../../../types';

const FieldsCustomisation = ({
	value,
	updateSelect,
	optionsFromFormValues,
}: {
	value: IDummyData;
	updateSelect: (a: string) => (b: IDummyData) => void;
	optionsFromFormValues: IDummyData;
}) => {
	const { t } = useTranslation('all');
	const theme = useTheme();
	const fieldsSettings = value || {};
	const fields = Object.keys(fieldsSettings);
	const { is_form_displayed } = optionsFromFormValues;

	const onSwitchChecked = (field: string, type: string, value: boolean) => {
		const updatedValues = {
			...fieldsSettings,
			[field]: {
				...fieldsSettings[field],
				[type]: value,
			},
		};

		updateSelect('form_fields')(updatedValues);
	};

	return (
		<StyledFieldsCustomisation>
			<div className="row header">
				<Typography variant="caption1" text={t('field')} className="col1" />
				<Tooltip text={t('visible')} className="col2" tipPosition="bottom" arrowPosition="right" right="3px">
					<Icon name="eyeSign" fill={theme.color.general.black} size={16} padding="10px" />
				</Tooltip>
				<Tooltip text={t('required')} className="col3" tipPosition="bottom" arrowPosition="right" right="3px">
					<Icon name="starRequiredSign" fill={theme.color.primary.main} size={16} padding="10px" />
				</Tooltip>
			</div>

			{fields.map((field) => (
				<div className="row field" key={field}>
					<Typography
						variant="button1"
						text={t(field)}
						className="col1"
						color={theme.color.general.dark}
					/>
					<Switch
						id={`${field}Visible`}
						className="col2"
						checked={fieldsSettings[field].is_visible}
						onChange={(value) => onSwitchChecked(field, 'is_visible', value)}
						disabled={!is_form_displayed}
					/>
					<Switch
						id={`${field}Required`}
						className="col3"
						checked={fieldsSettings[field].is_required}
						onChange={(value) => onSwitchChecked(field, 'is_required', value)}
						disabled={!is_form_displayed}
					/>
				</div>
			))}
		</StyledFieldsCustomisation>
	);
};

export default FieldsCustomisation;