import React from 'react';
import { components } from 'react-select';

import { useTheme } from 'styled-components';
import Icon from '../../../atoms/Icon/Icon';

const DropdownIndicator = ({ ...props }: {
	selectProps: {
		error: boolean;
		isDisabled: boolean;
	}
}) => {
	const {
		error,
		isDisabled,
	} = props.selectProps;
	const theme = useTheme();
	const errorColor = error ? theme.color.status.error : theme.color.general.black;

	return (
		// @ts-ignore
		<components.DropdownIndicator {...props}>
			<Icon
				name="chevronDown"
				fill={isDisabled ? theme.color.general.light : errorColor}
				className="chevronDown"
			/>
		</components.DropdownIndicator>
	);
};
export default DropdownIndicator;