import styled from 'styled-components';

export const StyledTooltip = styled.div<{
	right?: string;
	left?: string;
	width?: string;
	color?: string;
	textColor?: string;
	whiteSpace?: string;
}>`
  position: relative;

  .tooltip-arrow {
    position: absolute;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent ${({ color, theme }) => color || theme.color.general.black};

    &.left {
      left: 10%;
    }

    &.left-direction {
      left: -10px;
      border-color: transparent ${({ color, theme }) => color || theme.color.general.black} transparent transparent;
    }

    &.center {
      left: 45%;
    }

    &.right {
      right: 10%;
    }
  }
  
  .tooltip {
    position: absolute;
    right: ${({ right }) => right};
    left: ${({ left }) => left};
    z-index: 10;
    width: ${({ width }) => width};
    min-width: 100px;
    padding: 10px 12px;
    border-radius: 5px;
    background: ${({ color, theme }) => color || theme.color.general.black};
    color: ${({ textColor, theme }) => textColor || theme.color.general.white};
    text-align: center;
    white-space: ${({ whiteSpace }) => whiteSpace || 'nowrap'};
    visibility: hidden;

    &.bottom {
      top: 120%;

      .tooltip-arrow {
        bottom: 100%;
      }
    }

    &.top {
      bottom: 120%;

      .tooltip-arrow {
        top: 100%;
        border-color: ${({ color, theme }) => color || theme.color.general.black} transparent transparent;
      }
    }

    &.right {
      top: 2px;
      left: 110%;

      .tooltip-arrow {
        top: 13px;
        right: 100%;
      }
    }
  }
  
  &.disable-knowledge-base-item-tooltip .tooltip.right {
    top: -10px;
    left: 130%;
  }
  
  span.typography.tooltipText {
    color: ${({ theme }) => theme.color.general.white};
  }
`;