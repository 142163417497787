import React, { useCallback } from 'react';
import clsx from 'clsx';
import { useTheme } from 'styled-components';
import { pick } from 'dot-object';
import { Link as RouterLink, NavLink } from 'react-router-dom';

import { StyledLink } from './styled';
import Icon, { IIconProps } from '../Icon/Icon';

const Link = ({
	className,
	children,
	disabled,
	href,
	inverted,
	iconName,
	iconPosition,
	iconProps = {},
	isNavLink,
	isOuterLink,
	noStyles,
	onClick,
	rel,
	target,
	text,
	textTransform,
	themeColor = 'secondary.main',
	to,
	withBorder,
	download,
	...otherProps
}: {
	className?: string;
	children?: React.ReactNode;
	disabled?: boolean;
	href?: string;
	inverted?: boolean;
	iconName?: string;
	iconPosition?: string;
	iconProps?: IIconProps;
	isNavLink?: boolean;
	isOuterLink?: boolean;
	noStyles?: boolean;
	onClick?: () => void;
	rel?: string;
	target?: string;
	download?: boolean;
	text?: string;
	textTransform?: string;
	themeColor?: string;
	to?: string;
	withBorder?: boolean;
}) => {
	const theme = useTheme();
	const getIcon = useCallback(
		() => (
			<Icon
				name={iconName}
				className="icon"
				fill={inverted ? theme.color.general.white : pick(themeColor || '', theme.color)}
				{...iconProps}
			/>
		),
		[inverted, iconName, iconProps, theme, themeColor],
	);

	return (
		<StyledLink
			themeColor={themeColor}
			inverted={inverted}
			$textTransform={textTransform}
			className={clsx(
				className,
				disabled && 'disabled',
				noStyles && 'noStyles',
				withBorder && 'withBorder',
				'link',
			)}
			onClick={disabled ? undefined : onClick}
			{...otherProps}
		>
			{iconPosition === 'left' && getIcon()}

			{isOuterLink ? (
				<a href={href} target={target || '_blank'} rel={rel || 'noreferrer noopener'} download={download}>
					{children || text}
				</a>
			) : (
				// @ts-ignore
				<RouterLink as={isNavLink && NavLink} target={target} rel={rel} to={to || ''}>
					{children || text}
				</RouterLink>
			)}
			{iconPosition === 'right' && getIcon()}
		</StyledLink>
	);
};

export default Link;