import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const users = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
			<path
				d="M5.71596 6.28739C7.13612 6.28739 8.28739 5.13612 8.28739 3.71596C8.28739 2.2958 7.13612 1.14453 5.71596 1.14453C4.2958 1.14453 3.14453 2.2958 3.14453 3.71596C3.14453 5.13612 4.2958 6.28739 5.71596 6.28739Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.858 14.858H0.572266V13.7151C0.572266 12.3512 1.1141 11.043 2.07857 10.0786C3.04305 9.1141 4.35115 8.57227 5.71512 8.57227C7.07909 8.57227 8.3872 9.1141 9.35167 10.0786C10.3161 11.043 10.858 12.3512 10.858 13.7151V14.858Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.2871 1.14453C10.9691 1.14453 11.6231 1.41545 12.1054 1.89769C12.5876 2.37992 12.8585 3.03397 12.8585 3.71596C12.8585 4.39795 12.5876 5.052 12.1054 5.53423C11.6231 6.01647 10.9691 6.28739 10.2871 6.28739"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.1152 8.79102C13.0888 9.16136 13.9269 9.81857 14.5187 10.6757C15.1105 11.5328 15.4281 12.5494 15.4295 13.591V14.8596H13.7152"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};