import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const bulkMessaging = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
			<path
				d="M5.37185 2.28613H2.28613M2.28613 2.28613V5.37185M2.28613 2.28613L4.34328 4.34328M15.6575 15.6575L17.7147 17.7147M17.7147 17.7147L14.6289 17.7148M17.7147 17.7147L17.7146 14.6291M2.28613 14.629L2.28613 17.7147M2.28613 17.7147H5.37185M2.28613 17.7147L4.34328 15.6576M15.6575 4.34336L17.7147 2.28622M17.7147 2.28622L17.7148 5.37197M17.7147 2.28622L14.6291 2.28626M4.60029 6.40044H15.4003M4.60029 6.40044V13.6004H15.4003V6.40044M4.60029 6.40044L9.46445 11.0364C9.61434 11.1423 9.80415 11.2004 10.0003 11.2004C10.1964 11.2004 10.3863 11.1423 10.5361 11.0364L15.4003 6.40044"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};