import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';

import { useTheme } from 'styled-components';
import { LANGUAGES_FULL_NAME, TLanguages } from '../../locales';
import { useWidth } from '../../hooks/useWidth';
import Select from '../../atomic-design-components/molecules/Select/Select';
import { StyledSelect } from './styled';
import { IIconProps } from '../../atomic-design-components/atoms/Icon/Icon';

const LanguageSelect = ({
	width, iconLeftProps, isIntroScreensSelect,
}: {
	width?: string;
	iconLeftProps?: IIconProps
	isIntroScreensSelect: boolean;
}) => {
	const theme = useTheme();
	const screenWidth = useWidth();
	const isTablet = screenWidth && screenWidth < theme.breakpoints.lg;

	const {
		i18n: {
			language: currentLng,
			changeLanguage,
			// @ts-ignore
			options: { languages },
			t,
		},
	} = useTranslation();

	useEffect(() => {
		const cookies = new Cookies();
		const lngFromCookies = cookies.get('lng');
		if (!lngFromCookies) {
			cookies.set('lng', currentLng, { path: '/' });
		}
	}, []);

	const onChange = (lang: Record<string, string>) => {
		// @ts-ignore
		changeLanguage(Object.values(lang));
		const cookies = new Cookies();
		cookies.set('lng', lang.id, { path: '/' });
	};

	const getLanguagesList = () => languages.map((lang: TLanguages) => ({
		id: lang,
		label: LANGUAGES_FULL_NAME[lang],
	}));

	const value = isTablet ? {
		id: currentLng,
		label: '',
	} : {
		id: currentLng,
		label: LANGUAGES_FULL_NAME[currentLng]?.slice(0, 3),
	};

	if (isIntroScreensSelect) {
		return (
			<StyledSelect>
				<Select
					iconLeftProps={{ name: 'planet' }}
					// @ts-ignore
					onChange={onChange}
					options={getLanguagesList()}
					value={value}
					variant="primary"
					withoutValidation
					minWidth={width}
					className="languageSelect"
					iconText={isTablet ? '' : t('auth:language')}
				/>
			</StyledSelect>
		);
	}

	return (
		<Select
			iconLeftProps={iconLeftProps}
			// @ts-ignore
			onChange={onChange}
			options={getLanguagesList()}
			value={value}
			variant="primary"
			withoutValidation
			fullWidth
		/>
	);
};

export default LanguageSelect;