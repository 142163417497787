import { useEffect } from 'react';

export const useFacebookSdkInit = () => {
	useEffect(() => {
		// @ts-ignore
		window.fbAsyncInit = function b() {
			// @ts-ignore
			window.FB.init({
				appId: process.env.REACT_APP_FACEBOOK_APP_ID, // 623545556248981
				cookie: true,
				xfbml: true,
				version: 'v16.0',
			});

			// @ts-ignore
			window.FB.AppEvents.logPageView();
		};
		(function a(d, s, id) {
			const fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) {
				return;
			}
			const js = d.createElement(s);
			js.id = id;
			// @ts-ignore
			js.src = 'https://connect.facebook.net/en_US/sdk.js';
			// @ts-ignore
			fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));
	}, []);
};