import React from 'react';
import ReactTexty from '../lib/Texty';

import Icon from '../atomic-design-components/atoms/Icon/Icon';
import RatingView from '../atomic-design-components/atoms/RatingView/RatingView';
import Tooltip from '../atomic-design-components/atoms/Tooltip/Tooltip';
import Typography from '../atomic-design-components/atoms/Typography/Typography';
import NameWithAvatar from '../components/NameWithAvatar/NameWithAvatar';
import NameWithTag from '../components/NameWithTag/NameWithTag';
import SqueezeImageBlock from '../components/SqueezeImageBlock/SqueezeImageBlock';
import PlanPeriodEndDate from '../components/TableBlock/components/PlanPeriodEndDate';
import TagsCell from '../components/TableBlock/components/TagsCell';
import TextWithCopyOnClick from '../components/TableBlock/components/TextWithCopyOnClick';
import {
	BULK_MESSAGE_STATUSES_ICONS,
	CURRENCY,
	CUSTOMERS_ADDITIONAL_STATUS_ICON,
	PAYMENTS_STATUSES_ICONS,
	STATUSES_ICONS,
} from '../constants';
import ChatsTableCell from '../containers/Contacts/components/ChatsTableCell';
import ChatWidgetActions from '../containers/MyCompanyChatWidgets/components/ChatWidgetActions';
import theme from '../theme';
import { getPlanPrice } from '../utils/dataTransformations';
import { transformDate, transformDateToClientTimezone, transformUtcDateToISOWithTZ } from '../utils/dates';
import { getAvailableTranslation } from '../utils/getAvailableTranslation';
import { TLanguages } from '../locales';
import { IDummyData } from '../types';

const headerRenderer = (t: (a: string) => string) => ({ column }: {
	column: { key: string; title: string };
}) => (
	<Typography as={ReactTexty} variant="body2">
		{t(column.key) || t(column.title)}
	</Typography>
);

const getStatusColumnProps = (t: (a: string) => string) => ({
	key: 'status',
	dataKey: 'status',
	width: 80,
	flexShrink: 0,
	resizable: false,
	sortable: true,
	className: 'statusIcons',
	headerRenderer: headerRenderer(t),
	cellRenderer({ cellData, column }: {
		cellData: string;
		column: { key: string };
	}) {
		const allStatusesIcons: Record<string, string> = {
			...STATUSES_ICONS,
			...CUSTOMERS_ADDITIONAL_STATUS_ICON,
			...PAYMENTS_STATUSES_ICONS,
		};
		return allStatusesIcons[cellData] ? (
			<Tooltip left="8px" text={t(`${cellData}`)} arrowPosition="left">
				<Icon
					key={column.key}
					className="statusIcon"
					onClick={() => {}}
					name={allStatusesIcons[cellData]}
					width={16}
					margin="0 auto 0 5px"
					padding="10px"
				/>
			</Tooltip>
		) : (
			''
		);
	},
});

const getBulkStatusColumnProps = (t: (a: string) => string) => ({
	key: 'status',
	dataKey: 'status',
	width: 80,
	flexShrink: 0,
	resizable: false,
	sortable: true,
	className: 'statusIcons',
	headerRenderer: headerRenderer(t),
	cellRenderer: ({ cellData, column }: {
		cellData: string;
		column: { key: string };
	}) => (BULK_MESSAGE_STATUSES_ICONS[cellData] ? (
		<Tooltip left="8px" text={t(cellData)} arrowPosition="left">
			<Icon
				key={column.key}
				className="statusIcon"
				name={BULK_MESSAGE_STATUSES_ICONS[cellData]}
				width={cellData === 'scheduled' ? 19 : 16}
				height={20}
				margin="0 auto 0 5px"
				padding="10px"
			/>
		</Tooltip>
	) : (
		''
	)),
});

const getFullName = (t: (a: string) => string, withoutAvatar?: boolean, isContact?: boolean) => ({
	key: 'fullName',
	dataKey: 'full_name',
	width: 0,
	flexGrow: 1,
	sortable: true,
	cellRenderer({ cellData, rowData }: {
		cellData: string;
		rowData: { profile_picture: string; timezone: string; id: string };
	}) {
		return (
			<NameWithAvatar
				withoutAvatar={withoutAvatar}
				name={cellData || (isContact && `${t('guest')} #${rowData.id}`) || '-'}
				avatarSrc={rowData.profile_picture}
			/>
		);
	},
	headerRenderer: headerRenderer(t),
});

const getEmail = (t: (a: string) => string) => ({
	key: 'email',
	dataKey: 'email',
	width: 0,
	flexGrow: 1.2,
	sortable: true,
	cellRenderer: (props: {
		cellData: string;
		textVariant?: string;
	}) => <TextWithCopyOnClick {...props} />,
	headerRenderer: headerRenderer(t),
});

const getTableColumns = (type: string, t: (a: string) => string, lng?: TLanguages) => {
	const COLUMNS_CONFIG: Record<string, {
		key: string;
		dataKey: string;
		width: number;
		flexGrow?: number;
		flexShrink?: number;
		resizable?: boolean;
		sortable?: boolean;
		className?: string;
		getIsHidden?: (rowData: IDummyData, optionsData: IDummyData) => boolean;
		cellRenderer?: (props: {
			cellData: IDummyData;
			rowData: IDummyData;
			column: IDummyData;
			textVariant?: string;
			isActionButtonsExtended?: boolean;
		}) => React.ReactNode;
		headerRenderer?: (props: IDummyData) => React.ReactNode;
	}[]> = {
		contacts: [
			{
				key: 'channel',
				dataKey: 'channel',
				width: 85,
				sortable: true,
				cellRenderer({ cellData }) {
					const channel = cellData.replace('://', '').replace(/\d/g, '');
					return <Icon width={30} height={30} margin="0 0 0 10px" name={`${channel}Icon`} />;
				},
				headerRenderer: headerRenderer(t),
			},
			getFullName(t, true, true),
			getEmail(t),
			{
				key: 'tags',
				dataKey: 'tags',
				width: 0,
				flexGrow: 1.5,
				cellRenderer: (props) => <TagsCell {...props} />,
				headerRenderer: headerRenderer(t),
			},
			{
				key: 'widget',
				dataKey: 'widget_name',
				width: 0,
				flexGrow: 1,
				sortable: true,
				headerRenderer: headerRenderer(t),
			},
			{
				key: 'lastMessage',
				dataKey: 'last_message_at',
				width: 130,
				sortable: true,
				headerRenderer: headerRenderer(t),
				cellRenderer: ({ cellData, rowData }) => transformDateToClientTimezone(cellData, rowData.timezone, 'DD/MM/YYYY', true), // TODO: check timezone
			},
			{
				key: 'chats',
				dataKey: 'total_messages',
				sortable: true,
				width: 80,
				headerRenderer: headerRenderer(t),
				cellRenderer: ({ rowData, cellData }) => (
					<ChatsTableCell chatId={rowData.chat_id} messagesCount={cellData} />
				),
			},
		],
		bulkMessaging: [
			getBulkStatusColumnProps(t),
			{
				key: 'name',
				dataKey: 'name',
				width: 0,
				flexGrow: 1,
				sortable: true,
				headerRenderer: headerRenderer(t),
			},
			{
				key: 'date',
				dataKey: 'scheduled_at',
				width: 0,
				flexGrow: 1.5,
				sortable: true,
				cellRenderer: (props) => transformUtcDateToISOWithTZ(props.cellData, 'DD/MM/YYYY HH:mm'),
				headerRenderer: headerRenderer(t),
			},
			{
				key: 'messengers',
				dataKey: 'channels',
				width: 0,
				flexGrow: 1,
				cellRenderer({ cellData, rowData, column }) {
					if (!cellData) {
						return null;
					}
					return <SqueezeImageBlock cellData={cellData} rowData={rowData} type={column.key} />;
				},
				headerRenderer: headerRenderer(t),
			},
			{
				key: 'tags',
				dataKey: 'tags',
				width: 0,
				flexGrow: 1.5,
				cellRenderer: (props) => <TagsCell {...props} />,
				headerRenderer: headerRenderer(t),
			},
		],
		members: [
			getStatusColumnProps(t),
			getFullName(t),
			getEmail(t),
			{
				key: 'rating',
				dataKey: 'rating',
				width: 80,
				flexShrink: 0,
				sortable: true,
				cellRenderer: (props) => <RatingView fill={theme.color.primary.lightest} {...props} />,
				headerRenderer: headerRenderer(t),
			},
			{
				key: 'role',
				dataKey: 'role',
				width: 0,
				flexGrow: 1,
				sortable: true,
				cellRenderer: (props) => t(props.rowData.role),
				headerRenderer: headerRenderer(t),
			},
			{
				key: 'departments',
				dataKey: 'departments',
				width: 0,
				flexGrow: 1,
				cellRenderer: (props) => <TagsCell {...props} />,
				headerRenderer: headerRenderer(t),
			},
		],
		customers: [
			getStatusColumnProps(t),
			{
				key: 'companyName',
				dataKey: 'alias',
				width: 0,
				flexGrow: 1,
				sortable: true,
				cellRenderer: ({ cellData, rowData }) => (
					<NameWithAvatar
						withoutAvatar={!rowData.company_logo}
						name={cellData}
						avatarSrc={rowData.company_logo}
					/>
				),
				headerRenderer: headerRenderer(t),
			},
			{
				key: 'created',
				dataKey: 'created_at',
				width: 0,
				flexGrow: 1,
				sortable: true,
				headerRenderer: headerRenderer(t),
				cellRenderer: (props) => transformDate(props.cellData),
			},
			{
				key: 'tags',
				dataKey: 'customer_tags',
				width: 0,
				flexGrow: 1.5,
				cellRenderer: (props) => <TagsCell {...props} />,
				headerRenderer: headerRenderer(t),
			},
			{
				key: 'plan',
				dataKey: 'plan_titles',
				width: 0,
				flexGrow: 1,
				// sortable: true,
				headerRenderer: headerRenderer(t),
				cellRenderer: (props) => getAvailableTranslation(props.cellData, lng),
			},
			{
				key: 'planEnds',
				dataKey: 'subscription_expire_at',
				width: 0,
				flexGrow: 1,
				sortable: true,
				cellRenderer: (props) => <PlanPeriodEndDate {...props} />,
				headerRenderer: headerRenderer(t),
			},
			{
				key: 'pricing',
				dataKey: 'plan_prices',
				width: 0,
				flexGrow: 1,
				// sortable: true,
				headerRenderer: headerRenderer(t),
				cellRenderer: ({ cellData, rowData }) => (
					getPlanPrice(cellData, rowData.payment_type, 'UAH')
            ?? (rowData.payment_type === 'manual' && '-')
				),
			},
			{
				key: 'details',
				dataKey: 'limits',
				width: 0,
				flexGrow: 1,
				headerRenderer: headerRenderer(t),
				cellRenderer: ({ cellData }) => (cellData ? (
					<Typography as={ReactTexty} variant="body3" fontSize="13px">
						{`${t('widgets')} ${cellData.widgets}, ${t('channels')} ${cellData.channels}, ${t('users')} ${
							cellData.users
						}, ${t('templates')} ${cellData.templates}`}
					</Typography>
				) : null),
				// check transformCustomersGrid
			},
		],
		departments: [
			getStatusColumnProps(t),
			{
				key: 'name',
				dataKey: 'name',
				width: 0,
				flexGrow: 1,
				sortable: true,
				headerRenderer: headerRenderer(t),
			},
			{
				key: 'members',
				dataKey: 'members',
				width: 0,
				flexGrow: 2,
				cellRenderer({ cellData, column }) {
					if (!cellData.length) {
						return null;
					}

					return <SqueezeImageBlock cellData={cellData} type={column.key} />;
				},
				headerRenderer: headerRenderer(t),
			},
		],
		widgets: [
			getStatusColumnProps(t),
			{
				key: 'name',
				dataKey: 'name',
				width: 0,
				flexGrow: 1,
				sortable: true,
				cellRenderer: ({ rowData, cellData }) => (
					<NameWithTag
						rowData={rowData}
						// @ts-ignore
						data={cellData}
						t={t}
					/>
				),
				headerRenderer: headerRenderer(t),
			},
			{
				key: 'channels',
				dataKey: 'channels',
				width: 0,
				flexGrow: 1,
				cellRenderer({ cellData, rowData, column }) {
					if (!cellData) {
						return null;
					}
					return <SqueezeImageBlock cellData={cellData} rowData={rowData} type={column.key} />;
				},
				headerRenderer: headerRenderer(t),
			},
			{
				key: 'action',
				dataKey: 'action',
				width: 0,
				flexGrow: 1,
				className: 'actionButtons',
				headerRenderer: headerRenderer(t),
				cellRenderer: (props) => <ChatWidgetActions t={t} {...props} />,
			},
		],
		payments: [
			getStatusColumnProps(t),
			{
				key: 'companyName',
				dataKey: 'customer_name',
				width: 0,
				flexGrow: 1,
				sortable: true,
				getIsHidden: ({ role }) => role !== 'system_administrator',
				cellRenderer: ({ cellData, rowData }) => (
					<NameWithAvatar
						withoutAvatar={!rowData.company_logo}
						name={cellData}
						avatarSrc={rowData.company_logo}
					/>
				),
				headerRenderer: headerRenderer(t),
			},
			{
				key: 'created',
				dataKey: 'created_at',
				width: 0,
				flexGrow: 1,
				sortable: true,
				headerRenderer: headerRenderer(t),
				cellRenderer: (props) => transformDate(props.cellData),
			},
			{
				key: 'plan',
				dataKey: 'titles',
				width: 0,
				flexGrow: 1,
				headerRenderer: headerRenderer(t),
				cellRenderer: (props) => getAvailableTranslation(props.cellData, lng),
			},
			{
				key: 'paymentType',
				dataKey: 'type',
				width: 0,
				flexGrow: 1,
				sortable: true,
				headerRenderer: headerRenderer(t),
				cellRenderer: (props) => t(props.cellData),
			},
			{
				key: 'paymentMethod',
				dataKey: 'details',
				width: 0,
				flexGrow: 2,
				cellRenderer(props) {
					const { cardPan, cardType } = props.cellData || {};
					const panText = cardPan || '';
					const typeText = cardType ? `(${cardType})` : '';
					return `${panText}${typeText}`;
				},
				headerRenderer: headerRenderer(t),
			},
			{
				key: 'pricing',
				dataKey: 'amount',
				width: 0,
				flexGrow: 1,
				// sortable: true,
				cellRenderer: (props) => `${props.rowData.props || CURRENCY.UAH} ${props.cellData}`,
				headerRenderer: headerRenderer(t),
			},
		],
		subscriptionPlans: [
			getStatusColumnProps(t),
			{
				key: 'planTitle',
				dataKey: 'titles',
				width: 0,
				flexGrow: 1,
				headerRenderer: headerRenderer(t),
				cellRenderer: (props) => getAvailableTranslation(props.cellData, lng),
			},
			{
				key: 'details',
				dataKey: 'features',
				width: 0,
				flexGrow: 1,
				headerRenderer: headerRenderer(t),
				cellRenderer: ({ cellData }) => (
					<Typography as={ReactTexty} variant="body3" fontSize="13px">
						{`${t('widgets')} ${cellData.widgets}, ${t('channelsC')} ${cellData.channels}, ${t('users')} ${
							cellData.users
						}, ${t('templatesC')} ${cellData.templates}`}
					</Typography>
				),
			},
			{
				key: 'customers',
				dataKey: 'customers',
				width: 0,
				flexGrow: 1,
				headerRenderer: headerRenderer(t),
				cellRenderer: ({ cellData }) => (
					<Typography as={ReactTexty} variant="body3" fontSize="13px">
						{cellData.map((customer: {
							alias: string;
						}) => customer.alias).join(', ') || '-'}
					</Typography>
				),
			},
			{
				key: 'pricing',
				dataKey: 'prices',
				width: 0,
				flexGrow: 1,
				headerRenderer: headerRenderer(t),
				cellRenderer: (props) => `${CURRENCY.UAH} ${props.cellData.price_monthly.UAH || 0} / ${
					props.cellData.price_annually.UAH || 0
				}`,
			},
			{
				key: 'created',
				dataKey: 'created_at',
				width: 0,
				flexGrow: 1,
				sortable: true,
				headerRenderer: headerRenderer(t),
				cellRenderer: (props) => transformDate(props.cellData),
			},
		],
	};

	return COLUMNS_CONFIG[type];
};

export default getTableColumns;