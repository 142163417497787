import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const book = ({
	width, height, stroke,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 24} height={height || 24} viewBox="0 0 24 24" fill="none">
			<path
				d="M5.21387 7.46484H3.46387C3.18772 7.46484 2.96387 7.6887 2.96387 7.96484V19.4148C2.96387 19.691 3.18772 19.9148 3.46387 19.9148H8.12289C9.14158 19.9148 10.1186 20.3195 10.8389 21.0398H13.1639C13.8842 20.3195 14.8612 19.9148 15.8798 19.9148H20.5389C20.815 19.9148 21.0389 19.691 21.0389 19.4148V9.08984C21.0389 8.8137 20.815 8.58984 20.5389 8.58984H18.7889M12.0014 7.46484H12.3539C13.3776 6.73355 14.5927 6.33984 15.8751 6.33984H18.2889C18.565 6.33984 18.7889 6.5637 18.7889 6.83984V17.1648C18.7889 17.441 18.565 17.6648 18.2889 17.6648H15.8751C14.5927 17.6648 13.3776 18.0586 12.3539 18.7898H11.6488C10.6251 18.0586 9.41008 17.6648 8.12765 17.6648H5.71387C5.43772 17.6648 5.21387 17.441 5.21387 17.1648V5.71484C5.21387 5.4387 5.43772 5.21484 5.71387 5.21484H7.46387M12.0014 7.46484L12.0014 18.7898C12.0014 16.4731 10.2181 14.5653 7.96307 14.3172C7.68858 14.287 7.46388 14.066 7.46388 13.7898V3.46484C7.46388 3.1887 7.68858 2.96196 7.96307 2.99216C10.2181 3.24029 12.0014 5.14808 12.0014 7.46484Z"
				stroke={stroke || theme.color.general.black}
				strokeWidth="1.14"
				strokeMiterlimit="10"
			/>
		</svg>
	);
};