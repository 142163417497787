import React from 'react';
import { IPropsSvgIcon } from '../../types';

export const typeHEIC = ({
	width, height,
}: IPropsSvgIcon) => (
	<svg width={width || 30} height={height || 36} fill="none" viewBox="0 0 20 26">
		<path d="M.334 1.8333C.334 1.097.931.5 1.6673.5H13.334l8.6667 8.6667v16c0 .7363-.597 1.3333-1.3334 1.3333h-19C.931 26.5.334 25.903.334 25.1667V1.8333Z" fill="#5F4B8B" />
		<path d="M22.0007 9.1667h-6.5c-1.1967 0-2.1667-.97-2.1667-2.1667V.5l8.6667 8.6667Z" fill="#27164B" />
		<path fillRule="evenodd" clipRule="evenodd" d="M15.5023 18.9072a2.0992 2.0992 0 0 1 .1145-.7432c.0661-.187.1822-.3495.3346-.4675a.8548.8548 0 0 1 .528-.162.851.851 0 0 1 .4424.1139.7999.7999 0 0 1 .3035.3064c.0772.1356.1229.2885.1334.4463h.8503v-.0857c-.0135-.4377-.2017-.8489-.518-1.1321-.1548-.1401-.336-.2466-.5436-.3193-.2067-.076-.4316-.114-.6747-.114-.395 0-.7325.0873-1.0126.2618-.278.1739-.4895.4217-.6347.7434-.146.321-.2191.7038-.2191 1.1485v.5848c0 .4447.0719.8267.2157 1.1461.1344.3089.3562.5661.6347.7363.2771.1691.6158.2537 1.016.2537a1.9113 1.9113 0 0 0 .8737-.1926c.2557-.1284.4595-.3061.6114-.5331a1.5477 1.5477 0 0 0 .2512-.7915v-.0892h-.8503a.9747.9747 0 0 1-.1302.4273.795.795 0 0 1-.3034.2914.9578.9578 0 0 1-.4457.1033.9069.9069 0 0 1-.5302-.1539c-.1526-.1147-.2685-.2756-.3324-.4615-.0763-.202-.1145-.4474-.1145-.7363v-.5813ZM8.836 21.5365v-4.6972l-.8781-.0012v1.9095H6.0882v-1.9095H5.209v4.696h.8781v-2.0127h1.8708v2.0151h.8781Zm3.6438-.7609h-1.9896v-1.2589h1.8708v-.7128h-1.872v-1.2037h1.9897v-.7609H9.6175v4.696h2.8623v-.7597Zm1.5262-3.9363v4.696l-.001.0012h-.8782v-4.6972h.8792Z" fill="#fff" />
	</svg>
);