import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const briefcase = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
			<path
				d="M17.5431 5.37305H2.45737C1.69995 5.37305 1.08594 5.98706 1.08594 6.74448V17.5445C1.08594 18.3019 1.69995 18.9159 2.45737 18.9159H17.5431C18.3005 18.9159 18.9145 18.3019 18.9145 17.5445V6.74448C18.9145 5.98706 18.3005 5.37305 17.5431 5.37305Z"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.08594 12.0586H18.9145"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 10.6855V13.4284"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.1143 5.37211C14.1143 4.28093 13.6808 3.23444 12.9093 2.46286C12.1377 1.69128 11.0912 1.25781 10 1.25781V1.25781C8.90885 1.25781 7.86237 1.69128 7.09079 2.46286C6.31921 3.23444 5.88574 4.28093 5.88574 5.37211"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};