import React from 'react';

import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import Tooltip from '../../../atomic-design-components/atoms/Tooltip/Tooltip';
import Switch from '../../../atomic-design-components/molecules/Switch/Switch';
import { selectCurrentSubscription } from '../../../redux-saga/selectors';
import { IDummyData } from '../../../types';

const IsHideBrandingSwitch = (props: {
	value: IDummyData;
	updateCheckbox: (a: boolean, e: IDummyData) => void;
	t: (a: string) => string;
}) => {
	const {
		value,
		updateCheckbox,
		t,
	} = props;
	const planData = useSelector(selectCurrentSubscription);
	const { limits } = planData || {};
	const theme = useTheme();

	const isHideBrandingAllowedByBilling = limits?.is_branding; // subscription_status === 'active' &&

	const onSwitchChecked = (checked: boolean, e: {
		target: {
			name: string;
		};
	}) => {
		updateCheckbox(checked, e);
	};

	return (
		<Tooltip
			right="-15px"
			text={t('upgradeYourPlanToUse')}
			arrowPosition="right"
			whiteSpace="pre-wrap"
			disableTooltip={isHideBrandingAllowedByBilling}
		>
			<Switch
				{...props}
				labelColor={theme.color.general.dark}
				onChange={isHideBrandingAllowedByBilling ? onSwitchChecked : undefined}
				checked={!!value}
				disabled={!isHideBrandingAllowedByBilling}
			/>
		</Tooltip>
	);
};

export default IsHideBrandingSwitch;