import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const statusInProgress = ({
	width, height, fill, stroke,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 17} viewBox="0 0 16 17" fill="none">
			<path
				d="M15.4287 7.96863C15.4177 9.23852 15.129 10.4906 14.583 11.6372"
				stroke={stroke || theme.color.secondary.darker}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.0001 15.3972C7.02457 15.3972 6.05859 15.2051 5.15731 14.8317C4.25604 14.4584 3.43712 13.9112 2.74731 13.2214C1.35418 11.8283 0.571533 9.93881 0.571533 7.96863"
				stroke={stroke || theme.color.secondary.darker}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10.2858 10.2544L8.0001 7.39722H4.57153"
				stroke={stroke || theme.color.secondary.darker}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.7143 14.8258C14.6611 14.8258 15.4286 14.0583 15.4286 13.1115C15.4286 12.1647 14.6611 11.3972 13.7143 11.3972C12.7675 11.3972 12 12.1647 12 13.1115C12 14.0583 12.7675 14.8258 13.7143 14.8258Z"
				fill={fill || theme.color.secondary.lighter}
				stroke={stroke || theme.color.secondary.darker}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.05151 5.22579C1.30058 4.60334 1.63523 4.01866 2.0458 3.48865"
				stroke={stroke || theme.color.secondary.darker}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.25146 1.5229C4.89553 1.151 5.59333 0.881129 6.32004 0.7229"
				stroke={stroke || theme.color.secondary.darker}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.9486 5.22579C14.6996 4.60334 14.3649 4.01866 13.9543 3.48865"
				stroke={stroke || theme.color.secondary.darker}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.7487 1.5229C11.1047 1.151 10.4069 0.881129 9.68018 0.7229"
				stroke={stroke || theme.color.secondary.darker}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};