import React, { Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { StyledPasswordChecklist } from './styled';

const CustomPasswordChecklist = ({ password, setIsPasswordValid, className }: {
	password: string;
	setIsPasswordValid: Dispatch<SetStateAction<boolean | undefined>>;
	className?: string;
}) => {
	const { t } = useTranslation('validation');

	return (
		<StyledPasswordChecklist
			rules={['minLength', 'lowercase', 'capital', 'specialChar', 'number']}
			minLength={8}
			value={password}
			messages={{
				minLength: t('minLength'),
				lowercase: t('lowercase'),
				capital: t('capital'),
				specialChar: t('specialChar'),
				number: t('number'),
			}}
			onChange={(isValid) => {
				setIsPasswordValid(isValid);
			}}
			className={clsx(className, 'passwordCheckList')}
		/>
	);
};

export default CustomPasswordChecklist;