import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactHelmet from '../../wrappers/ReactHelmet';

import IntroScreenWrapper from '../../components/IntroScreens/IntroScreenWrapper';
import Form from '../../components/Form/Form';
import { FORM_CONFIGS } from '../../config/forms';
import Link from '../../atomic-design-components/atoms/Link/Link';
import Button from '../../atomic-design-components/molecules/Button/Button';
import Typography from '../../atomic-design-components/atoms/Typography/Typography';

import backOrange from '../../assets/images/backOrange.jpg';
import { userRequestResetPassword } from '../../redux-saga/actions';
import { selectPopupAlerts } from '../../redux-saga/selectors';

const initialValues = { email: '', password: '' };

const PasswordRecovery = () => {
	const theme = useTheme();
	const { t } = useTranslation('auth');
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const alert = useSelector(selectPopupAlerts);
	const [inProgress, setInProgress] = useState<boolean>(false);

	useEffect(() => {
		if (alert.length) {
			setInProgress(false);
		}
	}, [alert]);

	const onFormSubmit = (data: {
		email: string;
	}) => {
		setInProgress(true);
		dispatch(userRequestResetPassword(data.email, navigate));
	};

	return (
		<>
			<ReactHelmet title="signTitle" />
			<IntroScreenWrapper type="passwordRecovery" imageUrl={backOrange}>
				<Typography variant="body1" text={t('passRecoverText')} margin="10px 0 30px" />
				<Form
					type="passwordRecovery"
					initialValues={initialValues}
					// @ts-ignore
					onSubmit={onFormSubmit}
					validationRules={{
						email: ['required', 'email'],
					}}
					fields={[FORM_CONFIGS.passwordRecovery.fields[0]]}
				>
					<Button fullWidth variant="primary" type="submit" iconName="passwordReset" isLoading={inProgress}>
						{t('recoverPassword')}
					</Button>
				</Form>
				<div>
					<Typography variant="body2" color={theme.color.general.black} margin="20px 0 0 0">
						{t('backTo')}
					</Typography>
					{' '}
					<Typography variant="body2" fontWeight={500} color={theme.color.primary.main}>
						<Link to="/sign-in" className="link" themeColor="primary.main">
							{t('toSignIn')}
						</Link>
					</Typography>
				</div>
			</IntroScreenWrapper>
		</>
	);
};

export default PasswordRecovery;