export const formatSizeUnits = (bytes: number) => {
	let result;
	if (bytes >= 1073741824) {
		result = `${(bytes / 1073741824).toFixed(2)} GB`;
	} else if (bytes >= 1048576) {
		result = `${(bytes / 1048576).toFixed(2)} MB`;
	} else if (bytes >= 1024) {
		result = `${(bytes / 1024).toFixed(2)} KB`;
	} else if (bytes > 1) {
		result = `${bytes} bytes`;
	} else if (bytes === 1) {
		result = `${bytes} byte`;
	} else {
		result = '0 bytes';
	}
	return result;
};

export const formatTime = ({ seconds, t }: { seconds: number, t: (a: string) => string }) => {
	const hrs = Math.floor(seconds / 3600);
	const mins = Math.floor((seconds % 3600) / 60);
	const secs = Math.floor(seconds % 60);

	let ret = '';

	if (seconds === 0) {
		return `0${t('sec')}`;
	}

	if (hrs > 0) {
		ret += `${hrs}${t('h')}`;
	}

	ret += mins ? ` ${mins}${t('min')}` : '';
	ret += ` ${secs}${t('sec')}`;

	return ret;
};