import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const calendar = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 20} height={height || 17} viewBox="0 0 20 17" fill="none">
			<path
				d="M9.71387 7.42871H10.8567V12.5716"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.71387 12.5713H11.9996"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.71415 2.85938C1.41104 2.85938 1.12035 2.98935 0.906024 3.2207C0.691697 3.45205 0.571289 3.76583 0.571289 4.09301V15.1957C0.571289 15.5229 0.691697 15.8367 0.906024 16.0681C1.12035 16.2994 1.41104 16.4294 1.71415 16.4294H14.2856C14.5887 16.4294 14.8794 16.2994 15.0937 16.0681C15.308 15.8367 15.4284 15.5229 15.4284 15.1957V4.09301C15.4284 3.76583 15.308 3.45205 15.0937 3.2207C14.8794 2.98935 14.5887 2.85938 14.2856 2.85938H11.9999"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4 0.571289V5.14272"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 0.571289V5.14272"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4 2.85742H9.71429"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4 7.42871H6.85714L5.14286 9.71443C5.14286 9.71443 6.85714 9.71443 6.85714 10.8573C6.88939 11.09 6.86728 11.3269 6.79253 11.5496C6.71778 11.7723 6.59244 11.9747 6.42634 12.1408C6.26024 12.3069 6.0579 12.4322 5.83521 12.507C5.61252 12.5817 5.37553 12.6038 5.14286 12.5716H4"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};