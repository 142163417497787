import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import ReactTexty from '../../../lib/Texty';

import Typography from '../../../atomic-design-components/atoms/Typography/Typography';
import Button from '../../../atomic-design-components/molecules/Button/Button';
import TextPanel from '../../../atomic-design-components/molecules/TextPanel/TextPanel';
import { StyledPanel } from './styled';
import { popupAlertShow } from '../../../redux-saga/actions';

const SetupPanel = ({ data }: {
	data: string;

}) => {
	const { t } = useTranslation('all');
	const theme = useTheme();
	const dispatch = useDispatch();
	const codeText = `<meta name="viewport" content="width=device-width, initial-scale=1" /><script async src="${window.location.origin}/loader.js?license=${data}"></script>`;

	const handleClick = (event: {
		stopPropagation: () => void;
	}) => {
		event.stopPropagation();
		navigator.clipboard.writeText(codeText);
		dispatch(
			popupAlertShow({
				contentKey: 'codeCopied',
				type: 'success',
				withCloseButton: true,
				iconName: 'copy',
			}),
		);
	};

	return (
		<StyledPanel>
			<Typography variant="body2" color={theme.color.general.dark} text={t('setupCodeText')} />
			<TextPanel
				content={(
					<ReactTexty>
						<code>{codeText}</code>
					</ReactTexty>
				)}
				className="codePanel"
			/>
			<Button
				variant="primary"
				text={t('copyCode')}
				fullWidth
				iconLeftProps={{ name: 'copy' }}
				onClick={handleClick}
			/>
		</StyledPanel>
	);
};

export default SetupPanel;