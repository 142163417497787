import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const emptySubcriptionPlans = ({
	width, height,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 200} height={height || 200} fill="none" viewBox="0 0 200 200">
			<g clipPath="url(#clip0_15335_327869)">
				<circle cx="100.004" cy="100" r="100" fill={theme.color.general.lightest} />
				<path
					d="M150 78.4627V128.412C150 130.241 148.517 131.724 146.688 131.724H108.94C107.111 131.724 105.628 130.241 105.628 128.412V78.4627L136.528 75.8213L150 78.4627Z"
					fill="url(#paint0_linear_15335_327869)"
				/>
				<path
					d="M142.141 91.3812H128.107C127.273 91.3812 126.598 90.7057 126.598 89.8723C126.598 89.0389 127.273 88.3633 128.107 88.3633H142.141C142.974 88.3633 143.65 89.0389 143.65 89.8723C143.65 90.7057 142.974 91.3812 142.141 91.3812Z"
					fill={theme.color.general.light}
				/>
				<path
					d="M142.141 105.102H128.107C127.273 105.102 126.598 104.426 126.598 103.593C126.598 102.76 127.273 102.084 128.107 102.084H142.141C142.974 102.084 143.65 102.76 143.65 103.593C143.65 104.426 142.974 105.102 142.141 105.102Z"
					fill={theme.color.general.light}
				/>
				<path
					d="M142.141 119.145H128.107C127.273 119.145 126.598 118.47 126.598 117.636C126.598 116.803 127.273 116.127 128.107 116.127H142.141C142.974 116.127 143.65 116.803 143.65 117.636C143.65 118.47 142.974 119.145 142.141 119.145Z"
					fill={theme.color.general.light}
				/>
				<path
					d="M94.3723 78.4627V128.412C94.3723 130.241 92.8895 131.724 91.0604 131.724H53.3119C51.4828 131.724 50 130.241 50 128.412V78.4627L61.0805 75.8213L94.3723 78.4627Z"
					fill={theme.color.general.white}
				/>
				<path
					d="M94.3723 78.4627V128.412C94.3723 130.241 92.8895 131.724 91.0604 131.724H53.3119C51.4828 131.724 50 130.241 50 128.412V78.4627L61.0805 75.8213L94.3723 78.4627Z"
					fill="url(#paint1_linear_15335_327869)"
				/>
				<path
					d="M94.3723 71.5883V78.4635H50V71.5883C50 69.7594 51.4828 68.2764 53.3119 68.2764H91.0604C92.8893 68.2764 94.3723 69.7594 94.3723 71.5883Z"
					fill={theme.color.general.lighter}
				/>
				<path
					d="M150 71.5883V78.4635H105.628V71.5883C105.628 69.7594 107.111 68.2764 108.94 68.2764H146.688C148.517 68.2764 150 69.7594 150 71.5883Z"
					fill={theme.color.general.lighter}
				/>
				<path
					d="M63.2025 86.6958C62.5682 86.1558 61.6154 86.2321 61.0754 86.8669L59.2285 89.037L58.276 89.4161L57.7238 88.9177C57.1049 88.3593 56.1508 88.4081 55.5926 89.0269C55.0342 89.6456 55.083 90.5999 55.7018 91.1581L57.7637 93.0189C58.0418 93.2698 58.4024 93.4075 58.7746 93.4075C58.8076 93.4075 58.8408 93.4064 58.8738 93.4042C59.2807 93.3775 59.6596 93.187 59.9238 92.8765L63.3738 88.8228C63.9137 88.1884 63.8371 87.2359 63.2025 86.6958Z"
					fill={theme.color.general.light}
				/>
				<path
					d="M62.478 100.176C61.8888 99.5868 60.9333 99.5868 60.3438 100.176L59.0616 101.458L57.7794 100.176C57.1901 99.5866 56.2347 99.5866 55.6452 100.176C55.0558 100.765 55.0558 101.721 55.6452 102.31L56.9274 103.593L55.6452 104.875C55.0558 105.464 55.0558 106.419 55.6452 107.009C55.9399 107.304 56.3261 107.451 56.7122 107.451C57.0983 107.451 57.4847 107.303 57.7792 107.009L59.0614 105.727L60.3437 107.009C60.6384 107.304 61.0245 107.451 61.4106 107.451C61.7968 107.451 62.1831 107.303 62.4776 107.009C63.0671 106.42 63.0671 105.464 62.4776 104.875L61.1954 103.593L62.4776 102.31C63.0675 101.721 63.0675 100.765 62.478 100.176Z"
					fill={theme.color.general.light}
				/>
				<path
					d="M62.478 114.219C61.8888 113.63 60.9333 113.63 60.3438 114.219L59.0616 115.501L57.7794 114.219C57.1901 113.63 56.2347 113.63 55.6452 114.219C55.0558 114.809 55.0558 115.764 55.6452 116.353L56.9274 117.636L55.6452 118.918C55.0558 119.507 55.0558 120.463 55.6452 121.052C55.9399 121.347 56.3261 121.494 56.7122 121.494C57.0983 121.494 57.4847 121.347 57.7792 121.052L59.0614 119.77L60.3437 121.052C60.6384 121.347 61.0245 121.494 61.4106 121.494C61.7968 121.494 62.1831 121.347 62.4776 121.052C63.0671 120.463 63.0671 119.507 62.4776 118.918L61.1954 117.636L62.4776 116.353C63.0675 115.764 63.0675 114.809 62.478 114.219Z"
					fill={theme.color.general.light}
				/>
				<path
					d="M130.133 60.6342L131.064 68.276L101.883 71.7313L68.9355 68.276V60.2197C68.9355 57.6604 71.0119 55.584 73.5734 55.584H121.029L125.495 55.9984C128.056 55.9984 130.133 58.0748 130.133 60.6342Z"
					fill={theme.color.general.light}
				/>
				<path
					d="M131.064 60.2197L131.064 68.276L127.392 70.3299L123.897 68.276V60.2197C123.897 57.6604 121.821 55.584 119.26 55.584H126.427C128.988 55.584 131.064 57.6604 131.064 60.2197Z"
					fill={theme.color.general.light}
				/>
				<path
					d="M73.5734 144.417H119.303C119.289 144.417 119.274 144.417 119.26 144.417H126.427C128.988 144.417 131.065 142.341 131.065 139.781V68.2764H68.9355V139.781C68.9355 142.341 71.0121 144.417 73.5734 144.417Z"
					fill={theme.color.general.white}
				/>
				<path
					d="M80.574 87.8741C80.2018 87.8741 79.8412 87.7364 79.5631 87.4854L76.6018 84.8129C75.983 84.2545 75.934 83.3004 76.4926 82.6817C77.0508 82.0629 78.0049 82.0139 78.6238 82.5725L80.4318 84.2042L84.3799 79.5651C84.9203 78.9305 85.8727 78.854 86.507 79.394C87.1416 79.9342 87.2184 80.8866 86.6781 81.5211L81.7232 87.3432C81.459 87.6538 81.0803 87.8442 80.6732 87.871C80.6402 87.8729 80.607 87.8741 80.574 87.8741Z"
					fill={theme.color.general.light}
				/>
				<path
					d="M80.5739 102.871C80.2016 102.871 79.8411 102.733 79.563 102.482L76.6016 99.8094C75.9829 99.251 75.9339 98.2969 76.4923 97.6782C77.0505 97.0592 78.0046 97.0104 78.6235 97.5688L80.4317 99.2004L84.3798 94.5616C84.9202 93.927 85.8725 93.8504 86.5069 94.3905C87.1415 94.9307 87.2182 95.8831 86.678 96.5176L81.7231 102.34C81.4589 102.65 81.0801 102.841 80.6731 102.867C80.6401 102.87 80.6069 102.871 80.5739 102.871Z"
					fill={theme.color.general.light}
				/>
				<path
					d="M80.5739 117.867C80.2016 117.867 79.8411 117.73 79.563 117.479L76.6016 114.806C75.9829 114.248 75.9339 113.294 76.4923 112.675C77.0505 112.056 78.0046 112.007 78.6235 112.565L80.4317 114.197L84.3798 109.558C84.9202 108.923 85.8725 108.847 86.5069 109.387C87.1415 109.927 87.2182 110.88 86.678 111.514L81.7231 117.336C81.4589 117.647 81.0801 117.837 80.6731 117.864C80.6401 117.866 80.6069 117.867 80.5739 117.867Z"
					fill={theme.color.general.light}
				/>
				<path
					d="M80.5739 132.864C80.2016 132.864 79.8411 132.726 79.563 132.475L76.6016 129.803C75.9829 129.244 75.9339 128.29 76.4923 127.672C77.0505 127.053 78.0046 127.004 78.6235 127.562L80.4317 129.194L84.3798 124.555C84.9202 123.92 85.8725 123.844 86.5069 124.384C87.1415 124.924 87.2182 125.876 86.678 126.511L81.7233 132.333C81.4591 132.643 81.0803 132.834 80.6733 132.861C80.6401 132.863 80.6069 132.864 80.5739 132.864Z"
					fill={theme.color.general.light}
				/>
				<path
					d="M122.389 84.9629H92.4844C91.651 84.9629 90.9755 84.2874 90.9755 83.454C90.9755 82.6206 91.651 81.945 92.4844 81.945H122.389C123.222 81.945 123.898 82.6206 123.898 83.454C123.898 84.2874 123.222 84.9629 122.389 84.9629Z"
					fill={theme.color.general.light}
				/>
				<path
					d="M122.389 102.978H92.4844C91.651 102.978 90.9755 102.302 90.9755 101.469C90.9755 100.636 91.651 99.96 92.4844 99.96H122.389C123.222 99.96 123.898 100.636 123.898 101.469C123.898 102.302 123.222 102.978 122.389 102.978Z"
					fill={theme.color.general.light}
				/>
				<path
					d="M112.952 96.9413H92.4844C91.651 96.9413 90.9755 96.2657 90.9755 95.4323C90.9755 94.5989 91.651 93.9233 92.4844 93.9233H112.952C113.786 93.9233 114.461 94.5989 114.461 95.4323C114.461 96.2655 113.786 96.9413 112.952 96.9413Z"
					fill={theme.color.general.light}
				/>
				<path
					d="M122.389 114.956H92.4844C91.651 114.956 90.9755 114.281 90.9755 113.447C90.9755 112.614 91.651 111.938 92.4844 111.938H122.389C123.222 111.938 123.898 112.614 123.898 113.447C123.898 114.281 123.222 114.956 122.389 114.956Z"
					fill={theme.color.general.light}
				/>
				<path
					d="M116.142 129.953H92.4844C91.651 129.953 90.9755 129.277 90.9755 128.444C90.9755 127.611 91.651 126.935 92.4844 126.935H116.142C116.976 126.935 117.651 127.611 117.651 128.444C117.651 129.277 116.975 129.953 116.142 129.953Z"
					fill={theme.color.general.light}
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_15335_327869"
					x1="105.628"
					y1="103.542"
					x2="150.208"
					y2="103.542"
					gradientUnits="userSpaceOnUse"
				>
					<stop offset="0.560517" stopColor={theme.color.general.lighter} />
					<stop offset="1" stopColor={theme.color.general.white} />
				</linearGradient>
				<linearGradient
					id="paint1_linear_15335_327869"
					x1="50"
					y1="103.542"
					x2="94.5798"
					y2="103.542"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={theme.color.general.white} />
					<stop offset="0.421875" stopColor={theme.color.general.lighter} />
				</linearGradient>
				<clipPath id="clip0_15335_327869">
					<rect width="200" height="200" fill={theme.color.general.white} />
				</clipPath>
			</defs>
		</svg>
	);
};