import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const attachSign = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
			<g clipPath="url(#clip0_11190_75449)">
				<path
					d="M14.8575 7.78283L8.05751 14.64C7.79367 14.9088 7.47889 15.1224 7.13156 15.2681C6.78423 15.4139 6.41133 15.489 6.03465 15.489C5.65797 15.489 5.28507 15.4139 4.93775 15.2681C4.59042 15.1224 4.27564 14.9088 4.0118 14.64L1.97751 12.5714C1.44497 12.0305 1.14648 11.3019 1.14648 10.5428C1.14648 9.78378 1.44497 9.05516 1.97751 8.51426L9.22323 1.24569C9.43571 1.03145 9.68852 0.86141 9.96705 0.745367C10.2456 0.629325 10.5443 0.56958 10.8461 0.56958C11.1478 0.56958 11.4466 0.629325 11.7251 0.745367C12.0037 0.86141 12.2565 1.03145 12.4689 1.24569L13.2804 2.05712C13.4946 2.26961 13.6646 2.52241 13.7807 2.80094C13.8967 3.07948 13.9565 3.37824 13.9565 3.67998C13.9565 3.98172 13.8967 4.28047 13.7807 4.55901C13.6646 4.83754 13.4946 5.09035 13.2804 5.30283L6.85751 11.7485C6.75127 11.8557 6.62487 11.9407 6.4856 11.9987C6.34633 12.0567 6.19695 12.0866 6.04608 12.0866C5.89521 12.0866 5.74583 12.0567 5.60657 11.9987C5.4673 11.9407 5.3409 11.8557 5.23465 11.7485L4.83465 11.3371C4.72754 11.2309 4.64251 11.1045 4.58449 10.9652C4.52647 10.8259 4.4966 10.6766 4.4966 10.5257C4.4966 10.3748 4.52647 10.2254 4.58449 10.0862C4.64251 9.94691 4.72754 9.82051 4.83465 9.71426L9.14323 5.43998"
					stroke={fill || theme.color.general.black}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
};