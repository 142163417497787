import styled from 'styled-components';

export const StyledLoader = styled.div<{
	size?: string;
	top?: string;
	left?: string;
}>`
  position: absolute;
  top: 40vh;
  right: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;

  &.withOverlay {
    top: 0;
    bottom: 0;
    align-items: center;
    background-color: ${({ theme }) => `${theme.color.general.lighter}7d`};
  }

  ${({ top }) => top && `top: ${top};`}
  ${({ left }) => left && `left: ${left};`}

  .icon {
    width: ${({ size }) => size || '30px'};
    height: ${({ size }) => size || '30px'};
  }

  .rotate-center {
    animation: rotate-center 1s cubic-bezier(0.86, 0, 0.07, 1) infinite both;
    transform-origin: center;
  }

  @keyframes rotate-center {

    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes rotate-center {

    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;