import en from './en';
import ru from './ru';
import uk from './uk';

export type TLanguages = 'uk' | 'en' | 'ru';

export const resources = { uk, en, ru };

export const LANGUAGES_FULL_NAME: Record<string, string> = {
	en: 'English',
	uk: 'Українська',
	ru: 'Русский',
};

export const SUPPORTED_LNGS = Object.keys(LANGUAGES_FULL_NAME);

export const DEFAULT_LANGUAGE = 'uk';