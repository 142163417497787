import { createGlobalStyle } from 'styled-components';
import { getCssFromTheme } from './utils/css';

const GlobalStyle = createGlobalStyle`
  .readOnlyContainer {
    pointer-events: none;
  }

  [data-texty] {
    display:block !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  [data-texty-tooltip] {
    max-width: 60vw;
    padding: 10px 12px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.color.general.black};
    color: ${({ theme }) => theme.color.general.white};
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    line-height: 15px;
    
    span:not(.tag) {
      color: ${({ theme }) => theme.color.general.white};
    }
  }

  [data-texty-arrow] {
    border-color: ${({ theme }) => theme.color.general.black} transparent transparent;
  }

  [data-tooltip]::before {
    /* needed - do not touch */
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;
    z-index: 50000;

    /* customizable */
    transition: all 0.15s ease;
    padding: 10px;
    border-radius: 10px;
  }

  [data-tooltip]:not([data-tooltip-persistent])::before {
    pointer-events: none;
  }

  [data-tooltip]:hover::before {
    /* needed - do not touch */
    opacity: 1;

    /* customizable */
    background: ${({ theme }) => theme.color.general.lighter};

    .table & {
      margin-bottom: 0;
    }
  }
  
  body {
    margin: 0;
    font-family: ${({ theme }) => theme.font.family.primary};
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  button { 
    padding: 0; 
    border: none;
    color: inherit; 
    cursor: pointer;
  }
  
  button, input, optgroup, select, textarea {
    font-family: inherit;
  }
  
  figure {
    margin: 0;
  }
  
  input::-moz-focus-inner { 
    margin: 0; 
    padding: 0;
    border: 0;
  }
  
  ul, ol, dd {
    margin: 0;
    padding-left: 16px; 
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0; 
  }

  h1, .ql-snow .ql-editor h1 {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'h1')};
  }

  h2, .ql-snow .ql-editor h2 {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'h2')};
  }

  h3, .ql-snow .ql-editor h3 {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'h3')};
  }

  h4, .ql-snow .ql-editor h4 {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'h4')};
  }
  
  p {
    margin: 0;
  }
  
  fieldset { 
    margin: 0; 
    padding: 0;
    border-width: 0;
  }
  
  :focus {
    outline: 0;
  }
  
  *, ::after, ::before {
    box-sizing: border-box;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

 
/* Handle */

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: ${({ theme }) => theme.color.general.lighter};
}

/* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.color.general.light};
}

  .link.noStyles {
    color: ${({ theme }) => theme.color.secondary.main};
    text-decoration: none;
}

  @media screen and (width <= 850px) {

    input, textarea {
      font-size: 16px !important;
    }
}
`;

export default GlobalStyle;