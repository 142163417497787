export const isEqualArraysById = (newValueArr: { id: number }[], initialArr: { id: number }[]) => {
	let foundSameObjectsCount = 0;

	for (let i = 0; i < newValueArr.length; i += 1) {
		const index = initialArr.findIndex(
			(initialArrValue) => (initialArrValue.id || initialArrValue) === newValueArr[i]?.id,
		);

		if (index !== -1) {
			foundSameObjectsCount += 1;
		}
	}

	return foundSameObjectsCount === newValueArr.length;
};