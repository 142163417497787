import React from 'react';

import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import Button from '../../atomic-design-components/molecules/Button/Button';
import FlexRow from '../../atomic-design-components/atoms/FlexRow/FlexRow';
import Icon from '../../atomic-design-components/atoms/Icon/Icon';
import Link from '../../atomic-design-components/atoms/Link/Link';
import Typography from '../../atomic-design-components/atoms/Typography/Typography';
import { selectUserRole } from '../../redux-saga/selectors';
import { StyledMenuItem } from './styled';

const SubscriptionEndsBlock = ({
	isExtended, t, days,
}: {
	isExtended: boolean;
	t: (key: string) => string;
	days: number;
}) => {
	const theme = useTheme();
	const role = useSelector(selectUserRole);
	return (
		<StyledMenuItem className="subscriptionBlock" daysCount={days}>
			<Icon
				name="calendarWithDays"
				width={isExtended ? 36 : 20}
				height={isExtended ? 36 : 20}
				stroke={theme.color.general.white}
				fill={theme.color.general.white}
				className="daysIcon"
			/>
			{isExtended && (
				<FlexRow flexDirection="column" alignItems="start" margin="0 0 0 10px">
					<Typography variant="caption1" text={t('all:daysLeft')} />
					<Typography variant="caption3" text={t('all:yourSubscriptionEnds')} />
					{['customer_administrator', 'customer_owner'].includes(role) && (
						<Link to="/my-company/subscription">
							<Button
								text={t('all:extend')}
								variant="general"
								iconLeftProps={{
									name: 'coins',
									fill: theme.color.general.black,
									wrapperWidth: 20,
									wrapperHeight: 20,
									margin: '0 4px 0 0',
								}}
								padding="2px 15px"
								fullWidth
								margin="8px 0 0"
							/>
						</Link>
					)}
				</FlexRow>
			)}
		</StyledMenuItem>
	);
};

export default SubscriptionEndsBlock;