import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const palette = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
			<g clipPath="url(#clip0_12724_114079)">
				<path
					d="M9.71415 5.7154C10.3453 5.7154 10.857 5.20373 10.857 4.57254C10.857 3.94136 10.3453 3.42969 9.71415 3.42969C9.08296 3.42969 8.57129 3.94136 8.57129 4.57254C8.57129 5.20373 9.08296 5.7154 9.71415 5.7154Z"
					stroke={fill || theme.color.general.dark}
					strokeWidth="1.14"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M5.14272 11.43C5.45831 11.43 5.71415 11.1741 5.71415 10.8585C5.71415 10.5429 5.45831 10.2871 5.14272 10.2871C4.82713 10.2871 4.57129 10.5429 4.57129 10.8585C4.57129 11.1741 4.82713 11.43 5.14272 11.43Z"
					stroke={fill || theme.color.general.dark}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M5.14286 7.43025C5.77404 7.43025 6.28571 6.91857 6.28571 6.28739C6.28571 5.65621 5.77404 5.14453 5.14286 5.14453C4.51167 5.14453 4 5.65621 4 6.28739C4 6.91857 4.51167 7.43025 5.14286 7.43025Z"
					stroke={fill || theme.color.general.dark}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M10.8803 14.0351C10.8676 13.8134 10.7905 13.6003 10.6586 13.4217C10.5267 13.2431 10.3456 13.1068 10.1374 13.0294C9.62053 12.8595 9.18118 12.5108 8.89839 12.046C8.61559 11.5811 8.50789 11.0306 8.59466 10.4935C8.68144 9.95632 8.95698 9.46774 9.37175 9.11558C9.78653 8.76341 10.3133 8.57077 10.8574 8.57227H12.9946C13.3611 8.57329 13.7226 8.48614 14.0484 8.31817C14.3742 8.1502 14.6548 7.90634 14.8666 7.60716C15.0783 7.30798 15.2151 6.96226 15.2652 6.59915C15.3153 6.23605 15.2774 5.86621 15.1546 5.52084C14.7 4.23737 13.9015 3.10353 12.8461 2.24318C11.7908 1.38283 10.5193 0.829071 9.17061 0.642408C7.82188 0.455745 6.4478 0.643357 5.19847 1.18475C3.94915 1.72614 2.87266 2.60048 2.0866 3.71224C1.30054 4.824 0.835163 6.13042 0.741306 7.48877C0.647448 8.84711 0.928723 10.2051 1.5544 11.4144C2.18008 12.6237 3.12609 13.6378 4.28908 14.3459C5.45206 15.054 6.78729 15.4288 8.14887 15.4294C8.82128 15.4313 9.49064 15.339 10.1374 15.1551C10.375 15.0886 10.5807 14.9388 10.7171 14.7332C10.8535 14.5276 10.9114 14.2799 10.8803 14.0351V14.0351Z"
					stroke={fill || theme.color.general.dark}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_12724_114079">
					<rect width="16" height="16" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};