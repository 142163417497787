import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const chevronUpThin = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 14} height={height || 8} viewBox="0 0 14 8" fill="none">
			<path
				d="M12.9434 6.87992L7.3205 1.25706C7.27946 1.21333 7.22989 1.17848 7.17485 1.15465C7.11981 1.13082 7.06048 1.11853 7.0005 1.11853C6.94053 1.11853 6.88119 1.13082 6.82615 1.15465C6.77112 1.17848 6.72155 1.21333 6.6805 1.25706L1.05765 6.87992"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};