import React from 'react';
import { IPropsSvgIcon } from '../../types';

export const flagPL = ({
	width, height,
}: IPropsSvgIcon) => (
	<svg width={width || 17} height={height || 12} viewBox="0 0 17 12" fill="none">
		<path fillRule="evenodd" clipRule="evenodd" d="M16.5 12H0.5V0H16.5V12Z" fill="white" />
		<path d="M16 11.5H1V0.5H16V11.5Z" stroke="black" strokeOpacity="0.1" />
		<path fillRule="evenodd" clipRule="evenodd" d="M16.5 12H0.5V6H16.5V12Z" fill="#DC143C" />
	</svg>
);