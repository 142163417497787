import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const typeCallback = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 34} height={height || 34} viewBox="0 0 34 34" fill="none">
			<path
				d="M24.1534 21.6948L24.1535 21.6948L31.8161 26.2926C31.8161 26.2926 31.8162 26.2926 31.8162 26.2926C32.4285 26.6601 32.6387 27.4712 32.2819 28.0897L32.2818 28.0898C31.8092 28.9092 31.4534 29.5275 31.1838 29.9961C30.8655 30.5493 30.6673 30.8938 30.5389 31.1137C30.4513 31.2638 30.4059 31.3391 30.3825 31.3765C28.6175 33.1455 25.5548 33.5098 21.6902 32.2724C17.8638 31.0472 13.5243 28.3178 9.60377 24.3972C5.68324 20.4765 2.95326 16.1375 1.7278 12.3113C0.490177 8.4471 0.854259 5.3843 2.62455 3.61779C2.63552 3.61093 2.64928 3.60245 2.66651 3.59199C2.71557 3.5622 2.785 3.52106 2.88552 3.46238C3.10704 3.33307 3.4554 3.13259 4.01725 2.80925C4.48441 2.54039 5.09918 2.1866 5.91135 1.71814L5.91145 1.71808C6.52983 1.3613 7.34083 1.57141 7.70832 2.18388L12.3096 9.85296C12.3096 9.85297 12.3096 9.85297 12.3096 9.85298C12.6943 10.4942 12.4742 11.3232 11.8222 11.6891L11.8222 11.6891L9.01466 13.2648C8.37365 13.6243 8.13731 14.4437 8.51154 15.0911C10.9115 19.2439 14.7804 23.1083 18.9315 25.502L18.9318 25.5022C19.585 25.8785 20.4042 25.6334 20.7598 24.9927L20.7601 24.9922L22.3142 22.1883L22.3142 22.1883C22.6774 21.533 23.5089 21.308 24.1534 21.6948ZM32.9983 4.98042L26.6666 11.3124L25.9595 12.0195L26.6666 12.7266L28.9447 15.0046L18.0011 15.9995L19.0955 5.15514L21.2737 7.33355L21.9809 8.04072L22.688 7.33356L29.0197 1.00161L32.9983 4.98042ZM17.9964 16.0002L17.9968 16.0039L17.9968 16.0041L18.0869 16.9959L17.9973 16.0041L17.9973 16.004L17.9964 16.0002Z"
				stroke={fill || theme.color.general.black}
				strokeWidth="2"
			/>
		</svg>
	);
};