import React from 'react';
import clsx from 'clsx';

import { StyledLabel } from './styled';
import { IDummyData } from '../../../types';

const Label = ({
	as, children, className, color, icon, margin, onClick, isUppercase, text, size = 'big',
}: {
	as?: IDummyData;
	children?: React.ReactNode;
	className?: string;
	color?: string;
	icon?: React.ReactNode;
	margin?: string;
	onClick?: () => void;
	isUppercase?: boolean;
	text?: string;
	size?: 'small' | 'medium' | 'big';
}) => (
	<StyledLabel
		as={as}
		color={color}
		size={size}
		margin={margin}
		onClick={onClick}
		className={clsx(className, 'label')}
		$isUppercase={isUppercase}
	>
		{icon}
		{children || text}
	</StyledLabel>
);

export default Label;