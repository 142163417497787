import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../../constants';
import { getScreenHeight } from '../../../utils/getScreenHeight';

export const StyledDrawer = styled.div<{
	width?: string;
	absolutePositioned?: boolean;
	closeIconPadding?: string;
}>`
  position: ${({ absolutePositioned }) => (absolutePositioned ? 'absolute' : 'relative')};
  z-index: 4;
  display: block;
  width: ${({ width }) => width};
  height: 100vh;
  background-color: ${({ theme: { color } }) => color.general.white};
  transition: transform 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  -webkit-overflow-scrolling: touch;

  .titleRow {
    position: sticky;
    top: 0;
    z-index: 3;
    background-color: ${({ theme: { color } }) => color.general.white};
  }

  &.static {
    transition: none;
  }

  &.opened {
    overflow-y: auto;
  }

  &.left {
    transform: translateX(-100%);

    &.opened {
      transform: translateX(0);
    }

    &.static {

      @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({
	theme,
}) => theme.breakpoints.xl}px) {
        height: 100%;
      }
    }
  }

  &.right {
    transform: translateX(100%);

    &.opened {
      transform: translateX(0);
    }
  }

  &.displayed {
    display: block;
  }

  .closeIcon {
    padding: ${({ closeIconPadding }) => closeIconPadding};
    cursor: pointer;
  }

  @media only screen and (max-width: ${({ width }) => width}) {
    width: 100vw;
    max-width: 100vw;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {

    &.right.opened {
      top: ${HEADER_HEIGHT}px;
      z-index: 20;
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (width <= 900px) {

    &.right.opened {
      position: absolute;
      top: 0;
      right: 0;
      height: calc(${getScreenHeight()}px - ${HEADER_HEIGHT}px);
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    height: calc(${getScreenHeight()}px - ${HEADER_HEIGHT}px);
  }
`;