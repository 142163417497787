import styled from 'styled-components';

export const StyledUploadImages = styled.div<{
	dropHeight?: number | string;
}>`
  .actions {
    position: sticky;
    bottom: 0;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding: 10px 0;
    background-color: #fff;
  }

  &.hidden {
    position: absolute;
    bottom: 40px;
    left: 5px;
    width: calc(100% - 10px);
    opacity: 0;

    div,
    div * {
      cursor: auto;
    }
  }

  &.active {
    opacity: 1;
    bottom: 2px;

    div {
      height: ${({ dropHeight }) => `calc(90px + ${dropHeight}px)`};
    }
  }
`;

export const StyledUploadedList = styled.div`
  position: absolute;
  bottom: 80px;
  left: 35px;
  width: 80%;

  .uploadItemsList {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-right: 20px;
    gap: 8px 15px;
  }
`;

export const StyledUploadedItem = styled.div`
  display: flex;
  align-items: center;
  max-width: 190px;

  .size {
    white-space: nowrap;
  }
`;