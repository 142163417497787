import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const typeBubblesWidget = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg
			id="Layer_2"
			data-name="Layer 2"
			width={width || 56}
			height={height || 56}
			viewBox="0 0 56 56"
			fill="none"
		>
			<defs>
				<style>
					{`.cls-1 {
           fill: ${theme.color.general.white};
          }

          .cls-2 {
            fill: ${fill || theme.color.primary.main};
          }`}
				</style>
			</defs>
			<g id="Layer_1-2" data-name="Layer 1">
				<path
					className="cls-2"
					d="m1.06,11.77C1.8,6.19,6.19,1.8,11.77,1.06,22.54-.35,33.46-.35,44.23,1.06c5.58.73,9.98,5.13,10.71,10.71,1.42,10.77,1.42,21.68,0,32.45-.73,5.58-5.13,9.98-10.71,10.71-10.77,1.42-21.68,1.42-32.45,0-5.58-.73-9.98-5.13-10.71-10.71C-.35,33.46-.35,22.54,1.06,11.77Z"
				/>
				<g>
					<path
						className="cls-1"
						d="m16.7,35.55l-1.93.61,1.23-3.68c.03-.1.05-.21.05-.32V15.55c0-.22.09-.44.25-.6s.37-.25.6-.25h18.46c.23,0,.44.09.6.25s.25.38.25.6v5.66c0,.55.45,1,1,1s1-.45,1-1v-5.66c0-.76-.3-1.47-.83-2.01s-1.25-.83-2.01-.83h-18.46c-.76,0-1.48.3-2.01.83s-.83,1.25-.83,2.01v16.45l-1.79,5.38c-.12.36-.03.75.24,1.02.19.19.45.3.71.3.1,0,.2-.02.3-.05l3.8-1.2c.53-.17.82-.73.65-1.25-.17-.53-.73-.82-1.25-.65Z"
					/>
					<path
						className="cls-1"
						d="m43.73,42.93l-1.53-3.89v-11.99c0-.65-.3-1.28-.82-1.72-.48-.41-1.11-.63-1.78-.63h-16c-.66,0-1.29.22-1.78.63-.52.44-.82,1.07-.82,1.72v13.54c0,.65.3,1.28.82,1.72.48.41,1.11.63,1.78.63h12.7l6.3,1.33c.07.01.14.02.21.02.29,0,.57-.13.76-.35.24-.28.3-.67.17-1.01Zm-7.12-1.97c-.07-.01-.14-.02-.21-.02h-12.8c-.19,0-.37-.06-.48-.16-.07-.06-.12-.13-.12-.19v-13.54c0-.06.04-.13.11-.19.12-.1.3-.16.49-.16h16c.19,0,.37.06.49.16.07.06.11.13.11.19v12.18c0,.13.02.25.07.37l.92,2.33-4.58-.97Z"
					/>
				</g>
			</g>
		</svg>
	);
};