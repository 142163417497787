import styled from 'styled-components';

import InputMask from 'react-input-mask';
import { getCssFromTheme } from '../../../utils/css';

export const StyledInput = styled(InputMask)<{
	placeholderColor?: string;
}>`
  ${({ theme }) => getCssFromTheme(theme, 'typography', 'input')};
  
  min-height: 42px;
  padding: 8px 15px;
  border: none;
  border-radius: 3px;
  color: ${({ theme }) => theme.color.general.black};
  flex-grow: 1;
  resize: none;

  &.multiline {
    line-height: 22px;
  }

  &.hasLeftIcon {
    padding-left: 39px;
  }

  &.hasRightIcon {
    padding-right: 47px;
  }

  &.hasError {
    padding-right: 39px;
  }

  .disabled & {
    background-color: ${({ theme }) => theme.color.general.lightest};
    color: ${({ theme }) => theme.color.general.light};
    cursor: not-allowed;
  }

  ::placeholder {
    opacity: 1;
    color: ${({ theme, placeholderColor }) => placeholderColor || theme.color.general.dark};
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 16px !important;
    line-height: 20px !important;
  }
`;