import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const customers = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 20} height={height || 16} viewBox="0 0 20 16" fill="none">
			<path
				d="M18.9145 3.88544L14.8002 7.99972L10.0002 1.14258L5.20022 7.99972L1.08594 3.88544V12.7997C1.08594 13.3453 1.30267 13.8686 1.68846 14.2543C2.07425 14.6401 2.59749 14.8569 3.14308 14.8569H16.8574C17.403 14.8569 17.9262 14.6401 18.312 14.2543C18.6978 13.8686 18.9145 13.3453 18.9145 12.7997V3.88544Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};