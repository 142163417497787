import { put, takeLatest } from 'redux-saga/effects';

import { buildRequestGenerator, fetchData, showError } from '../../api';
import { popupAlertShow, userLogout } from '../actions';
import {
	CANCEL_SUBSCRIPTION,
	CANCEL_SUBSCRIPTION_ERROR,
	CANCEL_SUBSCRIPTION_SUCCESS,
	DELETE_COMPANY,
	GET_BILLING_PLANS,
	GET_BILLING_PLANS_ERROR,
	GET_BILLING_PLANS_SUCCESS,
	GET_COMPANY_DATA,
	GET_COMPANY_DATA_ERROR,
	GET_COMPANY_DATA_SUCCESS,
	UPDATE_COMPANY_DATA,
	UPDATE_COMPANY_DATA_ERROR,
	UPDATE_COMPANY_DATA_SUCCESS,
} from '../constants';
import { IApiSchema } from '../../types';
import { globalHandleError } from '../../utils/globalHandleError';

function* doGetMyCompany({ id }: { id: number }) {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'GET',
			type: 'customers',
			id,
		});

		const response: IApiSchema['CustomerDetailed'] = yield fetchData(request);

		yield showError(response);
		yield put({
			type: GET_COMPANY_DATA_SUCCESS,
			item: response,
		});
	} catch (error) {
		yield put({ type: GET_COMPANY_DATA_ERROR });

		globalHandleError({
			module: 'company',
			subModule: 'doGetMyCompany',
			error,
		});
	}
}

function* doUpdateMyCompanyData({ id, values }: {id: number, values: IApiSchema['UpdateCustomerDetailed']}) {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'PATCH',
			id,
			requestBody: values,
			type: 'customers',
		});

		const response: IApiSchema['CustomerDetailed'] = yield fetchData(request);

		yield showError(response);
		yield put({
			type: UPDATE_COMPANY_DATA_SUCCESS,
			item: response,
		});

		yield put(
			popupAlertShow({
				contentKey: 'dataSuccessfullySaved',
				type: 'success',
				withCloseButton: true,
				iconName: 'statusActive',
			}),
		);
	} catch (error) {
		yield put({ type: UPDATE_COMPANY_DATA_ERROR });

		globalHandleError({
			module: 'company',
			subModule: 'doUpdateMyCompanyData',
			error,
		});
	}
}

function* doGetBillingPlans() {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'GET',
			type: 'billingPlans',
			queryParams: [{ key: 'sort', value: 'id=asc' }],
		});

		const response: IApiSchema['LimitOffsetPage_CustomerPlanBrief_'] = yield fetchData(request);

		yield showError(response);

		yield put({ type: GET_BILLING_PLANS_SUCCESS, plans: response?.items });
	} catch (error) {
		yield put({ type: GET_BILLING_PLANS_ERROR });

		globalHandleError({
			module: 'company',
			subModule: 'doGetBillingPlans',
			error,
		});
	}
}

function* doCancelSubscription() {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'POST',
			type: 'cancelSubscription',
		});
		const response: IApiSchema['ResponseOk'] = yield fetchData(request);

		yield showError(response);

		yield put({ type: CANCEL_SUBSCRIPTION_SUCCESS });
	} catch (error) {
		yield put({ type: CANCEL_SUBSCRIPTION_ERROR });

		globalHandleError({
			module: 'company',
			subModule: 'doCancelSubscription',
			error,
		});
	}
}

function* doDeleteCompany(
	{ customerId, navigate }: { customerId: number, navigate: (path: string) => void },
) {
	try {
		const request: Request = yield buildRequestGenerator({
			apiMethod: 'POST',
			type: 'deleteCompany',
			queryParams: [{ key: 'customer_id', value: customerId }],
		});

		const response: IApiSchema['ResponseOk'] = yield fetchData(request);

		yield showError(response);

		if (response.status === 'ok') {
			navigate('/sign-in');

			yield put(userLogout());
		}
	} catch (error) {
		globalHandleError({
			module: 'company',
			subModule: 'doDeleteCompany',
			error,
		});
	}
}

export default function* sagas() {
	return [
		// @ts-ignore
		yield takeLatest(GET_COMPANY_DATA, doGetMyCompany),
		// @ts-ignore
		yield takeLatest(UPDATE_COMPANY_DATA, doUpdateMyCompanyData),
		// @ts-ignore
		yield takeLatest(GET_BILLING_PLANS, doGetBillingPlans),
		// @ts-ignore
		yield takeLatest(CANCEL_SUBSCRIPTION, doCancelSubscription),
		// @ts-ignore
		yield takeLatest(DELETE_COMPANY, doDeleteCompany),
	];
}