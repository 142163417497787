import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const searchSign = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
			<path
				d="M6.76557 12.9601C10.1866 12.9601 12.9599 10.1868 12.9599 6.76582C12.9599 3.34481 10.1866 0.571533 6.76557 0.571533C3.34457 0.571533 0.571289 3.34481 0.571289 6.76582C0.571289 10.1868 3.34457 12.9601 6.76557 12.9601Z"
				stroke={fill || theme.color.general.dark}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.4283 15.4288L11.1426 11.1431"
				stroke={fill || theme.color.general.dark}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};