import React from 'react';
import styled from 'styled-components';
import { IPropsSvgIcon } from '../../types';

const StyledDots = styled.div<IPropsSvgIcon>`
  display: flex;
  align-items: center;
  width: ${({ width }) => width || 20}px;
  font-size: 22px;
  font-weight: 700;

  &::after {
    content: '·';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgb(0 0 0 / 0%);
      text-shadow: 0.25em 0 0 rgb(0 0 0 / 0%), 0.5em 0 0 rgb(0 0 0 / 0%);
    }

    40% {
      color: white;
      text-shadow: 0.25em 0 0 rgb(0 0 0 / 0%), 0.5em 0 0 rgb(0 0 0 / 0%);
    }

    60% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 rgb(0 0 0 / 0%);
    }

    80%,
    100% {
      text-shadow: 0.25em 0 0 white, 0.5em 0 0 white;
    }
  }
`;

export const floatingDots = ({ fill, width }: IPropsSvgIcon) => (
	<StyledDots width={width} fill={fill} />
);