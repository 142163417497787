import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const templates = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
			<path
				d="M12.7436 3.82812H4.51498C3.75756 3.82812 3.14355 4.44213 3.14355 5.19955V17.5424C3.14355 18.2998 3.75756 18.9138 4.51498 18.9138H12.7436C13.501 18.9138 14.115 18.2998 14.115 17.5424V5.19955C14.115 4.44213 13.501 3.82812 12.7436 3.82812Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.88672 7.25781H11.3724"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.88672 10.6855H11.3724"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.88672 14.1152H8.62958"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.57227 1.08594H15.4866C15.8503 1.08594 16.1991 1.23043 16.4563 1.48762C16.7135 1.74481 16.858 2.09364 16.858 2.45737V15.4859"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};