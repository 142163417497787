import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const calendarWithDays = ({
	width, height, fill, stroke,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 24} height={height || 24} viewBox="0 0 24 24" fill="none">
			<g clipPath="url(#clip0_1096_167174)">
				<path
					d="M1 8.00002C1 5.58106 2 3.5 5 3.5V5.00002H7V3.5H17V5.00002H19V3.5C22 3.5 23 5.58106 23 8.00002H1Z"
					fill={theme.color.general.white}
					fillOpacity="0.25"
				/>
				<path
					d="M7 3.25H17M19 3.25V3.25C21.4853 3.25 23.5 5.26472 23.5 7.75V13.25V17.75C23.5 20.5114 21.2614 22.75 18.5 22.75H5.5C2.73858 22.75 0.5 20.5114 0.5 17.75V7.75C0.5 5.26472 2.51472 3.25 5 3.25V3.25"
					stroke={stroke || theme.color.general.black}
				/>
				<path d="M0.5 8.25H23.5" stroke={stroke || theme.color.general.black} />
				<path
					d="M5 4.25V2.25C5 1.69772 5.44772 1.25 6 1.25C6.55228 1.25 7 1.69772 7 2.25V4.25C7 4.80228 6.55228 5.25 6 5.25C5.44772 5.25 5 4.80228 5 4.25Z"
					stroke={stroke || theme.color.general.black}
				/>
				<path
					d="M17 4.25V2.25C17 1.69772 17.4477 1.25 18 1.25C18.5523 1.25 19 1.69772 19 2.25V4.25C19 4.80228 18.5523 5.25 18 5.25C17.4477 5.25 17 4.80228 17 4.25Z"
					stroke={stroke || theme.color.general.black}
				/>
				<path
					id="day-0"
					className="day-number"
					d="M11.9844 19.25C11.3615 19.25 10.827 19.0924 10.3806 18.7772C9.9368 18.4595 9.59511 18.0018 9.35556 17.4041C9.11852 16.804 9 16.0815 9 15.2368C9.00252 14.392 9.1223 13.6733 9.35934 13.0807C9.5989 12.4856 9.94058 12.0317 10.3844 11.719C10.8307 11.4063 11.3641 11.25 11.9844 11.25C12.6047 11.25 13.1381 11.4063 13.5844 11.719C14.0307 12.0317 14.3724 12.4856 14.6095 13.0807C14.849 13.6758 14.9688 14.3945 14.9688 15.2368C14.9688 16.084 14.849 16.8078 14.6095 17.4079C14.3724 18.0056 14.0307 18.462 13.5844 18.7772C13.1406 19.0924 12.6072 19.25 11.9844 19.25ZM11.9844 18.0661C12.4686 18.0661 12.8506 17.8278 13.1305 17.3512C13.4129 16.8721 13.5541 16.1673 13.5541 15.2368C13.5541 14.6215 13.4898 14.1045 13.3612 13.6859C13.2326 13.2673 13.0511 12.9521 12.8165 12.7403C12.582 12.526 12.3046 12.4188 11.9844 12.4188C11.5028 12.4188 11.122 12.6584 10.8421 13.1375C10.5622 13.6141 10.421 14.3138 10.4184 15.2368C10.4159 15.8546 10.4777 16.374 10.6038 16.7952C10.7324 17.2163 10.9139 17.534 11.1485 17.7483C11.383 17.9602 11.6616 18.0661 11.9844 18.0661Z"
					fill={fill || theme.color.general.black}
				/>
				<path
					id="day-1"
					className="day-number"
					d="M13.3672 11.25V19.25H11.918V12.6602H11.8711L10 13.8555V12.5273L11.9883 11.25H13.3672Z"
					fill={fill || theme.color.general.black}
				/>
				<path
					id="day-2"
					className="day-number"
					d="M9.04624 19.25V18.2172L11.7861 15.5313C12.0482 15.2667 12.2665 15.0316 12.4412 14.8261C12.6159 14.6206 12.7469 14.4215 12.8343 14.2288C12.9216 14.0361 12.9653 13.8306 12.9653 13.6122C12.9653 13.363 12.9088 13.1498 12.7958 12.9725C12.6827 12.7927 12.5273 12.654 12.3295 12.5564C12.1317 12.4587 11.9069 12.4099 11.6551 12.4099C11.3956 12.4099 11.1683 12.4639 10.973 12.5718C10.7778 12.6771 10.6262 12.8274 10.5183 13.0226C10.413 13.2179 10.3603 13.4504 10.3603 13.7201H9C9 13.2192 9.11432 12.7837 9.34297 12.4138C9.57161 12.0438 9.88632 11.7574 10.2871 11.5544C10.6904 11.3515 11.1529 11.25 11.6744 11.25C12.2036 11.25 12.6686 11.3489 13.0694 11.5467C13.4701 11.7445 13.781 12.0156 14.0019 12.3598C14.2254 12.7041 14.3372 13.0971 14.3372 13.539C14.3372 13.8345 14.2807 14.1248 14.1676 14.4099C14.0546 14.6951 13.8555 15.0111 13.5703 15.3579C13.2877 15.7047 12.8908 16.1248 12.3796 16.618L11.0193 18.0014V18.0554H14.4566V19.25H9.04624Z"
					fill={fill || theme.color.general.black}
				/>
				<path
					id="day-3"
					className="day-number"
					d="M11.8555 19.25C11.308 19.25 10.8213 19.1562 10.3954 18.9686C9.97212 18.7811 9.63752 18.52 9.39163 18.1854C9.14575 17.8508 9.01521 17.4642 9 17.0257H10.4297C10.4423 17.2361 10.512 17.4198 10.6388 17.577C10.7655 17.7316 10.9341 17.852 11.1445 17.9382C11.3549 18.0244 11.5906 18.0675 11.8517 18.0675C12.1305 18.0675 12.3777 18.0193 12.5932 17.923C12.8086 17.8241 12.9772 17.6873 13.0989 17.5124C13.2205 17.3375 13.2801 17.1359 13.2776 16.9078C13.2801 16.6721 13.2193 16.4642 13.0951 16.2842C12.9708 16.1042 12.7909 15.9636 12.5551 15.8622C12.3219 15.7608 12.0406 15.7101 11.711 15.7101H11.0228V14.6226H11.711C11.9823 14.6226 12.2193 14.5757 12.4221 14.4819C12.6274 14.3881 12.7883 14.2563 12.9049 14.0865C13.0215 13.9141 13.0786 13.7151 13.076 13.4895C13.0786 13.269 13.0292 13.0776 12.9278 12.9154C12.8289 12.7506 12.6882 12.6226 12.5057 12.5314C12.3257 12.4401 12.1141 12.3945 11.8707 12.3945C11.6324 12.3945 11.4119 12.4376 11.2091 12.5238C11.0063 12.6099 10.8428 12.7329 10.7186 12.8926C10.5944 13.0497 10.5285 13.2373 10.5209 13.4553H9.1635C9.17364 13.0193 9.29911 12.6366 9.53992 12.307C9.78327 11.975 10.1077 11.7164 10.5133 11.5314C10.9189 11.3438 11.3739 11.25 11.8783 11.25C12.398 11.25 12.8492 11.3476 13.2319 11.5428C13.6172 11.7354 13.9151 11.9952 14.1255 12.3222C14.3359 12.6492 14.4411 13.0105 14.4411 13.4059C14.4436 13.8444 14.3143 14.212 14.0532 14.5086C13.7947 14.8051 13.455 14.999 13.0342 15.0903V15.1511C13.5817 15.2272 14.0013 15.43 14.2928 15.7595C14.5868 16.0865 14.7326 16.4933 14.73 16.98C14.73 17.416 14.6058 17.8064 14.3574 18.1511C14.1115 18.4933 13.7719 18.762 13.3384 18.9572C12.9075 19.1524 12.4132 19.25 11.8555 19.25Z"
					fill={fill || theme.color.general.black}
				/>
				<path
					id="day-4"
					className="day-number"
					d="M9 17.7656V16.6133L12.3945 11.25H13.3555V12.8906H12.7695L10.4844 16.5117V16.5742H15.2227V17.7656H9ZM12.8164 19.25V17.4141L12.832 16.8984V11.25H14.1992V19.25H12.8164Z"
					fill={fill || theme.color.general.black}
				/>
				<path
					id="day-5"
					className="day-number"
					d="M11.7245 19.25C11.2107 19.25 10.7508 19.1537 10.3449 18.961C9.93899 18.7657 9.61657 18.4986 9.37765 18.1594C9.1413 17.8203 9.01541 17.4324 9 16.9957H10.3873C10.413 17.3194 10.553 17.584 10.8073 17.7895C11.0617 17.9925 11.3674 18.0939 11.7245 18.0939C12.0045 18.0939 12.2537 18.0297 12.4721 17.9013C12.6904 17.7728 12.8626 17.5943 12.9884 17.3656C13.1143 17.137 13.176 16.8762 13.1734 16.5833C13.176 16.2853 13.113 16.0207 12.9846 15.7895C12.8561 15.5583 12.6802 15.3772 12.4566 15.2461C12.2331 15.1126 11.9762 15.0458 11.6859 15.0458C11.4496 15.0432 11.2171 15.0869 10.9884 15.1768C10.7598 15.2667 10.5787 15.3849 10.4451 15.5313L9.15414 15.3194L9.56647 11.25H14.1445V12.4446H10.7495L10.5222 14.5371H10.5684C10.7148 14.365 10.9216 14.2224 11.1888 14.1093C11.456 13.9937 11.7489 13.9359 12.0674 13.9359C12.5453 13.9359 12.9717 14.049 13.3468 14.275C13.7219 14.4986 14.0173 14.8068 14.2331 15.1999C14.4489 15.593 14.5568 16.0425 14.5568 16.5487C14.5568 17.0702 14.4361 17.5352 14.1946 17.9436C13.9557 18.3496 13.623 18.6694 13.1965 18.9032C12.7726 19.1344 12.282 19.25 11.7245 19.25Z"
					fill={fill || theme.color.general.black}
				/>
			</g>
			<defs>
				<clipPath id="clip0_1096_167174">
					<rect width="24" height="24" fill={theme.color.general.white} />
				</clipPath>
				<style>
					{`.day-number {
            display:none;
          }`}
				</style>
			</defs>
		</svg>
	);
};