import React from 'react';
import { useTheme } from 'styled-components';

import Icon from '../../../atomic-design-components/atoms/Icon/Icon';
import { WIDGET_BUTTON_ICONS } from '../../../constants';
import { StyledContainer } from './styled';

const ChooseIconType = (props: {
	name: string;
	value: string;
	updateInput: (e: { target: { name: string; value: string } }) => void;
}) => {
	const { name, value, updateInput } = props;
	const theme = useTheme();

	return (
		<StyledContainer borderColor={theme.color.general.lighter}>
			{name === 'callback_button_style' ? (
				<Icon
					id="typeCallback"
					name="typeCallback"
					size={36}
					fill={theme.color.general.black}
					wrapperHeight={56}
					wrapperWidth={56}
					borderRadius="5px"
					wrapperColor={`${theme.color.secondary.lightest}80`}
				/>
			) : (
				WIDGET_BUTTON_ICONS.map((icon) => (
					<Icon
						key={icon}
						id={icon}
						name={icon}
						size={36}
						fill={theme.color.general.black}
						wrapperHeight={56}
						wrapperWidth={56}
						borderRadius="5px"
						wrapperColor={value === icon ? `${theme.color.secondary.lightest}80` : ''}
						onClick={() => {
							updateInput({ target: { name, value: icon } });
						}}
					/>
				))
			)}
		</StyledContainer>
	);
};

export default ChooseIconType;