import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '../../atomic-design-components/atoms/Typography/Typography';
import { COLLAPSED_FORM_BLOCKS } from '../../config';
import { FORM_CONFIGS } from '../../config/forms';
import FormBlockTabs from './FormBlockTabs';
import FormFields from './FormFields';
import { StyledExpansionPanel } from './styled';
import { IDummyData } from '../../types';

const FormBlock = ({
	blockKey,
	expansionPanelProps,
	fields,
	formErrors,
	formSubmit,
	formValues,
	formValuesChanged,
	FormBlockWrapper,
	initialValues,
	isReadOnly,
	isSecondaryBlock,
	isSubmitOnBlur,
	labelKey,
	labelType,
	optionsData,
	touchedFields,
	type,
	setTouchedFields,
	submitByEnterPressed,
	updateCheckbox,
	updateInput,
	updateSelect,
	validateField,
	validationRules,
	withoutTranslate,
}: {
	blockKey: string;
	withoutTranslate?: boolean;
	expansionPanelProps?: Record<string, unknown>;
	fields: Record<string, unknown>[];
	formErrors: Record<string, unknown>;
	formSubmit?: (e: React.FormEvent<HTMLFormElement>, values: Record<string, unknown>) => void;
	formValues: Record<string, unknown>;
	formValuesChanged: Record<string, unknown>;
	FormBlockWrapper: React.ComponentType<Record<string, unknown>>;
	initialValues: IDummyData;
	isReadOnly: IDummyData;
	isSecondaryBlock: boolean;
	isSubmitOnBlur: IDummyData;
	labelKey: IDummyData;
	labelType: IDummyData;
	optionsData: IDummyData;
	touchedFields: IDummyData;
	type: string;
	setTouchedFields: IDummyData;
	submitByEnterPressed: IDummyData;
	updateCheckbox?: (name: string, value: boolean) => void;
	updateInput?: (name: string, value: string) => void;
	updateSelect: IDummyData;
	validateField: (name: string, value: string) => void;
	validationRules: Record<string, unknown>;
}) => {
	const { t, i18n } = useTranslation(['all', 'menu', 'validation']);

	const withBlockTabs = ['translations', 'prices'].includes(blockKey) && FORM_CONFIGS[type]?.withTabs?.length;

	const isFormWithOneBlockOnly = !blockKey;

	const getPanelHeader = (blockKey: string) => {
		if (blockKey === 'noTitle') {
			return null;
		}

		const blockNameTranslation = blockKey
			&& (i18n.exists(blockKey, { ns: 'all' }) ? t(blockKey)
				: t(`menu:${blockKey}`));

		return (
			<Typography variant="h4">{blockNameTranslation}</Typography>
		);
	};

	const formFieldsProps = {
		formErrors,
		formSubmit,
		formValues,
		initialValues,
		isReadOnly,
		isSubmitOnBlur,
		withoutTranslate,
		labelKey,
		labelType,
		optionsData,
		submitByEnterPressed,
		type,
		updateCheckbox,
		updateInput,
		updateSelect,
		validateField,
		validationRules,
	};

	const compoundTypeKey = `${type}.${blockKey}`;

	const getIsBlockShown = () => {
		let isShown = true;

		if (isShown
				&& fields
					&& Array.isArray(fields)
					&& fields.some((field) => !!field.getIsHidden)
					&& !fields
						.filter((field) => !field.getIsHidden
						// @ts-ignore
						|| !field.getIsHidden(formValues, optionsData)).length
		) {
			isShown = false;
		}

		return isShown;
	};

	const isBlockShown = getIsBlockShown();

	const panelProps = {
		isHidden: isFormWithOneBlockOnly || blockKey === 'noTitle',
		key: blockKey,
		header: getPanelHeader(blockKey),
		initialOpened: !COLLAPSED_FORM_BLOCKS.includes(blockKey),
		id: blockKey,
		className: clsx(
			'formBlock',
			blockKey,
			!isBlockShown && 'hidden',
			isFormWithOneBlockOnly && 'oneBlockForm',
		),
		...expansionPanelProps,
	};

	const getFields = () => {
		if (!isBlockShown) {
			return null;
		}

		return Array.isArray(fields) ? (
			// @ts-ignore
			<FormFields {...formFieldsProps} fields={fields} key={formFieldsProps.id} />
		) : (
			Object.values(fields).map((values, i) => {
				const key = Object.keys(fields)[i];

				return Array.isArray(values) ? (
					<div key={JSON.stringify(values)}>
						{/* @ts-ignore */}
						<Typography type="h4" text={t(key)} margin="0 0 10px 0" />
						{/* @ts-ignore */}
						<FormFields {...formFieldsProps} fields={values} />
					</div>
				) : null;
			})
		);
	};

	return FormBlockWrapper ? (
		<StyledExpansionPanel {...panelProps} key={panelProps.key}>
			<FormBlockWrapper
				isBlockShown={isBlockShown}
				isReadOnly={isReadOnly}
				blockKey={blockKey}
				compoundTypeKey={compoundTypeKey}
				id={formValues.id}
				fields={fields}
				formValues={formValues}
				formValuesChanged={formValuesChanged}
				getPanelHeader={getPanelHeader}
				optionsData={optionsData}
				setTouchedFields={setTouchedFields}
				touchedFields={touchedFields}
				withInfo={!isSecondaryBlock}
				updateCheckbox={updateCheckbox}
				updateInput={updateInput}
				updateSelect={updateSelect}
				formErrors={formErrors}
			>
				{withBlockTabs ? (
					<FormBlockTabs
						formFieldsProps={formFieldsProps}
						fields={fields}
						type={type}
						blockKey={blockKey}
					/>
				) : (
					getFields()
				)}
			</FormBlockWrapper>
		</StyledExpansionPanel>
	) : (
		<StyledExpansionPanel {...panelProps} key={panelProps.id}>{getFields()}</StyledExpansionPanel>
	);
};

export default FormBlock;