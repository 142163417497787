import styled from 'styled-components';

import { getCssFromTheme } from '../../../utils/css';
import Label from '../../atoms/Label/Label';

export const StyledLabel = styled(Label)`
  display: block;
  
  &.required::after {
    content: ' *';
    color: ${({ theme }) => theme.color.status.error};
  }
  
  &.border {
    position: absolute;
    top: -8px;
    left: 9px;
    z-index: 2;
    padding: 0 7px;
    border-radius: 7px;
    background-color: ${({ theme }) => theme.color.general.white};

    .hasError & {
      color: ${({ theme }) => theme.color.status.error};
    }

    .disabled & {
      color: ${({ theme }) => theme.color.general.light};
    }
  }

  &.top {
    margin-bottom: 5px;
  }
`;

export const StyledWrapper = styled.div<{
	fullWidth?: boolean;
	width?: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${({ fullWidth, width }) => width || (fullWidth && '100%')};
  height: fit-content;
  border: 1px solid ${({ theme }) => theme.color.general.light};
  border-radius: 3px;

  & ~ .link {
    border-radius: 7px;
  }

  &.withoutBorder {
    border: 1px solid transparent;
  }

  &.withInsideButtons:not(.searchInput) {
    margin-bottom: 10px;
    padding-bottom: 60px;
  }

  &.hasError {
    margin-bottom: 0;
    border: 1px solid ${({ theme }) => theme.color.status.error};
	
    path {
      stroke: ${({ theme }) => theme.color.status.error};
	  }

    .statusActive {
      
      path {
        stroke: ${({ theme }) => theme.color.status.success};
      }      
    }
  }

  &.hasError ~ .link .label {
    color: ${({ theme }) => theme.color.status.error};
  }

  &.success {
    border: 1px solid ${({ theme }) => theme.color.status.success};
  }

  input {
    width: inherit;
  }

  &.disabled {
    border-color: ${({ theme }) => theme.color.general.lightest};
    background-color: ${({ theme }) => theme.color.general.lightest};

    input{
      pointer-events: none;
    }
  }

  .rightIcon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 16px;
  }

  & > .icon.leftIcon {
    position: absolute;
    top: 14px;
    left: 15px;
  }

  &.selectWrapper:not(.languageSelect).focused {
    border: 1px solid ${({ theme }) => theme.color.secondary.dark};
    outline: 2px solid ${({ theme }) => theme.color.secondary.lighter};

    .label.border {
      color: ${({ theme }) => theme.color.secondary.dark} !important;
    }

    .icon:not(.statusIcon, .chevronDown, .withoutHover) path {
      stroke: ${({ theme }) => theme.color.secondary.dark};
    }
  }

  &:not(.searchInput):focus-within:not(.hasError) {

    &.inputWrapper {
      border: 1px solid ${({ theme }) => theme.color.secondary.dark};
      outline: 2px solid ${({ theme }) => theme.color.secondary.lighter};
    }

    &:not(.selectWrapper) .label.border {
      color: ${({ theme }) => theme.color.secondary.dark} !important;
    }

    ~ .link .label.border {
      color: ${({ theme }) => theme.color.secondary.dark} !important;
    }

    .react-select__single-value .row {
      color: ${({ theme }) => theme.color.secondary.dark};
    }

    &.hasError {
      outline: 2px solid ${({ theme }) => theme.color.status.error}40;
    }
  }
`;

export const StyledInput = styled.input<{
	placeholderColor?: string;
}>`
  ${({ theme }) => getCssFromTheme(theme, 'typography', 'input')};
  
  min-height: 42px;
  padding: 8px 15px;
  border: none;
  border-radius: 3px;
  color: ${({ theme }) => theme.color.general.black};
  flex-grow: 1;
  resize: none;

  &.multiline {
    line-height: 22px;
  }

  &.hasLeftIcon {
    padding-left: 39px;
  }

  &.hasRightIcon {
    padding-right: 47px;
  }

  &.hasError {
    padding-right: 39px;
  }

  .disabled & {
    background-color: ${({ theme }) => theme.color.general.lightest};
    color: ${({ theme }) => theme.color.general.light};
    cursor: not-allowed;
  }

  ::placeholder {
    opacity: 1;
    color: ${({ theme, placeholderColor }) => placeholderColor || theme.color.general.dark};
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 16px !important;
    line-height: 20px !important;
  }
`;

export const StyledButtonsRow = styled.div`
  position: absolute;
  bottom: 10px;
  left: 15px;
  display: flex;

  & > button {
    width: 26px;
    height: 26px;

    &:not(:last-child) {
      margin-right: 10px;
    }

    background-color: ${({ theme }) => theme.color.general.lightest};

    &:hover:not(.disabled) {
      background-color: ${({ theme }) => theme.color.general.lighter} !important;
    }
  }
`;

export const StyledErrorLabel = styled(Label)`
  padding: 10px 0 12px 0;
  color: ${({ theme }) => theme.color.status.error};

  &:last-child {
    padding-bottom: 0;
  }

  .icon {
    margin-right: 5px;
  }
`;

export const StyledInputWrapper = styled.div`
  &.isHidden {
    display: none;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &.withoutValidation {
    margin-bottom: 0;
  }
  
  .quill {
    width: inherit;
    height: inherit;

    .ql-toolbar {

      &.ql-snow {
        border: none;
        border-bottom: 1px solid ${({ theme }) => theme.color.general.light};
      }
    }

    .ql-container {

      &.ql-snow {
        border: none;
      }

      .ql-editor {
        word-break: break-word;
      }
    }
  }

  &.searchInput {

    .withoutBorder {
      border: 1px solid transparent;
    }
  }
`;