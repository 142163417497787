import React from 'react';
import { IPropsSvgIcon } from '../../types';

export const flagUK = ({
	width, height,
}: IPropsSvgIcon) => (
	<svg width={width || 17} height={height || 12} viewBox="0 0 17 12" fill="none">
		<path fillRule="evenodd" clipRule="evenodd" d="M0.5 0H16.5V12H0.5V0Z" fill="#FFD700" />
		<path fillRule="evenodd" clipRule="evenodd" d="M0.5 0H16.5V6H0.5V0Z" fill="#0057B8" />
	</svg>
);