import { all } from 'redux-saga/effects';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';

import common from './redux-saga/reducers/common';
import data from './redux-saga/reducers/data';
import user from './redux-saga/reducers/user';
import company from './redux-saga/reducers/company';
import sidebar from './redux-saga/reducers/sidebar';
import fileUpload from './redux-saga/reducers/fileUpload';
import forms from './redux-saga/reducers/forms';

import authSaga from './redux-saga/sagas/auth';
import companySaga from './redux-saga/sagas/company';
import initSaga from './redux-saga/sagas/init';
import dataSaga from './redux-saga/sagas/data';
import sidebarSaga from './redux-saga/sagas/sidebar';
import fileUploadSaga from './redux-saga/sagas/fileUpload';

import chats, { sagas as chatsSaga } from './ducks/chats';

function* rootSaga() {
	yield all([
		authSaga(),
		companySaga(),
		initSaga(),
		dataSaga(),
		sidebarSaga(),
		chatsSaga(),
		fileUploadSaga(),
	]);
}

const reducers = combineReducers({
	common, data, user, company, sidebar, chats, fileUpload, forms,
});

export default function configureStore() {
	const sagaMiddleware = createSagaMiddleware();
	const middlewares = [sagaMiddleware];

	const middlewareEnhancer = applyMiddleware(...middlewares);

	const enhancers = [middlewareEnhancer];
	const composedEnhancers = composeWithDevToolsDevelopmentOnly(...enhancers);

	const store = createStore(reducers, undefined, composedEnhancers);

	sagaMiddleware.run(rootSaga);

	return store;
}