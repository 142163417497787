import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const subscription = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
			<path
				d="M10.6446 9.86375C10.4412 9.95208 10.2218 9.99766 10.0001 9.99766C9.7783 9.99766 9.55891 9.95208 9.35549 9.86375L1.53835 6.24318C1.41141 6.17916 1.30474 6.08115 1.23021 5.96008C1.15567 5.83902 1.11621 5.69964 1.11621 5.55747C1.11621 5.4153 1.15567 5.27592 1.23021 5.15485C1.30474 5.03378 1.41141 4.93578 1.53835 4.87175L9.35549 1.22375C9.55891 1.13542 9.7783 1.08984 10.0001 1.08984C10.2218 1.08984 10.4412 1.13542 10.6446 1.22375L18.4618 4.84432C18.5887 4.90835 18.6954 5.00635 18.7699 5.12742C18.8445 5.24849 18.8839 5.38787 18.8839 5.53004C18.8839 5.67221 18.8445 5.81159 18.7699 5.93266C18.6954 6.05372 18.5887 6.15173 18.4618 6.21575L10.6446 9.86375Z"
				stroke={fill || theme.color.general.darker}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.9145 10.4805L10.5488 14.3342C10.3701 14.4157 10.176 14.4579 9.97965 14.4579C9.78327 14.4579 9.58918 14.4157 9.41051 14.3342L1.08594 10.4805"
				stroke={fill || theme.color.general.darker}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.9145 14.9375L10.5488 18.7912C10.3701 18.8727 10.176 18.9149 9.97965 18.9149C9.78327 18.9149 9.58918 18.8727 9.41051 18.7912L1.08594 14.9375"
				stroke={fill || theme.color.general.darker}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};