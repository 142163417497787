import styled from 'styled-components';
import { getCssFromTheme } from '../../../utils/css';

export const StyledOptionRow = styled.div<{
	selected?: boolean;
}>`
  display: flex;
  align-items: center;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  background-color: ${({ theme }) => theme.color.general.white};

  &:hover {
    border-top: 1px solid ${({ theme }) => theme.color.general.lighter};
    border-bottom: 1px solid ${({ theme }) => theme.color.general.lighter};
    background-color: ${({ theme }) => theme.color.general.lightest};
  }

  .icon {

    &.rightIcon {
      padding-right: 16px;
    }

    &.optionLeftIcon {
      margin-left: 16px;
    }
  }

  .react-select__option {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'input')};
    padding: 12px 15px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    background-color: ${({ theme }) => theme.color.general.white};
    color: ${({ theme }) => theme.color.general.black};

    &:hover {
      background-color: ${({ theme }) => theme.color.general.lightest};

      ~ .icon {
        margin-right: 10px;
        background-color: ${({ theme }) => theme.color.general.lightest};
      }
    }

    &.react-select__option--is-disabled {
      display: none;
    }
  }

  .icon.isOptionDisabled {
    display: none !important;
  }

  .icon .react-select__option--is-focused {
    background-color: transparent;
  }
  
  &.selected {
    border-top: 1px solid ${({ theme }) => theme.color.general.lighter};
    border-bottom: 1px solid ${({ theme }) => theme.color.general.lighter};
    background-color: ${({ theme }) => theme.color.general.lightest};
	
    .react-select__option {
      background-color: ${({ theme }) => theme.color.general.lightest};
    }
  }
`;

export const StyledSelect = styled.div`
  width: 100%;

  .react-select__control {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'input')};
    min-height: 42px;
    border: none;
    box-shadow: none;

    .leftIcon {
      margin-left: 15px;
    }

    &:hover,
    &:focus {
      border: none;
    }
  }

  &.hasError {
    border-radius: 3px;

    .react-select__control {
      border: none;
    }
  }

  .react-select__control--is-focused.react-select__control--menu-is-open {
    border: none;

    &:hover,
    &:focus {
      border: none;
    }
  }

  .react-select__control--menu-is-open {
    border: none !important;
  }

  .react-select__value-container {
    padding: 7px 0 7px 10px;

    .react-select__single-value {
      margin: 0;
    }

    & > div:last-child {
      top: initial;
      margin: 0;
      padding: 0;
    }
  }

  .react-select__placeholder {
    color: ${({ theme }) => theme.color.general.dark};
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicator {
    padding-right: 0;
  }

  .react-select__indicators {
    padding-right: 15px;

    .react-select__indicator {
      padding: 0;
      padding-left: 8px;
    }
  }

  .react-select__menu {
    right: -1px;
    z-index: 9;
    width: calc(100% + 2px);
    border: none;
    border-radius: 3px;
    background-color: ${({ theme }) => theme.color.general.white};
    box-shadow: 0 14px 15px rgb(0 0 0 / 15%);
  }

  &.autosize {

    .react-select__menu {
      width: auto;
    }
  }

  .react-select__menu-list {
    padding: 0;
    border-radius: 3px;
  }

  .react-select__menu-notice--no-options {
    ${({ theme }) => getCssFromTheme(theme, 'typography', 'input')};
    padding: 13px 16px;
    background-color: ${({ theme }) => theme.color.general.white};
    text-align: left;
  }

  @media only screen and (width <= 850px) {

    .react-select__control {
      font-size: 16px;
    }
  }
`;