import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useIsPasswordShown } from '../../hooks/useIsPasswordShown';
import Form from '../../components/Form/Form';
import { FORM_CONFIGS } from '../../config/forms';
import Button from '../../atomic-design-components/molecules/Button/Button';
import { userSignUp } from '../../redux-saga/actions';
import { StyledLine } from '../../components/IntroScreens/styled';
import CustomPasswordChecklist from '../../components/IntroScreens/components/CustomPasswordChecklist';

import { selectUserSystemParams } from '../../redux-saga/selectors';

const FormInvite = ({ code, initialValues }: {
	code: string;
	initialValues: {
		full_name: string;
		company_name: string;
		company_phone: string;
		email: string;
	};

}) => {
	const [password, setPassword] = useState('');
	const [isPasswordValid, setIsPasswordValid] = useState<boolean | undefined>(undefined);
	const [isSubmitPressed, setIsSubmitPressed] = useState(false);

	const { t } = useTranslation('auth');
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { type, iconRightProps } = useIsPasswordShown();

	const { inProgress } = useSelector(selectUserSystemParams);

	const onFormSubmit = (credentials: {
		full_name: string;
		company_name: string;
		company_phone: string;
		email: string;
		code: string;
		password: string;
	}) => {
		dispatch(userSignUp({ ...credentials, code }, navigate));
	};

	return (
		<>
			<Form
				className="form formInvite"
				initialValues={initialValues}
				fields={[
					{ ...FORM_CONFIGS.signUp.fields[2], isDisabled: true },
					{ ...FORM_CONFIGS.signUp.fields[1], key: 'company_name', isDisabled: true },
				]}
			/>
			<StyledLine />
			<Form
				className="form"
				type="signUp"
				initialValues={initialValues}
				// @ts-ignore
				onSubmit={onFormSubmit}
				// @ts-ignore
				withCustomValidationRule
				validationRules={{
					full_name: ['required'],
					password: ['required', { type: 'password', isPasswordValid }, 'exceptCyrillicSymbol'],
				}}
				fields={[
					FORM_CONFIGS.signUp.fields[0],
					{
						...FORM_CONFIGS.signUp.fields[4],
						type,
						iconRightProps,
						onInputValueChange: (value: string) => setPassword(value),
					},
				]}
			>
				<CustomPasswordChecklist
					password={password}
					setIsPasswordValid={setIsPasswordValid}
					className={isSubmitPressed && !isPasswordValid ? 'passError' : ''}
				/>
				<Button
					fullWidth
					variant="primary"
					type="submit"
					iconName="peopleSign"
					onClick={() => setIsSubmitPressed(true)}
					disabled={inProgress}
				>
					{t('signUp')}
				</Button>
			</Form>
		</>
	);
};

export default FormInvite;