import React from 'react';
import { components } from 'react-select';

import clsx from 'clsx';
import { StyledOptionRow } from '../styled';
import Icon from '../../../atoms/Icon/Icon';

interface IProps {
	data: {
		iconName?: string;
		color?: string;
		className?: string;
	};
	getOptionBeforeTextComponent?: (props: unknown) => React.ReactNode;
	isDisabled: boolean;
	isSelected: boolean;
	onOptionClick: (props: unknown) => (e: React.MouseEvent) => void;
	selectProps: {
		getOptionBeforeTextComponent?: (props: unknown) => React.ReactNode;
		optionIconColor?: string;
		optionIconKey?: string;
		onOptionClick?: (props: unknown) => (e: React.MouseEvent) => void;
	};
}

const Option = (props: IProps) => {
	const {
		data, isDisabled, isSelected, selectProps,
	} = props;

	const {
		getOptionBeforeTextComponent, onOptionClick, optionIconColor, optionIconKey,
	} = selectProps;
	// @ts-ignore
	const optionIcon = data.iconName || (optionIconKey && data[optionIconKey]);
	const iconClassName = `${data.className} ${data.iconName}`;

	return (
		<StyledOptionRow
			onClick={onOptionClick && onOptionClick(props)}
			className={clsx(optionIcon ? 'withLeftIcon' : '', isSelected && 'selected')}
		>
			{(optionIcon || data.color) && (
				<Icon
					name={optionIcon}
					color={data.color}
					height={16}
					width={16}
					fill={optionIconColor}
					className={clsx(iconClassName, isDisabled && 'isOptionDisabled', 'optionLeftIcon')}
				/>
			)}
			{getOptionBeforeTextComponent && getOptionBeforeTextComponent(props)}
			{/* @ts-ignore */}
			<components.Option {...props} />
		</StyledOptionRow>
	);
};

export default Option;