import React from 'react';
import { useTheme } from 'styled-components';
import ReactTexty from '../../lib/Texty';

import Label from '../../atomic-design-components/atoms/Label/Label';
import Typography, { ITypographyProps } from '../../atomic-design-components/atoms/Typography/Typography';
import Userpic from '../../atomic-design-components/atoms/Userpic/Userpic';

const NameWithAvatar = ({
	avatarSrc,
	avatarSize,
	label,
	name,
	onlineState,
	typographyVariant,
	withoutAvatar,
	iconWidth,
	iconHeight,
	underText,
}: {
	avatarSrc?: string;
	avatarSize?: number;
	label?: string;
	name: string;
	onlineState?: string;
	typographyVariant?: ITypographyProps['variant']
	withoutAvatar?: boolean;
	iconWidth?: number;
	iconHeight?: number;
	underText?: string;
}) => {
	const theme = useTheme();
	return (
		<>
			{!withoutAvatar && name && (
				<Userpic
					height={avatarSize || 36}
					src={avatarSrc}
					onlineState={onlineState}
					width={avatarSize || 36}
					iconWidth={iconWidth || 14}
					iconHeight={iconHeight || 14}
				/>
			)}
			<Typography
				component="div"
				variant={typographyVariant || 'caption1'}
				margin={withoutAvatar ? undefined : '0 0 0 13px'}
				className="name"
				as={ReactTexty}
			>
				{name || '-'}
				{underText && (
					<Label
						color={theme.color.general.dark}
						margin={withoutAvatar ? undefined : '0'}
						text={underText}
						as={ReactTexty}
					/>
				)}
			</Typography>
			{label && (
				<Label
					color={theme.color.general.dark}
					margin={withoutAvatar ? undefined : '0 0 0 13px'}
					text={label}
					as={ReactTexty}
				/>
			)}
		</>
	);
};

export default NameWithAvatar;