import queryString from 'query-string';
import getTableColumns from '../config/columns';
import { SEARCH_FIELDS } from '../constants';

export const getApiSortString = (sortQuery: string, type: string) => {
	const sortArrOfAllSortParams = sortQuery.split(','); // ['name_asc', 'id_desc']

	return `sort=${sortArrOfAllSortParams.map((pair) => {
		const urlSortKey = pair.split('-')[0];
		let apiSortKey: string = '';
		if (getTableColumns !== undefined) {
			apiSortKey = getTableColumns(type, () => '')?.find((col: { key: string }) => col.key === urlSortKey)?.dataKey || '';
		}
		return pair.replace((urlSortKey) as string, apiSortKey);
	})}`;
};

export const getSearchValueApiQuery = (
	searchValue: string,
	type: string,
	searchFields?: string[] | null,
) => {
	// @ts-ignore
	const tableSearchField = searchFields || SEARCH_FIELDS[type];

	return [`searchValue=${searchValue}`, `searchFields=${tableSearchField || 'full_name'}`];
};

export const mapUrlToParams = (
	type: string,
	search: string,
	searchFields: string[] | null,
	sortString: string,
) => {
	let apiQueryPairs: string[];
	let apiQuery: { key: string, value: string }[] = [];
	const searchParsed = queryString.parse(search);

	if (searchParsed?.searchValue && typeof searchParsed.searchValue === 'string') {
		apiQueryPairs = getSearchValueApiQuery(searchParsed.searchValue, type, searchFields);
	} else {
		apiQueryPairs = [];
	}

	if (sortString || searchParsed?.sort) {
		// @ts-ignore
		const apiSortString = getApiSortString(sortString || searchParsed.sort, type);
		apiQueryPairs.push(apiSortString);
	}

	if (apiQueryPairs.length > 0) {
		apiQuery = apiQueryPairs.map((pair) => {
			const pairSplit = pair.split('=');
			return {
				key: pairSplit[0], value: pairSplit[1],
			} as { key: string, value: string };
		});
	}

	return { apiQuery: apiQuery || [] };
};