import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const arrowSync = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 17} height={height || 16} viewBox="0 0 17 16" fill="none">
			<path
				d="M13.0713 10.2853L15.357 9.71387L15.9284 11.9996"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15.3566 9.71387C14.849 11.1523 13.9269 12.4082 12.7066 13.3235C11.4863 14.2387 10.0224 14.7722 8.49943 14.8567C7.09156 14.857 5.71773 14.4239 4.56455 13.6162C3.41137 12.8086 2.53473 11.6656 2.05371 10.3424"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.92843 5.71429L1.64272 6.28571L1.07129 4"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.64258 6.28544C2.60258 3.65686 5.55115 1.14258 8.49972 1.14258C9.91445 1.14655 11.2933 1.58801 12.4473 2.40644C13.6013 3.22486 14.4739 4.38018 14.9454 5.71401"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};