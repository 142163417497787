import React from 'react';
import { IPropsSvgIcon } from '../../types';

export const books = ({
	width, height,
}: IPropsSvgIcon) => (
	<svg width={width || '200'} height={height || '200'} viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1399_200707)">
			<circle cx="100" cy="100" r="100" fill="#F5F5F5" />
			<g clipPath="url(#clip1_1399_200707)">
				<path d="M143.074 70.6208H56.9256C53.1773 71.6589 50 75.1074 50 79.1744V130.887C50 135.768 53.9934 139.761 58.8744 139.761H91.6699C92.7779 139.761 93.7377 140.477 94.1168 141.518C94.99 143.916 97.2889 145.628 99.9885 145.628C102.688 145.628 104.987 143.916 105.86 141.518C106.239 140.477 107.199 139.761 108.307 139.761H141.126C146.007 139.761 150 135.768 150 130.887V79.1744C150 75.1074 146.823 71.6589 143.074 70.6208Z" fill="#9D9D9D" />
				<path d="M139.648 64.3986H110.42C111.447 66.2823 112.033 68.4409 112.033 70.7376C112.033 74.9091 110.107 78.6302 107.096 81.0615C105.856 82.062 105.142 83.5736 105.142 85.1661V87.9335H105.786C106.479 87.9335 107.04 88.495 107.04 89.1876V92.1704C107.04 92.8632 106.479 93.4247 105.786 93.4247H104.66V95.6435C104.66 97.3833 103.249 98.7937 101.509 98.7937H99.7861V129.301C99.7861 131.416 101.703 133.131 103.818 133.131H139.648C141.763 133.131 143.478 131.416 143.478 129.301V68.229C143.478 66.1136 141.763 64.3986 139.648 64.3986Z" fill="white" />
				<path d="M98.453 98.7935C96.7132 98.7935 95.3028 97.3832 95.3028 95.6435V93.4248H94.1765C93.4837 93.4248 92.9222 92.8633 92.9222 92.1705V89.1877C92.9222 88.4951 93.4837 87.9336 94.1765 87.9336H94.8204V85.1679C94.8204 83.5502 94.0655 82.0402 92.8122 81.0174C89.6853 78.4654 87.744 74.5156 87.9433 70.1207C88.0368 68.0588 88.6118 66.116 89.5538 64.3988H60.3522C58.2368 64.3988 56.522 66.1136 56.522 68.2293V129.301C56.522 131.416 58.2368 133.131 60.3522 133.131H96.1583C98.2737 133.131 99.9886 131.416 99.9886 129.301V98.7935H98.453Z" fill="white" />
				<path d="M99.3546 57.4885C92.5564 57.8013 87.04 63.322 86.7318 70.1205C86.5326 74.5154 88.4738 78.4652 91.6007 81.0172C92.854 82.04 93.6089 83.5502 93.6089 85.1677V88.7412H106.354V85.1662C106.354 83.5734 107.069 82.0619 108.308 81.0615C111.319 78.6302 113.245 74.9093 113.245 70.7377C113.245 63.2045 106.965 57.1381 99.3546 57.4885Z" fill="#DADADA" />
				<path d="M99.9814 90.3848C99.1449 90.3848 98.4668 89.7069 98.4668 88.8701V79.527C98.4668 78.6905 99.1449 78.0123 99.9814 78.0123C100.818 78.0123 101.496 78.6903 101.496 79.527V88.8701C101.496 89.7069 100.818 90.3848 99.9814 90.3848Z" fill="white" />
				<path d="M99.9815 81.0416C97.5765 81.0416 95.6196 79.0849 95.6196 76.6797C95.6196 74.2746 97.5763 72.3177 99.9815 72.3177C102.387 72.3177 104.343 74.2744 104.343 76.6797C104.343 79.0849 102.387 81.0416 99.9815 81.0416ZM99.9815 75.347C99.2466 75.347 98.6487 75.9449 98.6487 76.6799C98.6487 77.4146 99.2466 78.0127 99.9815 78.0127C100.717 78.0127 101.314 77.4148 101.314 76.6799C101.314 75.9449 100.717 75.347 99.9815 75.347Z" fill="white" />
				<path d="M94.0913 92.819V96.2494C94.0913 97.9893 95.5017 99.3996 97.2415 99.3996H102.722C104.461 99.3996 105.872 97.9893 105.872 96.2494V92.8192H94.0913V92.819Z" fill="#9D9D9D" />
				<path d="M134.489 122.222H109.217C108.381 122.222 107.703 121.544 107.703 120.707C107.703 119.871 108.381 119.192 109.217 119.192H134.489C135.325 119.192 136.003 119.87 136.003 120.707C136.003 121.544 135.325 122.222 134.489 122.222Z" fill="#DADADA" />
				<path d="M78.2553 77.65H65.5117C64.6752 77.65 63.9971 76.9721 63.9971 76.1354C63.9971 75.2989 64.6752 74.6207 65.5117 74.6207H78.2553C79.0918 74.6207 79.7699 75.2987 79.7699 76.1354C79.7699 76.9721 79.0918 77.65 78.2553 77.65Z" fill="#DADADA" />
				<path d="M78.2553 84.0173H65.5117C64.6752 84.0173 63.9971 83.3394 63.9971 82.5027C63.9971 81.666 64.6752 80.988 65.5117 80.988H78.2553C79.0918 80.988 79.7699 81.666 79.7699 82.5027C79.7699 83.3394 79.0918 84.0173 78.2553 84.0173Z" fill="#DADADA" />
				<path d="M78.2553 90.3848H65.5117C64.6752 90.3848 63.9971 89.7068 63.9971 88.8701C63.9971 88.0336 64.6752 87.3555 65.5117 87.3555H78.2553C79.0918 87.3555 79.7699 88.0334 79.7699 88.8701C79.7699 89.7068 79.0918 90.3848 78.2553 90.3848Z" fill="#DADADA" />
				<path d="M84.2672 96.7521H65.5117C64.6752 96.7521 63.9971 96.0741 63.9971 95.2374C63.9971 94.4007 64.6752 93.7228 65.5117 93.7228H84.2672C85.1037 93.7228 85.7818 94.4007 85.7818 95.2374C85.7818 96.0741 85.1035 96.7521 84.2672 96.7521Z" fill="#DADADA" />
				<path d="M87.2252 103.119H65.5117C64.6752 103.119 63.9971 102.441 63.9971 101.605C63.9971 100.768 64.6752 100.09 65.5117 100.09H87.2252C88.0617 100.09 88.7398 100.768 88.7398 101.605C88.7396 102.441 88.0617 103.119 87.2252 103.119Z" fill="#DADADA" />
				<path d="M90.7828 109.487H65.5117C64.6752 109.487 63.9971 108.809 63.9971 107.972C63.9971 107.136 64.6752 106.458 65.5117 106.458H90.7828C91.6193 106.458 92.2975 107.135 92.2975 107.972C92.2975 108.809 91.6193 109.487 90.7828 109.487Z" fill="#DADADA" />
				<path d="M90.7828 115.854H65.5117C64.6752 115.854 63.9971 115.176 63.9971 114.34C63.9971 113.503 64.6752 112.825 65.5117 112.825H90.7828C91.6193 112.825 92.2975 113.503 92.2975 114.34C92.2975 115.176 91.6193 115.854 90.7828 115.854Z" fill="#DADADA" />
				<path d="M90.7828 122.222H65.5117C64.6752 122.222 63.9971 121.544 63.9971 120.707C63.9971 119.871 64.6752 119.192 65.5117 119.192H90.7828C91.6193 119.192 92.2975 119.87 92.2975 120.707C92.2975 121.544 91.6193 122.222 90.7828 122.222Z" fill="#DADADA" />
				<path d="M134.489 77.65H121.745C120.909 77.65 120.23 76.9721 120.23 76.1354C120.23 75.2989 120.909 74.6207 121.745 74.6207H134.489C135.325 74.6207 136.004 75.2987 136.004 76.1354C136.003 76.9721 135.325 77.65 134.489 77.65Z" fill="#DADADA" />
				<path d="M134.489 84.0173H121.745C120.909 84.0173 120.23 83.3394 120.23 82.5027C120.23 81.666 120.909 80.988 121.745 80.988H134.489C135.325 80.988 136.004 81.666 136.004 82.5027C136.004 83.3394 135.325 84.0173 134.489 84.0173Z" fill="#DADADA" />
				<path d="M134.489 90.3848H121.745C120.909 90.3848 120.23 89.7068 120.23 88.8701C120.23 88.0336 120.909 87.3555 121.745 87.3555H134.489C135.325 87.3555 136.004 88.0334 136.004 88.8701C136.003 89.7068 135.325 90.3848 134.489 90.3848Z" fill="#DADADA" />
				<path d="M134.489 96.7521H115.71C114.873 96.7521 114.195 96.0741 114.195 95.2374C114.195 94.4007 114.873 93.7228 115.71 93.7228H134.489C135.325 93.7228 136.003 94.4007 136.003 95.2374C136.003 96.0741 135.325 96.7521 134.489 96.7521Z" fill="#DADADA" />
				<path d="M134.489 103.119H112.752C111.915 103.119 111.237 102.441 111.237 101.605C111.237 100.768 111.915 100.09 112.752 100.09H134.489C135.325 100.09 136.003 100.768 136.003 101.605C136.003 102.441 135.325 103.119 134.489 103.119Z" fill="#DADADA" />
				<path d="M134.489 109.487H109.217C108.381 109.487 107.703 108.809 107.703 107.972C107.703 107.136 108.381 106.458 109.217 106.458H134.489C135.325 106.458 136.003 107.135 136.003 107.972C136.003 108.809 135.325 109.487 134.489 109.487Z" fill="#DADADA" />
				<path d="M134.489 115.854H109.217C108.381 115.854 107.703 115.176 107.703 114.34C107.703 113.503 108.381 112.825 109.217 112.825H134.489C135.325 112.825 136.003 113.503 136.003 114.34C136.003 115.176 135.325 115.854 134.489 115.854Z" fill="#DADADA" />
				<path d="M81.9383 71.6351H77.7549C76.9184 71.6351 76.2402 70.9572 76.2402 70.1205C76.2402 69.2838 76.9184 68.6058 77.7549 68.6058H81.9383C82.7748 68.6058 83.4529 69.2838 83.4529 70.1205C83.4529 70.9572 82.7748 71.6351 81.9383 71.6351Z" fill="#9D9D9D" />
				<path d="M122.222 71.6351H118.039C117.202 71.6351 116.524 70.9572 116.524 70.1205C116.524 69.2838 117.202 68.6058 118.039 68.6058H122.222C123.058 68.6058 123.737 69.2838 123.737 70.1205C123.737 70.9572 123.058 71.6351 122.222 71.6351Z" fill="#9D9D9D" />
				<path d="M83.1957 86.9131C82.6041 86.3215 82.6041 85.3627 83.1957 84.7711L86.1537 81.8129C86.7451 81.2213 87.7041 81.2215 88.2957 81.8127C88.8873 82.4043 88.8873 83.3631 88.2957 83.9547L85.3376 86.9129C84.7462 87.5045 83.7873 87.5045 83.1957 86.9131Z" fill="#9D9D9D" />
				<path d="M111.681 58.4279C111.089 57.8365 111.089 56.8777 111.681 56.2859L114.639 53.3279C115.231 52.7365 116.19 52.7366 116.781 53.3281C117.373 53.9195 117.373 54.8782 116.781 55.47L113.823 58.4281C113.231 59.0195 112.272 59.0195 111.681 58.4279Z" fill="#9D9D9D" />
				<path d="M99.9883 53.5847C99.1518 53.5847 98.4736 52.9068 98.4736 52.0701V47.8871C98.4736 47.0506 99.1518 46.3724 99.9883 46.3724C100.825 46.3724 101.503 47.0504 101.503 47.8871V52.0703C101.503 52.9068 100.825 53.5847 99.9883 53.5847Z" fill="#9D9D9D" />
				<path d="M114.639 86.9129L111.681 83.9547C111.089 83.3633 111.089 82.4045 111.681 81.8127C112.273 81.2215 113.232 81.2213 113.823 81.8129L116.781 84.7711C117.373 85.3625 117.373 86.3213 116.781 86.9131C116.189 87.5045 115.231 87.5047 114.639 86.9129Z" fill="#9D9D9D" />
				<path d="M86.1541 58.4281L83.1961 55.47C82.6045 54.8784 82.6045 53.9197 83.1961 53.3281C83.7875 52.7365 84.7465 52.7366 85.3381 53.3279L88.2961 56.2859C88.8877 56.8775 88.8877 57.8363 88.2961 58.4279C87.7045 59.0195 86.7457 59.0195 86.1541 58.4281Z" fill="#9D9D9D" />
				<path d="M106.998 87.9336H92.965C92.2725 87.9336 91.7109 88.4951 91.7109 89.1879V92.1709C91.7109 92.8635 92.2725 93.425 92.965 93.425H106.998C107.691 93.425 108.253 92.8635 108.253 92.1709V89.1879C108.252 88.4951 107.691 87.9336 106.998 87.9336Z" fill="#5E5E5E" />
			</g>
		</g>
		<defs>
			<clipPath id="clip0_1399_200707">
				<rect width={width || '200'} height={height || '200'} fill="white" />
			</clipPath>
			<clipPath id="clip1_1399_200707">
				<rect width={width || '200'} height={height || '200'} fill="white" transform="translate(50 46)" />
			</clipPath>
		</defs>
	</svg>
);