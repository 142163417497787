import {
	SIGN_IN_SUCCESS,
	SIGN_OUT,
	SIGN_UP_SUCCESS,
	CONFIRM_EMAIL_SUCCESS,
	GET_CURRENT_USER_SUCCESS,
	INITIALIZE_APP,
	SIGN_IN_ERROR,
	GET_CURRENT_USER_ERROR,
	GET_INVITE_DETAILS_SUCCESS,
	TOGGLE_IS_USER_ONLINE,
	RESET_PASSWORD,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_ERROR,
	SET_USER_AVATAR,
	GET_CURRENT_SUBSCRIPTION_SUCCESS,
	CANCEL_SUBSCRIPTION_SUCCESS,
	SIGN_UP_ERROR,
	SIGN_IN,
	SIGN_UP,
} from '../constants';
import { IAction, IUserState, LocalStorageKeys } from '../../types';

const defaultState: IUserState = {
	token: null,
	details: {
		email: '',
		// @ts-ignore
		role: '',
		plan_slug: '',
	},
	// @ts-ignore
	subscriptionDetails: {},
	inviteDetails: {
		email: '',
		company_name: '',
	},
	inProgress: false,
	resetPassInProgress: false,
	error: false,
	isAuthenticated: null,
	isAppInitialized: false,
	isUserOnline: localStorage.getItem(LocalStorageKeys.isUserOnline) !== '0',
	email_verified: undefined,
};

export default (state = defaultState, action = {} as IAction): IUserState => {
	switch (action?.type) {
		case INITIALIZE_APP:
			return {
				...state,
				isAppInitialized: true,
			};
		case SIGN_IN:
		case SIGN_UP:
			return {
				...state,
				inProgress: true,
				error: false,
			};
		case SIGN_IN_SUCCESS:
			return {
				...state,
				token: action.token,
				inProgress: false,
			};
		case SIGN_UP_SUCCESS:
			return {
				...state,
				token: action.token,
				details: { ...state.details, email: action.email },
				email_verified: false,
				inProgress: false,
			};
		case SIGN_UP_ERROR:
			return {
				...state,
				inProgress: false,
				error: true,
			};
		case RESET_PASSWORD:
			return {
				...state,
				resetPassInProgress: true,
				error: false,
			};
		case RESET_PASSWORD_SUCCESS:
			return {
				...state,
				resetPassInProgress: false,
			};
		case RESET_PASSWORD_ERROR:
			return {
				...state,
				resetPassInProgress: false,
				error: true,
			};
		case CONFIRM_EMAIL_SUCCESS:
			return {
				...state,
				email_verified: true,
			};
		case GET_CURRENT_USER_SUCCESS:
			return {
				...state,
				details: action.payload,
				isAuthenticated: true,
				inProgress: false,
			};
		case GET_CURRENT_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				subscriptionDetails: action.payload,
			};
		case SET_USER_AVATAR:
			return { ...state, details: { ...state.details, member_profile_picture: action.avatar } };
		case GET_INVITE_DETAILS_SUCCESS:
			return { ...state, inviteDetails: action.payload };
		case TOGGLE_IS_USER_ONLINE: {
			if (action.payload.isOnline) {
				localStorage.setItem(LocalStorageKeys.isUserOnline, '1');
			} else {
				localStorage.setItem(LocalStorageKeys.isUserOnline, '0');
			}
			return { ...state, isUserOnline: action.payload.isOnline };
		}
		case CANCEL_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				subscriptionDetails: {
					...state.subscriptionDetails,
					// @ts-ignore
					wfp_subscription_end_at: state.subscriptionDetails.subscription_expire_at,
				},
				inProgress: false,
			};
		case SIGN_IN_ERROR:
		case GET_CURRENT_USER_ERROR:
			return {
				...state, inProgress: false, error: true, isAppInitialized: true,
			};
		case SIGN_OUT:
			localStorage.removeItem(LocalStorageKeys.isUserOnline);
			return defaultState;
		default:
			return state;
	}
};