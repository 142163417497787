import styled from 'styled-components';
import { getCssFromTheme } from '../../../utils/css';

export const StyledTag = styled.span<{
	fontSize?: number;
	lineHeight?: number;
	fontWeight?: string;
	backgroundColor?: string;
	color?: string;
	borderRadius?: string;
	onClick?: () => void;
	outlineColor?: string;
	hexOpacity?: string;
}>`
  ${({ theme }) => getCssFromTheme(theme, 'tag')};

  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 3px;
  padding: 2px 7px;
  border: 1px solid transparent;
  border-radius: ${({ borderRadius }) => borderRadius || '3px'};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize}px;
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineHeight }) => lineHeight}px;
  white-space: nowrap;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'text')};

  &.selected {
    background-color: ${({ theme }) => theme.color.primary.main};
    color: ${({ theme }) => theme.color.general.white};
  }

  &.isOutlined {
    border: 1px solid ${({ outlineColor, color }) => outlineColor || color};
  }

  &.withBackgroundOpacity {
    background-color: ${({ backgroundColor, hexOpacity }) => `${backgroundColor}${hexOpacity || '40'}`};
    color: ${({ color }) => color};
  }

  .crossIcon {
    padding-left: 5px;
    cursor: pointer;

    div {
      background-color: transparent;
    }
  }
`;