import React from 'react';

import { StyledLoaderCircular } from './styled';

const LoaderCircular = ({
	color, left, size, top, borderWidth, loaderBg, position,
}: {
	color?: string;
	left?: string;
	size?: string;
	top?: string;
	borderWidth?: string;
	loaderBg?: string;
	position?: string;
}) => (
	<StyledLoaderCircular
		color={color}
		top={top}
		left={left}
		size={size}
		borderWidth={borderWidth}
		loaderBg={loaderBg}
		position={position}
		className="loader"
	>
		<div className="loaderBg" />
		<div className="spinnerHolder animate1">
			<div className="loaderSpinner1" />
		</div>
		<div className="spinnerHolder animate2">
			<div className="loaderSpinner2" />
		</div>
	</StyledLoaderCircular>
);

export default LoaderCircular;