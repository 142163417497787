import React from 'react';
import clsx from 'clsx';

import { StyledBadge, StyledBadgeWrapper } from './styled';
import capitalize from '../../../utils/capitalize';

interface IProps {
	anchorOrigin?: { vertical: 'top' | 'bottom'; horizontal: 'left' | 'right' };
	backgroundColor?: string;
	badgeContent?: React.ReactNode;
	borderColor?: string;
	children?: React.ReactNode;
	className?: string;
	color?: string;
	invisible?: boolean;
	isMini?: boolean;
	returnBadgeOnly?: boolean;
	variant?: 'primary' | 'general';
}

const Badge = ({
	anchorOrigin,
	backgroundColor,
	badgeContent,
	borderColor,
	children,
	className,
	color,
	invisible,
	variant = 'primary',
	isMini,
	returnBadgeOnly,
	...otherProps
}: IProps) => {
	const vertical = anchorOrigin?.vertical || 'top';
	const horizontal = anchorOrigin?.horizontal || 'right';

	if (returnBadgeOnly && !invisible) {
		return (
			<StyledBadge
				className={clsx(
					invisible ? 'invisible' : null,
					'badgeOnly',
					className,
				)}
				variant={variant}
				color={color}
				backgroundColor={backgroundColor}
				borderColor={borderColor}
			>
				{badgeContent}
			</StyledBadge>
		);
	}

	return (
		<StyledBadgeWrapper className={clsx(className, isMini && 'isMini', invisible && 'invisible')} {...otherProps}>
			{children}
			<StyledBadge
				className={clsx(`anchor${capitalize(vertical)}${capitalize(horizontal)}`, invisible && 'invisible')}
				variant={variant}
				color={color}
				backgroundColor={backgroundColor}
				borderColor={borderColor}
			>
				{badgeContent}
			</StyledBadge>
		</StyledBadgeWrapper>
	);
};

export default Badge;