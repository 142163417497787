import React from 'react';

export const pendingInactive = () => (
	<svg width="17" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#a)" stroke="#E83C0C" strokeWidth="1.1429" strokeLinecap="round" strokeLinejoin="round">
			<path d="M8.4999 15.4284c4.1026 0 7.4285-3.3259 7.4285-7.4285 0-4.1027-3.3259-7.4286-7.4285-7.4286-4.1027 0-7.4286 3.3259-7.4286 7.4286 0 4.1026 3.3259 7.4285 7.4286 7.4285Z" fill="#E83C0C" fillOpacity=".25" />
			<path d="M6.7852 5.1426v5.7143M10.2139 5.1426v5.7143" />
		</g>
		<defs><clipPath id="a"><path fill="#fff" transform="translate(.5)" d="M0 0h16v16H0z" /></clipPath></defs>
	</svg>
);