import React from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from 'styled-components';
import { useWidth } from '../../hooks/useWidth';
import HeaderIntroScreen from './components/HeaderIntroScreen';

import {
	StyledFooterText, StyledWindow, StyledWrapper, StyledIntroWrapper,
} from './styled';
import Typography from '../../atomic-design-components/atoms/Typography/Typography';
import PopupAlertsComponent from '../../wrappers/PopupAlertsComponent';
import LanguageSelect from '../LanguageSelect/LanguageSelect';

const IntroScreenWrapper = ({
	children, text, type, imageUrl,
}: {
	children: React.ReactNode;
	text?: string;
	type: string;
	imageUrl: string;
}) => {
	const { t } = useTranslation('auth');
	const theme = useTheme();
	const screenWidth = useWidth();
	const isMobile = screenWidth && screenWidth < theme.breakpoints.sm;
	const isTablet = screenWidth && screenWidth < theme.breakpoints.lg;
	const isNearTablet = screenWidth && screenWidth < 1140;
	const isNearMobile = screenWidth && screenWidth < 900;

	return (
		<StyledWrapper className={`${type}Wrapper`}>
			{!isTablet && (
				<StyledIntroWrapper className="introWrapper" url={imageUrl} color={theme.color.general.white}>
					<HeaderIntroScreen />
					{!isNearMobile && (
						<div className="footerText">
							<Typography
								variant="h2"
								textAlign="center"
								margin="15px 0"
								padding="0 30px"
								text={t('firstPageText')}
							/>
							<Typography variant="body1" textAlign="center" padding="0 80px" text={t('firstPageText1')} />
						</div>
					)}
				</StyledIntroWrapper>
			)}
			<div className="mainWrapper">
				<PopupAlertsComponent />
				{isTablet && <HeaderIntroScreen />}
				<StyledWindow className={type}>
					{isNearTablet && !isTablet && (
						<LanguageSelect isIntroScreensSelect width="204px" />
					)}
					<div className="contentWrapper">
						<Typography
							variant={(isMobile && 'h2') || 'h1'}
							className="text"
							textAlign="left"
							text={t(text || type)}
							margin="0"
						/>
						{children}
					</div>
				</StyledWindow>
				<StyledFooterText>
					<Typography variant="body2" className="footerText">
						{`Skibble ${new Date().getFullYear()} © ${t('footerText')}`}
					</Typography>
				</StyledFooterText>
			</div>
		</StyledWrapper>
	);
};

export default IntroScreenWrapper;