import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import ReactHelmet from '../../wrappers/ReactHelmet';

import Icon from '../../atomic-design-components/atoms/Icon/Icon';
import Typography from '../../atomic-design-components/atoms/Typography/Typography';
import IntroScreenWrapper from '../../components/IntroScreens/IntroScreenWrapper';
import backOrange from '../../assets/images/backOrange.jpg';

const PasswordRecoverySent = () => {
	const theme = useTheme();
	const { t } = useTranslation('auth');

	return (
		<>
			<ReactHelmet title="signTitle" />

			<IntroScreenWrapper
				type="passwordRecoverySent"
				imageUrl={backOrange}
				text={t('checkYourMailbox')}
			>
				<Icon name="checkMailbox" margin="30px 0 0" />
				<div className="inscriptionWrapper">
					<Typography
						variant="body2"
						color={theme.color.general.black}
						margin="20px 0 0 0"
						className="inline"
						text={t('passRecoverSentText')}
					/>
				</div>
			</IntroScreenWrapper>
		</>
	);
};

export default PasswordRecoverySent;