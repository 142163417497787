import { components } from 'react-select';
import React from 'react';
import { useTheme } from 'styled-components';
import Icon from '../../../atoms/Icon/Icon';

const ClearIndicator = ({ ...props }: {
	selectProps: {
		isDisabled: boolean;
		error: boolean;
	}
}) => {
	const { isDisabled, error } = props.selectProps;
	const theme = useTheme();
	const errorColor = error
		? theme.color.status.error
		: theme.color.general.black;

	return (
		// @ts-ignore
		<components.ClearIndicator {...props}>
			<Icon
				width={12}
				height={12}
				name="cross"
				className="crossIcon"
				fill={isDisabled
					? theme.color.general.light
					: errorColor}
			/>
		</components.ClearIndicator>
	);
};

export default ClearIndicator;