import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const noChats = ({
	width, height,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 200} height={height || 200} fill="none" viewBox="0 0 200 200">
			<g clipPath="url(#clip0_10742_225267)">
				<circle cx="100.504" cy="100" r="100" fill={theme.color.general.lightest} />
				<path
					d="M145.5 82C155.441 82 163.5 73.9411 163.5 64C163.5 54.0589 155.441 46 145.5 46C135.559 46 127.5 54.0589 127.5 64C127.5 73.9411 135.559 82 145.5 82Z"
					fill={theme.color.general.light}
				/>
				<path
					d="M145.498 72C144.201 71.995 143.084 71.6756 142.149 71.042C141.219 70.4083 140.502 69.4905 139.999 68.2885C139.501 67.0866 139.255 65.6407 139.26 63.951C139.26 62.2662 139.509 60.8304 140.007 59.6436C140.51 58.4567 141.226 57.554 142.157 56.9354C143.092 56.3118 144.206 56 145.498 56C146.791 56 147.902 56.3118 148.833 56.9354C149.768 57.559 150.487 58.4642 150.99 59.6511C151.493 60.8329 151.742 62.2662 151.737 63.951C151.737 65.6458 151.486 67.0941 150.983 68.2961C150.485 69.498 149.771 70.4158 148.84 71.0495C147.91 71.6832 146.796 72 145.498 72ZM145.498 69.2918C146.384 69.2918 147.09 68.8468 147.618 67.9566C148.146 67.0665 148.408 65.7313 148.403 63.951C148.403 62.7792 148.282 61.8036 148.041 61.024C147.804 60.2445 147.467 59.6587 147.03 59.2664C146.597 58.8741 146.087 58.678 145.498 58.678C144.618 58.678 143.914 59.118 143.386 59.9981C142.858 60.8782 142.592 62.1958 142.587 63.951C142.587 65.1378 142.705 66.1286 142.941 66.9231C143.183 67.7127 143.522 68.3061 143.96 68.7034C144.397 69.0957 144.91 69.2918 145.498 69.2918Z"
					fill={theme.color.general.white}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M123.505 63.514H58.1922C53.9609 63.514 50.5 66.975 50.5 71.2062V125.053C50.5 129.284 53.9609 132.745 58.1922 132.745H98.5766L122.423 155.514V132.744H137.808C142.039 132.744 145.5 129.283 145.5 125.052L145.5 86C133.35 86 123.5 76.1502 123.5 64C123.5 63.8376 123.502 63.6756 123.505 63.514Z"
					fill={theme.color.general.lighter}
				/>
			</g>
			<defs>
				<clipPath id="clip0_10742_225267">
					<rect width="200" height="200" fill={theme.color.general.white} transform="translate(0.5)" />
				</clipPath>
			</defs>
		</svg>
	);
};