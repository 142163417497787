import clsx from 'clsx';
import React from 'react';

import Icons from '../../../assets/icons';
import Color from '../Color/Color';
import { IconWrapper } from './styled';
import { NameTypeIcon } from '../../../types';

export interface IIconProps {
	alignItems?: 'start' | 'end' | 'center' | 'stretch';
	borderRadius?: string;
	className?: string;
	color?: string;
	iconName?: string;
	opened?: boolean;
	id?: string;
	opacity?: string;
	dataTooltip?: string;
	strokeWidth?: string;
	disabled?: boolean;
	fill?: string;
	fillColor?: string;
	checkboxColor?: string;
	height?: number;
	margin?: string;
	justifyContent?: 'start' | 'end' | 'center' | 'stretch' | 'space-around' | 'space-between' | 'space-evenly';
	name?: string | { id: string; slug: string };
	onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	onTouchEnd?: () => void;
	onMouseDown?: () => void;
	padding?: string;
	size?: number;
	stroke?: string;
	style?: React.CSSProperties;
	title?: string;
	width?: number;
	withoutWrapper?: boolean;
	wrapperColor?: string;
	wrapperHeight?: number | string;
	wrapperWidth?: number | string;
}

const Icon = ({
	alignItems = 'center',
	borderRadius,
	className,
	color,
	dataTooltip,
	disabled,
	fill = '',
	height,
	margin,
	justifyContent = 'center',
	name = '',
	onClick,
	padding,
	size,
	stroke,
	style,
	title,
	width,
	withoutWrapper,
	wrapperColor,
	wrapperHeight,
	wrapperWidth,
	...otherProps
}: IIconProps) => {
	// @ts-ignore
	const iconName: NameTypeIcon = typeof name === 'string' ? name : name?.id || name?.slug;
	const IconComponent = Icons[iconName];

	if (!IconComponent && !color) {
		return null;
	}

	if (color) {
		return <Color color={color} size={size} className={clsx(className, 'icon')} />;
	}

	if (withoutWrapper) {
		return (
			<IconComponent
				width={width}
				height={height}
				fill={fill}
				stroke={stroke}
				wrapperColor={wrapperColor}
				{...otherProps}
			/>
		);
	}

	return (
		<IconWrapper
			data-tooltip={dataTooltip}
			title={title}
			width={wrapperWidth}
			height={wrapperHeight}
			margin={margin}
			padding={padding}
			size={size}
			className={clsx(className, 'icon', disabled && 'disabled')}
			onClick={(disabled || !onClick) ? undefined : (e) => onClick(e)}
			wrapperColor={wrapperColor}
			borderRadius={borderRadius}
			style={style}
			justifyContent={justifyContent}
			alignItems={alignItems}
			onTouchEnd={otherProps.onTouchEnd}
			onMouseDown={otherProps.onMouseDown}
		>
			<IconComponent
				width={width || size}
				height={height || size}
				fill={fill}
				stroke={stroke}
				wrapperColor={wrapperColor}
				{...otherProps}
			/>
		</IconWrapper>
	);
};

export default Icon;