import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTheme } from 'styled-components';

import { StyledSwitch, StyledSwitchLabel } from './styled';
import Typography, { ITypographyProps } from '../../atoms/Typography/Typography';

const Switch = ({
	checked = false,
	disabled,
	height,
	gap,
	id,
	width,
	handleDiameter,
	justifyContent,
	label,
	labelColor,
	leftLabel,
	margin,
	name,
	onChange,
	readOnly,
	className,
	textVariant,
	revert,
}: {
	checked?: boolean;
	disabled?: boolean;
	height?: number;
	gap?: string;
	id?: string;
	width?: number;
	handleDiameter?: number;
	justifyContent?: string;
	label?: string;
	labelColor?: string;
	leftLabel?: string;
	margin?: string;
	name?: string;
	onChange?: (checked: boolean, event: {
		target: {
			name: string
		}
	}) => void;
	readOnly?: boolean;
	className?: string;
	textVariant?: ITypographyProps['variant'];
	revert?: boolean;
}) => {
	const theme = useTheme();

	const [stateChecked, setStateChecked] = useState(checked || false);

	const onClick = () => {
		setStateChecked((prev) => !prev);
		if (onChange) onChange(!stateChecked, { target: { name: name || '' } });
	};

	useEffect(() => {
		setStateChecked(checked);
	}, [checked]);

	return (
		<StyledSwitchLabel
			htmlFor={id || 'switch'}
			margin={margin}
			className={clsx(className, disabled && 'disabled', revert && 'revert')}
			justifyContent={justifyContent}
			gap={gap}
		>
			{leftLabel && <Typography text={leftLabel} variant={textVariant || 'body2'} color={labelColor} />}
			<StyledSwitch
				name={name}
				checked={stateChecked}
				onChange={onClick}
				disabled={disabled}
				readOnly={readOnly}
				uncheckedIcon={false}
				checkedIcon={false}
				offColor={theme.color.general.lighter}
				onColor={theme.color.secondary.main}
				offHandleColor={theme.color.general.white}
				onHandleColor={theme.color.general.white}
				handleDiameter={handleDiameter || 20}
				height={height || 24}
				width={width || 40}
				className="switch"
				id={`${id} switch`}
			/>
			{label && <Typography text={label} variant={textVariant} color={labelColor} />}
		</StyledSwitchLabel>
	);
};

export default Switch;