import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const members = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 20} height={height || 20} viewBox="0 0 20 20" fill="none">
			<path
				d="M9.99986 11.3708C11.8934 11.3708 13.4284 9.83579 13.4284 7.94224C13.4284 6.0487 11.8934 4.51367 9.99986 4.51367C8.10631 4.51367 6.57129 6.0487 6.57129 7.94224C6.57129 9.83579 8.10631 11.3708 9.99986 11.3708Z"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.5398 15.1435C13.2867 14.0366 11.6723 13.4258 10.0004 13.4258C8.32842 13.4258 6.71407 14.0366 5.46094 15.1435"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M18.9141 14.8008V17.5436C18.9141 17.9074 18.7696 18.2562 18.5124 18.5134C18.2552 18.7706 17.9064 18.9151 17.5427 18.9151H14.7998"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.7998 1.08594H17.5427C17.9064 1.08594 18.2552 1.23043 18.5124 1.48762C18.7696 1.74481 18.9141 2.09364 18.9141 2.45737V5.20022"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.08594 5.20022V2.45737C1.08594 2.09364 1.23043 1.74481 1.48762 1.48762C1.74481 1.23043 2.09364 1.08594 2.45737 1.08594H5.20022"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.20022 18.9151H2.45737C2.09364 18.9151 1.74481 18.7706 1.48762 18.5134C1.23043 18.2562 1.08594 17.9074 1.08594 17.5436V14.8008"
				stroke={fill || theme.color.general.white}
				strokeWidth="1"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};