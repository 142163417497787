import { IApiSchema, IKnowledgeBaseUpdatePositionServerRequest } from '../types';

export const sortedKnowledgeBase = (
	knowledgeBaseFromStore: {
		folders: IApiSchema['FolderSchema'][];
		articles: IApiSchema['ArticleSchema'][];
	},
	request: IKnowledgeBaseUpdatePositionServerRequest,
) => {
	const getArticlePositionById = (id: number) => (
		request.articles
			.find((item) => item.id === id)?.position || 0
	);

	const getFolderPositionById = (id: number) => (
		request.folders
			.find((item) => item.id === id)?.position || 0
	);

	const sortArticles = (a: { id: number }, b: { id: number }) => (
		getArticlePositionById(a.id) - getArticlePositionById(b.id)
	);

	const sortFolders = (a: { id: number }, b: { id: number }) => (
		getFolderPositionById(a.id) - getFolderPositionById(b.id)
	);

	const sortedFolders = knowledgeBaseFromStore?.folders
		.sort(sortFolders)
		.map((folder) => ({
			...folder,
			articles: folder.articles?.sort(sortArticles),
		}));

	return {
		folders: sortedFolders,
		articles: knowledgeBaseFromStore?.articles.sort(sortArticles),
	};
};