import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const statusDeleted = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 17} height={height || 16} viewBox="0 0 17 16" fill="none">
			<path
				d="M8.49986 15.4294C12.6025 15.4294 15.9284 12.1035 15.9284 8.00084C15.9284 3.89815 12.6025 0.572266 8.49986 0.572266C4.39717 0.572266 1.07129 3.89815 1.07129 8.00084C1.07129 12.1035 4.39717 15.4294 8.49986 15.4294Z"
				fill={fill || theme.color.status.error}
				fillOpacity="0.25"
				stroke={fill || theme.color.status.error}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M3.24316 13.2584L13.7575 2.74414"
				stroke={fill || theme.color.status.error}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};