import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const passwordLocked = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 17} fill="none" viewBox="0 0 16 17">
			<path
				d="M12.5714 6.28711H3.4285C2.79732 6.28711 2.28564 6.79878 2.28564 7.42997V14.2871C2.28564 14.9183 2.79732 15.43 3.4285 15.43H12.5714C13.2025 15.43 13.7142 14.9183 13.7142 14.2871V7.42997C13.7142 6.79878 13.2025 6.28711 12.5714 6.28711Z"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 6.28655V4.57227C12 3.5114 11.5786 2.49398 10.8284 1.74384C10.0783 0.993693 9.06087 0.572266 8 0.572266C6.93913 0.572266 5.92172 0.993693 5.17157 1.74384C4.42143 2.49398 4 3.5114 4 4.57227V6.28655"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.9999 11.43C8.31549 11.43 8.57132 11.1741 8.57132 10.8585C8.57132 10.5429 8.31549 10.2871 7.9999 10.2871C7.6843 10.2871 7.42847 10.5429 7.42847 10.8585C7.42847 11.1741 7.6843 11.43 7.9999 11.43Z"
				stroke={fill || theme.color.general.black}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};