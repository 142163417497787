import React from 'react';
import styled from 'styled-components';
import { IPropsSvgIcon } from '../../types';

const StyledMenuIcon = styled.div<IPropsSvgIcon>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;

  & span {
    display: block;
    width: ${({ width }) => width || 22}px;
    height: 1px;
    margin-bottom: 4px;
    background-color: ${({ fill, theme }) => fill || theme.color.primary.main};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const hamburgerMenu = ({ wrapperColor, fill, width }: IPropsSvgIcon) => (
	<StyledMenuIcon width={width} wrapperColor={wrapperColor} fill={fill}>
		<span />
		<span />
		<span />
	</StyledMenuIcon>
);