import React from 'react';
import { IPropsSvgIcon } from '../../types';

export const telegramIcon = ({ width, height, fill }: IPropsSvgIcon) => (
	<svg width={width || 20} height={height || 20} fill="none" viewBox="0 0 20 20">
		<circle cx="10" cy="10" r="10" fill={fill || '#49a9e9'} />
		<path
			d="M7.4049 11.6585L7.19812 14.5669C7.49396 14.5669 7.62209 14.4398 7.77574 14.2872L9.16276 12.9616L12.0368 15.0663C12.5639 15.3601 12.9352 15.2054 13.0774 14.5814L14.9639 5.74164L14.9645 5.74112C15.1316 4.96193 14.6827 4.65724 14.1691 4.84839L3.08032 9.09381C2.32353 9.38756 2.33498 9.80945 2.95167 10.0006L5.78663 10.8824L12.3717 6.76198C12.6816 6.55676 12.9634 6.67031 12.7316 6.87552L7.4049 11.6585Z"
			fill="white"
		/>
	</svg>
);