import React from 'react';
import { useTheme } from 'styled-components';
import { useWidth } from '../../hooks/useWidth';

import Icon from '../../atomic-design-components/atoms/Icon/Icon';
import Link from '../../atomic-design-components/atoms/Link/Link';
import { StyledLogoWrapper } from './styled';

const Logo = ({
	variant = 'medium', isExtended, isTextPage, hasMiniLogo, logoColor, to, className,
}: {
	variant: 'small' | 'medium' | 'large';
	isExtended?: boolean;
	isTextPage?: boolean;
	hasMiniLogo?: boolean;
	logoColor?: string;
	to: string;
	className?: string;
}) => {
	const theme = useTheme();
	const screenWidth = useWidth();
	const isMobile = screenWidth && screenWidth < theme.breakpoints.sm;
	const isTablet = screenWidth && screenWidth < theme.breakpoints.lg;

	const size = {
		small: {
			icon: { width: 36, height: 36 },
			text: { width: 134, height: 16 },
		},
		medium: {
			icon: { width: 44, height: 44 },
			text: { width: 162, height: 20 },
		},
		large: {
			text: { width: 184, height: 22 },
			icon: {},
		},
	};

	return (
		<StyledLogoWrapper className={className}>
			<Link noStyles to={to}>
				{hasMiniLogo ? (
					<>
						<Icon name="logo" {...size[variant].icon} className="logoIcon" />
						{isExtended && (
							<Icon
								name="skibbleTextLogo"
								className="skibbleTextLogo"
								fill={logoColor || theme.color.general.white}
								{...size[variant].text}
							/>
						)}
					</>
				) : (
					<>
						{(isMobile || isTablet || isTextPage) && <Icon name="logo" {...size[variant].icon} />}
						<Icon
							name="skibbleTextLogo"
							className="skibbleTextLogo"
							fill={((isTablet || isTextPage)
								&& theme.color.general.black)
								|| theme.color.general.white}
							{...size[variant].text}
						/>
					</>
				)}
			</Link>
		</StyledLogoWrapper>
	);
};

export default Logo;