import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const emptyFolder = ({
	width, height,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 200} height={height || 200} viewBox="0 0 200 200" fill="none">
			<g clipPath="url(#clip0_8088_85989)">
				<circle cx="100.004" cy="100" r="100" fill={theme.color.general.lightest} />
				<path
					d="M150.004 95H50.0039V67.3383C50.0039 65.6177 51.3972 64.2244 53.1178 64.2244H146.89C148.611 64.2244 150.004 65.6177 150.004 67.3383V95Z"
					fill={theme.color.general.light}
				/>
				<path
					d="M137.462 54.1072L59.557 57.1C58.0154 57.1593 56.8136 58.457 56.8729 59.9986L59.6539 132.392C59.7132 133.934 61.0109 135.136 62.5525 135.076L140.458 132.083C142 132.024 143.201 130.727 143.142 129.185L140.361 56.7914C140.302 55.2497 139.004 54.048 137.462 54.1072Z"
					fill={theme.color.general.white}
				/>
				<path
					opacity="0.2"
					d="M146.89 69.2849H118.19C116.591 69.2849 115.143 70.2194 114.482 71.6735L113.895 72.9623C113.234 74.4163 111.783 75.3508 110.187 75.3508H53.1178C51.3972 75.3542 50.0039 76.7475 50.0039 78.4681V137.127C50.0039 138.847 51.3972 140.241 53.1178 140.241H146.89C148.611 140.241 150.004 138.847 150.004 137.127V72.4022C150.004 70.6816 148.611 69.2883 146.89 69.2883V69.2849Z"
					fill={theme.color.general.darkest}
					fillOpacity="0.15"
				/>
				<path
					d="M146.89 74.3455H121.564C119.964 74.3455 118.517 75.28 117.856 76.734L117.269 78.0228C116.608 79.4769 115.157 80.4114 113.561 80.4114H53.1178C51.3972 80.4148 50.0039 81.8081 50.0039 83.5287V142.187C50.0039 143.908 51.3972 145.301 53.1178 145.301H146.89C148.611 145.301 150.004 143.908 150.004 142.187V77.4628C150.004 75.7422 148.611 74.3488 146.89 74.3488V74.3455Z"
					fill={theme.color.general.lighter}
				/>
			</g>
			<defs>
				<clipPath id="clip0_8088_85989">
					<rect width="200" height="200" fill={theme.color.general.white} />
				</clipPath>
			</defs>
		</svg>
	);
};