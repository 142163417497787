import styled from 'styled-components';

export const StyledCardContent = styled.div`
  .caption1 {
    margin-left: 10px;
  }

  .squezzyBlock {
    position: relative;
    min-width: 142px;
  }

  .button1 {
    display: flex;
  }

  .bottom.tooltip {
    right: auto;
    left: 0;
    white-space: pre-wrap;

    .tooltip-arrow {
      right: auto !important;
      left: 9% !important;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {

    .widgetName {

      .name {
        max-width: 100px;
      }

      .tag {
        max-width: 60px;
      }
    }

    .statusIcon {
      margin-left: 0;
      padding: 10px 2px;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {

    .widgetName .name {
      max-width: 90px;
    }
  }
`;

export const StyledPanel = styled.div`
  .codePanel {
    margin: 10px 0;
    border: 1px solid ${({ theme }) => theme.color.general.light};

    .content {
      display: block;
      font-size: 12px;

      [data-texty] {
        white-space: normal;
      }
    }
  }
`;

export const StyledContainer = styled.div<{
	borderColor: string;
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 5px;

  .icon {
    border: ${({ borderColor }) => `1px solid ${borderColor}`};
  }
`;

export const StyledFieldsCustomisation = styled.div`
  .row {
    display: grid;
    align-items: center;
    margin: 8px 0;
    grid-template-columns: 150px repeat(2, 1fr);
    grid-template-rows: 1fr;

    &.header {
      border-bottom: 1px solid ${({ theme }) => theme.color.general.lighter};
    }
  }

  .col2,
  .col3 {
    display: flex;
    justify-content: flex-end;
  }

  .rows {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .inputContainer {
    width: 100%;
    padding-top: 10px;

    .label:not(.error) {
      color: ${({ theme }) => theme.color.general.dark};
    }

    .inputWrapper {

      &:not(.hasError, .selectWrapper, .searchInput):focus-within:not(.hasError) {
        border: 1px solid ${({ theme }) => theme.color.general.light};
        outline: none;

        .label {
          color: ${({ theme }) => theme.color.general.dark} !important;
        }

        .icon svg {

          path {
            stroke: ${({ theme }) => theme.color.general.dark};
          }
        }
      }
    }
  }
`;