import React, { useMemo } from 'react';
import Select from 'react-select';
import {
	listTz, clientTz, findTzByName, TIMEZONES, findTzByKey,
} from './tz.helper';

const TimezoneSelect = ({
	value,
	onBlur,
	onChange,
	labelStyle = 'original',
	...props
}: {
	value: string | { label: string; value: string };
	onBlur?: () => void;
	onChange?: (tz: string) => void;
	labelStyle?: 'original' | 'offset';
}) => {
	const getOptions = useMemo(() => listTz(), [labelStyle]);

	const handleChange = (tz: string) => onChange && onChange(tz);

	// @ts-ignore
	const constructTz = (data: unknown) => (typeof data === 'string' ? findTzByName(data, getOptions) : data);

	return (
		<Select
			{...props}
			value={constructTz(value) || ''}
			// @ts-ignore
			onChange={handleChange}
			// @ts-ignore
			options={getOptions}
			onBlur={onBlur}
		/>
	);
};

export {
	clientTz, listTz, TIMEZONES, findTzByKey, findTzByName, TimezoneSelect,
};