import { components } from 'react-select';
import React from 'react';
import { useTheme } from 'styled-components';

import Icon, { IIconProps } from '../../../atoms/Icon/Icon';
import Typography from '../../../atoms/Typography/Typography';

const Control = ({ children, ...props }: {
	children: React.ReactNode;
	selectProps: {
		iconLeftProps?: IIconProps;
		iconText?: string;
		isDisabled: boolean;
		error: boolean;
		value: {
			iconName?: string;
			color?: string;
		}
	}
}) => {
	const {
		iconLeftProps, iconText, isDisabled, error,
	} = props.selectProps;
	const theme = useTheme();
	const iconName = props?.selectProps?.value?.iconName;
	const color = props?.selectProps?.value?.color;
	const errorFill = error
		? theme.color.status.error
		: theme.color.general.white;

	return (
		// @ts-ignore
		<components.Control {...props}>
			{(!!iconLeftProps || color) && (
				<>
					<Icon
						width={16}
						height={16}
						className="leftIcon"
						color={color}
						fill={
							isDisabled
								? theme.color.general.light
								: errorFill
						}
						{...iconLeftProps}
					/>
					{iconText && <Typography variant="input" text={iconText} className="iconText" />}
				</>
			)}
			{!!iconName && <Icon name={iconName} className="statusIcon" margin=" 0 0 0 15px" />}
			{children}
		</components.Control>
	);
};

export default Control;