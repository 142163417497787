import React from 'react';
import { IPropsSvgIcon } from '../../types';

export const typePPTX = ({
	width, height,
}: IPropsSvgIcon) => (
	<svg width={width || 30} height={height || 36} viewBox="0 0 20 26" fill="none">
		<path d="M.334 1.3333C.334.597.931 0 1.6673 0H13.334l8.6667 8.6667v16c0 .7363-.597 1.3333-1.3334 1.3333h-19C.931 26 .334 25.403.334 24.6667V1.3333Z" fill="#E65400" />
		<path d="M22.0007 8.6667h-6.5c-1.1967 0-2.1667-.97-2.1667-2.1667V0l8.6667 8.6667Z" fill="#CF1C00" />
		<path d="M5.1992 20.0417v-4.7272h1.865c.3586 0 .664.0684.9165.2054.2523.1354.4447.3239.577.5655.1339.2401.2008.5171.2008.831 0 .3139-.0677.5909-.2031.8309-.1354.2401-.3316.4271-.5886.5609-.2554.1339-.5648.2009-.928.2009H5.8502v-.801h1.0272c.1924 0 .3509-.0331.4755-.0993.1262-.0677.22-.1608.2816-.2793.0631-.12.0946-.2577.0946-.4131 0-.157-.0315-.2939-.0946-.4109-.0615-.1185-.1554-.21-.2816-.2747-.1262-.0661-.2862-.0992-.4801-.0992h-.674v3.9101h-.9995ZM9.4077 20.0417v-4.7272h1.8651c.3585 0 .664.0684.9163.2054.2524.1354.4448.3239.5771.5655.1339.2401.2008.5171.2008.831 0 .3139-.0677.5909-.2031.8309-.1354.2401-.3316.4271-.5886.5609-.2555.1339-.5648.2009-.9279.2009h-1.1888v-.801h1.0272c.1924 0 .3509-.0331.4755-.0993.1262-.0677.2201-.1608.2816-.2793.0631-.12.0946-.2577.0946-.4131 0-.157-.0315-.2939-.0946-.4109-.0615-.1185-.1554-.21-.2816-.2747-.1262-.0661-.2862-.0992-.4801-.0992h-.674v3.9101h-.9995ZM13.4362 16.1385v-.824h3.8824v.824h-1.4472v3.9032h-.988v-3.9032h-1.4472Z" fill="#fff" />
	</svg>
);