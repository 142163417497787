import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const callbackIcon = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 20} height={height || 20} fill="none" viewBox="0 0 20 20">
			<circle cx="10" cy="10" r="10" fill={fill || theme.color.primary.dark} />
			<path
				d="M15.0727 12.791L12.5372 11.2697C12.1629 11.0451 11.6796 11.1762 11.469 11.5563L10.9547 12.4841C10.925 12.5376 10.8573 12.557 10.8044 12.5265C9.4812 11.7635 8.24282 10.5266 7.4778 9.20283C7.44746 9.15036 7.46627 9.08262 7.51966 9.05267L8.44869 8.53128C8.82712 8.31891 8.95498 7.83701 8.73176 7.46494L7.20927 4.92737C6.99482 4.56996 6.52667 4.44858 6.16564 4.65688C4.98536 5.33766 5.05615 5.29172 5.0135 5.33406C3.573 6.76362 4.66715 10.0299 7.31874 12.6816C9.9704 15.3333 13.2373 16.4265 14.666 14.9869C14.7086 14.9439 14.6632 15.0136 15.3431 13.8347C15.5514 13.4737 15.4301 13.0055 15.0727 12.791Z"
				fill="white"
			/>
			<path
				d="M15.5285 5.78988L14.2101 4.47152C14.0814 4.34283 13.8727 4.34283 13.7441 4.47152L11.6481 6.56758L10.9254 5.84481C10.7292 5.64862 10.3922 5.76881 10.3644 6.04479L10.0019 9.63732C9.9811 9.84294 10.153 10.0173 10.3596 9.99863L13.985 9.66904C14.263 9.64375 14.3853 9.30483 14.1882 9.10775L13.4325 8.35205L15.5285 6.25599C15.6572 6.12729 15.6572 5.9186 15.5285 5.78988Z"
				fill="white"
			/>
		</svg>
	);
};