import React, { useEffect, useState } from 'react';

import Select from '../../atomic-design-components/molecules/Select/Select';
import { getSelectOptions, getSelectValue } from '../../utils/select';
import { IDummyData } from '../../types';

interface IProps {
	field: IDummyData;
	formSubmit?: IDummyData;
	initialValue: string;
	isDisabled?: boolean;
	maxLength?: number;
	tooltipText?: string;
	isHidden?: boolean;
	isSubmitOnBlur?: boolean;
	labelKey?: string;
	lng: string;
	name: string;
	optionsData: IDummyData;
	optionsFromFormValues?: IDummyData;
	t: (key: string) => string;
	updateSelect: (name: string, value: IDummyData) => IDummyData;
	value: IDummyData;
	optionsArray?: IDummyData;
}

const FormSelect = (props: IProps) => {
	const {
		field,
		formSubmit,
		tooltipText,
		initialValue,
		isDisabled,
		isHidden,
		isSubmitOnBlur,
		labelKey = 'label',
		lng,
		name,
		optionsData,
		optionsFromFormValues,
		t,
		maxLength,
		updateSelect,
		value,
		optionsArray,
	} = props;

	const [options, setOptions] = useState([]);

	const fieldLabelKey = field.labelKey || labelKey;

	useEffect(() => {
		const filteredOptions = (
			optionsFromFormValues
      || optionsArray
			// @ts-ignore
      || getSelectOptions(field, optionsData, lng)
		)?.filter((option: {
			state: string;
		}) => option.state !== 'deleted');

		setOptions(filteredOptions);
	}, [field, lng, optionsData, optionsArray, optionsFromFormValues]);

	const val = getSelectValue(value, options, field);

	const onCreateOption = (newValue: string) => {
		if (field.isCreatable && !field.getOptionsFromFormValues) {
			const newOptions = field.isMulti
				// @ts-ignore
				? [...options, ...val, newValue]
				: [...options, newValue];
			// @ts-ignore
			setOptions(newOptions);
		}

		// @ts-ignore
		updateSelect(name, field.onSelectValueChange)(field.isMulti ? [...val, newValue] : newValue);

		if (isSubmitOnBlur && formSubmit) {
			// @ts-ignore
			formSubmit({}, { [name]: field.isMulti ? [...val, newValue] : newValue });
		}
	};

	const onChange = (newValue: string) => {
		if (field.onChange) {
			field.onChange(newValue);
		} else {
			updateSelect(name, field.onSelectValueChange)(newValue);
		}

		if (isSubmitOnBlur && formSubmit) {
			formSubmit({}, { [name]: newValue });
		}
	};

	return (
		<Select
			{...props}
			{...field}
			key={val}
			value={val}
			tooltipText={tooltipText}
			maxLength={maxLength}
			onChange={onChange}
			onCreateOption={onCreateOption}
			createLabelText={t(field.createLabelText) || t('create')}
			isDisabled={isDisabled || (field.disabledWhenNotEmpty && initialValue !== '') || !options}
			autosize={field.autosize}
			isMulti={field.isMulti || false}
			isCreatable={field.isCreatable || false}
			isClearable={typeof field.isClearable === 'undefined' && field.isMulti ? true : field.isClearable}
			noOptionsMessage={t('nothingFound')}
			getOptionBeforeTextComponent={field.getOptionBeforeTextComponent}
			customGetOptionLabel={field.customGetOptionLabel}
			labelKey={fieldLabelKey || 'label'}
			valueKey="id"
			options={options}
			t={t}
			lng={lng}
			iconLeftProps={field.iconLeftProps}
		>
			{field.additionToInput}
			{!isHidden
				&& field.addElementToSelect
				&& field.addElementToSelect(updateSelect, t, field.disabled)}
		</Select>
	);
};

export default FormSelect;