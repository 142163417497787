import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import clsx from 'clsx';
import { useTimeout } from '../../../hooks/useTimeout';
import Icon, { IIconProps } from '../../atoms/Icon/Icon';
import Typography from '../../atoms/Typography/Typography';
import { StyledPopupAlert } from './styled';

const MAP_TYPE_TO_COLOR = {
	default: 'general.black',
	new: 'status.new',
	success: 'status.success',
	warning: 'status.warning',
	error: 'status.error',
};

const PopupAlert = ({
	children,
	className,
	content,
	iconName,
	id,
	onClose,
	onCloseCustom,
	position = 'top',
	timeout,
	type = 'default',
	withCloseButton,
	index,
	iconProps,
}: {
	children?: React.ReactNode;
	className?: string;
	content?: string;
	iconName?: string;
	id?: string;
	onClose: (id: string) => void;
	onCloseCustom?: () => void;
	position?: 'top' | 'bottom';
	timeout?: number;
	type?: 'default' | 'new' | 'success' | 'warning' | 'error';
	withCloseButton?: boolean;
	index: number;
	iconProps?: IIconProps;
}) => {
	const theme = useTheme();
	// @ts-ignore
	const backgroundColor = MAP_TYPE_TO_COLOR[type];
	const splitThemeColor = backgroundColor.split('.');

	const [hide, setHide] = useState(false);
	const isNotClosable = timeout === 0;

	useEffect(() => {
		if (hide) {
			const closeId = setTimeout(() => {
				if (onCloseCustom) onCloseCustom();
				onClose(id || '');
			}, 300);

			return () => {
				clearTimeout(closeId);
			};
		}
	}, [hide]);

	const onPopupClose = () => {
		setHide(true);
	};

	useEffect(() => {
		if (timeout && withCloseButton && index > 3) {
			onPopupClose();
		}
	}, [timeout, withCloseButton, index, onPopupClose]);

	useTimeout(() => {
		if (!isNotClosable) {
			onPopupClose();
		}
	}, timeout);

	return (
		<StyledPopupAlert
			// @ts-ignore
			splitThemeColor={splitThemeColor}
			className={clsx(className, position, type, hide && 'hide')}
		>
			{iconName && (
				<Icon
					name={iconName}
					className="leftIcon"
					fill={theme.color.general.white}
					stroke={theme.color.general.white}
					{...iconProps}
				/>
			)}

			<Typography variant="body2" className="content">{children || content}</Typography>
			{withCloseButton && (
				<Icon
					name="cross"
					className="rightIcon"
					wrapperColor="transparent"
					fill={theme.color.general.white}
					height={18}
					width={18}
					onClick={onPopupClose}
					style={{ marginLeft: 'auto' }}
				/>
			)}
		</StyledPopupAlert>
	);
};

export default PopupAlert;