import React from 'react';
import { IPropsSvgIcon } from '../../types';

export const templatesEmpty = ({
	width, height,
}: IPropsSvgIcon) => (
	<svg width={width || 200} height={height || 200} fill="none" viewBox="0 0 200 200">
		<g clipPath="url(#clip0_12822_286682)">
			<circle cx="100.004" cy="100" r="100" fill="#F5F5F5" />
			<g filter="url(#filter0_d_12822_286682)">
				<rect x="57" y="50" width="86" height="100" rx="10" fill="white" />
			</g>
			<path
				d="M69 92.5C69 91.6716 69.6716 91 70.5 91H95.5C96.3284 91 97 91.6716 97 92.5C97 93.3284 96.3284 94 95.5 94H70.5C69.6716 94 69 93.3284 69 92.5Z"
				fill="#DADADA"
			/>
			<path
				d="M69 74.5C69 73.6716 69.6716 73 70.5 73H81.5C82.3284 73 83 73.6716 83 74.5C83 75.3284 82.3284 76 81.5 76H70.5C69.6716 76 69 75.3284 69 74.5Z"
				fill="#DADADA"
			/>
			<path
				d="M86 74.5C86 73.6716 86.6716 73 87.5 73H112.5C113.328 73 114 73.6716 114 74.5C114 75.3284 113.328 76 112.5 76H87.5C86.6716 76 86 75.3284 86 74.5Z"
				fill="#DADADA"
			/>
			<path
				d="M117 74.5C117 73.6716 117.672 73 118.5 73H129.5C130.328 73 131 73.6716 131 74.5C131 75.3284 130.328 76 129.5 76H118.5C117.672 76 117 75.3284 117 74.5Z"
				fill="#DADADA"
			/>
			<path
				d="M69 80.5C69 79.6716 69.6716 79 70.5 79H95.5C96.3284 79 97 79.6716 97 80.5C97 81.3284 96.3284 82 95.5 82H70.5C69.6716 82 69 81.3284 69 80.5Z"
				fill="#DADADA"
			/>
			<path
				d="M100 80.5C100 79.6716 100.672 79 101.5 79H129.5C130.328 79 131 79.6716 131 80.5C131 81.3284 130.328 82 129.5 82H101.5C100.672 82 100 81.3284 100 80.5Z"
				fill="#DADADA"
			/>
			<path
				d="M69 86.5C69 85.6716 69.6716 85 70.5 85H81.5C82.3284 85 83 85.6716 83 86.5C83 87.3284 82.3284 88 81.5 88H70.5C69.6716 88 69 87.3284 69 86.5Z"
				fill="#DADADA"
			/>
			<path
				d="M86 86.5C86 85.6716 86.6716 85 87.5 85H112.5C113.328 85 114 85.6716 114 86.5C114 87.3284 113.328 88 112.5 88H87.5C86.6716 88 86 87.3284 86 86.5Z"
				fill="#DADADA"
			/>
			<path
				d="M117 86.5C117 85.6716 117.672 85 118.5 85H129.5C130.328 85 131 85.6716 131 86.5C131 87.3284 130.328 88 129.5 88H118.5C117.672 88 117 87.3284 117 86.5Z"
				fill="#DADADA"
			/>
			<path
				d="M100 92.5C100 91.6716 100.672 91 101.5 91H129.5C130.328 91 131 91.6716 131 92.5C131 93.3284 130.328 94 129.5 94H101.5C100.672 94 100 93.3284 100 92.5Z"
				fill="#DADADA"
			/>
			<path
				d="M69 98.5C69 97.6716 69.6716 97 70.5 97H81.5C82.3284 97 83 97.6716 83 98.5C83 99.3284 82.3284 100 81.5 100H70.5C69.6716 100 69 99.3284 69 98.5Z"
				fill="#DADADA"
			/>
			<path
				d="M86 98.5C86 97.6716 86.6716 97 87.5 97H112.5C113.328 97 114 97.6716 114 98.5C114 99.3284 113.328 100 112.5 100H87.5C86.6716 100 86 99.3284 86 98.5Z"
				fill="#DADADA"
			/>
			<path
				d="M117 98.5C117 97.6716 117.672 97 118.5 97H129.5C130.328 97 131 97.6716 131 98.5C131 99.3284 130.328 100 129.5 100H118.5C117.672 100 117 99.3284 117 98.5Z"
				fill="#DADADA"
			/>
			<path
				d="M69 104.5C69 103.672 69.6716 103 70.5 103H95.5C96.3284 103 97 103.672 97 104.5C97 105.328 96.3284 106 95.5 106H70.5C69.6716 106 69 105.328 69 104.5Z"
				fill="#DADADA"
			/>
			<path
				d="M100 104.5C100 103.672 100.672 103 101.5 103H129.5C130.328 103 131 103.672 131 104.5C131 105.328 130.328 106 129.5 106H101.5C100.672 106 100 105.328 100 104.5Z"
				fill="#DADADA"
			/>
			<path
				d="M69 110.5C69 109.672 69.6716 109 70.5 109H81.5C82.3284 109 83 109.672 83 110.5C83 111.328 82.3284 112 81.5 112H70.5C69.6716 112 69 111.328 69 110.5Z"
				fill="#DADADA"
			/>
			<path
				d="M86 110.5C86 109.672 86.6716 109 87.5 109H112.5C113.328 109 114 109.672 114 110.5C114 111.328 113.328 112 112.5 112H87.5C86.6716 112 86 111.328 86 110.5Z"
				fill="#DADADA"
			/>
			<path
				d="M117 110.5C117 109.672 117.672 109 118.5 109H129.5C130.328 109 131 109.672 131 110.5C131 111.328 130.328 112 129.5 112H118.5C117.672 112 117 111.328 117 110.5Z"
				fill="#DADADA"
			/>
			<path
				d="M69 116.5C69 115.672 69.6716 115 70.5 115H95.5C96.3284 115 97 115.672 97 116.5C97 117.328 96.3284 118 95.5 118H70.5C69.6716 118 69 117.328 69 116.5Z"
				fill="#DADADA"
			/>
			<path
				d="M100 116.5C100 115.672 100.672 115 101.5 115H129.5C130.328 115 131 115.672 131 116.5C131 117.328 130.328 118 129.5 118H101.5C100.672 118 100 117.328 100 116.5Z"
				fill="#DADADA"
			/>
			<path
				d="M69 122.5C69 121.672 69.6716 121 70.5 121H81.5C82.3284 121 83 121.672 83 122.5C83 123.328 82.3284 124 81.5 124H70.5C69.6716 124 69 123.328 69 122.5Z"
				fill="#DADADA"
			/>
			<path
				d="M86 122.5C86 121.672 86.6716 121 87.5 121H112.5C113.328 121 114 121.672 114 122.5C114 123.328 113.328 124 112.5 124H87.5C86.6716 124 86 123.328 86 122.5Z"
				fill="#DADADA"
			/>
			<path
				d="M117 122.5C117 121.672 117.672 121 118.5 121H129.5C130.328 121 131 121.672 131 122.5C131 123.328 130.328 124 129.5 124H118.5C117.672 124 117 123.328 117 122.5Z"
				fill="#DADADA"
			/>
			<path
				d="M69 128.5C69 127.672 69.6716 127 70.5 127H95.5C96.3284 127 97 127.672 97 128.5C97 129.328 96.3284 130 95.5 130H70.5C69.6716 130 69 129.328 69 128.5Z"
				fill="#DADADA"
			/>
			<path
				d="M100 128.5C100 127.672 100.672 127 101.5 127H129.5C130.328 127 131 127.672 131 128.5C131 129.328 130.328 130 129.5 130H101.5C100.672 130 100 129.328 100 128.5Z"
				fill="#DADADA"
			/>
			<path
				d="M69 134.5C69 133.672 69.6716 133 70.5 133H81.5C82.3284 133 83 133.672 83 134.5C83 135.328 82.3284 136 81.5 136H70.5C69.6716 136 69 135.328 69 134.5Z"
				fill="#DADADA"
			/>
			<path
				d="M86 134.5C86 133.672 86.6716 133 87.5 133H112.5C113.328 133 114 133.672 114 134.5C114 135.328 113.328 136 112.5 136H87.5C86.6716 136 86 135.328 86 134.5Z"
				fill="#DADADA"
			/>
			<path
				d="M117 134.5C117 133.672 117.672 133 118.5 133H129.5C130.328 133 131 133.672 131 134.5C131 135.328 130.328 136 129.5 136H118.5C117.672 136 117 135.328 117 134.5Z"
				fill="#DADADA"
			/>
			<path
				d="M71 64C69.8954 64 69 64.8954 69 66C69 67.1046 69.8954 68 71 68H95C96.1046 68 97 67.1046 97 66C97 64.8954 96.1046 64 95 64H71Z"
				fill="#2D2D2D"
			/>
			<path
				d="M102 64C100.895 64 100 64.8954 100 66C100 67.1046 100.895 68 102 68H112C113.105 68 114 67.1046 114 66C114 64.8954 113.105 64 112 64H102Z"
				fill="#2D2D2D"
			/>
			<path
				d="M112.72 87.2638C105.701 80.2454 94.2823 80.2454 87.2638 87.2638C80.2454 94.2823 80.2454 105.701 87.2638 112.72C94.2823 119.738 105.701 119.738 112.72 112.72C119.738 105.701 119.738 94.2823 112.72 87.2638Z"
				fill="#9D9D9D"
			/>
			<path
				d="M101.491 107.866C101.491 108.696 100.821 109.366 99.9914 109.366C99.5778 109.366 99.2024 109.198 98.9307 108.927C98.6591 108.655 98.4915 108.28 98.4915 107.866L98.4917 101.491L92.1171 101.492C91.7034 101.492 91.328 101.324 91.0565 101.052C90.7848 100.781 90.6172 100.405 90.6172 99.9917C90.6174 99.1622 91.2876 98.492 92.1171 98.4918L98.4917 98.492L98.4915 92.1173C98.4917 91.2879 99.162 90.6176 99.9914 90.6174C100.821 90.6176 101.491 91.2879 101.491 92.1173L101.491 98.492L107.866 98.4918C108.695 98.492 109.366 99.1622 109.366 99.9917C109.366 100.821 108.695 101.491 107.866 101.492L101.491 101.491L101.491 107.866Z"
				fill="white"
			/>
		</g>
		<defs>
			<filter
				id="filter0_d_12822_286682"
				x="32"
				y="40"
				width="136"
				height="150"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dy="15" />
				<feGaussianBlur stdDeviation="12.5" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12822_286682" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12822_286682" result="shape" />
			</filter>
			<clipPath id="clip0_12822_286682">
				<rect width="200" height="200" fill="white" />
			</clipPath>
		</defs>
	</svg>
);