import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const logout = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 16} height={height || 17} viewBox="0 0 16 17" fill="none">
			<path
				d="M5.42847 9H15.4285"
				stroke={fill || theme.color.general.white}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.1428 6.71429L15.4285 9.00001L13.1428 11.2857"
				stroke={fill || theme.color.general.white}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.3713 14.1314C12.3451 15.2011 11.0223 15.9393 9.57311 16.251C8.12392 16.5628 6.6146 16.4338 5.2393 15.8808C3.86399 15.3277 2.68559 14.3758 1.85567 13.1476C1.02576 11.9194 0.582275 10.4709 0.582275 8.98857C0.582275 7.50623 1.02576 6.05779 1.85567 4.82955C2.68559 3.6013 3.86399 2.64944 5.2393 2.09639C6.6146 1.54334 8.12392 1.41439 9.57311 1.72613C11.0223 2.03788 12.3451 2.77605 13.3713 3.84571"
				stroke={fill || theme.color.general.white}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};