import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import { StyledExpansionPanel, StyledHeaderWrapper } from './styled';
import Icon from '../../atoms/Icon/Icon';
import { getDataObjectFromLocalStorage } from '../../../utils/getDataObjectFromLocalStorage';
import { LocalStorageKeys } from '../../../types';

interface IProps {
	align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
	children: React.ReactNode;
	className?: string;
	disabled?: boolean;
	header: string | React.ReactNode;
	headerIconRightProps?: object;
	initialOpened?: boolean;
	isHidden?: boolean;
	marginBottomWhenOpened?: string;
	panelName?: string;
	withChevronInHeader?: boolean;
}

const ExpansionPanel = (props: IProps) => {
	const {
		className,
		children,
		disabled,
		header,
		headerIconRightProps,
		initialOpened,
		isHidden,
		marginBottomWhenOpened,
		panelName,
		withChevronInHeader = true,
		...otherProps
	} = props;
	const currentPanelStates = getDataObjectFromLocalStorage(LocalStorageKeys.explPanelStates);
	const hasLocalStorageState = panelName && currentPanelStates?.[panelName] !== undefined;

	const [opened, setOpened] = useState<boolean>(
		hasLocalStorageState
			? currentPanelStates?.[panelName]
			: initialOpened,
	);

	useEffect(() => {
		const explPanelStates = panelName
			? { ...currentPanelStates, [panelName]: opened }
			: currentPanelStates;
		localStorage.setItem(LocalStorageKeys.explPanelStates, JSON.stringify(explPanelStates));
	}, [opened]);

	const toggleOpened = () => {
		setOpened((prevValue: boolean) => !prevValue);
	};

	if (isHidden) {
		return (
			<StyledExpansionPanel className={clsx(className, 'expansionPanel')} {...otherProps}>
				{children}
			</StyledExpansionPanel>
		);
	}

	return (
		<StyledExpansionPanel
			marginBottomWhenOpened={marginBottomWhenOpened}
			className={clsx(
				className,
				opened && 'opened',
				'expansionPanel',
				!children || (Array.isArray(children) && !children.length && 'empty'),
			)}
			{...otherProps}
		>
			<StyledHeaderWrapper
				className={clsx(opened && 'opened', disabled && 'disabled', 'panelHeader')}
				onClick={disabled ? undefined : toggleOpened}
			>
				{header}
				{headerIconRightProps && <Icon {...headerIconRightProps} />}
				{withChevronInHeader && <Icon name="chevronDown" size={12} className="headerChevronIcon" />}
			</StyledHeaderWrapper>
			{opened && children}
		</StyledExpansionPanel>
	);
};

export default ExpansionPanel;