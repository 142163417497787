import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import UploadImages from '../../../components/UploadImages/UploadImages';
import UploadedItemsList from '../../../components/UploadImages/UploadedItemsList';
import useAutosizeTextArea from '../../../hooks/useAutosizeTextArea';
import { selectFilesUploaded } from '../../../redux-saga/selectors';
import Button from '../Button/Button';
import { StyledLabel } from '../Input/styled';
import { StyledInput, StyledInputContainer } from './styled';
import { FILE_SIZE_LIMIT } from '../../../constants';

const MessageTextarea = ({
	name,
	label,
	labelType = 'border',
	placeholder,
	disabled,
	required,
	value,
	error,
	onChange,
	initialHeight,
}: {
	name: string;
	label: string;
	labelType?: 'border' | 'top';
	placeholder: string;
	disabled?: boolean;
	required?: boolean;
	value: string;
	error: string;
	onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	initialHeight?: number;
}) => {
	const theme = useTheme();
	const { t } = useTranslation('all');

	const files = useSelector(selectFilesUploaded);
	const [extraPadding, setExtraPadding] = useState(0);
	const [uploadClicked, setUploadClicked] = useState(false);

	const textAreaRef = useRef(null);
	useAutosizeTextArea(textAreaRef.current, value, 500, initialHeight);

	const filesHeight = useRef(null);

	useEffect(() => {
		if (filesHeight.current) {
			// @ts-ignore
			setExtraPadding(filesHeight.current.clientHeight);
		}
	}, [filesHeight, extraPadding, uploadClicked, files]);

	const onUploadClick = () => setUploadClicked((prev) => !prev);

	return (
		<StyledInputContainer>
			{label && labelType === 'border' && (
				<StyledLabel className={clsx(required && 'required', 'label', 'border')}>
					{label?.toLowerCase()}
				</StyledLabel>
			)}
			<StyledInput
				className={clsx('inputWrapper', disabled && 'disabled', !!error && 'hasError')}
				error={error}
				multiline
				fullWidth
				required={required}
				// @ts-ignore
				onChange={onChange}
				value={value}
				inputRef={textAreaRef}
				extraPadding={extraPadding}
				placeholder={t(placeholder)}
				disabled={disabled}
				active
				name={name}
				insideButtons={(
					<Button
						disabled={disabled}
						variant="general"
						iconLeftProps={{
							name: 'attachSign',
							fill: theme.color.general.black,
							className: 'withoutHover',
						}}
						className="attachButton"
					>
						<UploadImages
							isSimpleButton
							maxFiles={10}
							maxSize={FILE_SIZE_LIMIT}
							onHide={onUploadClick}
							chatID="bulkMessage"
						/>
					</Button>
				)}
			>
				<UploadImages
					maxFiles={10}
					maxSize={FILE_SIZE_LIMIT}
					onHide={onUploadClick}
					noClick
					hiddenZone
					dropHeight={extraPadding}
					chatID="bulkMessage"
				/>
			</StyledInput>
			<UploadedItemsList
				chatID="bulkMessage"
				uploadRef={filesHeight}
				onHide={onUploadClick}
				className={clsx(!!error && 'hasError')}
				stateKey="filesUploaded"
			/>
		</StyledInputContainer>
	);
};

export default MessageTextarea;