export const calculateImageHeight = (
	width: number | string,
	height?: number | string,
	type?: string,
): string | number | undefined => {
	if ((!width && !height) || typeof width === 'string') {
		return '';
	}
	if (type === 'square') {
		return width;
	}
	if (type === 'vertical') {
		return Math.round((width * 7) / 6);
	}
	if (type === 'horizontal') {
		return Math.round(width / 1.5);
	}
	return height;
};