import clsx from 'clsx';
import React from 'react';
import { useTheme } from 'styled-components';
import ReactTexty from '../../lib/Texty';

import Icon from '../../atomic-design-components/atoms/Icon/Icon';
import Loader from '../../atomic-design-components/atoms/Loader/Loader';
import Typography from '../../atomic-design-components/atoms/Typography/Typography';
import { formatSizeUnits } from '../../utils/formatMeasureUnits';
import { StyledUploadedItem } from './styled';

const UploadedItem = ({
	error,
	fileName,
	fileSize,
	fnDelete = () => null,
	isDeleted,
	loading,
	saved,
	src,
}: {
	error: boolean;
	fileName: string;
	fileSize: number;
	fnDelete: (src: string) => void;
	isDeleted?: boolean;
	loading: boolean;
	saved?: boolean;
	src: string;
}) => {
	if (!src) {
		return null;
	}
	const theme = useTheme();
	const transformedSize = formatSizeUnits(fileSize);
	const fileNameOnly = fileName.split('.')[0];
	const fileType = `.${fileName.split('.')[1]}`;

	return (
		<StyledUploadedItem className={clsx(error && 'error', saved && 'saved', isDeleted && 'isDeleted')}>
			<Typography as={ReactTexty} variant="caption3" text={fileNameOnly} color={theme.color.general.dark} />
			<Typography
				variant="caption3"
				text={fileType}
				color={theme.color.general.dark}
			/>

			<Typography
				variant="caption3"
				text={transformedSize}
				color={theme.color.general.dark}
				className="size"
				style={{ margin: '0 5px' }}
			/>

			<Icon name="cross" size={12} fill={theme.color.general.dark} onClick={() => fnDelete(src)} />
			{loading && <Loader />}
		</StyledUploadedItem>
	);
};

export default UploadedItem;