import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const contactCard = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 17} height={height || 16} fill="none" viewBox="0 0 17 16">
			<path
				d="M15.2898 14.4284H2.71268C2.40958 14.4284 2.11889 14.308 1.90456 14.0937C1.69023 13.8794 1.56982 13.5887 1.56982 13.2856V2.71415C1.56982 2.41104 1.69023 2.12035 1.90456 1.90602C2.11889 1.6917 2.40958 1.57129 2.71268 1.57129H15.2898C15.5929 1.57129 15.8854 1.6917 16.0998 1.90602C16.3141 2.12035 16.4298 2.41104 16.4298 2.71415V13.2856C16.4298 13.5887 16.3141 13.8794 16.0998 14.0937C15.8854 14.308 15.5929 14.4284 15.2898 14.4284Z"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 7H14.2857"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12 9H14.2857"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.99492 8.9498C8.00284 8.9498 8.81992 8.13272 8.81992 7.1248C8.81992 6.11689 8.00284 5.2998 6.99492 5.2998C5.987 5.2998 5.16992 6.11689 5.16992 7.1248C5.16992 8.13272 5.987 8.9498 6.99492 8.9498Z"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 10.8444C9.73002 10.2774 9.30493 9.79861 8.77397 9.46337C8.24302 9.12813 7.62794 8.9502 7 8.9502C6.37206 8.9502 5.75698 9.12813 5.22603 9.46337C4.69507 9.79861 4.26997 10.2774 4 10.8444"
				stroke={fill || theme.color.general.light}
				strokeWidth="1.14286"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};