import React from 'react';
import { IPropsSvgIcon } from '../../types';

export const flagRU = ({
	width, height,
}: IPropsSvgIcon) => (
	<svg width={width || 16} height={height || 12} viewBox="0 0 16 12" fill="none">
		<path fillRule="evenodd" clipRule="evenodd" d="M0 0H16V12H0V0Z" fill="white" />
		<path fillRule="evenodd" clipRule="evenodd" d="M0 4H16V12H0V4Z" fill="#0039A6" />
		<path fillRule="evenodd" clipRule="evenodd" d="M0 8H16V12H0V8Z" fill="#D52B1E" />
	</svg>
);