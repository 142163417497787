import React from 'react';
import styled from 'styled-components';
import { IPropsSvgIcon } from '../../types';

const StyledSvg = styled.svg<IPropsSvgIcon>`
  .cls-1 {
    fill: ${({ theme, fillColor }) => fillColor || theme.color.secondary.darker};
  }

  .cls-2 {
    fill: ${({ theme, fillColor }) => fillColor || theme.color.secondary.lighter};
  }
`;

export const statusTrial = ({
	width, height, fill,
}: IPropsSvgIcon) => (
	<StyledSvg
		width={width || 16}
		height={height || 15.82}
		viewBox="0 0 16 15.82"
		fillColor={fill}
	>
		<g id="Layer_1-2" data-name="Layer 1">
			<path
				className="cls-2"
				d="M14.74,10.95c.43-.94,.69-1.99,.69-3.1C15.43,3.75,12.1,.42,8,.42S.57,3.75,.57,7.85s3.33,7.43,7.43,7.43c1.4,0,2.7-.4,3.82-1.07,.41,.62,1.1,1.03,1.9,1.03,1.26,0,2.27-1.02,2.27-2.27,0-.88-.51-1.64-1.24-2.02Z"
			/>
			<path
				className="cls-1"
				d="M10.73,9.75c.2,.25,.16,.61-.09,.8-.11,.08-.23,.12-.36,.12-.17,0-.33-.07-.45-.21l-2.11-2.64h-3.15c-.32,0-.57-.26-.57-.57s.26-.57,.57-.57h3.43c.17,0,.34,.08,.45,.21l2.29,2.86Zm4.54,1.55c.44,.42,.72,1,.72,1.66,0,1.26-1.03,2.29-2.29,2.29s-2.29-1.03-2.29-2.29,1.03-2.29,2.29-2.29c.19,0,.38,.03,.55,.08,.37-.93,.58-1.94,.59-2.94,0-.31,.26-.57,.57-.57h0c.32,0,.57,.26,.57,.58-.01,1.19-.27,2.38-.72,3.48Zm-.42,1.66c0-.63-.51-1.14-1.14-1.14s-1.14,.51-1.14,1.14,.51,1.14,1.14,1.14,1.14-.51,1.14-1.14Zm-6.86,1.71c-.9,0-1.79-.18-2.62-.52-.84-.35-1.59-.85-2.23-1.49-1.3-1.29-2.01-3.02-2.01-4.85,0-.32-.26-.57-.57-.57s-.57,.26-.57,.57c0,2.14,.83,4.15,2.34,5.66,.75,.75,1.62,1.33,2.6,1.73,.98,.4,2.01,.61,3.06,.61,.32,0,.57-.26,.57-.57s-.26-.57-.57-.57ZM.84,5.61c.07,.03,.14,.04,.21,.04,.23,0,.44-.14,.53-.36,.23-.57,.54-1.11,.92-1.6,.19-.25,.15-.61-.1-.8-.25-.19-.61-.15-.8,.1-.44,.57-.8,1.2-1.07,1.87-.12,.29,.02,.63,.32,.74ZM4.25,1.94c.1,0,.2-.02,.29-.08,.59-.34,1.23-.59,1.9-.74,.31-.07,.5-.37,.44-.68S6.5-.06,6.2,.01c-.79,.17-1.54,.46-2.23,.86-.27,.16-.37,.51-.21,.78,.11,.18,.3,.29,.5,.29Zm10.17,3.34c.09,.22,.3,.36,.53,.36,.07,0,.14-.01,.21-.04,.29-.12,.44-.45,.32-.74-.27-.67-.63-1.3-1.07-1.87-.19-.25-.55-.29-.8-.1-.25,.19-.29,.55-.1,.8,.38,.49,.68,1.02,.92,1.6ZM9.56,1.13c.67,.15,1.31,.39,1.9,.74,.09,.05,.19,.08,.29,.08,.2,0,.39-.1,.5-.29,.16-.27,.06-.62-.21-.78-.7-.4-1.45-.69-2.23-.86-.3-.07-.61,.13-.68,.44s.13,.61,.44,.68Z"
			/>
		</g>
	</StyledSvg>
);