import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const typeBubble = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 36} height={height || 36} viewBox="0 0 36 36" fill="none">
			<path
				d="M11.0872 18.0006C11.7815 18.0006 12.3444 17.4378 12.3444 16.7435C12.3444 16.0492 11.7815 15.4863 11.0872 15.4863C10.3929 15.4863 9.83008 16.0492 9.83008 16.7435C9.83008 17.4378 10.3929 18.0006 11.0872 18.0006Z"
				fill={fill || theme.color.general.black}
				stroke={fill || theme.color.general.black}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19.2591 18.0006C19.9534 18.0006 20.5162 17.4378 20.5162 16.7435C20.5162 16.0492 19.9534 15.4863 19.2591 15.4863C18.5648 15.4863 18.002 16.0492 18.002 16.7435C18.002 17.4378 18.5648 18.0006 19.2591 18.0006Z"
				fill={fill || theme.color.general.black}
				stroke={fill || theme.color.general.black}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M27.429 18.0006C28.1233 18.0006 28.6862 17.4378 28.6862 16.7435C28.6862 16.0492 28.1233 15.4863 27.429 15.4863C26.7347 15.4863 26.1719 16.0492 26.1719 16.7435C26.1719 17.4378 26.7347 18.0006 27.429 18.0006Z"
				fill={fill || theme.color.general.black}
				stroke={fill || theme.color.general.black}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.7153 31.8296L1.6582 34.3439L4.17249 26.8011V4.17249C4.17249 3.50566 4.43739 2.86614 4.90891 2.39462C5.38043 1.9231 6.01994 1.6582 6.68677 1.6582H31.8296C32.4965 1.6582 33.136 1.9231 33.6075 2.39462C34.079 2.86614 34.3439 3.50566 34.3439 4.17249V29.3153C34.3439 29.9822 34.079 30.6217 33.6075 31.0932C33.136 31.5647 32.4965 31.8296 31.8296 31.8296H11.7153Z"
				stroke={fill || theme.color.general.black}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};