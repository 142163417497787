import { findAll } from 'highlight-words-core';
import { FILE_TYPES } from '../constants';
import { IFileUploaded } from '../types';

export const getFileType = (fileName: string) => {
	const fileNameParts = fileName.split('.');
	const extension = fileNameParts[fileNameParts.length - 1];
	const types = {
		...FILE_TYPES.images,
		...FILE_TYPES.files,
	};

	return Object.keys(types).find((k) => {
		const values = types[k];

		return values?.find((val) => val === `.${extension}`);
	});
};

const getFileTypeFromName = (name: string) => name?.split('.')?.pop()?.toLowerCase();

export const getFiles = (
	files: IFileUploaded[] | undefined,
	types: Record<string, string[]>,
) => {
	if (!files || !Array.isArray(files)) {
		return;
	}

	return files?.filter((file) => (file.type
		? Object.keys(types).includes(file.type)
		: Object.values(types)
			.flat()
			.includes(`.${getFileTypeFromName(file.file_name)}`)));
};

export const prepareMessageText = (text?: string, searchValue?: string) => {
	let currText = text || '';

	const btnRegex = /<btn>\[[^\]]+]\(https?:\/\/[^)]+\)/g;
	const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

	const matcher = text?.match(btnRegex);

	if (matcher) {
		matcher.forEach((button) => {
			const [, urlBtn] = button
				.replace('<btn>[', '')
				.replace(')', '')
				.split('](');

			currText = currText.replace(button, (`${urlBtn}\n`));
		});
	}

	currText = currText.replace(urlRegex, (url: string, b: string, c: string) => {
		const url2 = c === 'www.' ? `http://${url}` : url;
		return `<a href="${url2}" target="_blank" class="noStyles link">${url}</a>`;
	});

	const chunks = findAll({
		caseSensitive: false,
		searchWords: [searchValue || ''],
		textToHighlight: currText,
		autoEscape: true,
	});

	return chunks
		.map((chunk: {
			end: number;
			highlight: boolean;
			start: number;
		}) => {
			const { end, highlight, start } = chunk;
			const tagsComponents = currText?.match(/<[^>]+>/g);
			let tagsIndex;
			const highText = currText?.substr(start, end - start);
			if (tagsComponents && tagsComponents?.length > 0) {
				tagsIndex = tagsComponents.map((tag: string) => {
					const startIndex = currText?.indexOf(tag);
					const endIndex = startIndex + tag.length;
					return start >= startIndex && endIndex >= end;
				});
			}

			const hasNoTag = tagsIndex ? !tagsIndex.includes(true) : true;
			if (highlight && hasNoTag) {
				return `<mark class="highlightedText">${highText}</mark>`;
			}
			return highText;
		})
		.join('');
};