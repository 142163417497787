import styled from 'styled-components';

export const StyledLoaderCircular = styled.div<{
	size?: string | number;
	top?: string;
	left?: string;
  loaderBg?: string;
	color?: string;
	withOverlay?: boolean;
  borderWidth?: string;
  position?: string;
}>`
  position: ${({ position }) => (position || 'relative')};
  z-index: 2;
  width: ${({ size }) => (typeof size === 'number' ? `${size}px` : size) || '16px'};
  height: ${({ size }) => (typeof size === 'number' ? `${size}px` : size) || '16px'};
  align-self: center;
  ${({ withOverlay }) => withOverlay && '\'top: 49vh;\''}
  ${({ top }) => top && `top: ${top};`}
  ${({ left }) => left && `left: ${left};`}

  .loaderBg {
    width: 100%;
    height: 100%;
    border: ${({ borderWidth }) => (typeof borderWidth === 'number' ? `${borderWidth}px` : borderWidth) || '3px'} solid white;
    border-color: ${({ loaderBg }) => (loaderBg || '#c4c4c4')};
    border-radius: 50%;
    box-sizing: border-box;
  }

  .spinnerHolder {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 50%;
    overflow: hidden;
    background: transparent;
    transform-origin: 100% 100%;
    box-sizing: border-box;
  }

  .loaderSpinner1 {
    width: 200%;
    height: 200%;
    border: ${({ borderWidth }) => (typeof borderWidth === 'number' ? `${borderWidth}px` : borderWidth) || '3px'} solid ${({ color }) => color};
    border-radius: 50%;
    box-sizing: border-box;
  }

  .loaderSpinner2 {
    width: 200%;
    height: 200%;
    border: ${({ borderWidth }) => (typeof borderWidth === 'number' ? `${borderWidth}px` : borderWidth) || '3px'} solid ${({ color }) => color};
    border-radius: 50%;
    box-sizing: border-box;
  }

  @keyframes round-1 {

    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes round-2 {

    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(-360deg);
    }
  }

  .animate1 {
    animation: round-1 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .animate2 {
    animation: round-2 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
`;