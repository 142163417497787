import { useEffect, useLayoutEffect, useRef } from 'react';

export function useTimeout(
	callback: () => void,
	delay?: number,
	deps = [] as unknown[],
	useLayout = false,
) {
	const savedCallback = useRef(callback);

	// Remember the latest callback if it changes.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	const useEffectFunction = useLayout ? useLayoutEffect : useEffect;

	// Set up the timeout.
	useEffectFunction(() => {
		// Don't schedule if no delay is specified.
		if (delay === null) {
			return;
		}

		const id = setTimeout(() => savedCallback.current(), delay);

		return () => clearTimeout(id);
	}, [delay, ...deps]);
}