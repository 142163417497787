import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from 'styled-components';
import Checkbox from '../../atomic-design-components/molecules/Checkbox/Checkbox';
import DatePicker from '../../atomic-design-components/organisms/DatePicker/DatePicker';
import Label from '../../atomic-design-components/atoms/Label/Label';
import Switch from '../../atomic-design-components/molecules/Switch/Switch';
import MessageTextarea from '../../atomic-design-components/molecules/MessageTextarea/MessageTextarea';
import FormInput from './FormInput';
import FormSelect from './FormSelect';
import { IDummyData } from '../../types';

const FormField = ({
	field,
	formError,
	formErrors,
	formFlexDirection,
	formSubmit,
	formValue,
	initialValue,
	isDisabled,
	isHidden,
	isSubmitOnBlur,
	isTabs,
	labelKey,
	labelType,
	name,
	optionsData,
	optionsFromFormValues,
	sidebarFormId,
	submitByEnterPressed,
	updateCheckbox,
	updateInput,
	updateSelect,
	validateField,
	validationRules,
}: {
	field: Record<string, IDummyData>;
	formError: string;
	formErrors: Record<string, unknown>;
	formFlexDirection: string;
	formSubmit: (e: React.FormEvent<HTMLFormElement>, values: Record<string, unknown>) => void;
	formValue: IDummyData;
	initialValue: string;
	isDisabled: boolean;
	isHidden: boolean;
	isSubmitOnBlur: boolean;
	isTabs: boolean;
	labelKey: string;
	labelType: string;
	name: string;
	optionsData: Record<string, unknown>;
	optionsFromFormValues: Record<string, unknown>;
	sidebarFormId: string;
	submitByEnterPressed: boolean;
	updateCheckbox: (name: string, value: boolean) => void;
	updateInput: IDummyData;
	updateSelect: IDummyData;
	validateField?: (name: string, value: string) => void;
	validationRules: IDummyData;
}) => {
	const theme = useTheme();
	const { t, i18n } = useTranslation(['all', 'validation']);
	const lng = i18n.language;

	const isSelect = field.component === 'dropdown';

	const disabled = field.disabled || field.isDisabled || isDisabled;

	const valueNormalized = typeof formValue === 'number' ? formValue.toString(10) : formValue;
	const value = (field.transformValue
		? field.transformValue(valueNormalized, lng)
		: valueNormalized)
    || field.defaultValue
    || '';

	const labelTranslation = field.label && (i18n.exists(field.label, { ns: 'all' }) ? t(field.label) : t(`table:${field.label}`));

	if (field.iconLeftProps && !field.iconLeftProps.fill) {
		field.iconLeftProps.fill = theme.color.general.light;
	}

	useEffect(() => {
		// TODO: fix for units in item content and stock items warehouse_id
		if (
			field.defaultValue
      && (formValue?.id !== field.defaultValue?.id || formValue !== field.defaultValue)
		) {
			if (isSelect) {
				updateSelect(field.key)(field.defaultValue);
			} else {
				updateInput({ target: { name: field.key, value: field.defaultValue } });
			}
		}
	}, []);

	const getTOptions = (formError: string) => {
		if (field.validationTranslationKeys) {
			return field.validationTranslationKeys.map(t).join(', ');
		}
		if (field.validationLimitValue) {
			if (typeof field.validationLimitValue === 'number') {
				return field.validationLimitValue;
			}
			return field.validationLimitValue.find(
				(value: Record<string, number>) => value[formError],
			)[formError];
		}
		return {};
	};

	const commonSelectAndInputProps = {
		className: clsx('formInput', isHidden && 'isHidden', field.className),
		disabled,
		tooltipText: field.tooltipText,
		error:
			formError && formError !== 'success' && t(
				`validation:${formError}`,
				{ fields: getTOptions(formError) },
			),
		fullWidth: true,
		field,
		formSubmit,
		initialValue: initialValue || field.initialValue || '',
		isDisabled: disabled,
		isHidden,
		isSubmitOnBlur,
		maxLength: field.maxLength,
		label: labelTranslation,
		labelType: field.labelType || labelType,
		labelWidth: '100px',
		name,
		placeholder:
			field.placeholder
				&& (i18n.exists(field.placeholder, { ns: 'all' })
					? t(field.placeholder)
					: t(`table:${field.placeholder}`)),
		primaryFocusColor: true,
		required: isTabs
			? field.required && validationRules[name]?.includes('required')
			: field.required || false,
		success: formError === 'success',
		t,
		type: field.type,
		withBorder: true,
		value,
		variant: field.variant,
		validateField,
	};

	if (field.component === 'checkbox') {
		return (
			<Checkbox
				{...field}
				disabled={disabled}
				name={name}
				value={field.value}
				checked={formValue}
				// @ts-ignore
				variant={field.variant || 'primary'}
				label={labelTranslation}
				handleChange={field.onChange || updateCheckbox}
				labelType={field.labelType || 'body2'}
				className="formCheckbox"
				key="formCheckbox"
			/>
		);
	}

	if (field.component === 'switch') {
		return (
			<Switch
				{...field}
				disabled={disabled}
				name={name}
				checked={formValue}
				label={labelTranslation}
				labelColor={theme.color.general.dark}
				onChange={field.onChange || updateCheckbox}
				key="switch"
			/>
		);
	}

	if (field.component === 'date') {
		return (
			<DatePicker
				onChange={(date) => {
					updateInput({ target: { name, value: date } }, field.onInputValueChange);
				}}
				minDate={field.minDate}
				withIcon
				{...commonSelectAndInputProps}
				key="date"
			/>
		);
	}

	if (field.component === 'messageTextarea') {
		return (
			// @ts-ignore
			<MessageTextarea
				{...field}
				{...commonSelectAndInputProps}
				onChange={(e) => updateInput(e)}
				name={field.key}
				key="messageTextarea"
			/>
		);
	}

	if (typeof field.component === 'function') {
		return (
			<div className={clsx(formFlexDirection, field.className, isHidden && 'isHidden')}>
				{!field.labelTopHidden && labelTranslation && <Label text={labelTranslation} />}
				{field.component({
					...field,
					...commonSelectAndInputProps,
					errors: formErrors,
					formSubmit,
					isSubmitOnBlur,
					lng,
					optionsData,
					sidebarFormId,
					labelKey,
					optionsFromFormValues,
					updateSelect,
					updateInput,
					updateCheckbox,
				})}
			</div>
		);
	}

	return (
		field.component === 'dropdown' ? (
			<FormSelect
				{...commonSelectAndInputProps}
				labelKey={labelKey}
				lng={lng}
				optionsData={optionsData}
				optionsFromFormValues={optionsFromFormValues}
				updateSelect={updateSelect}
			/>
		) : (
			// @ts-ignore
			<FormInput
				submitByEnterPressed={submitByEnterPressed}
				updateInput={updateInput}
				{...commonSelectAndInputProps}
			/>
		)
	);
};

export default FormField;