import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const paginationArrow = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg width={width || 22} height={height || 22} viewBox="0 0 22 22" fill="none">
			<g clipPath="url(#clip0_9581_50868)">
				<path
					d="M15.6796 1.34302L6.5425 10.4802C6.47143 10.5469 6.41479 10.6274 6.37608 10.7168C6.33736 10.8063 6.31738 10.9027 6.31738 11.0002C6.31738 11.0976 6.33736 11.194 6.37608 11.2835C6.41479 11.3729 6.47143 11.4535 6.5425 11.5202L15.6796 20.6573"
					stroke={fill || theme.color.general.light}
					strokeWidth="1.14286"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_9581_50868">
					<rect
						width="20.8"
						height="20.8"
						fill={theme.color.general.white}
						transform="translate(0.599609 0.600098)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};