import React from 'react';
import { useTheme } from 'styled-components';
import { IPropsSvgIcon } from '../../types';

export const typeBriefcaseWidget = ({
	width, height, fill,
}: IPropsSvgIcon) => {
	const theme = useTheme();
	return (
		<svg
			id="Layer_2"
			data-name="Layer 2"
			width={width || 56}
			height={height || 56}
			viewBox="0 0 56 56"
			fill="none"
		>
			<defs>
				<style>
					{`.cls-1 {
            fill: ${theme.color.general.white};
          }

          .cls-2 {
            fill: ${fill || theme.color.primary.main};
          }`}
				</style>
			</defs>
			<g id="Layer_1-2" data-name="Layer 1">
				<path
					className="cls-2"
					d="m1.06,11.77C1.8,6.19,6.19,1.8,11.77,1.06,22.54-.35,33.46-.35,44.23,1.06c5.58.73,9.98,5.13,10.71,10.71,1.42,10.77,1.42,21.68,0,32.45-.73,5.58-5.13,9.98-10.71,10.71-10.77,1.42-21.68,1.42-32.45,0-5.58-.73-9.98-5.13-10.71-10.71C-.35,33.46-.35,22.54,1.06,11.77Z"
				/>
				<path
					className="cls-1"
					d="m40.57,18.29h-4.78c-.22-1.72-.99-3.31-2.23-4.56-1.48-1.48-3.46-2.3-5.56-2.3s-4.07.82-5.56,2.3c-1.25,1.25-2.01,2.84-2.23,4.56h-4.78c-1.81,0-3.29,1.47-3.29,3.29v18c0,1.81,1.47,3.29,3.29,3.29h25.14c1.81,0,3.29-1.47,3.29-3.29v-18c0-1.81-1.47-3.29-3.29-3.29Zm-16.71-3.14c1.11-1.11,2.58-1.72,4.14-1.72s3.04.61,4.14,1.72c.87.87,1.42,1.96,1.63,3.14h-11.53c.2-1.18.76-2.28,1.63-3.14Zm-8.43,5.14h25.14c.71,0,1.29.58,1.29,1.29v7.86h-12.86v-1.29c0-.55-.45-1-1-1s-1,.45-1,1v1.29h-12.86v-7.86c0-.71.58-1.29,1.29-1.29Zm25.14,20.57H15.43c-.71,0-1.29-.58-1.29-1.29v-8.14h12.86v1.29c0,.55.45,1,1,1s1-.45,1-1v-1.29h12.86v8.14c0,.71-.58,1.29-1.29,1.29Z"
				/>
			</g>
		</svg>
	);
};