import styled from 'styled-components';
import { getCssFromTheme } from '../../../utils/css';

export const StyledTabs = styled.div<{
	variant: string;
}>`
  .rts___tabs___container {
    width: 100%;
    padding: 0;
    border-bottom: ${({ theme }) => `1px solid ${theme.color.general.lighter}`};

    * {
      ${({ variant, theme }) => getCssFromTheme(theme, 'typography', `${variant}`)};
    }

    .rts___tabs {
      padding: 0 5px;
    }

    .rts___nav___btn {
      padding: 0;
      border: none;

      &:disabled.rts___btn svg path {
        stroke: ${({ theme }) => theme.color.general.light};
      }

      &.left-nav-btn {
        margin-right: 10px;
      }

      &.right-nav-btn {
        margin-left: 10px;

        svg {
          transform: rotate(180deg);
        }
      }

      &:hover {
        background: none;
        transition: none;
      }
    }

    .rts___tab {
      margin: 0;
      padding: 10px 20px;
      border: none;
      border-bottom: 2px solid transparent;
      border-radius: 0;
      background: none;
      color: ${({ theme }) => theme.color.general.darker};
      box-shadow: none;

      &.rts___tab___selected {
        border-bottom: ${({ theme }) => `2px solid ${theme.color.primary.main}`};
      }
    }
  }
`;