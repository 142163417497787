import styled from 'styled-components';

export const StyledDropZone = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: ${({ theme }) => `1px dashed ${theme.color.general.light}`};
  border-radius: 3px;
  cursor: pointer;
  gap: 10px;
  outline: none;
  transition: border-color 0.24s ease-in-out;
`;

export const StyledDropButton = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 32px;
  height: 26px;
`;