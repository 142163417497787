import {
	createContext, useCallback, useEffect, useState,
} from 'react';
import { DefaultTheme } from 'styled-components';
import { useWidth } from './useWidth';

interface IScreenContext {
	width: number;
	height: number;
	currentBreakpoint: string;
	xs: number;
	sm: number;
	md: number;
	lg: number;
	xl: number;
	xxl: number;
}

export const ScreenContext = createContext<IScreenContext>({
	width: 0,
	height: 0,
	currentBreakpoint: 'xs',
	xs: 0,
	sm: 0,
	md: 0,
	lg: 0,
	xl: 0,
	xxl: 0,
});

export const useScreenContext = (theme: DefaultTheme): {
	width: number;
	height: number;
	currentBreakpoint: string;
	xs: number;
	sm: number;
	md: number;
	lg: number;
	xl: number;
	xxl: number;
} => {
	const isClient = typeof window !== 'undefined';

	const width = useWidth();
	const { breakpoints } = theme;

	const getHeight = () => (isClient ? document.documentElement.clientHeight : undefined);
	const height = getHeight();

	const getCurrentBreakpoint = useCallback(
		(setCurrentBreakpoint?: (a: string) => void) => {
			// eslint-disable-next-line no-restricted-syntax
			for (const [key, value] of Object.entries(breakpoints)) {
				if (width && width >= value) {
					if (setCurrentBreakpoint) {
						setCurrentBreakpoint(key);
					} else {
						return key;
					}
					break;
				} else if (width && width < breakpoints.sm) {
					if (setCurrentBreakpoint) {
						setCurrentBreakpoint('xs');
					} else {
						return 'xs';
					}
				}
			}
		},
		[theme, width],
	);

	const [currentBreakpoint, setCurrentBreakpoint] = useState(getCurrentBreakpoint);

	useEffect(() => {
		getCurrentBreakpoint(setCurrentBreakpoint);
	}, [getCurrentBreakpoint]);

	return {
		// @ts-ignore
		width, currentBreakpoint, ...breakpoints, height,
	};
};