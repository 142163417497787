import clsx from 'clsx';
import React from 'react';
import { useTheme } from 'styled-components';

import { isObjectEmpty } from '../../../utils/isObjectEmpty';
import Icon, { IIconProps } from '../../atoms/Icon/Icon';
import Typography from '../../atoms/Typography/Typography';
import {
	StyledErrorLabel, StyledInputWrapper, StyledLabel, StyledWrapper,
} from '../Input/styled';
import { StyledInput } from './styled';

interface IProps {
	mask: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	value: string;
	className?: string;
	withoutValidation?: boolean;
	required?: boolean;
	label?: string;
	labelType?: 'border' | 'top';
	ref?: React.RefObject<HTMLInputElement>;
	fullWidth?: boolean;
	type?: string;
	disabled?: boolean;
	error?: string | boolean;
	field?: {
		isUppercase: boolean;
	};
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	name?: string;
	placeholder?: string;
	iconLeftProps?: IIconProps;
	formatChars?: {
		[formatChar: string]: RegExp;
	};
}

const TimeInputWithMask = (props: IProps) => {
	const {
		mask,
		onChange,
		value,
		className,
		withoutValidation,
		required,
		label,
		labelType = 'border',
		ref,
		fullWidth,
		disabled,
		error,
		field,
		onBlur,
		name,
		placeholder,
		iconLeftProps,
		formatChars,
	} = props;
	const theme = useTheme();
	const hasLeftIcon = iconLeftProps && !isObjectEmpty(iconLeftProps);
	const errorColor = error ? theme.color.status.error : theme.color.general.light;

	return (
		<StyledInputWrapper
			className={clsx(className, withoutValidation && 'withoutValidation', 'inputContainer')}
		>
			{label && labelType === 'top' && (
				<StyledLabel
					as={Typography}
					variant="caption1"
					className={clsx(required && 'required', 'label', 'top')}
				>
					{label}
				</StyledLabel>
			)}
			<StyledWrapper
				ref={ref}
				fullWidth={fullWidth}
				className={clsx('inputWrapper', disabled && 'disabled', !!error && 'hasError')}
			>
				{label && labelType === 'border' && (
					<StyledLabel
						className={clsx(required && 'required', 'label', 'border')}
						isUppercase={field?.isUppercase}
					>
						{label?.toLowerCase()}
					</StyledLabel>
				)}
				{!!iconLeftProps && (
					<Icon
						width={16}
						height={16}
						className="leftIcon"
						fill={disabled ? theme.color.general.lighter : errorColor}
						{...iconLeftProps}
					/>
				)}
				<StyledInput
					mask={mask}
					// @ts-ignore
					formatChars={formatChars}
					onChange={onChange}
					value={value}
					placeholder={placeholder}
					className={clsx(!!error && 'hasError', hasLeftIcon && 'hasLeftIcon')}
					name={name}
					onBlur={onBlur}
				/>
			</StyledWrapper>
			{!!error && typeof error === 'string' && (
				<StyledErrorLabel icon={<Icon name="inputError" size={13} />} className="error">
					{error}
				</StyledErrorLabel>
			)}
		</StyledInputWrapper>
	);
};

export default TimeInputWithMask;