import api, { API_VERSION_V1, API_VERSION_V2 } from '../apiV2';
import { ChatStatus, IApiSchema } from '../types';

export const getManagerChats = async (params: {
	status: ChatStatus;
	operator_id?: number;
	operators?: boolean;
	from_time?: string;
	to_time?: string;
	query?: string;
	limit?: number;
	offset?: number;
}) => {
	const response = await api.get<IApiSchema['LimitOffsetPage_ChatBrief_']>(
		`${API_VERSION_V2}/manager/chats`,
		{ params },
	);

	return response.data;
};

export const getManagerChatById = async (params: {
	chat_id: number;
}) => {
	const response = await api.get<IApiSchema['ChatBrief']>(
		`${API_VERSION_V2}/manager/chats/${params.chat_id}`,
	);

	return response.data;
};

export const getContactsOnline = async () => {
	const response = await api.get<number[]>(
		`${API_VERSION_V1}/chat/contacts_online`,
	);

	return response.data;
};

export const getManagerChatsEvents = async (params: {
	chat_id: number;
	query?: string;
	limit?: number;
	offset?: number;
}) => {
	const response = await api.get<IApiSchema['LimitOffsetPage_IChatEventBrief_']>(`${API_VERSION_V2}/manager/chats/${params.chat_id}/events`, { params });

	return response.data;
};