import styled from 'styled-components';
import { getScreenHeight } from '../../utils/getScreenHeight';

export const StyledWrapper = styled.div`
  display: flex;
  min-height: 100vh;

  .footerText {
    max-width: 454px;
    margin: 0 auto;
    text-align: center;
    white-space: pre-wrap;

    .typography {
      padding: 0;
    }
  }

  .mainWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    padding: 0 80px;

    h1.text {
      width: 100%;
    }

    .inscriptionWrapper {
      margin-top: 20px;

      .typography {
        margin-top: 0;
      }

      .inline {
        display: inline;
      }
    }

    form {

      button {
        margin-top: 20px;
      }
    }
  }

  .link {
    color: ${({ theme: { color } }) => color.primary.main};
    text-decoration: none;
  }

  .introHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .icon {
    height: fit-content;
  }

  @media only screen and (width <= 1139px) {

    .mainWrapper {
      padding-top: 60px;
    }

    .introHeader {
      justify-content: center;
      align-items: center;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {

    .mainWrapper {
      width: 100%;
      padding: 30px 15px;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    min-height: ${getScreenHeight()}px;

    .mainWrapper {
      width: 100%;
      padding: 30px;
      overflow-y: auto;
    }

    .introHeader {
      justify-content: space-between;
    }

    .skibbleTextLogo {
      margin-left: 0;
    }
  }
`;

export const StyledIntroWrapper = styled.div<{
	url: string;
	color: string;
}>`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 50%;
  min-height: 100vh;
  padding: 60px;
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  color: ${({ color }) => color};
`;

export const StyledWindow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 454px;
  margin-top: -60px;
  flex-grow: 1;

  &.signUp {
    margin-top: 0;
  }

  &.requestSent,
  &.passwordRecoverySent {

    .typography,
    .inscriptionWrapper {
      text-align: center;
    }
  }
  
  .form {
    margin-top: 30px;
  }

  .formBlock {
    padding: 0;
  }

  @media only screen and (width <= 1139px) {
    justify-content: start;
    margin-top: 0;

    .contentWrapper {
      margin: auto 0;
    }

    &.signUp .contentWrapper {
      padding-top: 30px;
    }
  }
`;

export const StyledFooterText = styled.div`
  padding: 20px 20px 60px;
  text-align: center;

  span,
  a {
    color: ${({ theme: { color } }) => color.general.black};
    text-decoration: none;
  }

  a:hover {
    opacity: 0.8;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 20px 0 0;

    .typography,
    a {
      color: ${({ theme: { color } }) => color.general.black};
    }
  }
`;
export const StyledLine = styled.hr`
  height: 1px;
  margin: 10px 0;
  border-width: 0;
  background-color: ${({ theme }) => theme.color.general.lighter};
  color: ${({ theme }) => theme.color.general.lighter};
`;

export const StyledTextWrapper = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 60px 60px 0;
  white-space: pre-wrap;

  .inline {
    display: inline;
  }

  a,
  a:visited {
    color: ${({ theme: { color } }) => color.secondary.main};
    text-decoration: none;
  }

  .skibbleTextLogo {
    margin: 0 auto 0 20px;
  }

  hr {
    margin: 30px 0;
  }

  .headerWrapper {
    display: flex;
    justify-content: space-between;
  }

  .react-select__control {

    .leftIcon path {
      stroke: ${({ theme: { color } }) => color.general.black};
    }

    .chevronDown path {
      fill: ${({ theme: { color } }) => color.general.black};
    }

    .react-select__value-container {
      padding: 10px 7px 10px 15px;
      text-transform: uppercase;
    }

    .react-select__indicators {
      padding-right: 12px;

      .react-select__indicator {
        padding: 0;
      }
    }
  }

  .react-select__value-container .react-select__single-value {
    color: ${({ theme: { color } }) => color.general.black};
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    padding: 40px 15px;
  }
`;