import { pick } from 'dot-object';

import { LANGUAGES_FULL_NAME, TLanguages } from '../locales';

export const transformStringSelectValue = (
	value: unknown,
	labelKey?: string,
	lng?: TLanguages,
	valueKey?: string,
) => {
	if (typeof value === 'object') {
		return value;
	}

	return {
		[valueKey || 'id']: value,
		// @ts-ignore
		[labelKey || 'label']: value[lng] || value,
	};
};

export const getSelectLanguageValue = (lngKey: TLanguages) => {
	const name = LANGUAGES_FULL_NAME[lngKey];

	return {
		id: lngKey,
		label: name,
	};
};

export const getSelectOptions = (
	field: {
		options?: unknown[];
		optionsKeys?: string[];
		getOptions?: (optionsData: Record<string, unknown>) => unknown[];
		valueKey?: string;
		labelKey?: string;
		key: string;
	},
	optionsData: unknown,
	lng: TLanguages,
) => {
	if (!field.options && !field.optionsKeys && !field.getOptions) {
		return;
	}
	const valueKey = field.valueKey || 'id';
	const labelKey = field.labelKey || 'label';

	// @ts-ignore
	const getOptionsArr = field.getOptions && field.getOptions(optionsData);

	const optionsArr = getOptionsArr
		|| field.options
		|| field.optionsKeys
			// @ts-ignore
			?.reduce((acc: string[], curr: string) => {
				// @ts-ignore
				const rawOptions = optionsData[curr] || {};
				const options = Object.values(rawOptions);

				return [...acc, ...options];
			}, []);
	// @ts-ignore
	return optionsArr[0]?.[valueKey] && optionsArr[0]?.[labelKey]
		? optionsArr
		// @ts-ignore
		: optionsArr.map((option: never) => (field?.key?.toLowerCase().includes('language')
			? getSelectLanguageValue(option)
			: transformStringSelectValue(option, field?.labelKey || '', lng)));
};

export const getSelectValue = (
	value: unknown,
	options: Record<string, string>[],
	field: {
		labelKey: string;
		valueKey: string;
	},
) => {
	if (!value) {
		return '';
	}

	if (!options) {
		return value;
	}
	const labelKey = field.labelKey || 'label';
	const valueKey = field.valueKey || 'id';

	if (Array.isArray(value)) {
		return value
			.map((oneValue) => options
				.find((option) => option[valueKey] === (oneValue[valueKey] || oneValue)))
			?.filter((val) => val);
	}
	if (typeof value === 'string' || typeof value === 'number') {
		return options
			.find((option) => option[valueKey] === value);
	}
	if (!pick(labelKey, value)) {
		// @ts-ignore
		return options.find((option) => option[valueKey] === (value[valueKey] || value));
	}

	return value;
};