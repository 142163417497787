import { IDummyData } from '../types';

export const DEFAULT_RIGHT_PANEL_DATA: Record<string, IDummyData> = {
	'/members/invite': {
		email: '',
	},
	'/my-company/departments/new': {
		name: '',
		members: [],
	},
};

export const DEFAULT_TABLE_SORTING = {
	contacts: { fullName: 'asc' },
	customers: { status: 'asc', created: 'desc' },
	departments: { status: 'asc', name: 'asc' },
	members: { status: 'asc', role: 'asc', fullName: 'asc' },
	widgets: { status: 'asc', name: 'asc' },
	billingPlans: { status: 'asc', planTitle: 'asc' },
	bulkMessaging: { status: 'asc', name: 'asc' },
};

export const DEFAULT_WIDGET_DATA = {
	name: '',
	status: 'active',
	install_status: 'not_installed',
	license_id: '',
	channels: {
		live_chat: {
			status: 'inactive',
		},
		callback: {
			status: 'inactive',
		},
		telegram: {
			token: '',
			bot_name: '',
			status: 'inactive',
		},
		viber: {
			token: '',
			bot_name: '',
			status: 'inactive',
		},
		facebook: {
			page_name: '',
			status: 'inactive',
		},
		instagram: {
			page_name: '',
			status: 'inactive',
		},
		whatsapp: {
			page_name: '',
			status: 'inactive',
		},
	},
};

const defaultPlanValue = {
	id: '',
	is_custom: true,
	titles: { uk: '' },
	prices: { price_monthly: { UAH: '' }, price_annually: { UAH: '' } },
	features: { is_branding: true, is_customization: true, is_support: false },
};

export const defaultBulkMessaging = {
	name: '',
	tags: [],
	channels: [],
	is_scheduled: false,
	scheduled_at: new Date(),
	status: 'draft',
	message: '',
	files: [],
};

export const defaultKnowledgeBaseArticle = {
	status: '',
	folder: '',
	widgets: [],
	translations: {
		titles: { uk: '' },
		texts: { uk: '' },
	}, //
};

export const defaultKnowledgeBaseFolder = {
	created_by: null,
	id: null,
	name: '',
	status: '',
	widget_license_id_list: [],
};

export const DEFAULT_VALUES_DATA: Record<string, unknown> = {
	subscriptionPlans: defaultPlanValue,
	bulkMessaging: defaultBulkMessaging,
	knowledgeBaseFolder: defaultKnowledgeBaseFolder,
	knowledgeBaseArticle: defaultKnowledgeBaseArticle,
};