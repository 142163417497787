import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledResultsBlock } from '../styled';
import FlexRow from '../../../atomic-design-components/atoms/FlexRow/FlexRow';
import Link from '../../../atomic-design-components/atoms/Link/Link';
import Typography from '../../../atomic-design-components/atoms/Typography/Typography';

interface IProps {
	value?: {
		success?: never[];
		failure?: {
			chat_id: string;
			name: string;
			id: string;
			channel: string;
		}[]
	};
}

const ResultsBlock = ({ value }: IProps) => {
	const { t } = useTranslation('all');
	const errors = value?.failure || [];
	return (
		<StyledResultsBlock>
			<Typography text={t('bulkSuccess', { messagesCount: value?.success?.length || 0 })} variant="button1" />
			{!!errors.length && (
				<>
					<Typography text={t('bulkErrors', { messagesCount: errors.length })} variant="button1" />
					<FlexRow alignItems="start" flexWrap="wrap" gap="7px" flexDirection="column">
						{errors.map((errorInfo) => (
							<Link
								key={errorInfo?.chat_id}
								text={errorInfo?.name || `${t('guest')} #${errorInfo?.id || 1}`}
								to={`/chats/${errorInfo?.chat_id}`}
								iconName={`${errorInfo?.channel}Icon`}
								iconPosition="left"
								iconProps={{ size: 16 }}
							/>
						))}
					</FlexRow>
				</>
			)}
		</StyledResultsBlock>
	);
};

export default ResultsBlock;