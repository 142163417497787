import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

interface IProps {
	title: string;
	content?: string;
	children?: React.ReactNode;
}

const ReactHelmet = ({ title, content, children }: IProps) => {
	const { t } = useTranslation('meta');

	return (
		<Helmet>
			{content ? (
				<meta name="description" content={t(content)} />
			) : null}
			<title>{t(title)}</title>
			{children}
		</Helmet>
	);
};

export default ReactHelmet;